import React from "react";
import { BsFillBellFill } from "@react-icons/all-files/bs/BsFillBellFill";

import { Drawer, DrawerBody, DrawerContent, DrawerOverlay } from "Organisms";
import { CartProduct, MenuBundleProduct, MenuProduct, PotentialComboDiscount } from "Types";
import { Box, Grid, GridArea } from "Atoms";
import { bellStyles } from "./DiscountNotification";
import { useDiscounts, usePos, useQopla } from "Providers";
import { productCardAction } from "Utils";
import { getPriceText } from "../../utils/priceUtils";

type Props = {
    openDrawer: boolean;
    onCloseDrawer: () => void;
    discount: PotentialComboDiscount;
    cartProducts: CartProduct[];
    increment: (cartProduct: CartProduct) => void;
    setUpsellDiscountId: (discountId: string, productId: string) => void;
};

type ProductTileProps = {
    menuProduct: any;
    onConfirm: () => void;
};

const productTileStyling = {
    background: "linear-gradient(90deg, rgba(240, 240, 240, 0.9) 25%, rgba(253,252,253,1) 85%);",
    borderRadius: "1rem",
    border: "0.4rem solid white",
    boxShadow: "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
    padding: "2rem",
    maxWidth: "250px"
};

export const ProductTile: React.FC<ProductTileProps> = ({ menuProduct, onConfirm }) => {
    const getPrice = getPriceText(menuProduct);
    const refProduct = menuProduct?.refProduct || menuProduct.refBundleProduct;

    return (
        <Box {...productTileStyling}>
            <Grid templateRows="1fr 1fr" justifyItems="center" alignItems="center" onClick={onConfirm} gap="1rem">
                <Box as="h3" margin={0} textAlign="center">
                    {refProduct.name}
                </Box>
                <Box as="h4" margin={0}>
                    {getPrice}
                </Box>
            </Grid>
        </Box>
    );
};

export const DiscountDrawer: React.FC<Props> = ({ openDrawer, onCloseDrawer, discount, ...rest }) => {
    const { selectedShop } = useQopla();
    const shopId = selectedShop!.id;
    const { addOrderProductToCart, addBundleProductToCart, outOfStockIds } = usePos();
    const {
        potentialUpsell: { controlSetForcedUpsellCheck }
    } = useDiscounts();

    const notificationText = `Köp ${discount.comboCategories[0].limit} för ${discount.staticPrice} kr`;
    const menuProducts = discount.upsellMenuProducts;

    const menuProductsInStock = menuProducts?.reduce(
        (productsInStock: (MenuProduct | MenuBundleProduct)[], product: any) => {
            const refProduct = product.refProduct || product.refBundleProduct;
            if (!outOfStockIds.has(refProduct.id)) {
                productsInStock = [...productsInStock, product];
            }

            return productsInStock;
        },
        []
    );

    const onAddMenuProductToCart = (menuProduct: any) => {
        const product = menuProduct.refProduct || menuProduct.refBundleProduct;

        const menuProductInCart = rest.cartProducts.reduce((cartProduct: CartProduct, cart: CartProduct) => {
            const { orderProduct } = cart;
            const cartProductId = orderProduct.refProductId || orderProduct.refBundleProductId;
            if (cartProductId === product.id) {
                return cart;
            }
            return cartProduct;
        }, {} as CartProduct);

        if (!!menuProductInCart.id) {
            rest.increment(menuProductInCart);
        } else {
            productCardAction(
                menuProduct,
                {
                    addBundleProductToCart,
                    addOrderProductToCart
                },
                shopId,
                product.refProductCategoryId,
                () => {},
                false,
                () => {},
                () => {}
            );
        }
        rest.setUpsellDiscountId(discount.id!, product.id);
        controlSetForcedUpsellCheck(discount.id!, false);
        onCloseDrawer();
    };

    return (
        <Drawer open={openDrawer} onClose={onCloseDrawer} size="xs" duration={4}>
            <DrawerOverlay />
            <DrawerContent bg="white" overflow="auto">
                <DrawerBody>
                    <Grid
                        height="auto"
                        templateColumns="auto 1fr"
                        templateRows="repeat(2, 1fr)"
                        alignItems="center"
                        justifyItems="center"
                        columnGap="2.5rem"
                        gap="2.5rem"
                        templateAreas={`". ." "text text"`}
                        style={{ marginBottom: "2rem" }}
                    >
                        <Box ml={4} {...bellStyles} textAlign="center" alignItems="center">
                            <Box as={BsFillBellFill} size="1.3rem" color="green.500" />
                        </Box>
                        <Box marginRight="auto">
                            <Box as="h4">{discount.name}</Box>
                        </Box>
                        <GridArea name="text">
                            <Box as="h3">{notificationText}</Box>
                        </GridArea>
                    </Grid>

                    <Grid templateColumns="1fr" height="auto" gap="1rem">
                        {menuProductsInStock?.map((menuProduct: MenuProduct | MenuBundleProduct) => {
                            return (
                                <ProductTile
                                    menuProduct={menuProduct}
                                    key={menuProduct.id}
                                    onConfirm={() => onAddMenuProductToCart(menuProduct)}
                                />
                            );
                        })}
                    </Grid>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};
