import React from "react";
import { Dimmer } from "semantic-ui-react";
import { useNavigate, useParams } from "react-router-dom";

import { useOnline, EatingPreference, useLanguage } from "Providers";
import { Flex, NewButton, Box, ScrollToTop, Text } from "Atoms";
import { formatSwedishFinancialNumbers, LoadingMessage, roundNumber } from "TempUtils";
import {
    calculateDeliveryFee,
    getCartTotalPrice,
    getExpressOnlineCheckoutUrl,
    hasPickupTimeSessionExpired,
    isCartFinished
} from "Utils";
import { OnlineCustomerCartList, OnlineHeader, OnlineText } from "OnlineComponents";
import { OnlineUpsellModal } from "OnlineComponents";
import { getCartTotalOriginalPrice } from "../../../../../admin/components/poses/pos/utils";
import { calculateOrderWindowTotalDiscount } from "../../../../../admin/components/poses/pos/utils/paymentHelpers";
import { LanguageHeader } from "Components";
import { SetOnlineProductModalState } from "OnlineTypes";
import { dimExpress, getOnlineBrowseOnlyInfo } from "OnlineUtils";
import { ExpressOnlineOrderStickyCartHeader } from "./components/ExpressOnlineOrderStickyCartHeader";
import { useShouldClearOnlineCart } from "Hooks";
import { useOrderWindowsStore, useQoplaStore } from "Stores";
import { OnlineEatingPreference } from "Types";

type Props = {
    upsellCategory: any;
    outOfStockIds: any;
    productsIdsAndQuantity: any;
    shopId: string;
    isCompanyLanding: boolean;
    subdomainName: string;
    openPickupModal: () => void;
    toggleProductModal?: SetOnlineProductModalState;
    handleEatingOptionClick: (eatingSelection: OnlineEatingPreference) => void;
    setUpsellModalState: React.Dispatch<
        React.SetStateAction<{
            open: boolean;
            hasShown: boolean;
        }>
    >;
    isProductCommentsEnabled?: boolean;
    upsellModalState: {
        open: boolean;
        hasShown: boolean;
    };
    eatingOptions: OnlineEatingPreference[];
};

export const ExpressOnlineOrderStickyCart: React.FC<Props> = ({
    toggleProductModal,
    openPickupModal,
    isProductCommentsEnabled,
    handleEatingOptionClick,
    upsellModalState,
    setUpsellModalState,
    eatingOptions,
    isCompanyLanding,
    subdomainName,
    ...rest
}) => {
    const navigate = useNavigate();
    const params = useParams<{ name: string; id: string }>();

    const { getActiveOrderWindow } = useOrderWindowsStore();
    const orderWindow = getActiveOrderWindow();

    const { selectedShop } = useQoplaStore();
    const settings = selectedShop?.settings;

    const {
        foodOptions: { pickupOptions, eatingPreference, deliveryInformation },
        browseMode,
        backendDiff
    } = useOnline();
    const { translate } = useLanguage();
    useShouldClearOnlineCart();

    const minAmountFreeDelivery = settings?.homeDeliverySettings?.minAmountFreeDelivery || 0;
    const minOrderTotalAmount = settings?.homeDeliverySettings?.minOrderTotalAmount || 0;

    const isHomeDelivery = eatingPreference === EatingPreference.HOME_DELIVERY;

    const subTotal = roundNumber(getCartTotalPrice(orderWindow?.cartProducts));
    const addedDeliveryFee = deliveryInformation
        ? calculateDeliveryFee(subTotal, minAmountFreeDelivery, deliveryInformation.fee)
        : 0;

    const totalPrice = subTotal + addedDeliveryFee;
    const hasMinHomeDeliveryAmount = isHomeDelivery && subTotal >= minOrderTotalAmount;

    const renderEmptyCart = !orderWindow?.cartProducts?.length;
    const toCheckoutDisabled = !isCartFinished(orderWindow?.cartProducts) || hasMinHomeDeliveryAmount;

    const headerInfo = getOnlineBrowseOnlyInfo(browseMode);
    const shouldDim = dimExpress(browseMode);

    const expressCheckoutUrl = getExpressOnlineCheckoutUrl({
        shopName: params.name!,
        shopId: params.id!,
        isCompanyLanding,
        subdomainName
    });

    const handleToCart = () => {
        if (hasPickupTimeSessionExpired(pickupOptions, backendDiff)) {
            return;
        }

        const hasUpsellCatergory = !!rest.upsellCategory;
        const hasNotSeenUpsellModal = !upsellModalState.hasShown;
        const shouldOpenUpsellModal = hasUpsellCatergory && hasNotSeenUpsellModal;

        if (shouldOpenUpsellModal) {
            setUpsellModalState({ open: true, hasShown: true });
        } else {
            navigate(expressCheckoutUrl);
        }
    };

    if (!orderWindow) {
        return <LoadingMessage />;
    }

    return (
        <Flex
            direction="column"
            position="relative"
            paddingTop={{ base: 24, xl: 2, "2xl": 0 }}
            paddingBottom={{ base: 6, xl: 2, "2xl": 0 }}
        >
            <Box position="sticky" top={48}>
                <Dimmer.Dimmable dimmed={shouldDim}>
                    <Flex
                        direction="column"
                        boxShadow="0 2px 6px 2px rgba(0,0,0,0.1)"
                        maxHeight="xs"
                        borderRadius="0.75rem"
                        backgroundColor="white"
                        overflow="hidden"
                    >
                        <Box>
                            <ExpressOnlineOrderStickyCartHeader
                                eatingOptions={eatingOptions}
                                handleEatingOptionClick={handleEatingOptionClick}
                            />
                        </Box>
                        <Flex flex="1" overflow="auto" maxHeight="40vh">
                            <OnlineCustomerCartList
                                appliedDiscount={orderWindow.discount}
                                isProductCommentsEnabled={isProductCommentsEnabled}
                            />
                        </Flex>
                        <Flex
                            zIndex={1}
                            direction="column"
                            padding={4}
                            marginTop={4}
                            boxShadow="0 0 15px rgba(0,0,0,0.1)"
                            borderTop="2px solid #CBD5E0"
                        >
                            {orderWindow.discount && (
                                <>
                                    <Flex justify="space-between">
                                        <OnlineText m="0">{translate("originalPrice")}.</OnlineText>
                                        <OnlineText m="0">
                                            {formatSwedishFinancialNumbers(
                                                getCartTotalOriginalPrice(orderWindow?.cartProducts)
                                            )}
                                        </OnlineText>
                                    </Flex>
                                    <Flex justify="space-between">
                                        <OnlineText m="0" color="red.500" fontWeight={600}>
                                            {`${translate("discount")} (${
                                                orderWindow.discount.name.includes("%")
                                                    ? orderWindow.discount.name
                                                    : `${orderWindow.discount.name}
                                            ${orderWindow.discount.rate.toString()}%`
                                            })
                                        `}
                                        </OnlineText>
                                        <OnlineText m="0" color="red.500" fontWeight={600}>
                                            {formatSwedishFinancialNumbers(
                                                calculateOrderWindowTotalDiscount(orderWindow?.cartProducts)
                                            )}
                                        </OnlineText>
                                    </Flex>
                                </>
                            )}
                            <Flex justify="space-between">
                                <OnlineHeader size="lg" color="gray.900" m="0">
                                    {translate("total")}
                                </OnlineHeader>
                                <OnlineHeader size="lg" color="gray.900" m="0">
                                    {formatSwedishFinancialNumbers(totalPrice)}
                                </OnlineHeader>
                            </Flex>
                            {!renderEmptyCart && (
                                <NewButton
                                    mt={6}
                                    backgroundColor="newPrimary"
                                    themeColor="green"
                                    color="newPrimaryFont"
                                    size="xl"
                                    fullWidth
                                    onClick={handleToCart}
                                    disabled={toCheckoutDisabled}
                                >
                                    {translate("pay")}
                                </NewButton>
                            )}
                        </Flex>
                    </Flex>
                    {upsellModalState.open && (
                        <OnlineUpsellModal
                            {...rest}
                            onClose={() => setUpsellModalState({ open: false, hasShown: true })}
                            onContinuePress={() => navigate(expressCheckoutUrl)}
                        />
                    )}
                    <Dimmer active={shouldDim}>
                        {headerInfo && <LanguageHeader tid={headerInfo.titleKey} as="h3" size="lg" color="white" />}
                    </Dimmer>
                </Dimmer.Dimmable>
                <Text px={"1rem"} pt={"2rem"} color="gray.600" textAlign={"center"}>
                    {translate("allergyText")}
                </Text>
            </Box>
            <ScrollToTop
                size="2xl"
                offset={800}
                themeColor="blue"
                position={{ base: "absolute", "2xl": "sticky" }}
                top={{ base: "0", "2xl": "87%" }}
                right={{ base: "0", "2xl": "initial" }}
                paddingLeft="0"
                paddingRight="0"
                width="fit-content"
                mx="auto"
                rounded="lg"
                className="animate-online-express-scroll-to-top"
            />
        </Flex>
    );
};
