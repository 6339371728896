import moment from "moment";
import { deviceDetect } from "react-device-detect";

import {
    Shop,
    WebOrderType,
    EatingOption,
    OrderWindow,
    SubscriptionMeta,
    UserSubscriptions,
    DISCOUNT_TYPE,
    UserGiftCard,
    TableMeta,
    Table,
    WebOrderDTOInput,
    OrderInvoiceData,
    OnlineEatingPreference,
    FoodOptions,
    DeliveryInformation,
    EatingPreference
} from "Types";
import { isEatingPreferenceCatering, ILanguages, ClientInformationType } from "Providers";
import { PaymentMethod, timeConstants } from "Constants";
import { getCartTotalPrice, convertCartProductsToOrderProducts, calculateDeliveryFee } from "Utils";
import { OnlineCheckoutFormValues } from "../components/OnlineCheckoutForm";
import { formatDateToBackendUTCString, roundNumber } from "../../../../admin/utils/TextFormat";
import {
    discountToDiscountMeta,
    getGiftCardMeta,
    setAllDiscountsToDiscountMeta
} from "../../../../admin/components/poses/pos/utils/paymentHelpers";

const takeAwayEatingPreferences = [
    EatingPreference.CATERING_DELIVERY,
    EatingPreference.CATERING_TAKE_AWAY,
    EatingPreference.TAKE_AWAY,
    EatingPreference.HOME_DELIVERY
];
const deliveryWebOrderTypes = [WebOrderType.CATERING_DELIVERY, WebOrderType.HOME_DELIVERY];

const getPickupTime = ({ pickupOptions }: FoodOptions) => {
    if (pickupOptions.time !== timeConstants.ASAP) {
        return formatDateToBackendUTCString(moment(`${pickupOptions.date} ${pickupOptions.time}`));
    }
    return null;
};

export const convertCheckoutFormValuesToContactInformation = (
    values: OnlineCheckoutFormValues
): WebOrderDTOInput["contactInformation"] => {
    const baseContactInformation: WebOrderDTOInput["contactInformation"] = {
        name: values.firstName,
        email: values.email,
        phoneNumber: values.phoneNumber,
        addressLine: values.addressLine,
        postCode: values.postCode,
        city: values.city,
        phoneNumber2: values.phoneNumber2,
        floorNumber: values.floorNumber,
        doorCode: values.doorCode
    };

    return baseContactInformation;
};

const getContactInformation = (
    values: OnlineCheckoutFormValues,
    { eatingPreference, deliveryInformation }: FoodOptions,
    isThirdPartyDelivery: boolean
): WebOrderDTOInput["contactInformation"] => {
    const baseContactInformation = convertCheckoutFormValuesToContactInformation(values);

    if (eatingPreference === EatingPreference.HOME_DELIVERY) {
        return {
            ...baseContactInformation,
            latLng: isThirdPartyDelivery ? deliveryInformation!.latLng : undefined
        };
    }
    return baseContactInformation;
};

const getInvoiceData = (values: OnlineCheckoutFormValues): OrderInvoiceData | null => {
    if (values.paymentInformation.paymentMethod !== PaymentMethod.INVOICE) return null;

    const {
        invoiceData: {
            organisationNumber,
            invoiceAddress: { tmpUseSameAsAbove, ...invoiceAddressToUse }
        }
    } = values;

    const baseInvoiceData = {
        organisationNumber,
        contactName: values.firstName,
        invoiceAddress: invoiceAddressToUse
    };

    if (tmpUseSameAsAbove) {
        return {
            ...baseInvoiceData,
            invoiceAddress: {
                addressLine: values.addressLine,
                postCode: values.postCode,
                city: values.city,
                name: values.invoiceData.invoiceAddress.name
            }
        };
    } else {
        return baseInvoiceData;
    }
};

const getPaymentInformation = (values: OnlineCheckoutFormValues) => {
    const basePaymentInformation = { paymentMethod: values.paymentInformation.paymentMethod };
    const {
        paymentInformation: { paymentMethod }
    } = values;

    if (paymentMethod === PaymentMethod.SWISH) {
        return {
            ...basePaymentInformation
        };
    } else {
        return basePaymentInformation;
    }
};

const takeAway = (eatingPreference: EatingPreference) => takeAwayEatingPreferences.includes(eatingPreference);
const homeDelivery = (eatingPreference: EatingPreference) => eatingPreference === EatingPreference.HOME_DELIVERY;

export const getWebOrderType = (eatingPreference: OnlineEatingPreference): WebOrderType => {
    if (eatingPreference === EatingPreference.CATERING_DELIVERY) {
        return WebOrderType.CATERING_DELIVERY;
    } else if (eatingPreference === EatingPreference.CATERING_TAKE_AWAY) {
        return WebOrderType.CATERING_TAKE_AWAY;
    } else if (eatingPreference === EatingPreference.HOME_DELIVERY) {
        return WebOrderType.HOME_DELIVERY;
    } else {
        return WebOrderType.BASIC;
    }
};

const getEatingOption = (eatingPreference: OnlineEatingPreference) => {
    if (isEatingPreferenceCatering(eatingPreference)) {
        return EatingOption.CATERING;
    } else if (eatingPreference === EatingPreference.TAKE_AWAY) {
        return EatingOption.TAKE_AWAY;
    } else if (eatingPreference === EatingPreference.HOME_DELIVERY) {
        return EatingOption.HOME_DELIVERY;
    } else {
        return EatingOption.EAT_HERE;
    }
};

const getThirdPartyDeliveryType = (webOrderType: WebOrderType, deliveryInformation: DeliveryInformation | null) => {
    const isDelivery = deliveryWebOrderTypes.includes(webOrderType);
    if (!isDelivery) {
        return null;
    }

    if (deliveryInformation?.thirdPartyDelivery) {
        return deliveryInformation?.thirdPartyDelivery;
    }

    return null;
};

export const getSubscriptionMeta = (
    orderWindow: OrderWindow,
    userSubscriptions: UserSubscriptions
): SubscriptionMeta | null => {
    const { fixedDiscounts } = orderWindow;

    const subscriptionDiscount = fixedDiscounts?.find(
        discount => discount.type === DISCOUNT_TYPE.SUBSCRIPTION_DISCOUNT
    );
    const userSubscription = userSubscriptions.find(
        userSubscription => userSubscription.subscription.id === subscriptionDiscount?.id
    );

    if (userSubscription && subscriptionDiscount) {
        return {
            userSubscriptionId: userSubscription.id,
            name: userSubscription.subscription.name,
            subscriptionId: subscriptionDiscount.id
        };
    }

    return null;
};

const getTableMeta = (table: Table | null, shopId: string): TableMeta | null => {
    if (table) {
        return {
            id: table.id as string,
            name: table.name,
            shopId
        };
    }

    return null;
};

export const buildWebOrderInput = (
    values: OnlineCheckoutFormValues,
    orderWindow: OrderWindow,
    foodOptions: FoodOptions,
    shop: Shop,
    table: Table | null,
    isThirdPartyDelivery: boolean,
    userLanguage: ILanguages | null,
    userAccountId: string | null,
    qr: any,
    clientInformation: ClientInformationType,
    userSubscriptions: UserSubscriptions,
    userGiftCard: UserGiftCard | null,
    posId: string | null | undefined
): WebOrderDTOInput => {
    const {
        settings: { homeDeliverySettings }
    } = shop;
    const { cateringInformation, deliveryInformation, eatingPreference } = foodOptions;

    const subTotal = roundNumber(getCartTotalPrice(orderWindow?.cartProducts));
    const deliveryFee = deliveryInformation ? deliveryInformation.fee : 0;
    const addedDeliveryFee = calculateDeliveryFee(subTotal, homeDeliverySettings.minAmountFreeDelivery, deliveryFee);
    const timeInterval = deliveryInformation?.timeInterval ?? "";
    const eatingOption = getEatingOption(eatingPreference);

    const isCatering = eatingOption === EatingOption.CATERING;
    const isTakeAway = takeAway(eatingPreference as EatingPreference);
    const isHomeDelivery = homeDelivery(eatingPreference as EatingPreference);
    const hasFixedDiscounts = !!orderWindow.fixedDiscounts?.length;

    const webOrderType = getWebOrderType(eatingPreference);
    const thirdPartyDelivery = getThirdPartyDeliveryType(webOrderType, deliveryInformation);

    const subscriptionMeta = getSubscriptionMeta(orderWindow, userSubscriptions);

    const discountMeta = hasFixedDiscounts
        ? setAllDiscountsToDiscountMeta(orderWindow)
        : discountToDiscountMeta(orderWindow);

    const giftCardMeta = getGiftCardMeta(orderWindow, userGiftCard, discountMeta?.totalDiscountValue, addedDeliveryFee);

    const tableMeta = getTableMeta(table, shop.id);

    return {
        thirdPartyDelivery,
        tableMeta,
        shopId: shop.id,
        comment: values.comment,
        comment2: values.comment2,
        eatingOption,
        cateringConfirmationHours:
            isCatering && cateringInformation ? cateringInformation.earliestHoursToConfirmCatering : null,
        takeAway: isTakeAway || isHomeDelivery,
        homeDelivery: isHomeDelivery,
        timeInterval: timeInterval,
        deliveryFee: addedDeliveryFee,
        paymentInformation: getPaymentInformation(values),
        subscriptionMeta,
        giftCardMeta,
        discountMeta: hasFixedDiscounts
            ? setAllDiscountsToDiscountMeta(orderWindow)
            : discountToDiscountMeta(orderWindow),
        orderProducts: convertCartProductsToOrderProducts(orderWindow?.cartProducts),
        pickupTime: getPickupTime(foodOptions),
        contactInformation: getContactInformation(values, foodOptions, isThirdPartyDelivery),
        invoiceData: getInvoiceData(values),
        deviceInformation: deviceDetect(window.navigator.userAgent),
        webOrderType,
        acceptsMarketing: values.acceptsCampaigns,
        userLanguage,
        userAccountId,
        qr,
        clientInformation,
        tip: values.tip,
        posId
    };
};
