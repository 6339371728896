// remove property from object immutable
export const removeObjsProperty = (obj: any, prop: string) => {
    return Object.keys(obj).reduce((o: any, k: string) => {
        if (k !== prop) {
            o[k] = obj[k];
        }
        return o;
    }, {});
};

export const isObject = <T>(obj: T) => {
    return Object.prototype.toString.call(obj) === "[object Object]";
};

const omitTypename = (key: string, value: any) => (key === "__typename" ? undefined : value);
const omitNullishValues = (_: string, value: any) => (value === null || value === undefined ? undefined : value);

/**
 * Example use:
 * const object = { age: 20, __typename: "Age" }
 * const cleanObject = deepOmitFromObject(object, omitTypename) // { age: 20 }
 */
export const deepOmitFromObject = (value: any, reviver?: (this: any, key: string, value: any) => any) =>
    JSON.parse(JSON.stringify(value), reviver);

export const deepOmitTypename = (value: any) => deepOmitFromObject(value, omitTypename);
export const deepOmitNullishValues = (value: any) => deepOmitFromObject(value, omitNullishValues);

/**
 * Will remove undefined + null from props (another issue that can occur from hash id) 
 * @example omitUndefinedOrNullPropsFromObject<CartProduct>(obj)
 */
export const omitUndefinedOrNullPropsFromObject = <T extends object>(obj: T) =>
    Object.fromEntries(Object.entries(obj).filter(([_, value]) => value !== null && value !== undefined));

/**
 * Will order an object by prop name (used for hash ids) as the order can be mixed up
* @example orderPropsInObjectAlphabetically<CustomerMeta>(obj)
* @example orderPropsInObjectAlphabetically<Partial<CustomerMeta>>(obj)
 */
export const orderPropsInObjectAlphabetically = <T extends object>(obj: T) =>
    Object.fromEntries(Object.entries(obj).sort(([propA], [propB]) => propA.localeCompare(propB))) as T;

/**
 * [FUNCTION] check that string is a valid json
 * @param value
 * @returns true or false
 */
export const isValidJson = (value: string) => {
    try {
        return JSON.parse(value) && !!value;
    } catch {
        return false;
    }
};
