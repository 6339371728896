import { envConstants, frontyEnv } from "Constants";
import { qoplaStore } from "Stores";
import { SelectedCompany, Shop, Table } from "Types";

export const formatShopUrlName = (shopName: string) => {
    return shopName.toLowerCase().replace(/\s+/g, "-").replace(/å|ä/g, "a").replace(/ö/g, "o").replace(/\//g, "%20");
};

export const getOnlineUrl = (shopName: string, publicId: string, shouldAppendOrigin: boolean = true) => {
    const baseUrl = shouldAppendOrigin ? window.location.origin : "";
    return `${baseUrl}/restaurant/${formatShopUrlName(shopName)}/${publicId}`;
};

export const getPickupDisplayMobileUrl = (shop_Id?: string, public_Id?: string) => {
    const { selectedShop } = qoplaStore.getState();
    const publicId = public_Id ? public_Id : selectedShop?.publicId;
    const shopId = shop_Id ? shop_Id : selectedShop?.id;
    return `${window.location.origin}/pickupDisplayMobile/${publicId}/${shopId}`;
};

export const getEndUrl = (fallBackUrl?: string) => {
    if (envConstants.PROFILE === frontyEnv.DEV) {
        return "dev.qopla.com";
    } else if (envConstants.PROFILE === frontyEnv.PROD) {
        return "qopla.com";
    } else {
        return fallBackUrl || "";
    }
};

export const getSubdomainName = (hostname: string): string => {
    const parts = hostname.split(".");
    if (parts.length === 0) {
        return "";
    }
    return parts[0];
};

export const isSubscriptionUrl = (hostname: string): boolean => {
    const subdomainName = getSubdomainName(hostname);
    return subdomainName.startsWith("subscription");
};

export const getCompanyFriendlySubdomainNameFromHostname = (hostname: string, profile: string | undefined) => {
    if (!isSubscriptionUrl(hostname)) {
        return null;
    }
    /**
     *  Something like
     *  - subscription-company
     *  - subscriptions-company
     *  - subscriptions-brod-and-salt
     */
    const subdomainName = getSubdomainName(hostname);

    /**
     * matches should contain two entries on success
     * [0] - the whole string = subscriptions-brod-and-salt
     * [1] - the matched friendlySubdomainName = brod-and-salt
     */
    const matches = /^subscription[s]?-(.+)$/.exec(subdomainName);
    if (matches && matches.length > 1) {
        return matches[1];
    }
    return null;
};

export const navigateToAccountRoute = (isSubscription: boolean) => {
    if (isSubscription) {
        const endUrl = getEndUrl("qopla.com:3000");
        const protocol = window.location.protocol;

        const url = `${protocol}//${endUrl}/account`;

        window.location.assign(url);
    } else {
        window.location.assign("/account");
    }
};

export const navigateToAccountTab = (tab: string) => {
    window.location.assign(`/account?tab=${tab}`);
};

export const navigateToCompanyTab = (tab: string, id: string) => {
    return `/admin/company/${id}?tab=${tab}`;
};

export const navigateToCompanySubscriptionPage = () => {
    const selectedCompany = qoplaStore.getState().selectedCompany;
    const selectedShop = qoplaStore.getState().selectedShop;

    if (!selectedCompany || !selectedShop) {
        return;
    }

    const {
        settings: { companySubscriptionSettings }
    } = selectedCompany as SelectedCompany;

    const endUrl = getEndUrl("qopla.com:3000");
    const protocol = window.location.protocol;
    const url = `${protocol}//subscriptions-${companySubscriptionSettings!.friendlySubdomainName}.${endUrl}?shopId=${
        selectedShop?.id
    }`;
    window.location.assign(url);
};

export const createGroupSubscriptionInvitationLink = (groupSubscriptionId: string): string => {
    const endUrl = getEndUrl("localhost:3000");
    const protocol = window.location.protocol;

    const url = `${protocol}//${endUrl}/subscription-invitation/${groupSubscriptionId}`;

    return url;
};

export const getFortnoxRedirectUri = (url: string) => {
    const cleanUrl = url.replace("www.", "");
    return cleanUrl;
};

export const createGroupOrderUrlForTableAndShopLink = (table: Table, shop: Shop) => {
    return `${window.location.origin}/group-online-order/${table.tableId}/${shop.publicId}/${table.posId!}`;
};

export const createGroupOrderStatusPath = (
    orderId: string,
    tableId: string,
    publicShopId: string,
    posId: string,
    orderWindowId: string
) => {
    return `/order-status/${orderId}/${tableId}/${publicShopId}/${posId}/${orderWindowId}`;
};

type ExpressOnlineCheckoutParams = {
    shopName: string;
    shopId: string;
    isCompanyLanding: boolean;
    subdomainName: string;
};
export const getExpressOnlineCheckoutUrl = (params: ExpressOnlineCheckoutParams) => {
    return `/express/restaurant/${params.shopName}/${params.shopId}/checkout${
        params.isCompanyLanding ? "?clp=" + params.subdomainName : ""
    }`;
};

export type GroupOnlineOrderOverviewParams = {
    tableId: string;
    publicShopId: string;
    posId: string;
    orderWindowId: string;
};
export const getGroupOnlineOrderOverviewUrl = (params: GroupOnlineOrderOverviewParams) => {
    return `/group-online-order/${params.tableId}/${params.publicShopId}/${params.posId}/order-overview/${params.orderWindowId}`;
};

export type GroupOnlineOrderParams = {
    tableId: string;
    publicShopId: string;
    posId: string;
};
export const getGroupOnlineOrderUrl = (params: GroupOnlineOrderParams) => {
    return `/group-online-order/${params.tableId}/${params.publicShopId}/${params.posId}`;
};
