import React from "react";
import { FaRegCircle } from "@react-icons/all-files/fa/FaRegCircle";
import { MdCheckBoxOutlineBlank } from "@react-icons/all-files/md/MdCheckBoxOutlineBlank";

import { CustomRadioButton, Flex, Header, Box, Button, Label } from "Atoms";
import { RadioButtonGroup } from "Molecules";
import { useLanguage } from "Providers";
import { SHAPE, SIMPLE_CHOICE } from "../utils/types";
import { ASPECT_RATIO } from "Types";

type Props = {
    isCircleCrop: boolean;
    aspectRatio?: ASPECT_RATIO | number;
    hasGridLines?: boolean;
    disableAspectRatio?: boolean;
    setAspectRatio: (aspectRatio: ASPECT_RATIO) => void;
    setCirclularCrop: (isCircleCrop: boolean) => void;
    setGridLines: (hasGridLines: boolean) => void;
};

/**
 * [COMPONENT] - Settings and selections for the image crop tool
 * @param param0
 * @returns
 */
export const ImageCropSettings: React.FC<Props> = ({
    setAspectRatio,
    setCirclularCrop,
    setGridLines,
    aspectRatio,
    disableAspectRatio = false,
    isCircleCrop = false,
    hasGridLines = true
}) => {
    const { translate } = useLanguage();

    return (
        <Flex direction="column" width="100%" flex="0" justifyContent="center" alignItems="center">
            <Flex justifyContent="center" alignItems="center">
                <Flex direction="column" mt={4} width="max-content" justifyContent="center" alignItems="center">
                    <Header as="h5" fontSize="1rem">
                        {translate("aspectRatio")}
                    </Header>
                    <RadioButtonGroup
                        activeThemeColor="blue"
                        name="aspectRatio"
                        value={aspectRatio!}
                        isDisabled={disableAspectRatio}
                        onValueChanged={(radioButtonValue: any) => {
                            setAspectRatio(radioButtonValue);
                        }}
                    >
                        <CustomRadioButton
                            value={ASPECT_RATIO["16 / 9"]}
                            borderTopLeftRadius={8}
                            borderBottomLeftRadius={8}
                            px={3}
                        >
                            {"16 / 9"}
                        </CustomRadioButton>
                        <CustomRadioButton value={ASPECT_RATIO["4 / 3"]} px={3}>
                            {"4 / 3"}
                        </CustomRadioButton>
                        <CustomRadioButton value={ASPECT_RATIO["3 / 2"]} px={3}>
                            {"3 / 2"}
                        </CustomRadioButton>
                        <CustomRadioButton value={ASPECT_RATIO["1 / 1"]} px={3}>
                            {"1 / 1"}
                        </CustomRadioButton>
                        <CustomRadioButton
                            value={ASPECT_RATIO.none}
                            borderTopRightRadius={8}
                            borderBottomRightRadius={8}
                            px={3}
                        >
                            {"N/A"}
                        </CustomRadioButton>
                    </RadioButtonGroup>
                </Flex>
                {/*       
                WILL KEEP THIS IN - JUST IN CASE WE WANT IT BACK!!!!
                <Flex ml={2} direction="column" mt={4} width="max-content" justifyContent="center" alignItems="center">
                    <Header as="h5" fontSize="1rem">
                        {translate("shape")}
                    </Header>
                    <RadioButtonGroup
                        activeThemeColor="blue"
                        name="shape"
                        value={isCircleCrop ? SHAPE.CIRCLE : SHAPE.RECTANGLE}
                        onValueChanged={(radioButtonValue: any) => {
                            setCirclularCrop(SHAPE.CIRCLE === radioButtonValue);
                        }}
                    >
                        <CustomRadioButton
                            value={SHAPE.RECTANGLE}
                            borderTopLeftRadius={8}
                            borderBottomLeftRadius={8}
                            px={3}
                        >
                            <Box as={MdCheckBoxOutlineBlank} />
                        </CustomRadioButton>
                        <CustomRadioButton
                            value={SHAPE.CIRCLE}
                            borderTopRightRadius={8}
                            borderBottomRightRadius={8}
                            px={3}
                        >
                            <Box as={FaRegCircle} />
                        </CustomRadioButton>
                    </RadioButtonGroup>
                </Flex> */}
                <Flex ml={2} direction="column" mt={4} width="max-content" justifyContent="center" alignItems="center">
                    <Header as="h5" fontSize="1rem">
                        {translate("gridlines")}
                    </Header>
                    <RadioButtonGroup
                        activeThemeColor="blue"
                        name="shape"
                        value={hasGridLines ? SIMPLE_CHOICE.YES : SIMPLE_CHOICE.NO}
                        onValueChanged={(radioButtonValue: any) => {
                            setGridLines(radioButtonValue === SIMPLE_CHOICE.YES);
                        }}
                    >
                        <CustomRadioButton
                            value={SIMPLE_CHOICE.YES}
                            borderTopLeftRadius={8}
                            borderBottomLeftRadius={8}
                            px={3}
                        >
                            {translate("yes")}
                        </CustomRadioButton>
                        <CustomRadioButton
                            value={SIMPLE_CHOICE.NO}
                            borderTopRightRadius={8}
                            borderBottomRightRadius={8}
                            px={3}
                        >
                            {translate("no")}
                        </CustomRadioButton>
                    </RadioButtonGroup>
                </Flex>
            </Flex>
            {disableAspectRatio ? (
                <Label backgroundColor="none" fontSize="0.8rem">
                    {translate("canStillfreeformCrop")}
                </Label>
            ) : null}
        </Flex>
    );
};
