import { getUniqueNamesFrom } from "./utils";
import { DiscountStatsType, ReportStats, DiscountType } from "./reportTypes";
import { TranslateFunc } from "Providers";

const getValue = (reportStats: DiscountStatsType, discountId: string) => {
    return reportStats[discountId]
        ? {
              name: reportStats[discountId].name,
              code: reportStats[discountId].code,
              quantity: reportStats[discountId].quantity,
              total: reportStats[discountId].total,
              totalNet: reportStats[discountId].totalNet
          }
        : {
              name: null,
              code: "",
              quantity: 0,
              total: 0,
              totalNet: 0
          };
};

export const calculateDiscountReportData = (report: ReportStats, translate: TranslateFunc, shopName?: string) => {
    const discountsPos = report.Pos.discountStats;
    const discountsWeb = report.Web.discountStats;
    const discountsFoodora = report.Foodora.discountStats;
    const discountsWolt = report.Wolt.discountStats;
    const discountsBolt = report.Bolt.discountStats;
    const discountsUberEats = report.UberEats.discountStats;

    const combinedUniqueDiscountIds = getUniqueNamesFrom(
        discountsPos,
        discountsWeb,
        discountsBolt,
        discountsWolt,
        discountsFoodora,
        discountsUberEats
    );
    return combinedUniqueDiscountIds.map((discountId: string) => {
        const currentDiscountPos = getValue(discountsPos, discountId);
        const currentDiscountWeb = getValue(discountsWeb, discountId);
        const currentDiscountFoodora = getValue(discountsFoodora, discountId);
        const currentDiscountWolt = getValue(discountsWolt, discountId);
        const currentDiscountBolt = getValue(discountsBolt, discountId);
        const currentDiscountUberEats = getValue(discountsUberEats, discountId);

        let sources = [];
        let sourcesList = [];
        let result;

        if (!!currentDiscountPos.name) {
            sourcesList.push(translate("cashRegister"));
            sources.push({ ...currentDiscountPos, source: "Kassa" });
        }
        if (!!currentDiscountWeb.name) {
            sourcesList.push(translate("online"));
            sources.push({ ...currentDiscountWeb, source: "Online" });
        }
        if (!!currentDiscountFoodora.name) {
            sourcesList.push("Foodora");
            sources.push({ ...currentDiscountFoodora, source: "Foodora" });
        }
        if (!!currentDiscountWolt.name) {
            sourcesList.push("Wolt");
            sources.push({ ...currentDiscountWolt, source: "Wolt" });
        }
        if (!!currentDiscountBolt.name) {
            sourcesList.push("Bolt");
            sources.push({ ...currentDiscountBolt, source: "Bolt" });
        }
        if (!!currentDiscountUberEats.name) {
            sourcesList.push("UberEats");
            sources.push({ ...currentDiscountUberEats, source: "UberEats" });
        }
        const getDiscountName = [...new Set(sources.map(source => source.name))].toString().replace(/,/g, ", ");
        const getDiscountCode = [...new Set(sources.map(source => source.code))].toString().replace(/,/g, ", ");
        const allCurrent = [
            currentDiscountPos,
            currentDiscountWeb,
            currentDiscountFoodora,
            currentDiscountWolt,
            currentDiscountBolt,
            currentDiscountUberEats
        ];

        const [discountTotal, discountTotalNet, discountQuantity] = allCurrent.reduce(
            ([dTotal, dTotalNet, dQuantity], current) => {
                dTotal += current.total;
                dTotalNet += current.totalNet;
                dQuantity += current.quantity;
                return [dTotal, dTotalNet, dQuantity];
            },
            [0, 0, 0]
        );

        result = {
            key: discountId,
            shopName,
            name: getDiscountName === "Justerat pris" ? translate("adjustedPrice") : getDiscountName,
            code: getDiscountCode,
            quantity: discountQuantity,
            total: discountTotal,
            totalNet: discountTotalNet,
            sourcesList: sourcesList,
            children: sources
        };

        return result;
    });
};

export const calculateTotalSum = (combinedDiscountsList: DiscountType[], isVATIncluded: boolean) => {
    return combinedDiscountsList.reduce((currTotalSum: number, currDiscount: DiscountType) => {
        const currDiscountTotal = isVATIncluded ? currDiscount.total : currDiscount.totalNet;
        return currTotalSum + currDiscountTotal;
    }, 0);
};

export const calculateTotalQuantity = (combinedDiscountsList: DiscountType[], isVATIncluded: boolean) => {
    return combinedDiscountsList.reduce(
        (currTotalQuantity: number, currDiscount: DiscountType) => currTotalQuantity + currDiscount.quantity,
        0
    );
};
