import React, { createContext, useEffect, useRef } from "react";
import { get, } from "lodash";
import { useMutation } from "react-apollo";
import { useLocation } from "react-router-dom";

import { useLocalStorage, useMothershipMutation, } from "Hooks";
import { PersistConstants, paymentTabStatusTypes, LOCAL_STORAGE_CONSTANTS } from "Constants";
import {
    findFixedSubscriptionDiscount,
    recalculatePriceAndDiscount,
    updateCombinedDiscountsAndOtherDiscounts
} from "../../admin/components/poses/shared/utils/cartController";
import { findCartProduct, getPersistedObject } from "Utils";
import {
    getDiscountedCartTotalOriginalPrice,
    getDiscountedCartTotalAdjustedQuantities,
    getDiscountedCartTotalFromCartProducts
} from "../../admin/components/poses/pos/utils/priceUtils";
import { createCartProductHashId, mergeCartProducts } from "./utils/index";
import { SET_ORDER_PAYMENT_STATUS , CREATE_BONG_FROM_ORDERS} from "../../graphql/mutations";
import { handleSetAddonsOnCartProduct, createOpenCartProduct} from "./utils";
import { calculateMenuProductOrBundlePrice } from "PriceUtils";
import { useOrderWindowsStore, usePosStore, useQoplaStore } from "Stores";
import { isVariablePriceType } from "ProductUtils";

const POS_ROUTE = "/admin/pos";

export const PosContext = createContext();

export const PosProvider = ({ children = {} }) => {
    const { selectedCompany, setSelectedCompany, selectedShop, setSelectedShop } = useQoplaStore();

    const state = useOrderWindowsStore();
    const {
        CartManager,
        selectedBundleCartProduct,
        setSelectedBundleCartProduct,
        deselectSelectedBundleCartProduct,
        allActiveFixedDiscounts,
        setAllActiveFixedDiscounts,
        activeFixedMenuDiscount,
        setActiveFixedMenuDiscount,
        activeOrderWindowId,
        getOrderWindow,
        getActiveOrderWindow,
        setActiveOrderWindowId,
        setOrderWindows,
        setShopId,
        upsertOrderWindows,
        updateOrderWindow,
        addOrderWindow,
        clearOrderWindow,
        clearOrderWindows,
        removeEmptyOrderWindow,
        addBundleProductToCart,
        addOrderProductToCart,
        activeMenus,
        setActiveMenus,
        menus,
        setMenus,
        productsStock,
        setProductsStock,
        refetchProductsStock,
        getRemainingStockQuantity,
        isRefProductOutOfStock,
        addCartToDTPreviousCart,
        updateOrderWindowPuckNo,
        setBundleProductToFinished,
    } = state;

    const {
        handleCartProduct,
        handleIncrementOrderProductQuantity,
        onHandleFixedDiscount,
        setCartProductsToOrderWindow,
        addManyBundleItemsToBundleProduct,
        handleUpdateCartProduct,
        addBundleItemToBundleProduct,
        addAddonToBundleItem,
        getCurrentCartProducts,
        getCartProduct,
        setActiveDiscountWithQuantities,
        setActiveOrderWindowDiscount,
        attachContactInformationToPostponedOrderWindow,
        updateUnitPrice,
        resetUnitPrice,
        splitOrderWindowCartProducts,
        updateDiscountedProductIds,
        handleAddNewCartProduct,
        handleAddNewCartProducts,
        updateAppliedDiscountQuantities,
        updateFixedDiscountedProductIds,
        updateBundleItemWithModification
    } = CartManager;

    const {
        setSelectedPos,
        setSelectedTerminal,
        activePaymentTabs,
        setActivePaymentTabs,
        getActivePaymentTab,
        handleSetActivePaymentTabs,
        updateActivePaymentTabStatus,
        addActivePaymentTab,
        refProducts,
        setRefProducts,
        outOfStockIds,
        setOutOfStockIds,
        hideFromStockIds,
        setHideFromStockIds,
        activeTerminalIds,
        setActiveTerminalIds,
        posZoomLevel,
        setPosZoomLevel,
        handleSetAddons,
        addons,
        selectedActiveMenuId,
        setSelectedActiveMenuId,
    } = usePosStore();

    useEffect(() => {
        setShopId(selectedShop?.id);
        refetchProductsStock();
    }, [selectedShop]);

    const [posZoomLevelStored, setPosZoomLevelStored] = useLocalStorage(LOCAL_STORAGE_CONSTANTS.POS_ZOOM_LEVEL);
    const location = useLocation();
    const isOnPOSRoute = location.pathname === POS_ROUTE;

    const [abortParkedOrder] = useMutation(SET_ORDER_PAYMENT_STATUS);
    const [createBongFromOrders, { loading }] = useMothershipMutation(CREATE_BONG_FROM_ORDERS);

    const updatePosZoomLevel = zoomLevel => {
        setPosZoomLevel(zoomLevel);
        setPosZoomLevelStored(zoomLevel);
    };

    if (!posZoomLevel) {
        if (posZoomLevelStored) {
            setPosZoomLevel(posZoomLevelStored);
        } else {
            updatePosZoomLevel(100);
        }
    }


    const activePaymentTabsRef = useRef(null);
    activePaymentTabsRef.current = activePaymentTabs;

    useEffect(() => {
        const persistedActivePaymentTabs = getPersistedObject(PersistConstants.ACTIVE_PAYMENT_TABS);
        setActivePaymentTabs(persistedActivePaymentTabs);
        refetchProductsStock();
    }, []);


    const handleSetActiveOrderWindowId = orderWindowId => {
        deselectSelectedBundleCartProduct();
        setActiveOrderWindowId(orderWindowId);
    };

    const recalculateDiscountWithAddons = orderWindow => {
        const cartTotalOriginalPrice = getDiscountedCartTotalAdjustedQuantities(
            orderWindow,
            orderWindow.discountedIdsAndQuantity
        );
        return orderWindow?.cartProducts?.map(cartProduct => {
            if (orderWindow.discount) {
                return recalculatePriceAndDiscount(
                    cartProduct,
                    orderWindow.discount,
                    orderWindow.discountedProductIds,
                    cartTotalOriginalPrice,
                    orderWindow.discountedIdsAndQuantity
                );
            } else {
                return cartProduct;
            }
        });
    };

    const handleCartProductDiscountedQuantities = orderWindow => {
        const { discountedIdsAndQuantity, discount } = orderWindow;
        if (discountedIdsAndQuantity && discount) {
            return orderWindow?.cartProducts?.reduce((discountedTotal, cartProduct) => {
                const discountQuantity = discountedIdsAndQuantity.find(
                    cart => cart.cartId === cartProduct.id || cart.cartId === cartProduct.oldId
                );
                if (discountQuantity) {
                    const cartIdAndQuantity = {
                        ...discountQuantity,
                        cartId: cartProduct.id
                    };
                    return [...discountedTotal, cartIdAndQuantity];
                }
                return discountedTotal;
            }, []);
        } else {
            return discountedIdsAndQuantity;
        }
    };

    const handleUpdateAddonOnCartProduct = (cartProductToUpdate, orderWindow) => {
        const updatedCartProducts = orderWindow?.cartProducts?.map(cartProduct => {
            if (cartProductToUpdate.id === cartProduct.id) {
                const { orderProduct } = cartProductToUpdate;

                const updatedPriceAndVats = calculateMenuProductOrBundlePrice(
                    cartProductToUpdate.menuProduct || cartProductToUpdate.menuBundleProduct,
                    orderProduct.quantity,
                    orderProduct.weight,
                    orderProduct.modifications,
                    orderProduct.selectedBundleProductItems,
                    orderProduct.addons,
                    cartProduct.updatedUnitPrice ? cartProduct.orderProduct.unitPrice : null
                );
                let updatedCartProduct = {
                    ...cartProductToUpdate,
                    orderProduct: {
                        ...orderProduct,
                        ...updatedPriceAndVats
                    }
                };

                const isWeightedProduct = isVariablePriceType(orderProduct.priceType);
                if (!isWeightedProduct) { 
                    const newId = createCartProductHashId(updatedCartProduct, orderWindow.id);

                    updatedCartProduct = {
                        ...updatedCartProduct,
                        id: newId,
                        oldId: cartProduct.id
                    }

                    if (orderWindow.discount) {
                        const { discountedProductIds } = orderWindow;
                        if (discountedProductIds.includes(updatedCartProduct.oldId)) {
                            const newDiscountedProductIds = discountedProductIds.filter(id => id !== updatedCartProduct.oldId);
                            orderWindow.discountedProductIds = [...newDiscountedProductIds, newId];
                        }
                    }
                }

                return updatedCartProduct;
            } else {
                return cartProduct;
            }
        });

        const addOnAdjustedOrderWindow = {
            ...orderWindow,
            cartProducts: mergeCartProducts(updatedCartProducts)
        };

        const updatedOrderWindow = {
            ...addOnAdjustedOrderWindow,
            discountedIdsAndQuantity: handleCartProductDiscountedQuantities(addOnAdjustedOrderWindow)
        };

        return recalculateDiscountWithAddons(updatedOrderWindow);
    };

    const handleDiscountedIdChangeAndQuantities = updatedOrderWindows => {
        return updatedOrderWindows.map(window => {
            if (window.id === activeOrderWindowId) {
                if (window.discount && window.discountedIdsAndQuantity?.length) {
                    const idsAndQuantities = window.cartProducts.reduce((totals, cart) => {
                        const discountedId = window.discountedIdsAndQuantity.find(
                            quantity => quantity.cartId === cart.id || quantity.cartId === cart.oldId
                        );
                        if (discountedId) {
                            const idAndQuantity = {
                                cartId: cart.id,
                                quantity: discountedId.quantity,
                                discountType: discountedId.discountType
                            };
                            totals = totals.length > 0 ? [...totals, idAndQuantity] : [idAndQuantity];
                        }
                        return totals;
                    }, []);
                    return {
                        ...window,
                        discountedIdsAndQuantity: idsAndQuantities
                    };
                } else {
                    return window;
                }
            } else {
                return window;
            }
        });
    };


    const updateAddonToCartProduct = cartProduct => {
        
        if (selectedBundleCartProduct) {
            const { orderProduct } = cartProduct;
            
            const updatedSelectedBundleCartProduct = {
                ...selectedBundleCartProduct, 
                orderProduct: { 
                    ...selectedBundleCartProduct.orderProduct, 
                    addons: orderProduct.addons
                }};
            setSelectedBundleCartProduct(updatedSelectedBundleCartProduct);
        }
        const updatedOrderWindows = handleCartProduct(cartProduct, handleUpdateAddonOnCartProduct);
        const handlePotentialdiscountQuantities = handleDiscountedIdChangeAndQuantities(updatedOrderWindows);
        return setOrderWindows(handlePotentialdiscountQuantities);
    };

    const addCartProductToOrderWindow = cartProduct => {
        setOrderWindows(handleCartProduct(cartProduct, handleAddNewCartProduct));
    };

    const addCartProductsToOrderWindow = cartProducts => {
        setOrderWindows(handleCartProduct(cartProducts, handleAddNewCartProducts));
    };

    const addOpenProductToCart = (openProductPrice, vatRate, modification, type) => {
        const openProduct = {
            companyId: selectedCompany.id,
            shopId: selectedShop.id,
            totalPrice: openProductPrice,
            vatRate: vatRate,
            menuId: selectedActiveMenuId,
            modification: modification,
            type: type
        };
        const cartOpenProduct = createOpenCartProduct(openProduct, activeOrderWindowId);
        const activeOrderWindow = getOrderWindow(activeOrderWindowId);
        const cartProductExists = findCartProduct(activeOrderWindow, cartOpenProduct.id);

        let updatedOrderWindows;
        if (cartProductExists) {
            const existingCartProduct = activeOrderWindow?.cartProducts?.find(cart => cart.id === cartOpenProduct.id);
            updatedOrderWindows = handleCartProduct(existingCartProduct, handleIncrementOrderProductQuantity);
        } else {
            updatedOrderWindows = handleCartProduct(cartOpenProduct, handleAddNewCartProduct);
        }

        setOrderWindows(updatedOrderWindows);
    };

    const handleEditMenuProduct = cartProduct => {

        const newCartProductId = createCartProductHashId(cartProduct, activeOrderWindowId)
        const orderWindow = getOrderWindow(activeOrderWindowId);

        const isAmountDiscounted = orderWindow.discount && orderWindow.discount.amount > 0;
        const hasFixedDiscount = get(cartProduct, "fixedDiscount.isFixedDiscountActive", false);
        const hasBeenModified = cartProduct.id !== newCartProductId;

        const twoOfSameIndex =
            hasBeenModified && orderWindow?.cartProducts?.findIndex(cartProduct => cartProduct.id === newCartProductId);
        const isTwoOfSame = hasBeenModified ? twoOfSameIndex > -1 : false;

        const clonedCartProducts = [...orderWindow?.cartProducts];
        const currCartProducts = isTwoOfSame
            ? clonedCartProducts.filter((_, i) => twoOfSameIndex !== i)
            : orderWindow?.cartProducts;

        const tmpCartProduct = {
            ...cartProduct,
            id: newCartProductId,
            oldId: cartProduct.id,
            orderProduct: isTwoOfSame
                ? {
                      ...orderWindow?.cartProducts[twoOfSameIndex].orderProduct,
                      quantity: isTwoOfSame
                          ? orderWindow?.cartProducts[twoOfSameIndex].orderProduct.quantity +
                            cartProduct.orderProduct.quantity
                          : cartProduct.quantity
                  }
                : cartProduct.orderProduct
        };

        const usedFixedDiscount = findFixedSubscriptionDiscount(orderWindow.fixedDiscounts, cartProduct?.fixedDiscount)

        const updatedDiscountIds = updateDiscountedProductIds(newCartProductId, cartProduct.id);
        const discountedIdsAndQuantity = updateAppliedDiscountQuantities(newCartProductId, cartProduct.id);

        let updatedCartProduct = recalculatePriceAndDiscount(
            tmpCartProduct,
            isAmountDiscounted ? null : orderWindow.discount,
            isAmountDiscounted ? [] : updatedDiscountIds,
            null,
            discountedIdsAndQuantity
        );

        const fixedDiscountedIds = updateFixedDiscountedProductIds(newCartProductId, cartProduct.id);

        if (isAmountDiscounted) {
            // needs to be calculated again to get full cart original price
            const updateCartProducts = orderWindow?.cartProducts?.map(cp =>
                cp.id === updatedCartProduct.oldId ? updatedCartProduct : cp
            );

            const calculateNewCartValue = getDiscountedCartTotalFromCartProducts(
                updateCartProducts,
                orderWindow.discount,
                updatedDiscountIds,
                discountedIdsAndQuantity
            );

            updatedCartProduct = recalculatePriceAndDiscount(
                tmpCartProduct,
                orderWindow.discount,
                updatedDiscountIds,
                calculateNewCartValue,
                discountedIdsAndQuantity
            );
        }

        if (!isAmountDiscounted && hasFixedDiscount) {
            const updatedOrderWindow = {
                ...orderWindow,
                fixedDiscountedProductIds: orderWindow.fixedDiscounts,
                cartProducts: orderWindow?.cartProducts?.map(cp =>
                    cp.id === updatedCartProduct.oldId ? updatedCartProduct : cp
                )
            };
            updatedCartProduct = recalculatePriceAndDiscount(
                { ...updatedCartProduct, id: newCartProductId, oldId: cartProduct.id },
                usedFixedDiscount,
                fixedDiscountedIds,
                getDiscountedCartTotalOriginalPrice(updatedOrderWindow),
                orderWindow.discountedIdsAndQuantity
            );
        }

        const updatedCartProducts = currCartProducts.map(cp =>
            cp.id === updatedCartProduct.oldId ? updatedCartProduct : cp
        );

        if (isAmountDiscounted && updatedCartProducts.length > 1) {
            let updatedOrderWindow = {
                ...orderWindow,
                discountedProductIds: updatedDiscountIds,
                fixedDiscountedProductIds: fixedDiscountedIds,
                cartProducts: updatedCartProducts,
                discountedIdsAndQuantity: discountedIdsAndQuantity
            };

            updatedOrderWindow?.cartProducts = updateCombinedDiscountsAndOtherDiscounts(updatedOrderWindow);

            return updateOrderWindow(updatedOrderWindow);
        }

        return updateOrderWindow({
            ...orderWindow,
            discountedProductIds: updatedDiscountIds,
            fixedDiscountedProductIds: fixedDiscountedIds,
            cartProducts: updatedCartProducts,
            discountedIdsAndQuantity: discountedIdsAndQuantity
        });
    };

    const editProduct = cartProduct => {
        if (cartProduct.menuBundleProduct) {
            setSelectedBundleCartProduct({...cartProduct, idBeforeEdit: cartProduct.oldId});
        } else if (cartProduct.menuProduct) {
            handleEditMenuProduct(cartProduct);
        }
    };

    // ACTIVE PAYMENT FUNCTIONS

    const resetTerminalIdForPaymentTab = paymentTabId => {
        const activePaymentTab = activePaymentTabsRef.current[paymentTabId];
        setActiveTerminalIds(activeTerminalIds.filter(t => t !== activePaymentTab.terminalId));

        handleSetActivePaymentTabs(activePaymentTabs, {
            ...activePaymentTab,
            terminalId: null,
            status: paymentTabStatusTypes.DT_ONGOING
        });
    };

    const getActiveOrderWindowDiscount = () => {
        const orderWindow = getOrderWindow(activeOrderWindowId);
        const discount = orderWindow ? orderWindow.discount : null;
        const discountedProductIds = orderWindow ? orderWindow.discountedProductIds : null;
        return {
            discount,
            discountedProductIds
        };
    };

    // This is a very useful console log.  I'd recommend leaving it here for future use.
    // const tempDisplayName = getActiveOrderWindow()?.contactInformation?.name || getActiveOrderWindow()?.displayName;
    // console.log("Active OrderWindow : ", tempDisplayName);

    const addOrderProductComment = (cartProductId, comment) => {
        const activeOrderWindow = getOrderWindow(activeOrderWindowId);
        const shouldUpdateSelectedBundleCartProductId = cartProductId === selectedBundleCartProduct?.id;
        const cartProductToUpdate = activeOrderWindow?.cartProducts?.find(cp => cp.id === cartProductId);

        const cartProductWithComment = {
            ...cartProductToUpdate,
            orderProduct: {
                ...cartProductToUpdate.orderProduct,
                comment: comment
            }
        };

        /**Do not change weighted product id each weighted product id should not change 
         * from creation - Uuid identification is used
         */
        const isWeightedProduct = isVariablePriceType(cartProductToUpdate.orderProduct.priceType);
        const newCartProductId = isWeightedProduct ? cartProductId : createCartProductHashId(cartProductWithComment, activeOrderWindowId);

        const prodWithCommentExistsIdx = activeOrderWindow?.cartProducts?.findIndex(cp => cp.id === newCartProductId);

        let updatedCartProducts = [...activeOrderWindow?.cartProducts];

        // if product with comment already exists, remove and add to that quantity
        if (prodWithCommentExistsIdx > -1 && cartProductId !== newCartProductId && !isWeightedProduct) {
            updatedCartProducts[prodWithCommentExistsIdx] = CartManager.handleIncrementDecrementPriceUpdates(
                activeOrderWindow,
                updatedCartProducts[prodWithCommentExistsIdx],
                cartProductToUpdate.orderProduct.quantity
            );
            updatedCartProducts = updatedCartProducts.filter(cp => cp.id !== cartProductId);
        } else {
            updatedCartProducts = updatedCartProducts.map(cp =>
                cp.id === cartProductId
                    ? {
                        ...cp,
                        id: newCartProductId,
                        ...(!isWeightedProduct && { oldId: cartProductId }),
                        orderProduct: {
                            ...cp.orderProduct,
                            comment
                        }
                      }
                    : cp
            ); 
        }

        const updatedOrderWindow = {
            ...activeOrderWindow,
            cartProducts: updatedCartProducts
        };

        if (shouldUpdateSelectedBundleCartProductId) {
            const updatedSelectedBundleCartProduct = {
                ...selectedBundleCartProduct,
                id: newCartProductId,
                orderProduct: {
                    ...selectedBundleCartProduct.orderProduct,
                    comment: comment
                }
            };
            setSelectedBundleCartProduct(updatedSelectedBundleCartProduct);
        }
        updateOrderWindow(updatedOrderWindow);
    };

    /**
     * 1. Update orderWindow with new value of shouldHide
     * 2. Add orderWindow to state
     * 3. Set activeOrderWindowId to something other than this orderWindow
     *
     * NOTE: This could be split into two functions
     *  hideOrderWindow(orderWindowId)
     *  showOrderWindow(orderWindowId)
     *
     * which would treat the activeOrderWindowId differently depending on which is called
     */
    const onToggleShouldHideOrderWindow = (orderWindowId, shouldHide) => {
        if (!shouldHide) {
            showOrderWindow([orderWindowId]);
        } else {
            hideOrderWindow([orderWindowId]);
        }
    };

    const showOrderWindow = orderWindowIds => {
        const updatedOrderWindows = orderWindowIds.map(orderWindowId => ({
            ...getOrderWindow(orderWindowId),
            shouldHide: false
        }));
        const [newActiveOrderWindowId] = orderWindowIds;

        upsertOrderWindows(updatedOrderWindows);
        handleSetActiveOrderWindowId(newActiveOrderWindowId);
    };

    const hideOrderWindow = orderWindowIds => {
        const updatedOrderWindows = orderWindowIds.map(orderWindowId => ({
            ...getOrderWindow(orderWindowId),
            shouldHide: true
        }));

        upsertOrderWindows(updatedOrderWindows);
        handleSetActiveOrderWindowId(null);
    };

    const setAddonOnCartProduct = (cartProductToUpdate, selectedAddons, bundleItemKey = null) =>
        updateAddonToCartProduct(handleSetAddonsOnCartProduct(cartProductToUpdate, selectedAddons, bundleItemKey));

    const getActiveMenu = () => {
        return menus.find(menu => menu.id === selectedActiveMenuId) ?? {};
    };



    const value ={ 
        useOrderWindowsStore,
        setCartProductsToOrderWindow,
        setOrderWindows,
        refProducts,
        getOrderWindow,
        setRefProducts,
        outOfStockIds,
        hideFromStockIds,
        handleUpdateAddonOnCartProduct,
        setOutOfStockIds: setOutOfStockIds,
        setHideFromStockIds: setHideFromStockIds,
        editProduct,
        addBundleProductToCart,
        handleUpdateCartProduct,
        addBundleItemToBundleProduct,
        addAddonToBundleItem,
        addOrderProductToCart,
        addOpenProductToCart,
        setBundleProductToFinished,
        getCurrentCartProducts,
        cartUtils: {
            addOrderProductComment,
            addCartToDTPreviousCart,
            setAddonOnCartProduct,
            addCartProductToOrderWindow,
            addCartProductsToOrderWindow
        },
        getCartProduct,
        menus,
        setMenus,
        addOrderWindow,
        upsertOrderWindows,
        getActiveOrderWindow,
        handleSetActiveOrderWindowId,
        activeOrderWindowId,
        setSelectedActiveMenuId,
        activePaymentTabs,
        updateOrderWindow,
        updateOrderWindowPuckNo,
        activeTerminalIds,
        handleSetAddons,
        addons,
        handleUpdateAddonOnCartProduct,
        paymentTabsUtils: {
            addActivePaymentTab,
            getActivePaymentTab,
            updateActivePaymentTabStatus,
            setActivePaymentTabs,
            clearOrderWindow,
            clearOrderWindows,
            setActiveTerminalIds,
            resetTerminalIdForPaymentTab,
            attachContactInformationToPostponedOrderWindow,
            abortParkedOrder
        },
        discountUtils: {
            getActiveOrderWindowDiscount,
            setActiveOrderWindowDiscount,
            setActiveDiscountWithQuantities,
            splitOrderWindowCartProducts,
            updateUnitPrice,
            resetUnitPrice
        },
        fixedDiscountUtils: {
            activeFixedMenuDiscount,
            setActiveFixedMenuDiscount,
            selectedActiveMenuId,
            allActiveFixedDiscounts,
            setAllActiveFixedDiscounts,
            onHandleFixedDiscount,
        },
        activeMenus,
        getActiveMenu,
        setActiveMenus,
        posZoomLevel,
        setPosZoomLevel,
        updatePosZoomLevel,
        activePaymentTabsRef,
        removeEmptyOrderWindow,

        // TODO Put this in an object to keep organized
        productsStock,
        getRemainingStockQuantity,
        isRefProductOutOfStock,
        addManyBundleItemsToBundleProduct,
        updateBundleItemWithModification,
        onToggleShouldHideOrderWindow,
        hideOrderWindow,
        setActiveOrderWindowId,
    };
    return <PosContext.Provider value={value}>{children}</PosContext.Provider>;
};

export const PosConsumer = PosContext.Consumer;

export const withPos = Component => props =>
    <PosConsumer>{state => <Component {...props} qoplaStore={state} />}</PosConsumer>;

export const usePos = () => {
    const ctx = React.useContext(PosContext);
    if (!ctx) {
        throw new Error("usePos must be within a PosProvider");
    }
    return ctx;
};
