import React from "react";

import { NewButton, Box, ButtonProps } from "../../atoms";

type OmitedNewIconButton = Omit<ButtonProps, "leftIcon" | "rightIcon" | "childen" | "fullWidth">;

export type NewIconButtonProps = {
    icon?: React.ComponentType;
} & OmitedNewIconButton;

export const NewIconButton: React.FC<NewIconButtonProps> = ({ icon, ...rest }) => {
    return (
        <NewButton p={0} rounded="md" {...rest}>
            <Box as={icon} />
        </NewButton>
    );
};

export const IconButton = NewIconButton;
