import React from "react";
import { ProductCard } from "./orderRenderUtils";
import { isOutOfStock, activeHoursProducts } from "Utils";
import { isVariablePriceType } from "ProductUtils";

import "../expressCheckout.scss";

export const ProductCards = ({
    productCategory,
    handleProductToCart,
    selectedKey,
    shouldRenderProductUtils,
    productUtils,
    selectedUpsellProducts = [],
    clickableCard,
    cardsPerRow,
    outOfStockProductIds,
    hideStockProductIds,
    refProductHashMap
}) => {
    const allProds = productCategory.menuProducts.concat(productCategory.menuBundleProducts);

    const className = cardsPerRow === 4 ? "four-items-grid" : "five-items-grid"; // Will extend this if else statment if we add this type of class
    const productCardStyle = className === "four-items-grid" ? "menu-tabs-card" : "upsell-modal-card"; // The same with this one
    return (
        <section className={className} id="full-hd-specific" key={selectedKey ? selectedKey : null}>
            {activeHoursProducts(allProds)
                .filter(menuProduct => {
                    const product = menuProduct.refBundleProduct
                        ? menuProduct.refBundleProduct
                        : menuProduct.refProduct;
                    const shouldHideFromStock = hideStockProductIds && hideStockProductIds.includes(product.id);
                    const hasVariablePrice = isVariablePriceType(product.priceType);
                    const excluded = shouldHideFromStock || hasVariablePrice;
                    return !excluded;
                })
                .sort((a, b) => a.sortOrder - b.sortOrder)
                .map(product => {
                    const outOfStock = isOutOfStock(
                        product.refProduct ? product.refProduct.id : product.refBundleProduct.id,
                        new Set(outOfStockProductIds)
                    );

                    const upsellProductQuantity =
                        selectedUpsellProducts.length &&
                        selectedUpsellProducts.filter(foundProd => foundProd.refProduct.id === product.refProduct.id)
                            .length;
                    return (
                        <ProductCard
                            refProductHashMap={refProductHashMap}
                            key={product.id}
                            product={{ ...product, menuCategoryId: productCategory.id, outOfStock }}
                            handleProductToCart={handleProductToCart}
                            shouldRenderProductUtils={shouldRenderProductUtils}
                            productUtils={productUtils}
                            upsellProductQuantity={upsellProductQuantity}
                            clickableCard={clickableCard}
                            productCardStyle={productCardStyle}
                        />
                    );
                })}
        </section>
    );
};
