import React, { useEffect, useState } from "react";

import { GET_MENU_SYNC_LOGS_BY_MENU_ID_AND_COMPANY_ID, GET_MENU_SYNC_LOG_BY_ID } from "GraphQLQueries";
import { useMothershipQuery, useMothershipLazyQuery } from "Hooks";
import {
    Pagination,
    CurrentPage,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    PageButtons
} from "Organisms";
import { IDrawerComponentContext, useLanguage, useTheme } from "Providers";
import { LOG_STATUS, MenuChangelog, RegionTimeZone, ShopOption } from "Types";
import { Box, Flex, NewDivider, Skeleton } from "Atoms";
import { FadeInBox } from "../../subscriptions/overview/components/FadeInBox";
import { formatMenuSyncDate, sortByDateDesc, isSuccessfulLog } from "./functions";
import { MenuLogTile } from "./MenyLogTile";
import { MenuLog } from "./types";
import { MenuSyncPopUp } from "./MenuSyncPopUp";
import { getMenuOccurrenceInShops } from "TempUtils";
import { MenuLogFilter } from "./MenuLogFilter";
import { httpCodeStatusCodes, isValidJson } from "Utils";
import { BAD_REQUEST } from "Constants";

type Props = {
    companyId: string;
    menuIdAndName: Map<string, string>;
    shopList: ShopOption[];
    changeLog: MenuChangelog[];
};

export const MenuLogDrawer: React.FC<IDrawerComponentContext<Props>> = ({
    props: { companyId, menuIdAndName, shopList, changeLog },
    onCloseDrawer
}) => {
    const { colors } = useTheme();
    const { translate } = useLanguage();
    const [menuId, menuName] = [...menuIdAndName][0];

    const [allMenuLogs, setAllMenuLogs] = useState<MenuLog.All[]>([]);
    const [filteredMenuLogs, setFilteredMenuLogs] = useState<MenuLog.All[]>([]);
    const [openJsonViewer, setOpenJsonViewer] = useState<MenuLog.JsonViewer>({ open: false, request: "" });

    const { data, loading } = useMothershipQuery<MenuLog.Query>(GET_MENU_SYNC_LOGS_BY_MENU_ID_AND_COMPANY_ID, {
        variables: {
            companyId: companyId,
            menuIds: [menuId],
            limit: 150
        }
    });

    const [loadLogById] = useMothershipLazyQuery<MenuLog.QueryLogById>(GET_MENU_SYNC_LOG_BY_ID, {
        fetchPolicy: "network-only",
        onCompleted: data => {
            if (data?.getMenuSyncLogById) {
                const displayLog = data?.getMenuSyncLogById;

                const isSuccessful = isSuccessfulLog(displayLog.status);
                const requestIsValid = isValidJson(displayLog?.request?.data);
                const responseIsValid = isValidJson(displayLog?.response?.message);

                let request = displayLog.request?.data;
                let response = displayLog.response?.message;
                if (!isSuccessful && !responseIsValid) {
                    response = getResponseMsg(displayLog as MenuLog.ThirdParty);
                }
                if (!requestIsValid) {
                    // not likely to happen but just in case
                    request = `{"message": "Unknown"}`;
                }
                setOpenJsonViewer({
                    open: true,
                    request: request,
                    response: !isSuccessful ? response : ""
                });
            }
        }
    });

    const getResponseMsg = (log: MenuLog.ThirdParty) => {
        const statusCode = log.response?.status ?? 400;
        const httpStatusMsg = Reflect.get(httpCodeStatusCodes, statusCode);
        const response: any = {
            message: httpStatusMsg ? httpStatusMsg : BAD_REQUEST,
            response: { message: log.response.message, status: log.status }
        };
        return JSON.stringify(response);
    };

    const handleLoadLogById = (logId: string) => {
        loadLogById({ variables: { logId } });
    };

    const onFilterMenuLogs = (logStatus: LOG_STATUS[]) => {
        if (logStatus.length == 0) {
            setFilteredMenuLogs([]);
        } else {
            const filteredLogs = allMenuLogs.filter((log: MenuLog.All) => {
                return logStatus.includes(log.status);
            });
            setFilteredMenuLogs(filteredLogs);
        }
    };

    useEffect(() => {
        if (data?.getMenuSyncLogsByCompanyAndMenuId) {
            //note: this is for super admin so timezone will always be Swedish time
            const menuSyncLogs: MenuLog.ThirdParty[] = data.getMenuSyncLogsByCompanyAndMenuId.map(menuLog => {
                return {
                    ...menuLog,
                    displayDate: formatMenuSyncDate(menuLog.createdAt, RegionTimeZone.SE, "ThirdParty"),
                    shop: shopList.find(shop => shop.id === menuLog.shopId && shop.menuIds.includes(menuId))
                };
            });
            const changeLogAndStatus: MenuLog.Qopla[] = changeLog.map(menuLog => {
                return {
                    ...menuLog,
                    status: LOG_STATUS.SUCCESS,
                    displayDate: formatMenuSyncDate(menuLog.updatedAt, RegionTimeZone.SE, "Qopla"),
                    numberOfOccurrences: getMenuOccurrenceInShops(shopList, menuId)
                };
            });
            const currentMenuLogs: MenuLog.All[] = [...menuSyncLogs, ...changeLogAndStatus];
            const sortByDates = currentMenuLogs.sort(sortByDateDesc);
            setAllMenuLogs(sortByDates);
            setFilteredMenuLogs(sortByDates);
        }
    }, [data]);

    const hasNoLogs = !loading && allMenuLogs.length === 0;
    const allLogsAreLoaded = !loading && filteredMenuLogs.length > 0;
    const showFilter = !loading && allMenuLogs.length > 0;

    return (
        <Drawer open onClose={onCloseDrawer}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerHeader display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                    <Box>{hasNoLogs ? translate("noLogs") : translate("logs")}</Box>
                    <Box>{menuName}</Box>
                    {showFilter && <MenuLogFilter onFilterMenuLogs={onFilterMenuLogs} />}
                </DrawerHeader>
                <DrawerCloseButton top="15px" />
                <DrawerBody overflow="auto" pt="0">
                    {loading && (
                        <Flex direction="column">
                            <Skeleton width="50%" height="2rem" marginLeft="auto" marginRight="auto" />
                            {[...Array(15)].map((_, index: number) => {
                                return <Skeleton width="100%" height="4rem" mt={2} key={index + 1} />;
                            })}
                        </Flex>
                    )}
                    {allLogsAreLoaded && (
                        <FadeInBox width="100%" minH="6rem" p={0}>
                            <Pagination items={filteredMenuLogs} numberPerPage={10}>
                                <PageButtons
                                    showMaxPage={5}
                                    pageButtonTheme={{
                                        WRAPPER: {
                                            border: "none",
                                            boxShadow: "none",
                                            marginBottom: "1rem",
                                            marginTop: "0",
                                            top: "0"
                                        },
                                        BUTTONS: { color: "gray.900" },
                                        HOVERCOLOUR: colors.blue["100"]
                                    }}
                                />
                                <CurrentPage
                                    width="100%"
                                    wrapperProps={{ justifyContent: "flex-start" }}
                                    renderItem={(log: MenuLog.All, index: number) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <MenuLogTile menuLog={log} handleLoadLogById={handleLoadLogById} />
                                                <NewDivider color="gray.400" />
                                            </React.Fragment>
                                        );
                                    }}
                                />
                            </Pagination>
                        </FadeInBox>
                    )}
                    {openJsonViewer.open && (
                        <MenuSyncPopUp
                            request={openJsonViewer.request}
                            response={openJsonViewer.response}
                            onClose={() => {
                                setOpenJsonViewer({ open: false, request: "", response: "" });
                            }}
                        />
                    )}
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};
