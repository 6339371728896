import React, { useEffect } from "react";
import moment from "moment";

import { Text } from "Atoms";
import { useCountDown } from "Hooks";
import { useSubscriptions, useLanguage, useOnline } from "Providers";

type Props = {
    id: string;
};

export const SubscriptionCountDown: React.FC<Props> = ({ id }) => {
    const { subscriptionsOnHold, removeSubscriptionFromHold } = useSubscriptions();
    const { userLanguage, translate } = useLanguage();
    const earliestTimeToOrder = subscriptionsOnHold[id];

    if (!earliestTimeToOrder) {
        return null;
    }

    const { backendDiff } = useOnline();
    const countDown: number = useCountDown(earliestTimeToOrder.diff(moment().add(backendDiff, "ms"), "seconds"));

    useEffect(() => {
        if (countDown === 0) {
            removeSubscriptionFromHold(id);
        }
    }, [countDown]);

    return (
        <Text>
            {translate("timeUntilNextOrder")}:{" "}
            {moment(earliestTimeToOrder).add(backendDiff, "ms").locale(userLanguage).toNow(true)}
        </Text>
    );
};
