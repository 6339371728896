import React, { useState } from "react";
import { default as InternalTruncate, TruncateProps as InternalTruncateProps } from "react-truncate";
import { Link } from "Atoms";
import { useLanguage } from "Providers";

interface TruncateProps extends InternalTruncateProps {
    showExpand?: boolean;
}

export const Truncate: React.FC<TruncateProps> = ({
    lines: defaultLines,
    showExpand,
    children,
    ...rest
}: TruncateProps) => {
    const { translate } = useLanguage();
    const [lines, setLines] = useState(defaultLines);

    const isExpandable = showExpand && !!defaultLines;

    const toggleShowMore = () => {
        if (!!lines) {
            setLines(false);
        } else {
            setLines(defaultLines);
        }
    };

    return (
        <>
            {/** @ts-ignore */}
            <InternalTruncate lines={lines} {...rest} onClick={toggleShowMore}>
                {children}
            </InternalTruncate>
            {isExpandable && (
                <Link ml={2} color="blue.500" as="span" onClick={toggleShowMore}>
                    {(!!lines ? translate("more") : translate("less"))?.trim()}
                </Link>
            )}
        </>
    );
};
