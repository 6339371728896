import React from "react";

import { CloseButtonProps, CloseButton, useModalContext } from "Molecules";

export type ModalCloseBtnProps = CloseButtonProps;

export const ModalCloseBtn: React.FC<CloseButtonProps> = props => {
    const { onClose } = useModalContext();

    return <CloseButton onClick={onClose} position="absolute" top="8px" right="12px" {...props} />;
};
