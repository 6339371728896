import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from "react";
import ReactCrop, { Crop, PixelCrop, convertToPixelCrop } from "react-image-crop";

import { Box, Flex, Spinner, Text } from "Atoms";
import {
    centreAspectCrop,
    convertBlobToFile,
    getImageCropped,
    getImageFileExtension,
    getImageFileNameAndExtension,
    getNewImageFileName
} from "./utils/functions";
import { ImageCropSettings } from "./components/ImageCropSettings";
import { FileNameAndExtension, ImageNaturalAndRenderedSize, ScaledCrop } from "./utils/types";
import { LoadingDots } from "Molecules";
import { useLanguage } from "Providers";
import { filetypeConstants } from "Constants";
import { ASPECT_RATIO, ImageFile, IMAGE_FORMAT } from "Types";

import "react-image-crop/dist/ReactCrop.css";

type Props = {
    companyId: string;
    imageUrl: string;
    allowedCroppedImageFiles?: IMAGE_FORMAT[];
    minCropAreaPixels?: { minWidth: number; minHeight: number };
    disableSaveImage?: (isDisabled: boolean) => void;
    ref: React.Ref<{ getImageFile: (fileName?: string) => Promise<ImageFile | null> }>;
};

export const defaultImageFiles: IMAGE_FORMAT[] = [IMAGE_FORMAT.JPEG, IMAGE_FORMAT.PNG, IMAGE_FORMAT.JPG];

export const ImageCrop: React.FC<Props> = forwardRef(
    (
        { companyId, imageUrl, minCropAreaPixels, disableSaveImage, allowedCroppedImageFiles = defaultImageFiles },
        ref
    ) => {
        /** This is used for resizing the browser and re-rendering the image */
        const [reloadImageKey, setReloadImageKey] = useState<number>(1);

        const [crop, setCrop] = useState<Crop>();
        const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
        const [scaledCompletedCrop, setScaledCompletedCrop] = useState<ScaledCrop>();

        /** Improvement - later scale and maybe rotate */
        const [scale, ___] = useState(1);
        const [rotate, __] = useState(0);

        const [aspect, setAspect] = useState<number | undefined>(ASPECT_RATIO["16 / 9"]);
        const [disableAspects, setDisableAspects] = useState<boolean>(false);
        const [circularCrop, setCircularCrop] = useState<boolean>(false);
        const [hasGridLines, setHasGridLines] = useState<boolean>(true);

        const [imageSizes, setImageSizes] = useState<ImageNaturalAndRenderedSize>();
        const [imageFile, setImageFile] = useState<FileNameAndExtension>();
        const [previewUrl, setPreviewUrl] = useState("");

        /** Check Image extension for allowing */
        const [canCropImage, _] = useState(() => {
            const extension = getImageFileExtension(imageUrl);
            /** Allowing a prop for allowed cropped image file formats
             * * however if they miss gif it can't be cropped so it's always here
             */
            return !!extension && allowedCroppedImageFiles.includes(extension) && extension !== IMAGE_FORMAT.GIF;
        });

        const imgRef = useRef<HTMLImageElement>(null);

        const { translate } = useLanguage();

        const hasMinCropArea = (minCropAreaPixels?.minHeight ?? 0) > 0 && (minCropAreaPixels?.minWidth ?? 0) > 0;

        const resetAspectRatio = () => {
            /** Reset aspect ratio and crop state
             * * This happens when N/A is click for a freeform crop
             */
            setAspect(0);
            setCrop({ unit: "%", x: 0, y: 0, width: 0, height: 0 });
            setScaledCompletedCrop({ naturalHeight: 0, naturalWidth: 0 });
            disableSaveImage && disableSaveImage(true);
        };

        /**
         * On Image Load - get the rendered image size + original image size
         * * Also when the image loads - the crop is calculated based on the aspect ratio
         */
        const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
            if (aspect) {
                const { width, height, naturalWidth, naturalHeight } = e.currentTarget;
                const imageFile = getImageFileNameAndExtension(imageUrl);
                if (imageFile) {
                    setImageFile(imageFile);
                }

                const imageIsTooSmall = width < 300 || height < 300;
                if (imageIsTooSmall) {
                    setDisableAspects(true);
                    resetAspectRatio();
                } else {
                    setCrop(centreAspectCrop(width, height, aspect));
                }

                /** Get the scale of original size width to normal width */
                const scaleX = naturalWidth / width;
                const scaleY = naturalHeight / height;

                setImageSizes({
                    renderedWidth: width,
                    renderedHeight: height,
                    naturalWidth,
                    naturalHeight,
                    scaleX,
                    scaleY
                });
            }
        };

        if (!imageUrl) {
            return (
                <Flex width="100%" justifyContent="center" alignItems="center" height="300px">
                    <Spinner />
                </Flex>
            );
        }

        if (!canCropImage) {
            return (
                <Flex direction="column" justifyContent="center" alignItems="center" height="100%">
                    <Flex
                        justifyContent="center"
                        alignItems="center"
                        width="300px"
                        maxHeight="200px"
                        border="2px dashed #1A202C"
                        outline="2px solid white"
                    >
                        <Text fontWeight="bold">{"Can not crop this image"}</Text>
                    </Flex>
                </Flex>
            );
        }

        /**
         * On completion of the crop - will set the scaled crop
         * e.g say the "rendered" image is 1000px x 1000px & the original image is 2000px x 2000px
         * The scaled crop figure will be based on the original image size, even though the crop is done on the rendered image
         */
        const setCompletedCropByScale = (crop: PixelCrop) => {
            const hasNoCroppedArea = crop.width === 0 && crop.height === 0;
            disableSaveImage && disableSaveImage(hasNoCroppedArea);
            /** Recalculated cropped height + width based on scale to match the size on the original image */
            const scaledHeight = Math.floor(crop.height * (imageSizes?.scaleY ?? 1));
            const scaledWidth = Math.floor(crop.width * (imageSizes?.scaleX ?? 1));
            setPreviewUrl("");
            setScaledCompletedCrop({ naturalHeight: scaledHeight, naturalWidth: scaledWidth });
            setCompletedCrop(crop);
        };

        /** Get the cropped image part in order to show
         * ** Not being used at present perhaps activate this later
         */
        const previewCroppedImage = async () => {
            try {
                if (!!imageFile?.fileExtension) {
                    const imageBlob = await getImageCropped(
                        imgRef.current!,
                        completedCrop!,
                        scale,
                        rotate,
                        imageFile?.fileExtension
                    );
                    if (imageBlob) {
                        const previewUrl = URL.createObjectURL(imageBlob as unknown as Blob);
                        setPreviewUrl(previewUrl as string);
                    }
                }
            } catch (e) {
                console.error(e);
            }
        };

        const getImageFile = async (fileName?: string): Promise<ImageFile | null> => {
            try {
                if (!!imageFile?.fileExtension) {
                    /** Step 1: Crop Image to size */
                    const imageBlob = await getImageCropped(
                        imgRef.current!,
                        completedCrop!,
                        scale,
                        rotate,
                        imageFile?.fileExtension
                    );
                    if (imageBlob && imageFile) {
                        /** Step 2: Use original file name or sent in one */
                        const changeFileName: FileNameAndExtension = {
                            ...imageFile,
                            fileName: !!fileName ? fileName : imageFile.fileName
                        };
                        /** Step 3: Create new file name - with cropped */
                        const newFileName = getNewImageFileName(changeFileName, scaledCompletedCrop!);
                        /** Step 4: Convert blob to file */
                        const file = convertBlobToFile(imageBlob as Blob, newFileName);
                        /** Step 5: return image file type */
                        return {
                            companyId,
                            imageFolder: `${filetypeConstants["COMPANY"]}/cropped`,
                            fileName: newFileName,
                            file
                        };
                    }
                }
            } catch (e) {
                console.error(e);
            }
            return null;
        };

        /**
         * Set the aspect ratio of the crop - based on the buttons in the component
         */
        const setAspectRatio = (aspectRatio: ASPECT_RATIO) => {
            if (aspectRatio === ASPECT_RATIO.none) {
                resetAspectRatio();
            } else {
                const { width, height } = imgRef.current!;
                /** Step 1: Get a centred crop based on the selected aspect ratio and image width and height */
                const centeredAspectCrop = centreAspectCrop(width, height, aspectRatio);
                /** Step 2: Get pixel crop size */
                const pixelCrop = convertToPixelCrop(centeredAspectCrop, width, height);
                /** Step 3: Set state with new calculations */
                setCrop(centeredAspectCrop);
                setAspect(aspectRatio);
                setCompletedCropByScale(pixelCrop);
            }
        };

        /**
         * Setting the circular crop
         */
        const settingCircularCrop = (isCircleCrop: boolean) => {
            setCircularCrop(isCircleCrop);
        };

        /**
         * Setting the grid lines - rule of thirds
         */
        const settingGridLines = (hasGridLines: boolean) => {
            setHasGridLines(hasGridLines);
        };

        /** Resize the browser
         * * This will re-render the image based on the new size from the size of the browser
         */
        useEffect(() => {
            const handleResizeAndReload = () => {
                setReloadImageKey(prev => prev + 1);
            };
            window.addEventListener("resize", handleResizeAndReload);
            return () => window.removeEventListener("resize", handleResizeAndReload);
        }, []);

        /** Want to pass this function back to a ref so that the save / save button is outside of this component */
        useImperativeHandle(
            ref,
            () => ({
                getImageFile
            }),
            [imageFile, completedCrop]
        );

        const hasImageRenderedSizes = !!imageSizes?.renderedHeight && !!imageSizes?.renderedWidth;
        const hasImageOrginalSizes = !!imageSizes?.naturalHeight && !!imageSizes?.naturalWidth;

        return (
            <Flex direction="column" height="100%">
                <Box backgroundColor="blue.300" color="gray.800" borderTopRightRadius={10} borderTopLeftRadius={10}>
                    <Flex justifyContent="space-around" mt={4}>
                        {hasImageOrginalSizes ? (
                            <Text fontWeight="bold">{`${translate("originalImage")}: ${imageSizes?.naturalWidth}px x ${
                                imageSizes?.naturalHeight
                            }px`}</Text>
                        ) : (
                            <LoadingDots numberOfDots={10} />
                        )}
                        {hasImageRenderedSizes ? (
                            <Text fontWeight="bold">{`${translate("imageAsShown")}: ${imageSizes?.renderedWidth}px x ${
                                imageSizes?.renderedHeight
                            }px`}</Text>
                        ) : (
                            <LoadingDots numberOfDots={10} />
                        )}
                    </Flex>
                </Box>
                <ReactCrop
                    crop={crop}
                    {...(hasMinCropArea && { ...minCropAreaPixels })}
                    onChange={(crop, percentCrop) => {
                        setCrop(percentCrop);
                        aspect === ASPECT_RATIO.none && setCompletedCropByScale(crop);
                    }}
                    onComplete={(crop: PixelCrop) => setCompletedCropByScale(crop)}
                    aspect={aspect === ASPECT_RATIO.none ? undefined : aspect}
                    circularCrop={circularCrop}
                    ruleOfThirds={hasGridLines}
                    keepSelection={aspect !== ASPECT_RATIO.none}
                    style={{ backgroundColor: "gray" }}
                >
                    <img
                        ref={imgRef}
                        key={reloadImageKey}
                        alt="Crop me"
                        src={imageUrl}
                        crossOrigin={undefined}
                        style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                        onLoad={onImageLoad}
                    />
                </ReactCrop>
                <Box
                    boxShadow="sm"
                    backgroundColor="blue.300"
                    color="gray.800"
                    borderBottomRightRadius={10}
                    borderBottomLeftRadius={10}
                >
                    <Flex justifyContent="space-around" mt={2} mb={2}>
                        {!!scaledCompletedCrop ? (
                            <>
                                <Text fontWeight="bold">
                                    {`${translate("croppedSize")}: ${scaledCompletedCrop?.naturalWidth}px x ${
                                        scaledCompletedCrop?.naturalHeight
                                    }px`}
                                    {` ${translate("basedOnOriginalImageSize")} `}
                                </Text>
                            </>
                        ) : (
                            <LoadingDots numberOfDots={10} />
                        )}
                    </Flex>
                </Box>
                <ImageCropSettings
                    setCirclularCrop={settingCircularCrop}
                    isCircleCrop={circularCrop}
                    aspectRatio={aspect}
                    setAspectRatio={setAspectRatio}
                    hasGridLines={hasGridLines}
                    setGridLines={settingGridLines}
                    disableAspectRatio={disableAspects}
                />
            </Flex>
        );
    }
);
