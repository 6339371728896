import moment from "moment";
import { deviceDetect } from "react-device-detect";

import {
    Shop,
    WebOrderType,
    EatingOption,
    OrderWindow,
    UserSubscriptions,
    DISCOUNT_TYPE,
    Table,
    WebOrderDTOInput,
    OnlineEatingPreference,
    OnlinePaymentMethod,
    FoodOptions,
    EatingPreference
} from "Types";
import { isEatingPreferenceCatering, ILanguages, ClientInformationType } from "Providers";
import { timeConstants } from "Constants";
import { getCartTotalPrice, convertCartProductsToOrderProducts, calculateDeliveryFee } from "Utils";
import { formatDateToBackendUTCString } from "../../../../admin/utils/TextFormat";
import { roundNumber } from "NumberUtils";
import {
    discountToDiscountMeta,
    getGiftCardMeta,
    setAllDiscountsToDiscountMeta
} from "../../../../admin/components/poses/pos/utils/paymentHelpers";
import { OnlineCheckoutFormValues } from "src/customer/pages/onlineCheckout/OnlineCheckout";
import { parseTip, isDeliveryOrder } from "./onlineUtils";

const takeAwayEatingPreferences = [
    EatingPreference.CATERING_DELIVERY,
    EatingPreference.CATERING_TAKE_AWAY,
    EatingPreference.TAKE_AWAY,
    EatingPreference.HOME_DELIVERY
];

/**
 * Get pickup time for the order based on the pickup options.
 * @param {FoodOptions} foodOptions - The food options object.
 * @returns {string | null} - The formatted pickup time or null if it's ASAP.
 */
const getPickupTime = (foodOptions: FoodOptions) => {
    const { pickupOptions } = foodOptions;
    if (pickupOptions.time !== timeConstants.ASAP) {
        return formatDateToBackendUTCString(moment(`${pickupOptions.date} ${pickupOptions.time}`));
    }
    return null;
};

/**
 * Convert checkout form values to contact information object.
 * @param {OnlineCheckoutFormValues} values - The checkout form values object.
 * @returns {WebOrderDTOInput["contactInformation"]} - The contact information object.
 */
export const convertCheckoutFormValuesToContactInformation = (
    values: OnlineCheckoutFormValues,
    isDelivery: boolean
) => {
    const name = isDelivery ? `${values.name} ${values.lastName}` : values.name;
    const baseContactInformation = {
        name,
        email: values.email,
        phoneNumber: values?.phoneNumber || null,
        addressLine: values.addressLine,
        postCode: values.postCode,
        city: values.city,
        phoneNumber2: values.phoneNumber2,
        floorNumber: values.floorNumber,
        doorCode: values.doorCode
    };

    return baseContactInformation;
};

/**
 * Get invoice data based on the checkout form values.
 * @param {OnlineCheckoutFormValues} values - The checkout form values object.
 * @returns {OrderInvoiceData | null} - The invoice data object or null if payment method is not invoice.
 */
const getInvoiceData = (values: OnlineCheckoutFormValues) => {
    if (values.paymentMethod !== OnlinePaymentMethod.INVOICE) return null;

    const {
        invoiceData: {
            organisationNumber,
            invoiceAddress: { shouldUseSameInvoiceAddress, ...invoiceAddressToUse }
        }
    } = values;

    const baseInvoiceData = {
        organisationNumber,
        contactName: values.name,
        invoiceAddress: invoiceAddressToUse
    };

    if (shouldUseSameInvoiceAddress) {
        return {
            ...baseInvoiceData,
            invoiceAddress: {
                addressLine: values.addressLine,
                postCode: values.postCode,
                city: values.city,
                name: values.invoiceData.invoiceAddress.name
            }
        };
    } else {
        return baseInvoiceData;
    }
};

/**
 * Check if the eating preference is take away.
 * @param {EatingPreference} eatingPreference - The eating preference.
 * @returns {boolean} - Whether the eating preference is take away.
 */
const takeAway = (eatingPreference: EatingPreference) => takeAwayEatingPreferences.includes(eatingPreference);

/**
 * Check if the eating preference is home delivery.
 * @param {EatingPreference} eatingPreference - The eating preference.
 * @returns {boolean} - Whether the eating preference is home delivery.
 */
const homeDelivery = (eatingPreference: EatingPreference) => eatingPreference === EatingPreference.HOME_DELIVERY;

/**
 * Get web order type based on the online eating preference.
 * @param {OnlineEatingPreference} eatingPreference - The online eating preference.
 * @returns {WebOrderType} - The corresponding web order type.
 */
const getWebOrderType = (eatingPreference: OnlineEatingPreference) => {
    if (eatingPreference === EatingPreference.CATERING_DELIVERY) {
        return WebOrderType.CATERING_DELIVERY;
    } else if (eatingPreference === EatingPreference.CATERING_TAKE_AWAY) {
        return WebOrderType.CATERING_TAKE_AWAY;
    } else if (eatingPreference === EatingPreference.HOME_DELIVERY) {
        return WebOrderType.HOME_DELIVERY;
    } else {
        return WebOrderType.BASIC;
    }
};

/**
 * Get eating option based on the online eating preference.
 * @param {OnlineEatingPreference} eatingPreference - The online eating preference.
 * @returns {EatingOption} - The corresponding eating option.
 */
const getEatingOption = (eatingPreference: OnlineEatingPreference) => {
    if (isEatingPreferenceCatering(eatingPreference)) {
        return EatingOption.CATERING;
    } else if (eatingPreference === EatingPreference.TAKE_AWAY) {
        return EatingOption.TAKE_AWAY;
    } else if (eatingPreference === EatingPreference.HOME_DELIVERY) {
        return EatingOption.HOME_DELIVERY;
    } else {
        return EatingOption.EAT_HERE;
    }
};

/**
 * Get subscription metadata based on the order window and user subscriptions.
 * @param {OrderWindow} orderWindow - The order window object.
 * @param {UserSubscriptions} userSubscriptions - The user subscriptions object.
 * @returns {SubscriptionMeta | null} - The subscription metadata object or null if not applicable.
 */
export const getSubscriptionMeta = (orderWindow: OrderWindow, userSubscriptions: UserSubscriptions) => {
    const { fixedDiscounts } = orderWindow;

    const subscriptionDiscount = fixedDiscounts?.find(
        discount => discount.type === DISCOUNT_TYPE.SUBSCRIPTION_DISCOUNT
    );
    const userSubscription = userSubscriptions.find(
        userSubscription => userSubscription.subscription.id === subscriptionDiscount?.id
    );

    if (userSubscription && subscriptionDiscount) {
        return {
            userSubscriptionId: userSubscription.id,
            name: userSubscription.subscription.name,
            subscriptionId: subscriptionDiscount.id
        };
    }

    return null;
};

/**
 * Get table metadata based on the table and shop ID.
 * @param {Table | null} table - The table object.
 * @param {string} shopId - The shop ID.
 * @returns {TableMeta | null} - The table metadata object or null if not applicable.
 */
const getTableMeta = (table: Table | null, shopId: string) => {
    if (table) {
        return {
            id: (table.id as string) ?? "",
            name: table.name,
            shopId
        };
    }

    return null;
};

type BuildWebOrderInputArgs = {
    values: OnlineCheckoutFormValues;
    orderWindow: OrderWindow;
    foodOptions: FoodOptions;
    shop: Shop;
    table: Table | null;
    userLanguage: ILanguages | null;
    userAccountId: string | null;
    qr: any;
    clientInformation: ClientInformationType;
    userSubscriptions: UserSubscriptions;
    posId: string | null | undefined;
    anonymousUserId: string | null | undefined;
};
/**
 * Build the web order input object based on various parameters.
 * @param {OnlineCheckoutFormValues} values - The checkout form values object.
 * @param {OrderWindow} orderWindow - The order window object.
 * @param {FoodOptions} foodOptions - The food options object.
 * @param {Shop} shop - The shop object.
 * @param {Table | null} table - The table object.
 * @param {ILanguages | null} userLanguage - The user language object.
 * @param {string | null} userAccountId - The user account ID.
 * @param {any} qr - The QR code object.
 * @param {ClientInformationType} clientInformation - The client information object.
 * @param {UserSubscriptions} userSubscriptions - The user subscriptions object.
 * @param {string | null | undefined} posId - The point of sale ID.
 * @returns {WebOrderDTOInput} - The web order input object.
 */
export const buildWebOrderInput = ({
    values,
    orderWindow,
    foodOptions,
    shop,
    table,
    userLanguage,
    userAccountId,
    qr,
    clientInformation,
    userSubscriptions,
    posId,
    anonymousUserId
}: BuildWebOrderInputArgs): WebOrderDTOInput => {
    const {
        settings: { homeDeliverySettings }
    } = shop;
    const { cateringInformation, deliveryInformation, eatingPreference } = foodOptions;
    const hasMandatoryComment = !!values.mandatoryComment;
    const hasComment = !!values.comment;

    // Calculate order and delivery details
    const _isOrderDelivery = isDeliveryOrder(eatingPreference);
    const subTotal = roundNumber(getCartTotalPrice(orderWindow?.cartProducts));
    const deliveryFee = _isOrderDelivery && deliveryInformation?.fee ? deliveryInformation.fee : 0;
    const addedDeliveryFee = calculateDeliveryFee(subTotal, homeDeliverySettings.minAmountFreeDelivery, deliveryFee);
    const timeInterval = deliveryInformation?.timeInterval ?? "";

    // Determine order types
    const eatingOption = getEatingOption(eatingPreference);
    const isCatering = eatingOption === EatingOption.CATERING;
    const isTakeAway = takeAway(eatingPreference as EatingPreference);
    const isHomeDelivery = homeDelivery(eatingPreference as EatingPreference);

    const hasFixedDiscounts = !!orderWindow.fixedDiscounts?.length;
    // Get metadata for order
    const webOrderType = getWebOrderType(eatingPreference);
    const subscriptionMeta = getSubscriptionMeta(orderWindow, userSubscriptions);
    const discountMeta = hasFixedDiscounts
        ? setAllDiscountsToDiscountMeta(orderWindow)
        : discountToDiscountMeta(orderWindow);
    const giftCardMeta = getGiftCardMeta(
        orderWindow,
        values.giftCard,
        discountMeta?.totalDiscountValue,
        addedDeliveryFee
    );
    const tabeleToUse = values.table?.name ? values.table : table;
    const tableMeta = getTableMeta(tabeleToUse, shop.id);

    // Comments + mandatory comment (if there)
    const comment = hasMandatoryComment
        ? hasComment
            ? `${values.mandatoryComment} - ${values.comment}`
            : values.mandatoryComment
        : values.comment;

    return {
        thirdPartyDelivery: null,
        tableMeta,
        shopId: shop.id,
        comment: comment,
        comment2: values.comment2,
        eatingOption,
        cateringConfirmationHours:
            isCatering && cateringInformation ? cateringInformation.earliestHoursToConfirmCatering : null,
        takeAway: isTakeAway || isHomeDelivery,
        homeDelivery: isHomeDelivery,
        timeInterval: timeInterval,
        deliveryFee: addedDeliveryFee,
        paymentInformation: {
            // @ts-ignore
            paymentMethod: values.paymentMethod
        },
        subscriptionMeta,
        giftCardMeta,
        discountMeta: hasFixedDiscounts
            ? setAllDiscountsToDiscountMeta(orderWindow)
            : discountToDiscountMeta(orderWindow),
        orderProducts: convertCartProductsToOrderProducts(orderWindow?.cartProducts),
        pickupTime: getPickupTime(foodOptions),
        contactInformation: convertCheckoutFormValuesToContactInformation(values, _isOrderDelivery),
        invoiceData: getInvoiceData(values),
        deviceInformation: deviceDetect(window.navigator.userAgent),
        webOrderType,
        acceptsMarketing: values.acceptsCampaigns,
        userLanguage,
        userAccountId,
        qr,
        clientInformation,
        tip: parseTip(values.tip),
        posId,
        anonymousUserId
    };
};
