import React from "react";
import { useLanguage } from "Providers";
import { Control } from "react-hook-form";

import { BaseBoxProps } from "../Box/Box";
import { Stack } from "../Stack/Stack";
import { RHFormInput } from "./RHFormInput";

export type RHDeliveryContactInformationProps = {
    control: Control<any>;
} & BaseBoxProps;

export const RHDeliveryContactInformation: React.FC<RHDeliveryContactInformationProps> = ({ control, ...rest }) => {
    const { translate } = useLanguage();

    return (
        <Stack stretch={6} {...rest}>
            <RHFormInput control={control} name="addressLine" placeholder={translate("deliveryAddress")} isMandatory />
            <RHFormInput control={control} name="postCode" placeholder={translate("postCode")} isMandatory isReadOnly />
            <RHFormInput control={control} name="city" placeholder={translate("district")} isMandatory />
            <Stack isInline>
                <RHFormInput
                    control={control}
                    name="doorCode"
                    placeholder={translate("portCode")}
                    wrapperProps={{ mr: 4, mb: 0, w: "full" }}
                />
                <RHFormInput
                    control={control}
                    name="floorNumber"
                    placeholder={translate("floorNo")}
                    wrapperProps={{ mb: 0, w: "full" }}
                />
            </Stack>
        </Stack>
    );
};
