import React, { useEffect, useMemo } from "react";
import { useForm, Control, useWatch } from "react-hook-form";
import { TypeAnimation } from "react-type-animation";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { MdTouchApp } from "@react-icons/all-files/md/MdTouchApp";
import { VscDebugRestart } from "@react-icons/all-files/vsc/VscDebugRestart";
import { FaCloudUploadAlt } from "@react-icons/all-files/fa/FaCloudUploadAlt";

import {
    BetaLabel,
    Box,
    Button,
    Flex,
    ImageProps,
    LoadingSkeleton,
    RHCheckBoxGroupInput,
    Stack,
    Text,
    useHasImageLoaded
} from "Atoms";
import { Modal, ModalActions, ModalBody, ModalCloseBtn, ModalHeader } from "Molecules";
import { modals, ProductType, useLanguage, useModal } from "Providers";
import { MENU_UPLOAD_STATE, useMenuUploadStore, useQoplaStore } from "Stores";
import {
    PRODUCT_CATEGORY_UNCATEGORIZED,
    convertHEXToRGBA,
    createInitialRefProductsFromPreviewProductsAndCategories,
    formatFinancialNumbers,
    getCategoriesFromPreviewProducts,
    showToastError
} from "Utils";
import { useTheme } from "Providers";
import { QAnalysisMenuAPI } from "../menu-analysis/QAnalysisAPI";
import { useEffectOnce } from "Hooks";
import { confirmOrCancelNotification } from "TempUtils";
import { MenuPreviewProduct, RefBundleProduct, RefProduct, RefProductCategory } from "Types";

const ImagePreview = ({ src, ...rest }: ImageProps) => {
    return (
        <img
            src={src}
            style={{
                objectFit: "contain",
                borderRadius: "0.75rem",
                minWidth: "4rem",
                minHeight: "5rem",
                maxWidth: "100%",
                maxHeight: "15rem"
            }}
            {...rest}
            width="100%"
            height="auto"
        />
    );
};

type ProductLibraryUploadFormValues = {
    [key: string]: string[];
};

const MenuUploadProductsSelection = ({
    menuProducts,
    control
}: {
    menuProducts: MenuPreviewProduct[];
    control: Control<ProductLibraryUploadFormValues>;
}) => {
    const { companyLocale } = useQoplaStore();

    const menuPreviewProductsByCategory = menuProducts?.reduce((acc, product) => {
        const productCategory = product.category || PRODUCT_CATEGORY_UNCATEGORIZED;

        if (!acc[productCategory]) {
            acc[productCategory] = [];
        }

        acc[productCategory].push(product);

        return acc;
    }, {} as Record<string, MenuPreviewProduct[]>);

    return (
        <Stack>
            {Object.entries(menuPreviewProductsByCategory).map(([category, products]) => {
                const options = [
                    ...products.map(product => {
                        const formattedTitle = product.title;
                        const formattedPrice = formatFinancialNumbers(+(product.price || 0), companyLocale);

                        // Prevents the trailing "-" if there is no description
                        const labelValues = [formattedTitle, formattedPrice];
                        if (product?.description) {
                            labelValues.push(product.description);
                        }
                        return {
                            label: labelValues.join(" - "),
                            value: product.title,
                            isDisabled: false
                        };
                    })
                ];

                return (
                    <RHCheckBoxGroupInput
                        key={category}
                        options={options}
                        groupHeader={category}
                        control={control}
                        name={category}
                        selectAndUnselectAllType="checkbox"
                        rowMargin={2}
                    />
                );
            })}
        </Stack>
    );
};

type Props = {
    modalContent: {
        updateCategories: (categories: RefProductCategory[]) => Promise<RefProductCategory[] | null>;
        saveProduct: (
            products: ProductType[],
            modificationChanged: boolean,
            oldCategoryId?: string
        ) => Promise<(RefProduct | RefBundleProduct)[] | null>;
    };
};

export const ProductLibraryMenuUploadModal = ({ modalContent }: Props) => {
    const { updateCategories, saveProduct } = modalContent;
    const {
        isLoading,
        base64Image,
        setBase64Image,
        loadStore,
        menuUploadState,
        setMenuUploadState,
        initialScanResults,
        setInitialScanResults,
        uploadResults,
        setUploadResults,
        clearUploadData
    } = useMenuUploadStore();
    const { closeModal } = useModal();

    // TESTING: To test using test produces and already finished menu state
    // const menuProducts = testMenuProducts;
    // const menuUploadState = MENU_UPLOAD_STATE.FINISHED;
    const menuProducts = uploadResults?.menu_items ?? [];

    const defaultValues = useMemo(() => {
        return menuProducts.reduce((acc: ProductLibraryUploadFormValues, product) => {
            const productCategory = product.category || PRODUCT_CATEGORY_UNCATEGORIZED;

            if (!acc[productCategory]) {
                acc[productCategory] = [];
            }

            acc[productCategory].push(product.title);

            return acc as ProductLibraryUploadFormValues;
        }, {});
    }, [menuProducts]);

    const {
        control,
        setValue,
        formState: { isSubmitting },
        handleSubmit
    } = useForm<ProductLibraryUploadFormValues>({
        defaultValues: defaultValues,
        mode: "onChange"
    });

    // Set initial values after the form has been initialized
    useEffect(() => {
        // The default values should be checked by default
        Object.keys(defaultValues).forEach(key => {
            setValue(key, defaultValues[key]);
        });
    }, [defaultValues]);

    const values = useWatch({ control });

    const { translate, translateWithArgument } = useLanguage();
    const image = base64Image as string | undefined;
    const hasImageLoaded = useHasImageLoaded({ src: image });

    const imagePreview = useMemo(() => {
        if (image === undefined || isLoading || !hasImageLoaded)
            return <LoadingSkeleton columns={1} rows={1} height={"15rem"} width={"100%"} />;

        return <ImagePreview src={image} />;
    }, [image, isLoading, hasImageLoaded]);
    const numProductsSelected = Object.values(values).reduce(
        (acc: number, category) => acc + (category as string[]).length,
        0
    );

    useEffectOnce(() => {
        // This loads the image from the IndexedDB into the zustand store
        loadStore();
    });

    const { selectedCompany } = useQoplaStore();

    const createRefCategoriesAndProducts = async (selectedMenuProducts: MenuPreviewProduct[]) => {
        // Collect, create, and save RefProductCategories
        const categoryNames = getCategoriesFromPreviewProducts(selectedMenuProducts);
        const categoriesToSave = categoryNames.map(categoryName => {
            return {
                companyId: selectedCompany?.id,
                description: "",
                name: categoryName,
                sortOrder: 0
            } as RefProductCategory;
        });

        const savedCategories = await updateCategories(categoriesToSave);
        if (!savedCategories) {
            throw new Error(translate("errorCreatingCategories"));
        }

        // Use the saved categories to create and save RefProducts
        const products = createInitialRefProductsFromPreviewProductsAndCategories(
            selectedMenuProducts,
            savedCategories
        );
        const savedProducts = await saveProduct(products, false);
        if (!savedProducts) {
            throw new Error(translate("errorCreatingProducts"));
        }
    };

    useEffect(() => {
        if (uploadResults) {
            setMenuUploadState(MENU_UPLOAD_STATE.FINISHED);
        } else if (isSubmitting) {
            setMenuUploadState(MENU_UPLOAD_STATE.UPLOADING);
        } else if (image) {
            setMenuUploadState(MENU_UPLOAD_STATE.PREVIEW);
        } else {
            setMenuUploadState(MENU_UPLOAD_STATE.SELECTING);
        }
    }, [image, isSubmitting, uploadResults]);

    const onSubmit = async () => {
        if (isFinished) {
            const selectedProductTitles = Object.values(values).flat();

            // If you need to match these values back to your original menuProducts
            const selectedProducts = menuProducts.filter(product => selectedProductTitles.includes(product.title));

            try {
                await createRefCategoriesAndProducts(selectedProducts);
                closeModal(modals.productLibraryMenuUploadModal);
            } catch (error) {
                console.error("Error creating ref categories and products: ", error);
                showToastError(String(error));
            }
        } else if (isPreview) {
            if (!image) {
                throw new Error("No image selected");
            }

            try {
                const uploadInitialScanResults = await QAnalysisMenuAPI.uploadMenuInitialScan(image);
                const uploadInitialScanResultsString = uploadInitialScanResults.results as string;

                // Split on new lines
                const menuItemStrings = uploadInitialScanResultsString.split("\n");
                const cleanedMenuItemStrings = menuItemStrings.map(str => str.trim()).filter(str => str.length > 0);

                // If fewer than 3 lines are returned, the menu is likely empty
                if (cleanedMenuItemStrings.length < 3) {
                    throw new Error("Menu is empty, or too few items were detected. Please try again.");
                }
                setInitialScanResults(cleanedMenuItemStrings);

                // Finished initial scan, now parse the menu
                const uploadResults = await QAnalysisMenuAPI.uploadMenuParse(image);
                setUploadResults(uploadResults);
            } catch (error) {
                console.error("Error uploading menu: ", error);
                showToastError(translate("errorUploadingMenu"));
            }
        }
    };
    const onPrepareAndSetImage = (base64image: string) => {
        // Parse and print the size of the image in MB
        const sizeInMB = (base64image.length * 3) / 4 / 1000000;

        if (sizeInMB > 20) {
            toast.error(translate("qMenuImageTooLarge"));
            return;
        }

        setBase64Image(base64image);
    };
    const onDrop = async (files: any) => {
        const file = files[0];

        // Get Base64 image from file
        const reader = new FileReader();
        reader.onload = () => {
            const base64image = reader.result as string;
            onPrepareAndSetImage(base64image);
        };

        reader.readAsDataURL(file);
    };

    const onResetMenuUpload = async () => {
        clearUploadData();
    };

    /*
      Weird issue causing re-render `onBlur` after uploading an image
      This led to needing to click the button twice to submit the form
      To negate this, we need to prevent the onBlur event from firing
      According to the docs, the onBlur event can be nullified by setting

      Ref https://react-dropzone.js.org/#src
       > If you'd like to selectively turn off the default dropzone behavior for onKeyDown, onFocus and onBlur, use the {noKeyboard} property:
    */
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, noKeyboard: true });

    // Remove the ref from the rootProps, it causes a warning in the console
    const { ref, ...rootProps } = getRootProps();

    const { colors } = useTheme();
    const currentColorScheme = {
        primary: colors.green[500],
        primaryVariant: colors.green[600],
        secondary: colors.green[400],
        secondaryVariant: colors.green[500],
        surface: colors.green[100],
        surfaceTint: colors.green[200],
        background: colors.green[50],
        backgroundTint: colors.green[100],
        secondaryContainer: colors.green[200],
        onSecondaryContainer: colors.green[500],
        onPrimary: colors.white,
        onSecondary: colors.black,
        onBackground: colors.black,
        onSurface: colors.black,
        onSurfaceTint: colors.black,
        outline: colors.green[300],
        shadow: colors.green[300]
    };

    const isValid = !!image;

    const isSelecting = menuUploadState === MENU_UPLOAD_STATE.SELECTING;
    const isPreview = menuUploadState === MENU_UPLOAD_STATE.PREVIEW;
    const isUploading = menuUploadState === MENU_UPLOAD_STATE.UPLOADING;
    const isFinished = menuUploadState === MENU_UPLOAD_STATE.FINISHED;
    const isSubmitDisabled = isFinished ? numProductsSelected === 0 : !isValid;

    const canReset = !isSelecting;

    const canSelectImage = [MENU_UPLOAD_STATE.SELECTING, MENU_UPLOAD_STATE.PREVIEW].includes(menuUploadState);
    /**
     * Create an animated sequence of the initial scan results
     * Link to the TypedAnimation documentation: https://react-type-animation.netlify.app/examples
     */
    const animatedInitialResultsSequence: any[] = [];
    if (!!initialScanResults?.length) {
        for (let i = 0; i < initialScanResults.length; i++) {
            // String to type
            animatedInitialResultsSequence.push(initialScanResults.slice(0, i + 1).join(" ... "));
            // Delay until the next string
            animatedInitialResultsSequence.push(200);
        }
    }

    return (
        <Modal
            size="lg"
            open
            onClose={() => closeModal(modals.productLibraryMenuUploadModal)}
            isScrolling
            overlayProps={{ zIndex: 1400 }}
        >
            <ModalHeader>
                <Flex alignItems="center" justifyContent="center" style={{ columnGap: "0.5rem" }}>
                    {"AI Upload Menu"}
                    <BetaLabel themeColor="red" />
                </Flex>
            </ModalHeader>
            <ModalCloseBtn />
            <ModalBody pt={2}>
                {menuUploadState !== MENU_UPLOAD_STATE.FINISHED && (
                    <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
                        <Flex w="100%" alignContent={"center"} justifyContent={"center"}>
                            <Stack
                                w="100%"
                                minH={"16rem"}
                                m={4}
                                rounded={4}
                                p={4}
                                cursor="pointer"
                                stretch={4}
                                // Glassmorphism effect
                                backgroundColor={convertHEXToRGBA(currentColorScheme.secondaryContainer, 0.5)}
                                boxShadow={`1px 3px 4px 0px ${currentColorScheme.outline}`}
                                style={{ backdropFilter: `blur(5px)`, WebkitBackdropFilter: `blur(5px)` }}
                                border={`1px dashed ${currentColorScheme.surfaceTint}`}
                                {...rootProps}
                            >
                                <input {...getInputProps()} disabled={!canSelectImage} accept="image/*" type="file" />

                                {isSelecting && (
                                    <Flex mb={0} display={"inline"}>
                                        <Box
                                            as={MdTouchApp}
                                            color={currentColorScheme.onBackground}
                                            fontSize={"1.3rem"}
                                        />
                                        <Text
                                            display={"inline"}
                                            color={currentColorScheme.onBackground}
                                            fontSize="1.1rem"
                                            fontWeight={"bold"}
                                            ml={1}
                                        >
                                            {isDragActive ? translate("dropTheFilesHere") : translate("tapToChoose")}
                                        </Text>
                                    </Flex>
                                )}
                                {isSelecting && (
                                    <Flex
                                        justifyContent="space-evenly"
                                        alignContent={"center"}
                                        alignItems={"center"}
                                        w="100%"
                                        my={12}
                                        mx={4}
                                    >
                                        <Box
                                            as={FaCloudUploadAlt}
                                            size="6rem"
                                            color={currentColorScheme.onSecondaryContainer}
                                            filter={`drop-shadow(0px 2px 2px ${currentColorScheme.onSecondaryContainer})`}
                                        />
                                    </Flex>
                                )}
                                {[MENU_UPLOAD_STATE.PREVIEW, MENU_UPLOAD_STATE.UPLOADING].includes(menuUploadState) && (
                                    <Box>
                                        <Text
                                            w="100%"
                                            fontWeight="bold"
                                            color={currentColorScheme.onBackground}
                                            fontSize="1rem"
                                            textAlign="center"
                                        >
                                            {isUploading && translate("creatingLivePreview")}
                                        </Text>
                                        {isUploading && !!animatedInitialResultsSequence?.length && (
                                            <TypeAnimation
                                                sequence={animatedInitialResultsSequence}
                                                cursor={false}
                                                style={{
                                                    fontSize: "0.8rem",
                                                    fontWeight: "bold",
                                                    color: currentColorScheme.onBackground,
                                                    textAlign: "start",
                                                    flexDirection: "column-reverse",
                                                    maxHeight: "2.8rem",
                                                    display: "flex",
                                                    overflow: "hidden"
                                                }}
                                                speed={70}
                                                repeat={Infinity}
                                            />
                                        )}
                                        {imagePreview}

                                        {isPreview && (
                                            <Text
                                                color={currentColorScheme.onBackground}
                                                fontSize="0.8rem"
                                                fontWeight={"bold"}
                                                textAlign={"center"}
                                            >
                                                {translate("tapToChange")}
                                            </Text>
                                        )}
                                    </Box>
                                )}
                            </Stack>
                        </Flex>
                    </form>
                )}
                {menuUploadState === MENU_UPLOAD_STATE.FINISHED && (
                    <MenuUploadProductsSelection menuProducts={menuProducts} control={control} />
                )}
            </ModalBody>
            <ModalActions display="flex" justifyContent="space-between">
                <Button
                    leftIcon={VscDebugRestart}
                    themeColor="red"
                    isDisabled={isSubmitting || !canReset}
                    onClick={async () => {
                        const { value } = await confirmOrCancelNotification(
                            translate("qMenuResetMenuUpload"),
                            translate("qMenuResetAreYouSure"),
                            translate("yes"),
                            colors.green["500"],
                            translate("cancel")
                        );
                        if (value) {
                            onResetMenuUpload();
                        }
                    }}
                >
                    <Text>Reset</Text>
                </Button>

                <Button
                    themeColor="blue"
                    onClick={handleSubmit(onSubmit)}
                    isDisabled={isSubmitting || isSubmitDisabled}
                    isLoading={isSubmitting}
                >
                    {isFinished ? (
                        <Text>{translateWithArgument("createChosenProducts", numProductsSelected)}</Text>
                    ) : (
                        <Text>{translate("upload")}</Text>
                    )}
                </Button>
            </ModalActions>
        </Modal>
    );
};
