import React from "react";

import { BaseBoxProps, Box, useHasImageLoaded } from "Atoms";
import { useTheme } from "Providers";
import { useAvatarStyles, avatarSizes } from "./styles";

export type AvatarSizes = "2xs" | "xs" | "sm" | "md" | "lg" | "xl" | "2xl" | "full";

export interface IAvatar {
    name?: string;
    size?: AvatarSizes;
    showBorder?: boolean;
    children?: React.ReactNode;
    src?: string;
}

export type AvatarNameProps = {
    name: IAvatar["name"];
} & BaseBoxProps;
export type AvatarProps = IAvatar & BaseBoxProps;

const getInitials = (name: string) => {
    const [firstName, lastName] = name.split(" ");

    if (firstName && lastName) {
        return `${firstName.charAt(0)}${lastName.charAt(0)}`;
    } else {
        return firstName.charAt(0);
    }
};

const AvatarName: React.FC<AvatarNameProps> = ({ name, ...props }) => (
    <Box textAlign="center" textTransform="uppercase" {...props}>
        {name ? getInitials(name) : null}
    </Box>
);

const DefaultAvatar = (props: BaseBoxProps) => (
    <Box size="100%" {...props}>
        <svg fill="#fff" viewBox="0 0 128 128" role="img">
            <g>
                <path d="M103,102.1388 C93.094,111.92 79.3504,118 64.1638,118 C48.8056,118 34.9294,111.768 25,101.7892 L25,95.2 C25,86.8096 31.981,80 40.6,80 L87.4,80 C96.019,80 103,86.8096 103,95.2 L103,102.1388 Z" />
                <path d="M63.9961647,24 C51.2938136,24 41,34.2938136 41,46.9961647 C41,59.7061864 51.2938136,70 63.9961647,70 C76.6985159,70 87,59.7061864 87,46.9961647 C87,34.2938136 76.6985159,24 63.9961647,24" />
            </g>
        </svg>
    </Box>
);

export const Avatar: React.FC<AvatarProps> = ({ name, size = "md", src, children, ...rest }) => {
    const avatarStyleProps = useAvatarStyles({
        hasImage: !!src,
        name,
        size
    });
    const hasLoaded = useHasImageLoaded({ src });

    const theme = useTheme();
    //@ts-ignore
    const sizeKey = avatarSizes[size];
    const _size = theme.sizes[sizeKey];
    const fontSize = `calc(${_size} / 2.5)`;

    const renderChildren = () => {
        if (src && hasLoaded) {
            return <Box as="img" size="100%" rounded="full" objectFit="cover" src={src} alt={name} />;
        }

        if (src && !hasLoaded) {
            if (name) {
                return <AvatarName size={_size} name={name} />;
            } else {
                return <DefaultAvatar aria-label={name} />;
            }
        }

        if (!src && name) {
            return <AvatarName size={_size} name={name} />;
        }

        return <DefaultAvatar aria-label={name} />;
    };

    return (
        <Box fontSize={fontSize} lineHeight={_size} verticalAlign="top" {...avatarStyleProps} {...rest}>
            {renderChildren()}
            {children}
        </Box>
    );
};
