import React from "react";
import { Control } from "react-hook-form";

import { useLanguage } from "Providers";
import { BaseBoxProps, Stack } from "../";
import { RHFormInput, RHPhoneInputWithCountryCode } from "./";

export type RHBasicContactInformationProps = {
    control: Control<any>;
    isEmailDisabled: boolean;
    shouldShowLastName: boolean;
} & BaseBoxProps;

export const RHBasicContactInformation: React.FC<RHBasicContactInformationProps> = ({
    control,
    isEmailDisabled,
    shouldShowLastName,
    ...rest
}) => {
    const { translate } = useLanguage();

    const namePlaceHolder = translate(shouldShowLastName ? "firstName" : "name");

    return (
        <Stack stretch={6} {...rest}>
            <Stack isInline w="full">
                <RHFormInput
                    control={control}
                    name="name"
                    placeholder={namePlaceHolder}
                    isMandatory
                    isFullWidth
                    wrapperProps={{ mr: shouldShowLastName ? 4 : 0, mb: 0, w: "full" }}
                />
                {shouldShowLastName && (
                    <RHFormInput
                        control={control}
                        name="lastName"
                        placeholder={translate("lastName")}
                        isMandatory
                        isFullWidth
                        wrapperProps={{ mb: 0, w: "full" }}
                    />
                )}
            </Stack>
            <RHPhoneInputWithCountryCode control={control} name="phoneNumber" isMandatory />
            <RHFormInput
                control={control}
                name="email"
                placeholder={translate("email")}
                isMandatory
                isDisabled={isEmailDisabled}
                wrapperProps={{ mb: 0 }}
            />
        </Stack>
    );
};
