const redirectIssueProps = ["data.getShopByUrl", "getPublicShopById.getPublicShopById", "networkError.result.data"];

/** NOTE THIS FILE IS IN THE TEST SUITE  */

/**
 * [FUNCTION] check object has a property
 * @param obj
 * @param prop
 * @returns true or false
 */
export const hasProperty = (obj: Object, prop: string) => {
    return Reflect.has(obj, prop);
};

/**
 * [FUNCTION] get drilled down object values from redirect issues
 * @param json
 * @param value
 * @returns undefined or null
 * @description if undefined then it's not there if null it is there
 * @description uses Redirect isssue props
 */
export const checkNestedObject = (json: Object, value: string) => {
    const propDrill = value.split(".");
    return propDrill.reduce((obj: Object | undefined, prop: string) => {
        if (typeof obj === "object") {
            obj = hasProperty(obj, prop) ? Reflect.get(obj, prop) : undefined;
        }
        return obj;
    }, json);
};

/**
 * [FUNCTION] checks the sentry error before it sends, if the error has been set up not to be sent
 * will return a null if not will return the error
 * @param sentryError
 * @returns
 */
export const sentryErrorHelper = (sentryError: Event) => {
    try {
        if (hasProperty(sentryError, "extra")) {
            const extra = Reflect.get(sentryError, "extra");
            const { REDIRECT_REASONS = null } = extra || {};
            if (REDIRECT_REASONS) {
                const json = JSON.parse(REDIRECT_REASONS);
                const hasNullProp = redirectIssueProps.some((value: string) => {
                    const drilled = checkNestedObject(json, value);
                    return drilled === null;
                });
                return hasNullProp ? null : sentryError;
            }
        }
        return sentryError;
    } catch (error) {
        return sentryError;
    }
};
