import gql from "graphql-tag";

import {
    LAST_ORDER_PRODUCT_ATTRIBUTES,
    ORDER_ATTRIBUTES,
    ORDER_PRODUCT_ATTRIBUTES,
    SERVICE_FEE_ATTRIBUTES
} from "../fragments";

export const GET_COMPANY_RIGHTS = gql`
    query getCompanyRights($companyId: String) {
        getCompanyRights(companyId: $companyId) {
            onlineEnabled
            posEnabled
        }
    }
`;

export const GET_INVOICES = gql`
    query getInvoiceOrders($shopId: String!, $startDate: String!, $endDate: String!) {
        getInvoiceOrders(shopId: $shopId, startDate: $startDate, endDate: $endDate) {
            ... on Order {
                ...orderResults
            }
        }
    }
    ${ORDER_ATTRIBUTES}
`;

// TODO: Can't find where this is used..
export const GET_ONLINE_ORDERS = gql`
    query getOnlineOrders(
        $shopId: String!
        $startDate: String!
        $endDate: String!
        $onlineOrderStatuses: [OnlineOrderStatus]
    ) {
        getOnlineOrders(
            shopId: $shopId
            startDate: $startDate
            endDate: $endDate
            onlineOrderStatuses: $onlineOrderStatuses
        ) {
            ... on Order {
                ...orderResults
            }
        }
    }
    ${ORDER_ATTRIBUTES}
`;

export const GET_CATERING_ORDER = gql`
    query getCateringOrderQuery($orderId: String!) {
        getCateringKitchenOrdersByOrderId(orderId: $orderId) {
            id
            status
            orderStatusTimestamps {
                status
                timestamp
            }
        }
        getCateringOrder(orderId: $orderId) {
            ... on Order {
                ...orderResults
            }
        }
    }
    ${ORDER_ATTRIBUTES}
`;

export const GET_CATERING_ORDERS_BY_ORDER_IDS = gql`
    query getCateringOrdersByOrderIdsQuery($orderIds: [String]!) {
        getCateringOrdersByOrderIds(orderIds: $orderIds) {
            ... on Order {
                ...orderResults
            }
        }
    }
    ${ORDER_ATTRIBUTES}
`;

export const GET_CATERING_ORDERS_BY_SHOP_ID = gql`
    query getCateringOrdersByShopIdQuery(
        $shopId: String!
        $showOngoing: Boolean
        $startDate: String
        $endDate: String
    ) {
        getCateringOrdersByShopId(
            shopId: $shopId
            showOngoing: $showOngoing
            startDate: $startDate
            endDate: $endDate
        ) {
            ... on Order {
                ...orderResults
            }
        }
    }
    ${ORDER_ATTRIBUTES}
`;

export const GET_SWISH_PAYMENT_RESPONSE_QUERY = gql`
    query getSwishPaymentResponseQuery($orderId: String!) {
        getKitchenOrdersByOrderId(orderId: $orderId) {
            id
            status
            denyReason
            orderStatusTimestamps {
                status
                timestamp
            }
        }
        getSwishPaymentResponse(orderId: $orderId) {
            order {
                ... on Order {
                    ...orderResults
                }
            }
            webPaymentResponse {
                errorText
                operationSuccess
                transactionId
                qrCode
            }
        }
    }
    ${ORDER_ATTRIBUTES}
`;

export const GET_SWISH_REFUND_RESPONSE_QUERY = gql`
    query getSwishRefundResponseQuery($orderId: String!) {
        getSwishRefundResponse(orderId: $orderId) {
            order {
                ... on Order {
                    ...orderResults
                }
            }
            webPaymentResponse {
                errorText
                operationSuccess
                transactionId
            }
        }
    }
    ${ORDER_ATTRIBUTES}
`;

// TODO: Can't find where this is used..
export const SEARCH_ORDER_HISTORY_BY_DATE_AND_TOTALAMOUNT = gql`
    query searchOrderHistoryByDateAndTotalAmount(
        $shopId: String
        $startDate: String
        $endDate: String
        $totalAmount: Float
    ) {
        searchOrderHistoryByDateAndTotalAmount(
            shopId: $shopId
            startDate: $startDate
            endDate: $endDate
            totalAmount: $totalAmount
        ) {
            ... on Order {
                ...orderResults
            }
        }
    }
    ${ORDER_ATTRIBUTES}
`;

export const SEARCH_REFUND_ORDER_BY_REFERENCE_ORDER_ID = gql`
    query searchRefundOrderByReferenceOrderId($shopId: String, $referenceOrderId: String) {
        searchRefundOrderByReferenceOrderId(shopId: $shopId, referenceOrderId: $referenceOrderId) {
            ... on Order {
                ...orderResults
            }
        }
    }
    ${ORDER_ATTRIBUTES}
`;

export const SEARCH_REFUND_ORDER_AND_GET_ORDER_BY_ORDER_ID = gql`
    query searchRefundOrderByReferenceOrderId($shopId: String, $referenceOrderId: String) {
        searchRefundOrderByReferenceOrderId(shopId: $shopId, referenceOrderId: $referenceOrderId) {
            ... on Order {
                ...orderResults
            }
        }
        getOrderById(orderId: $referenceOrderId) {
            ... on Order {
                ...orderResults
            }
        }
    }
    ${ORDER_ATTRIBUTES}
`;

// TODO: Can't find where this is used..
export const SEARCH_ORDER_BY_RECEIPTNUMBER = gql`
    query searchOrderByReceiptNumber($shopId: String, $receiptNumber: Long) {
        searchOrderByReceiptNumber(shopId: $shopId, receiptNumber: $receiptNumber) {
            ... on Order {
                ...orderResults
            }
        }
    }
    ${ORDER_ATTRIBUTES}
`;

/**
 *  TODO: Can't find where this is used..
 *    Also, not sure how to migrate this to fragments..
 */
export const FETCH_CLIENT_ORDER_HISTORY = gql`
    {
        orderHistory @client {
            __typename
            orders {
                id
                orderNo
                receiptNumber
                totalAmount
                purchaseDate
                orderProducts {
                    quantity
                    priceType
                    weight
                    name
                    unitPrice
                    totalPrice
                    vatRate
                    refProductId
                    selectedBundleProductItems {
                        name
                        refProductId
                        kdsUnitDisplayName
                        bundleProductCategoryId
                        modifications {
                            sizes {
                                name
                                price
                                addonPrice
                            }
                            flavours {
                                name
                                price
                                addonPrice
                            }
                        }
                    }
                    modifications {
                        sizes {
                            name
                            price
                            addonPrice
                        }
                        flavours {
                            name
                            price
                            addonPrice
                        }
                    }
                }
            }
        }
    }
`;

export const CUT_DOWN_SEARCH_ORDER_HISTORY = gql`
    query getReducedSearchOrderHistory(
        $endDate: String
        $shopId: String
        $startDate: String
        $orderTypes: [OrderType]
        $includeOrderProducts: Boolean!
    ) {
        getReducedSearchOrderHistory(
            endDate: $endDate
            shopId: $shopId
            startDate: $startDate
            orderTypes: $orderTypes
            includeOrderProducts: $includeOrderProducts
        ) {
            id
            createdAt
            shopId
            totalAmount
            receiptNumber
            customerId
            purchaseDate
            paymentMethodAmounts {
                paymentMethod
                cardType
            }
            orderNo
            orderType
            webOrderType
            receiptType
            POSReceiptType
            onlineOrderStatus
            swishResponses {
                status
                amount
            }
            eatingOption
            thirdPartyDelivery
            paymentStatus
            refundStatus
            failedReason
            userInformation {
                onlineContactInformation {
                    email
                }
            }
            onlineOrderStatusTimestamps {
                status
                timestamp
            }
            discountMeta {
                name
                code
            }
            orderProducts {
                ... on OrderProduct @include(if: $includeOrderProducts) {
                    ...orderProductResults
                }
            }
            __typename
        }
    }
    ${ORDER_PRODUCT_ATTRIBUTES}
`;

export const FULL_SEARCH_ORDER_HISTORY = gql`
    query searchOrderHistory(
        $totalAmount: Float
        $orderNo: Int
        $receiptType: ReceiptType
        $excludeReceiptType: Boolean
        $orderId: String
        $endDate: String
        $referenceOrderId: String
        $shopId: String
        $paymentStatus: PaymentStatus
        $receiptNumber: Long
        $startDate: String
        $controlUnitFailure: Boolean
        $orderTypes: [OrderType]
    ) {
        searchOrderHistory(
            totalAmount: $totalAmount
            orderNo: $orderNo
            receiptType: $receiptType
            excludeReceiptType: $excludeReceiptType
            orderId: $orderId
            endDate: $endDate
            referenceOrderId: $referenceOrderId
            shopId: $shopId
            paymentStatus: $paymentStatus
            receiptNumber: $receiptNumber
            startDate: $startDate
            controlUnitFailure: $controlUnitFailure
            orderTypes: $orderTypes
        ) {
            ... on Order {
                ...orderResults
            }
        }
    }
    ${ORDER_ATTRIBUTES}
`;

export const GET_ORDERS_BY_GIFT_CARD_ID = gql`
    query getOrdersByUserGiftCardId($userGiftCardId: String) {
        getOrdersByUserGiftCardId(userGiftCardId: $userGiftCardId) {
            id
            shopName
            receiptNumber
            totalAmount
            purchaseDate
            onlineOrderStatus
            giftCardMeta {
                amount
            }
        }
    }
`;

export const GET_ONLINE_ORDER_BY_ID = gql`
    query getOnlineOrderById($orderId: String) {
        getOnlineOrder(orderId: $orderId)
    }
`;

export const GET_ORDERS_WITH_FEEDBACK_BY_USER_ACCOUNT_ID = gql`
    query getOrdersWithNoFeedback($orderIds: [String]!) {
        getOrdersWithNoFeedback(orderIds: $orderIds)
    }
`;

export const GET_ORDERS_BY_ORDER_IDS = gql`
    query getOrdersByOrderIds($orderIds: [String]!) {
        getOrdersByOrderIds(orderIds: $orderIds) {
            ... on Order {
                ...orderResults
            }
        }
    }
    ${ORDER_ATTRIBUTES}
`;
export const GET_ORDER_BY_ID = gql`
    query getOrderById($orderId: String) {
        getOrderById(orderId: $orderId) {
            ... on Order {
                ...orderResults
            }
        }
    }
    ${ORDER_ATTRIBUTES}
`;

export const GET_ORDER_AND_KITCHEN_TIMESTAMPS_BY_ID = gql`
    query getOrderById($orderId: String) {
        getOrderById(orderId: $orderId) {
            ... on Order {
                ...orderResults
                orderProducts {
                    ... on OrderProduct {
                        ...orderProductResults
                        discountValue
                    }
                }
            }
        }
        getKitchenOrderTimestampsByOrderId(orderId: $orderId) {
            status
            onlineOrderStatus
            shopTableStatus
            timestamp
        }
    }
    ${ORDER_ATTRIBUTES}
`;

export const CALCULATE_SERVICE_FEE = gql`
    query calculateServiceFee(
        $shopId: String
        $orderType: OrderType
        $webOrderType: WebOrderType
        $eatingOption: EatingOption
        $subscriptionMeta: SubscriptionMetaInput
        $isUsingGiftCards: Boolean!
        $discountRate: Float!
    ) {
        calculateServiceFee(
            shopId: $shopId
            orderType: $orderType
            webOrderType: $webOrderType
            eatingOption: $eatingOption
            subscriptionMeta: $subscriptionMeta
            isUsingGiftCards: $isUsingGiftCards
            discountRate: $discountRate
        ) {
            ... on ServiceFee {
                ...serviceFeeResults
            }
        }
    }
    ${SERVICE_FEE_ATTRIBUTES}
`;

export const GET_UNHANDLED_INVOICE_NUMBER = gql`
    query getUnhandledInvoiceNumber($shopId: String!) {
        getUnhandledInvoiceNumber(shopId: $shopId)
    }
`;

export const GET_SWISH_PAYMENT_STATUS = gql`
    query getSwishPaymentStatus($orderId: String!) {
        getSwishPaymentStatus(orderId: $orderId) {
            id
            payeePaymentReference
            payerPaymentReference
            originalPaymentReference
            paymentReference
            payerAlias
            payeeAlias
            callbackUrl
            amount
            message
            status
            dateCreated
            datePaid
            errorCode
            errorMessage
            additionalInformation
            paymentRequestToken
            qrFormat
            currency
        }
    }
`;

export const GET_LAST_ORDER_BY_USER_ID_OR_ANONYMOUS_USER_ID = gql`
    query getLastOrderByShopAndAnonymousUserIdOrUserAccountId(
        $shopId: String
        $userAccountId: String
        $anonymousUserId: String
        $menuIds: [String]
    ) {
        getLastOrderByShopAndAnonymousUserIdOrUserAccountId(
            shopId: $shopId
            userAccountId: $userAccountId
            anonymousUserId: $anonymousUserId
            menuIds: $menuIds
        ) {
            ... on LastOrderDTO {
                ...lastOrderDTOResults
            }
        }
    }
    ${LAST_ORDER_PRODUCT_ATTRIBUTES}
`;
