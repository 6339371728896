import React, { createContext, useEffect, useState, useRef, PropsWithChildren } from "react";
import { useLazyQuery } from "react-apollo";
import { get } from "lodash";
import { useMutation } from "@apollo/react-hooks";
import moment from "moment";
import Axios from "axios";
import { v4 as uuidv4 } from "uuid";

import { GET_BAXI_API_KEY } from "GraphQLQueries";
import { baxiCancel, baxiReconciliation, baxiPurchase, baxiRefund, baxiOpenConnection } from "./baxiMessages/messages";
import {
    BaxiCloseReason,
    BaxiConnectionErrorCodes,
    BaxiLocalModeResults,
    BaxiTerminalAction,
    BaxiTerminalStates,
    envConstants,
    LOCAL_STORAGE_CONSTANTS,
    paymentTabStatusTypes
} from "Constants";
import { useInterval, useLocalStorage, usePrev, useStartStopInterval } from "Hooks";
import { LOG_CLOUD_BAXI_MESSAGE } from "GraphQLMutations";
import { PosOrderDTO } from "Types";
import { replaceNewLineCharactersWithSpace } from "Utils";
import { useOrderWindowsStore, usePosStore } from "Stores";
import { addTipToCardOrder } from "../admin/components/poses/pos/utils";

interface ProviderValues {
    setTerminalIds: (terminalIds: string[]) => void;
    openConnection: (terminalId: string) => void;
    makeCloudPurchase: (terminalId: string, amount: number, posOrderInput: PosOrderDTO, orderWindowId: string) => void;
    makeCloudRefund: (terminalId: string, amount: number, posOrderInput: PosOrderDTO) => void;
    cancelCloudPurchase: (terminalId: string) => void;
    terminalMessages: Map<string, string | null>;
    terminalStates: Map<string, TerminalAction> | null;
    prevTerminalStates: Map<string, TerminalAction> | null;
    resetTerminalStateById: (terminalId: string) => void;
    connectionErrorMsgs: Map<string, string>;
    attemptReconciliation: () => void;
    getLastPurchase: (terminalId: string) => void;
    apiToken: string;
    addOngoingOrder: (terminalAction: TerminalAction) => void;
    logBaxiMessage: () => void;
}

type ApiKeyQuery = {
    getCloudApiKey: ApiKeyResult;
};

type ApiKeyResult = {
    apiKey: string | null;
};

type TerminalAction = {
    busy: boolean;
    terminalStatus: BaxiTerminalStates;
    action: BaxiTerminalAction;
    order?: PosOrderDTO;
    orderWindowId?: string;
    refund?: boolean;
    errorMessage: string;
    prevState?: TerminalAction;
    timestamp?: moment.Moment | null;
    orderIdentifier?: string;
};

export type TerminalState = {
    terminalStatus: string;
    action: string;
};

export const BaxiSocketContext = createContext<ProviderValues>(null as any);
type Props = {};

const url = `${envConstants.NETS_CLOUD_SOCKET_URL}/ws/json?auth_token=`;

const initialTerminalState: TerminalAction = {
    busy: false,
    terminalStatus: BaxiTerminalStates.CLOSED,
    action: BaxiTerminalAction.IDLE,
    errorMessage: "",
    timestamp: null,
    orderIdentifier: undefined
};

const readyTerminalState: TerminalAction = {
    busy: false,
    terminalStatus: BaxiTerminalStates.READY,
    action: BaxiTerminalAction.IDLE,
    errorMessage: "",
    timestamp: null,
    orderIdentifier: undefined
};

const startPurchaseState: TerminalAction = {
    busy: true,
    terminalStatus: BaxiTerminalStates.BUSY,
    action: BaxiTerminalAction.PURCHASE,
    errorMessage: ""
};

const startReconciliation: TerminalAction = {
    busy: true,
    terminalStatus: BaxiTerminalStates.BUSY,
    action: BaxiTerminalAction.ADMIN,
    errorMessage: ""
};

const startRefundState: TerminalAction = {
    busy: true,
    terminalStatus: BaxiTerminalStates.BUSY,
    action: BaxiTerminalAction.REFUND,
    errorMessage: "",
    refund: true
};

const baxiConnectionErrors = [
    BaxiConnectionErrorCodes.INVALID_TERMINAL_ID,
    BaxiConnectionErrorCodes.TERMINAL_DISCONNECTED
];

const ALREADY_OPEN = "ALREADY_OPEN";
const ORDER_HISTORY_LENGTH = 10;

export const BaxiSocketProvider: React.FC<Props & PropsWithChildren> = ({ children }) => {
    const [websockets, setWebsockets] = useState<Map<string, WebSocket>>(new Map());
    const [terminalMessages, setTerminalMessages] = useState<Map<string, string | null>>(new Map());
    const [terminalStates, setTerminalStates] = useState<Map<string, TerminalAction>>(new Map());
    const prevTerminalStates = usePrev(terminalStates);
    const [apiToken, setApiToken] = useState<string>("");
    const [terminalIds, setTerminalIds] = useState<string[]>([]);
    const [connectionErrorMsgs, setConnectionErrorMsgs] = useState<Map<string, string>>(new Map());
    const [logBaxiMessage] = useMutation(LOG_CLOUD_BAXI_MESSAGE);
    const [ongoingOrders, setOngoingOrders] = useLocalStorage(LOCAL_STORAGE_CONSTANTS.BAXI_ONGOING_ORDERS);
    const { updateActivePaymentTabStatus, getActivePaymentTab } = usePosStore();
    const { activeOrderWindowId } = useOrderWindowsStore();

    const websocketsRef = useRef<Map<string, WebSocket>>(websockets);

    const waitForIdleTerminal = () => {
        const areAllIdle = terminalIds.every(terminalId => {
            return (
                terminalStates.get(terminalId)?.terminalStatus === BaxiTerminalStates.READY &&
                terminalStates.get(terminalId)?.action === BaxiTerminalAction.IDLE
            );
        });
        if (areAllIdle) {
            stopWaitToReconnect();
            openConnection();
        }
    };

    const addOngoingOrder = (terminalAction: TerminalAction) => {
        let currentOrders = ongoingOrders ? ongoingOrders : [];
        currentOrders.push(terminalAction);

        // rotate entries, delete first since map remembers order
        if (currentOrders.length > ORDER_HISTORY_LENGTH) {
            const noOrdersToDelete = currentOrders.length - ORDER_HISTORY_LENGTH;

            currentOrders = currentOrders.slice(noOrdersToDelete);
        }
        setOngoingOrders(currentOrders);
    };

    const removeOngoingOrders = (orderIdentifier: string | undefined) => {
        if (orderIdentifier) {
            const updatedOngoing = ongoingOrders?.filter(
                (ongoingOrder: TerminalAction) => ongoingOrder.orderIdentifier !== orderIdentifier
            );
            setOngoingOrders(updatedOngoing);
        }
    };

    const getLastPurchase = async (terminalId: string) => {
        let errorMessage = "";
        await Axios.get(`${envConstants.NETS_CLOUD_REST_URL}/v1/terminal/${terminalId}/transaction`, {
            headers: {
                Authorization: `Bearer ${apiToken}`
            }
        })
            .then((response: any) => {
                const transactionResult = response?.data?.result?.latestTransactionResult;
                const orderIdentifier = response?.data?.result?.orderId;

                const foundOrder = ongoingOrders.find(
                    (ongoingOrder: TerminalAction) => ongoingOrder.orderIdentifier === orderIdentifier
                );

                if (
                    transactionResult &&
                    parseInt(transactionResult.result) === BaxiLocalModeResults.FINANCIAL_TRANSACTION_OK &&
                    !!foundOrder
                ) {
                    sendBaxiLogToBackend(terminalId, `SUCCESSFULLY checked and approved last purchase`);
                    approvePurchase(terminalId, transactionResult, foundOrder);
                } else {
                    if (
                        transactionResult &&
                        parseInt(transactionResult.result) !== BaxiLocalModeResults.FINANCIAL_TRANSACTION_OK
                    ) {
                        errorMessage = `last purchase was failed with result: ${transactionResult.result}`;
                    } else if (!orderIdentifier) {
                        errorMessage = `no orderIdentifier found: ${JSON.stringify(response?.data?.result)}`;
                    } else if (!foundOrder) {
                        errorMessage = `could not find orderidentifier among ongoingOrders captured orderIdentifier: ${orderIdentifier} ongoingOrders identifiers: ${ongoingOrders
                            .map((ongoingOrder: TerminalAction) => ongoingOrder.orderIdentifier)
                            .join(",")}`;
                    }
                    // used to display message and remove active payment tab
                    if (activeOrderWindowId) {
                        const activePaymentTab = getActivePaymentTab(activeOrderWindowId);

                        updateActivePaymentTabStatus(
                            activePaymentTab.id,
                            false,
                            "FEL",
                            "Kunde inte hitta matchande senaste köp",
                            paymentTabStatusTypes.FAILED
                        );
                    }
                    sendBaxiLogToBackend(terminalId, `FAILED check for last purchase msg: ${errorMessage}`);
                    resetTerminalStateById(terminalId);
                }
            })
            .catch((err: any) => {
                if (err.response) {
                    console.error("reponse", err.response.data);
                    console.error("status code", err.response.status);
                    errorMessage = `${err.response.status} : ${err.response.data?.failure?.error}`;
                }

                const error = JSON.stringify(err);
                console.error(error);
                sendBaxiLogToBackend(
                    terminalId,
                    `FAILED crashed last purchase check: ${errorMessage} errorobject: ${error}`
                );
                return false;
            });
    };
    const [startWaitToReconnect, stopWaitToReconnect] = useStartStopInterval(waitForIdleTerminal, 3000);
    const [startStatusPoll, stopStatusPoll] = useStartStopInterval(() => {
        websockets.forEach((_, terminalId) => testTerminalConnection(terminalId));
    }, 10000);

    const [getApiKey, { data: baxiApiResult }] = useLazyQuery<ApiKeyQuery>(GET_BAXI_API_KEY, {
        fetchPolicy: "network-only"
    });

    useInterval(() => {
        startWaitToReconnect();
    }, 10800000); // 3 hours

    useEffect(() => {
        if (hasConnectionErrorMessages()) {
            startStatusPoll();
        } else {
            stopStatusPoll();
        }
    }, [connectionErrorMsgs]);

    useEffect(() => {
        if (terminalIds.length === 0) return;
        openConnection();
    }, [terminalIds]);

    useEffect(() => {
        if (!baxiApiResult) return;

        // single cloudKey allows access to multiple terminals
        const cloudKey = baxiApiResult.getCloudApiKey.apiKey;
        const wssUrl = url + cloudKey;
        if (cloudKey && apiToken !== cloudKey) {
            setApiToken(cloudKey);

            startWebSocketConnection(wssUrl, true);
        } else if (!cloudKey) {
            terminalIds.forEach(terminalId => setNewTerminalConnectionError(terminalId, "Anslutningsfel kortterminal"));

            console.error("Could not find terminal", terminalIds);
            setTimeout(() => {
                openConnection();
            }, 15000);
        } else if (terminalIds.length > 0) {
            startWebSocketConnection(wssUrl, false);
            console.log("Restarting websocket connection");
            sendBaxiLogToBackend(terminalIds[0], "Restarting websocket connection due to connection lost");
        } else {
            console.log("refetched api token but same as before");
        }
    }, [baxiApiResult]);

    useEffect(() => {
        return () => {
            closeSockets(BaxiCloseReason.SOCKET_UNMOUNT);
        };
    }, []);

    const startWebSocketConnection = (wssUrl: string, resetTerminalState: boolean) => {
        const socketMap = new Map();
        const terminalStates = new Map();
        const _terminalMessages = new Map();
        const connectionErrorMessages = new Map();
        terminalIds.forEach(terminalId => {
            socketMap.set(terminalId, new WebSocket(wssUrl));
            terminalStates.set(terminalId, readyTerminalState);
            _terminalMessages.set(terminalId, null);
            connectionErrorMessages.set(terminalId, "");
        });

        if (websockets.size > 0) {
            // close old sockets
            closeSockets();
        }
        setConnectionErrorMsgs(connectionErrorMessages);
        setTerminalMessages(_terminalMessages);
        setWebsockets(socketMap);
        websocketsRef.current = socketMap;
        if (resetTerminalState) setTerminalStates(terminalStates);
    };

    const hasConnectionErrorMessages = () => {
        return Array.from(connectionErrorMsgs.values()).some(msg => !!msg);
    };

    const closeSockets = (closeReason = BaxiCloseReason.SWITCH_API_KEY) => {
        console.log("closing websockets reason: " + closeReason);
        websocketsRef.current.forEach(socket => socket.close(1000, closeReason));
    };

    const sendBaxiLogToBackend = (terminalId: string, msg: string) => {
        try {
            logBaxiMessage({ variables: { terminalId, msg: JSON.stringify(msg) } });
        } catch (e) {
            console.log("failed to send error log", e);
        }
    };

    const updateTerminalState = (terminalId: string, updatedState: TerminalAction) => {
        if (terminalId) {
            setTerminalStates(prevState => new Map(prevState).set(terminalId, updatedState));
        }
    };

    const setNewTerminalConnectionError = (terminalId: string, message: string) => {
        setConnectionErrorMsgs(prevState => new Map(prevState).set(terminalId, message));
    };

    const updateTerminalMessage = (terminalId: string, message: string | null) => {
        const cleanedMessage = replaceNewLineCharactersWithSpace(message);
        setTerminalMessages(prevState => new Map(prevState).set(terminalId, cleanedMessage));
    };

    // on close event listeners
    websockets.forEach(
        (socket, terminalId) =>
            (socket.onclose = (e: any) => {
                if (e.reason !== BaxiCloseReason.SWITCH_API_KEY) {
                    const error = JSON.stringify(e, [
                        "message",
                        "stack",
                        "code",
                        "reason",
                        "arguments",
                        "type",
                        "name"
                    ]);
                    sendBaxiLogToBackend(terminalId, "BAXI CLOUD SOCKET CLOSED UNEXPECTEDLY: " + error);
                    console.log("websocket closed", error);
                    setNewTerminalConnectionError(terminalId, "Anslutningsfel kortterminal");
                }
            })
    );

    const connectionIsOpened = (parse: any) => {
        return (
            parse.NetsResponse.MethodRejected?.code === 7102 && parse.NetsResponse.MethodRejected?.Info === ALREADY_OPEN
        );
    };

    // on close event listeners
    websockets.forEach(
        (socket, terminalId) =>
            (socket.onopen = () => {
                console.log("websocket opened", terminalId, socket);
                testTerminalConnection(terminalId);
                setNewTerminalConnectionError(terminalId, "");
            })
    );

    // on message receive event listeners
    websockets.forEach(
        (socket, terminalId) =>
            (socket.onmessage = event => {
                if (event.data instanceof Blob) {
                    let reader = new FileReader();
                    reader.onload = () => {
                        //@ts-ignore
                        const parse = JSON.parse(reader.result);
                        const msgTerminalId = get(parse, "NetsResponse.MessageHeader.$.TerminalID", null);

                        //console.log("parse", parse);
                        if (msgTerminalId === terminalId) {
                            sendBaxiLogToBackend(terminalId, parse);

                            const netsResult = parse.NetsResponse.Dfs13LocalMode;
                            if (netsResult) {
                                const result = netsResult.Result;
                                if (parseInt(result) === BaxiLocalModeResults.FINANCIAL_TRANSACTION_OK) {
                                    // used to filter out invalid results
                                    if (netsResult.ResultData === "D 0" && netsResult.IssuerID === "0") {
                                        sendBaxiLogToBackend(
                                            terminalId,
                                            "UNEXPECTED VALUE RECEIVED FOR TERMINAL : " +
                                                terminalId +
                                                " msg:" +
                                                JSON.stringify(parse)
                                        );
                                        return;
                                    }

                                    approvePurchase(terminalId, netsResult);
                                } else if (parseInt(result) === BaxiLocalModeResults.TRANS_REJECTED) {
                                    const prevState = terminalStates.get(terminalId);
                                    if (prevState) {
                                        updateTerminalState(terminalId, {
                                            ...prevState,
                                            timestamp: null,
                                            busy: false,
                                            action: BaxiTerminalAction.PURCHASE_REJECTED,
                                            prevState: prevState
                                        });
                                    }
                                }
                            } else if (parse.NetsResponse.Dfs13DisplayText) {
                                updateTerminalMessage(terminalId, parse.NetsResponse.Dfs13DisplayText._);
                                const prevState = terminalStates.get(terminalId);
                                if (prevState) {
                                    updateTerminalState(terminalId, {
                                        ...prevState,
                                        timestamp: moment()
                                    });
                                }
                            } else if (parse.NetsResponse.Dfs13TerminalReady === "") {
                                setNewTerminalConnectionError(terminalId, "");
                                resetTerminalStateById(terminalId);
                            } else if (parse.NetsResponse.Dfs13Error && parse.NetsResponse.Dfs13Error.ErrorString) {
                                const errorCode = parse?.NetsResponse?.Dfs13Error?.ErrorCode;
                                const prevState = terminalStates.get(terminalId);
                                if (prevState) {
                                    if (
                                        [BaxiTerminalAction.ERROR, BaxiTerminalAction.IDLE].includes(
                                            prevState.action
                                        ) &&
                                        !baxiConnectionErrors.includes(errorCode)
                                    ) {
                                        return;
                                    }

                                    const errorMessage =
                                        parse.NetsResponse.Dfs13Error.ErrorCode === 9100
                                            ? parse.NetsResponse.Dfs13Error.ErrorString +
                                              "\n Kontrollera nätverksanslutningen och starta om terminalen"
                                            : parse.NetsResponse.Dfs13Error.ErrorString;

                                    const isNewStatus =
                                        errorMessage !== prevState.errorMessage &&
                                        prevState.action !== BaxiTerminalAction.ERROR;

                                    if (isNewStatus) {
                                        updateTerminalState(terminalId, {
                                            ...prevState,
                                            timestamp: null,
                                            action: BaxiTerminalAction.ERROR,
                                            errorMessage: errorMessage,
                                            prevState
                                        });
                                    }

                                    updateTerminalMessage(terminalId, parse.NetsResponse.Dfs13Error.ErrorString);
                                    if (baxiConnectionErrors.includes(errorCode)) {
                                        setNewTerminalConnectionError(terminalId, "Anslutningsfel kortterminal");
                                        sendBaxiLogToBackend(
                                            terminalId,
                                            "Received message about terminal connection terminal"
                                        );
                                    }
                                }
                            } else if (connectionIsOpened(parse)) {
                                setNewTerminalConnectionError(terminalId, "");
                            }
                        }
                    };
                    reader.readAsText(event.data);
                } else if (typeof event.data === "string") {
                    if (event.type === "message") {
                        const prevState = terminalStates.get(terminalId);
                        if (prevState) {
                            updateTerminalState(terminalId, {
                                ...prevState,
                                timestamp: null,
                                busy: false,
                                action: BaxiTerminalAction.ERROR,
                                errorMessage: event.data
                            });
                        }
                        updateTerminalMessage(terminalId, event.data);
                    }
                } else {
                    console.log("event", event.data);
                }
            })
    );

    const approvePurchase = (
        terminalId: string,
        netsResult: any,
        overriddenTerminalAction: TerminalAction | undefined = undefined
    ) => {
        const hasGivenTip = !!netsResult.TipAmount;
        const forceComplete = !!overriddenTerminalAction;
        const terminalState = overriddenTerminalAction ? overriddenTerminalAction : terminalStates.get(terminalId);
        let posOrder = terminalState?.order;

        if (posOrder && hasGivenTip) {
            // Append tip to paymentMethodAmounts
            posOrder = addTipToCardOrder(posOrder, netsResult.TipAmount);
        }

        const updatedOrder = {
            ...posOrder,
            creditCardInfo: {
                cardNumber: netsResult.TruncatedPAN,
                cardIssuer: netsResult.CardIssuerName
            }
        };
        const prevState = terminalState;
        if (prevState) {
            updateTerminalState(terminalId, {
                ...prevState,
                timestamp: null,
                busy: false,
                action: BaxiTerminalAction.PURCHASE_APPROVED,
                order: updatedOrder as PosOrderDTO,
                prevState
            });
            removeOngoingOrders(prevState.orderIdentifier);

            if (forceComplete) {
                setTimeout(() => {
                    console.log(overriddenTerminalAction);
                    setNewTerminalConnectionError(terminalId, "");
                    updateTerminalState(terminalId, readyTerminalState);
                    updateTerminalMessage(terminalId, null);
                }, 10000);
            }
        }
    };

    const openConnection = () => {
        if (terminalIds.length > 0) {
            getApiKey({ variables: { terminalId: terminalIds[0] } });
        }
    };

    const setTerminalIsBusy = (terminalId: string) => {
        updateTerminalMessage(terminalId, "Terminalen är upptagen");
        const prevState = terminalStates.get(terminalId);
        if (prevState) {
            updateTerminalState(terminalId, {
                ...prevState,
                timestamp: null,
                action: BaxiTerminalAction.ERROR,
                errorMessage: "Terminalen är upptagen"
            });
        }
    };

    const testTerminalConnection = (terminalId: string) => {
        const baxiMessage = baxiOpenConnection(terminalId);
        const terminalSocket = websockets.get(terminalId);
        if (terminalSocket && terminalSocket.readyState === WebSocket.OPEN) {
            terminalSocket.send(JSON.stringify(baxiMessage));
        } else if (terminalSocket && terminalSocket.readyState === WebSocket.CLOSED) {
            openConnection();
        }
    };

    const makeCloudPurchase = (
        terminalId: string,
        amount: number,
        posOrderInput: PosOrderDTO,
        orderWindowId: string
    ) => {
        const orderIdentifier = uuidv4();
        const baxiMessage = baxiPurchase(terminalId, amount, orderIdentifier);
        const terminalSocket = websockets.get(terminalId);

        if (terminalSocket && terminalSocket.readyState === WebSocket.OPEN) {
            const prevState = terminalStates.get(terminalId);
            if (prevState) {
                if (prevState.busy) {
                    setTerminalIsBusy(terminalId);
                } else {
                    updateTerminalMessage(terminalId, null);
                    const purchaseAction = {
                        ...startPurchaseState,
                        timestamp: moment(),
                        order: posOrderInput,
                        orderWindowId,
                        orderIdentifier
                    };
                    updateTerminalState(terminalId, purchaseAction);
                    terminalSocket.send(JSON.stringify(baxiMessage));
                    addOngoingOrder(purchaseAction);

                    // ONLY USE FOR LOCAL DEVELOPMENT
                    // comment out the terminalSocket.send as well (not needed but will reduce errors)
                    // if (envConstants.PROFILE === frontyEnv.LOCAL) {
                    //     setTimeout(() => {
                    //         console.log("**TEST** auto approving order", purchaseAction);
                    //         const netsResult = { TruncatedPAN: "172361263761t23", CardIssuerName: "VISA" };
                    //         approvePurchase(terminalId, netsResult, purchaseAction);
                    //     }, 2500);
                    // }
                }
            }
        } else {
            updateTerminalState(terminalId, initialTerminalState);
        }
    };

    const attemptReconciliation = () => {
        websockets.forEach((socket, terminalId) => {
            if (socket && socket.readyState === WebSocket.OPEN) {
                const baxiMessage = baxiReconciliation(terminalId);
                updateTerminalMessage(terminalId, null);
                updateTerminalState(terminalId, startReconciliation);
                socket.send(JSON.stringify(baxiMessage));
            } else {
                updateTerminalState(terminalId, initialTerminalState);
            }
        });
    };

    const makeCloudRefund = (terminalId: string, amount: number, posOrderInput: PosOrderDTO) => {
        const baxiMessage = baxiRefund(terminalId, amount);
        const terminalSocket = websockets.get(terminalId);
        if (terminalSocket && terminalSocket.readyState === WebSocket.OPEN) {
            updateTerminalMessage(terminalId, null);
            updateTerminalState(terminalId, { ...startRefundState, order: posOrderInput });
            terminalSocket.send(JSON.stringify(baxiMessage));
        } else {
            updateTerminalState(terminalId, initialTerminalState);
        }
    };

    const cancelCloudPurchase = (terminalId: string) => {
        const baxiMessage = baxiCancel(terminalId);
        const terminalSocket = websockets.get(terminalId);
        if (terminalSocket && terminalSocket.readyState === WebSocket.OPEN) {
            terminalSocket.send(JSON.stringify(baxiMessage));
        }
    };

    const resetTerminalStateById = (terminalId: string) => {
        updateTerminalState(terminalId, readyTerminalState);
        updateTerminalMessage(terminalId, null);
    };

    const value: ProviderValues = {
        setTerminalIds,
        openConnection,
        makeCloudPurchase,
        makeCloudRefund,
        terminalMessages,
        cancelCloudPurchase,
        prevTerminalStates,
        terminalStates,
        resetTerminalStateById,
        connectionErrorMsgs,
        attemptReconciliation,
        getLastPurchase,
        apiToken,
        addOngoingOrder,
        logBaxiMessage
    };

    return <BaxiSocketContext.Provider value={value}>{children}</BaxiSocketContext.Provider>;
};

const BaxiSocketConsumer = BaxiSocketContext.Consumer;

export const useBaxiSocket = () => {
    const ctx = React.useContext(BaxiSocketContext);
    if (!ctx) {
        throw new Error("useBaxiSocket must be within a BaxiSocketProvider");
    }
    return ctx;
};

export const withBaxiSocket = (Component: any) => (props: any) =>
    <BaxiSocketConsumer>{(state: any) => <Component {...props} baxiCloudUtils={state} />}</BaxiSocketConsumer>;
