import React from "react";
import { useController } from "react-hook-form";
import { FiPlus } from "@react-icons/all-files/fi/FiPlus";
import { FiMinus } from "@react-icons/all-files/fi/FiMinus";

import { FormLabel, RHCommonProps, RHWrapper, Stack, Input, Image, Box } from "Atoms";
import { useTheme } from "Providers";
import { IconButton } from "Molecules";

const counterInputSizes: { [key: string]: { wrapperMaxWH: number; imageMaxW: number; imageMaxH: number } } = {
    lg: {
        wrapperMaxWH: 400,
        imageMaxW: 320,
        imageMaxH: 260
    },
    md: {
        wrapperMaxWH: 320,
        imageMaxW: 240,
        imageMaxH: 180
    },
    sm: { wrapperMaxWH: 240, imageMaxW: 160, imageMaxH: 120 }
};

export type RHCounterInputProps = {
    min?: number;
    max?: number;
    image?: string;
    defaultValue?: number;
    themeColor?: string;
    isReadOnly?: boolean;
    size?: "lg" | "md" | "sm";
} & RHCommonProps;

const getSize = (size: string) => counterInputSizes[size];

export const RHCounterInput: React.FC<RHCounterInputProps> = ({
    formLabel,
    helperText,
    isMandatory = false,
    control,
    name,
    wrapperProps,
    min = 0,
    max = undefined,
    image,
    defaultValue = 0,
    themeColor = "green",
    isReadOnly = false,
    size = "md"
}) => {
    const { colors } = useTheme();
    const {
        field: { onChange, onBlur, value }
    } = useController({
        name,
        control,
        defaultValue: defaultValue
    });

    const increment = () => {
        if (max && +value >= max) {
            return;
        } else {
            onChange(+value + 1);
        }
    };

    const decrement = () => {
        if (+value <= min) {
            return;
        } else {
            onChange(+value - 1);
        }
    };

    const _counterInputSizes = getSize(size);

    return (
        <RHWrapper
            name={name}
            control={control}
            formLabel={formLabel}
            helperText={helperText}
            wrapperProps={{
                border: `1px solid ${colors.gray["200"]}`,
                rounded: "lg",
                padding: 4,
                maxW: _counterInputSizes.wrapperMaxWH,
                maxH: _counterInputSizes.wrapperMaxWH,
                textAlign: "center",
                ...wrapperProps
            }}
            isMandatory={isMandatory}
        >
            <Stack width="auto" justifyContent="space-between" height="100%" alignItems="center">
                <Image
                    src={image}
                    maxH={_counterInputSizes.imageMaxH}
                    maxW={_counterInputSizes.imageMaxW}
                    mx={8}
                    my={4}
                    objectFit={"contain"}
                />
                <Stack
                    isInline
                    stretch={4}
                    justifyContent="space-between"
                    h="auto"
                    w={_counterInputSizes.imageMaxW}
                    mx="auto"
                >
                    <IconButton
                        icon={FiMinus}
                        themeColor={themeColor}
                        disabled={+value <= min && +defaultValue <= min}
                        size={size}
                        onClick={() => {
                            decrement();
                            onBlur();
                        }}
                    />
                    <Input
                        type="number"
                        width={60}
                        value={value || defaultValue || 0}
                        textAlign={"center"}
                        isReadOnly={isReadOnly}
                        pr={0}
                        size={size}
                        onChange={onChange}
                    />
                    <IconButton
                        icon={FiPlus}
                        themeColor={themeColor}
                        disabled={!!max && +value >= max}
                        size={size}
                        onClick={() => {
                            increment();
                            onBlur();
                        }}
                    />
                </Stack>
            </Stack>
        </RHWrapper>
    );
};
