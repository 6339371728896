import moment, { Moment } from "moment";
import Axios from "axios";
import { ApolloClient } from "apollo-client";

import { envConstants } from "Constants";
import { formatDateToBackendUTCString } from "../../../utils/TextFormat";
import { GET_QREPORT_TOKEN } from "GraphQLQueries";
import { UserRoles } from "Constants";
import { hasAccountingRights } from "../../../../utils";
import { getHoursAndMinutes, findLatestActiveHour, findEarliestActiveHour, getShopsActiveDay } from "Utils";
import {
    ReportStats,
    IncludedDataSets,
    ReportType,
    DataType,
    SaleStatsPerTimePeriod,
    AlcoholStats
} from "./reportTypes";
import { ActiveHour, PublicShopInfo, Shop } from "Types";
import { foodoraColor, ubereatsColor, woltColor, boltColor } from "./dashboardColors";

export const DAILY_SALES_GRAPH_MODE = { CHOSEN_PERIOD: "week", YEAR: "year" };

export const fetchQReportToken = async (client: ApolloClient<object>, companyId: string, shopIds: string[]) => {
    try {
        const { data } = await client.query({
            query: GET_QREPORT_TOKEN,
            variables: { companyId: companyId, shopIds: shopIds },
            fetchPolicy: "network-only"
        });

        return data.getQReportToken;
    } catch (err) {
        console.error("Failed fetching qReport token:", JSON.stringify(err));
    }
};

const getFormattedDateForQReport = (moment: Moment) => {
    return moment.toISOString();
};

export const fetchQReport = async (
    shopIds: string[],
    token: string | undefined,
    startDate: moment.Moment,
    endDate: moment.Moment
) => {
    let formattedStart = getFormattedDateForQReport(startDate);
    let formattedEnd = getFormattedDateForQReport(endDate);

    //for correct work endDate should be greather than startDate, if not we will switch them
    if (endDate.diff(startDate) < 0) {
        formattedStart = getFormattedDateForQReport(endDate);
        formattedEnd = getFormattedDateForQReport(startDate);
    }

    try {
        const { data } = await Axios.post(
            window.location.href.includes("legacy")
                ? `${envConstants.QREPORT_SERVICE_URL}/reports-overview`
                : `${envConstants.QREPORT_SERVICE_URL_NEW}/overview`,
            {
                shopIDs: shopIds,
                startDate: formattedStart,
                endDate: formattedEnd
            },
            {
                headers: {
                    Authorization: token
                }
            }
        );
        return data;
    } catch (error) {
        console.error("Failed fetching qReport:", JSON.stringify(error));
        return false;
    }
};

export const fetchQReportStatus = async (
    shopIds: string[],
    token: string | undefined,
    startDate: moment.Moment,
    endDate: moment.Moment
) => {
    // TODO Remove this once the aggregate replaces the old report version completely
    if (window.location.href.includes("legacy")) {
        return;
    }

    const formattedStart = getFormattedDateForQReport(startDate);
    const formattedEnd = getFormattedDateForQReport(endDate);
    try {
        const response = await Axios.post(
            `${envConstants.QREPORT_SERVICE_URL_NEW}/status`,
            {
                shopIDs: shopIds,
                startDate: formattedStart,
                endDate: formattedEnd
            },
            {
                headers: {
                    Authorization: token
                }
            }
        );
        return response.data;
    } catch (err) {
        console.error("Failed to fetch qReport status:", JSON.stringify(err));
        return false;
    }
};

export const fetchAlcoholReport = async (
    companyId: string,
    shopIds: string[],
    token: string | undefined,
    year: number
) => {
    try {
        const response = await Axios.post(
            `${envConstants.QREPORT_SERVICE_URL_NEW}/alcoholreport`,
            {
                companyId: companyId,
                shopIDs: shopIds,
                year: year
            },
            {
                headers: {
                    Authorization: token
                }
            }
        );
        return response.data;
    } catch (err) {
        console.log("Failed to fetch alcohol report", JSON.stringify(err));
        return false;
    }
};

export const capitalizeDayOfWeek = (value: number) => {
    const dayName = moment().day(value).locale("sv").format("dddd");
    const dayNameCapitalized = dayName.substring(0, 1).toUpperCase() + dayName.substring(1);

    return dayNameCapitalized;
};

/**
 * [FUNCTION] capitalise day of week by user locale
 * @description All this all depends on users locale language en or sv
 * @param value
 * @param userLocale
 * @returns Day Of The Week
 * @example capitaliseDayOfWeekByLanguage(1, new Intl.Locale("en-SE")) => "Monday"
 */
//@ts-ignore
export const capitaliseDayOfWeekByLanguage = (value: number, userLocale: Intl.Locale) => {
    //@ts-ignore
    const dayName = moment().day(value).locale(userLocale.language).format("dddd");
    const dayNameCapitalized = dayName.substring(0, 1).toUpperCase() + dayName.substring(1);
    return dayNameCapitalized;
};

export const formatAxisNumbers = (number: number) =>
    new Intl.NumberFormat("en-US", { style: "decimal", minimumFractionDigits: 0, maximumFractionDigits: 1 })
        .format(number)
        .replace(/,/g, " ");

export const filterData = (
    legendKey: string,
    setIncludedDataSets: (value: any) => void,
    includedDataSets: IncludedDataSets
) => {
    setIncludedDataSets((prev: IncludedDataSets) => ({ ...prev, [legendKey]: !includedDataSets[legendKey] }));
};

export const DATA_SOURCE = {
    WEB: "web",
    POS: "pos",
    PREV_POS: "prevPos",
    PREV_WEB: "prevWeb",
    FOODORA: "foodora",
    UBEREATS: "ubereats",
    WOLT: "wolt",
    BOLT: "bolt",
    THIRD_PARTY_SERVICE: "thirdPartyService",
    PREV_THIRD_PARTY_SERVICE: "prevThirdPartyService"
};

export const DATA_SOURCE_NAMES: { [key: string]: string } = {
    WEB: "Online",
    POS: "Kassa",
    FOODORA: "Foodora",
    UBEREATS: "Uber Eats",
    WOLT: "Wolt",
    BOLT: "Bolt"
};

const makeShopArr = (companyShops: any) =>
    companyShops
        .map((shop: any) => ({
            key: shop.id,
            value: shop.id,
            text: shop.name
        }))
        .sort((a: any, b: any) => a.text.localeCompare(b.text));

export const getShopOptions = (userRoles: UserRoles[], userShopIds: string[], companyShops: any) => {
    if (userRoles.includes(UserRoles.ROLE_SUPER_ADMIN)) {
        return makeShopArr(companyShops);
    } else if (hasAccountingRights(userRoles)) {
        const accessibleShops =
            userShopIds.length > 0 ? companyShops.filter((shop: any) => userShopIds.includes(shop.id)) : companyShops;
        return makeShopArr(accessibleShops);
    } else {
        return [];
    }
};
export const getCategoryStats = (dataType: DataType, sourceKey: ReportType, report: any) => {
    return dataType === DataType.Quantity
        ? report[sourceKey].categoryQuantityStats
        : report[sourceKey].categoryAmountStats;
};

export const getUniqueNamesFrom = (
    posData: any,
    webData: any,
    boltData: any,
    woltData: any,
    foodoraData: any,
    uberEatsData: any
) => {
    return [
        ...new Set(
            Object.keys(posData).concat(
                Object.keys(webData),
                Object.keys(boltData),
                Object.keys(woltData),
                Object.keys(foodoraData),
                Object.keys(uberEatsData)
            )
        )
    ];
};
export const checkIsOneWeekPeriod = (startDate: moment.Moment, endDate: moment.Moment) => {
    if (moment(endDate).diff(startDate, "day") < 8 && moment(startDate).week() == moment(endDate).week()) {
        return true;
    } else return false;
};

export const getWorkingHours = (activeHours: any, startDate: moment.Moment, endDate: moment.Moment) => {
    const t = getShopsActiveDay(activeHours, moment(startDate).locale("en").format("dddd").toUpperCase());
    let openingTime = t?.startingHour;
    let closureTime = t?.stoppingHour;
    if (endDate.diff(startDate, "days") > 1) {
        openingTime = findEarliestActiveHour(activeHours, "startingHour")?.startingHour!;

        const erliestClosureTime = findEarliestActiveHour(activeHours, "stoppingHour")?.stoppingHour!;
        const latestClosureTime = findLatestActiveHour(activeHours, "stoppingHour")?.stoppingHour!;

        closureTime =
            latestClosureTime < openingTime
                ? latestClosureTime
                : erliestClosureTime < openingTime
                ? erliestClosureTime
                : latestClosureTime;
    }
    const openingHours = openingTime ? +getHoursAndMinutes(openingTime)[0] : 0;
    let [closureHours, endMinute] = closureTime ? getHoursAndMinutes(closureTime) : [23, 0];
    if (endMinute > 0) closureHours = closureHours + 1;
    return [openingHours, closureHours];
};

export const updateWorkingHoursForChosenShop = (
    startDate: moment.Moment,
    endDate: moment.Moment,
    activeHours: ActiveHour[] | undefined,
    setWorkingHours: (value: any) => void,
    setSelectedTimeSpan: (value: any) => void
) => {
    const [openingHours, closureHours] = getWorkingHours(activeHours, startDate, endDate);
    setWorkingHours({
        opening: openingHours && openingHours >= 0 ? +openingHours : 0,
        closure: closureHours && closureHours >= 0 ? +closureHours : 23
    });
    if (+moment(endDate).format("HH") > +moment(startDate).format("HH") && closureHours < openingHours) {
        setSelectedTimeSpan({
            start: moment(startDate).hour(openingHours).minute(0),
            end: moment(endDate).add(1, "d").hour(closureHours).minute(0)
        });
    } else if (+moment(endDate).format("HH") < +moment(startDate).format("HH") && closureHours > openingHours) {
        setSelectedTimeSpan({
            start: moment(startDate).hour(openingHours).minute(0),
            end: moment(endDate).subtract(1, "d").hour(closureHours).minute(0)
        });
    } else {
        const newStart = moment(startDate).startOf("day");
        const newEnd = moment(endDate).endOf("day");
        if (moment(newStart).diff(startDate) !== 0 || moment(newEnd).diff(endDate) !== 0) {
            setSelectedTimeSpan({
                start: newStart,
                end: newEnd
            });
        }
    }
};

export const getTotalSum = (report: ReportStats, keyName: string): number => {
    return Object.values(report)
        .map((value: any) => {
            return value[keyName] ? value[keyName] : 0;
        })
        .reduce((prevVal: number, currVal: number) => currVal + prevVal, 0);
};

export const isThirdPartyAvailable = (availableThirdPartyServices: string[], ReportType: string) => {
    return availableThirdPartyServices.includes(ReportType);
};

export const getTip = (includedDataSets: any, reportData: any) => {
    let currentValue = 0;
    if (includedDataSets && reportData) {
        currentValue = reportData.tipsTotalSum;
    }
    return currentValue;
};
export const setSettingsFromShop = (shop: Shop) => ({
    ids: [shop?.id],
    name: shop?.name,
    selected: [{ label: shop?.name, value: shop?.id }],
    activeHours: shop?.activeHours,
    availableThirdPartyServices: shop?.thirdPartyDeliveryServices
        ? shop?.thirdPartyDeliveryServices.map(
              (service: { thirdPartyDeliveryType: string }) => service.thirdPartyDeliveryType
          )
        : [],
    onlineEatingOptions: shop?.settings?.onlineSettings?.eatingOptions
});

export const getThirdPartyColor = (thirdPartyService: string) => {
    switch (thirdPartyService) {
        case ReportType.FOODORA:
            return foodoraColor;
        case ReportType.UBEREATS:
            return ubereatsColor;
        case ReportType.WOLT:
            return woltColor;
        case ReportType.BOLT:
            return boltColor;
        default:
            return "";
    }
};

export const makeMapFromShopsArray = (companyShops: PublicShopInfo[]) =>
    new Map(companyShops.map(shop => [shop.id, shop.name]));

// TODO RENAME THIS or at least describe what it does.
// It trims the whitespace of a number after being formatted??
// I also default this to zero to support undefined values from qReport
export const getDecimalSeparator = (value: number) => {
    return value?.toLocaleString()?.replace(/\s/g, "") || 0;
};

export const getValue = (
    isVATIncluded: boolean,
    includedDataSets: boolean,
    key: number,
    reportData: SaleStatsPerTimePeriod,
    isWithinOneWeek: boolean
) => {
    if (includedDataSets && !!reportData[key]) {
        const quantity = reportData[key].quantity;
        if (quantity > 0) {
            const subtotal = isVATIncluded ? reportData[key].total : reportData[key].totalNet;
            // Totals for time periods less than one week
            if (isWithinOneWeek) {
                return subtotal;
            }

            // Averages for time periods greater than one week
            return subtotal / quantity;
        }
    }
    return 0;
};
