import moment, { unitOfTime, MomentInput, Moment } from "moment";
import { capitalize } from "lodash";

import { convertMomentDayNoToSwe } from "TempUtils";
import { ActiveHour } from "Types";
import { ILanguages } from "Providers";
import { MIDNIGHT_HOURS } from "Constants";

export const englishDayToSwedishTable = {
    MONDAY: "Måndag",
    TUESDAY: "Tisdag",
    WEDNESDAY: "Onsdag",
    THURSDAY: "Torsdag",
    FRIDAY: "Fredag",
    SATURDAY: "Lördag",
    SUNDAY: "Söndag"
};
export const englishDayToEnglishTable = {
    MONDAY: "Monday",
    TUESDAY: "Tuesday",
    WEDNESDAY: "Wednesday",
    THURSDAY: "Thursday",
    FRIDAY: "Friday",
    SATURDAY: "Saturday",
    SUNDAY: "Sunday"
};
export const momentDayNoToBackendDay = {
    "1": "MONDAY",
    "2": "TUESDAY",
    "3": "WEDNESDAY",
    "4": "THURSDAY",
    "5": "FRIDAY",
    "6": "SATURDAY",
    "0": "SUNDAY"
};

export const swedishIsoDayOfWeek = [
    { dayOfWeek: "MONDAY", swe: "Måndag", momentIsoDayNo: 1 },
    { dayOfWeek: "TUESDAY", swe: "Tisdag", momentIsoDayNo: 2 },
    { dayOfWeek: "WEDNESDAY", swe: "Onsdag", momentIsoDayNo: 3 },
    { dayOfWeek: "THURSDAY", swe: "Torsdag", momentIsoDayNo: 4 },
    { dayOfWeek: "FRIDAY", swe: "Fredag", momentIsoDayNo: 5 },
    { dayOfWeek: "SATURDAY", swe: "Lördag", momentIsoDayNo: 6 },
    { dayOfWeek: "SUNDAY", swe: "Söndag", momentIsoDayNo: 7 }
];
export const swedishDayOfWeek = [
    { dayOfWeek: "MONDAY", swe: "Måndag", momentDayNo: 1 },
    { dayOfWeek: "TUESDAY", swe: "Tisdag", momentDayNo: 2 },
    { dayOfWeek: "WEDNESDAY", swe: "Onsdag", momentDayNo: 3 },
    { dayOfWeek: "THURSDAY", swe: "Torsdag", momentDayNo: 4 },
    { dayOfWeek: "FRIDAY", swe: "Fredag", momentDayNo: 5 },
    { dayOfWeek: "SATURDAY", swe: "Lördag", momentDayNo: 6 },
    { dayOfWeek: "SUNDAY", swe: "Söndag", momentDayNo: 0 }
];
export const englishDayOfWeek = [
    { dayOfWeek: "MONDAY", swe: "Monday", momentDayNo: 1 },
    { dayOfWeek: "TUESDAY", swe: "Tuesday", momentDayNo: 2 },
    { dayOfWeek: "WEDNESDAY", swe: "Wednesday", momentDayNo: 3 },
    { dayOfWeek: "THURSDAY", swe: "Thursday", momentDayNo: 4 },
    { dayOfWeek: "FRIDAY", swe: "Friday", momentDayNo: 5 },
    { dayOfWeek: "SATURDAY", swe: "Saturday", momentDayNo: 6 },
    { dayOfWeek: "SUNDAY", swe: "Sunday", momentDayNo: 0 }
];

export const multiLangDaysOfWeek = {
    sv: swedishDayOfWeek,
    en: englishDayOfWeek
};

const multiLangDateStrings = {
    sv: {
        closed: "Stängt",
        today: "Idag"
    },
    en: {
        closed: "Closed",
        today: "Today"
    }
};

const getToday = (userLanguage: ILanguages) => moment().locale(userLanguage).startOf("day");
export const toMoment = (date: string) => moment(date);
export const getDate = (format: string) => moment().format(format);

export const getHoursAndMinutes = (activeTime: string) => activeTime.split(":").map(Number);

export const getShopsActiveDay = (activeHours: ActiveHour[] | undefined, dayName: string) => {
    if (activeHours && activeHours.length > 0) {
        return activeHours.find(({ dayOfWeek }) => dayOfWeek === dayName);
    }
    return undefined;
};

export const isShopOpen = (activeHours: ActiveHour[], backendDiff: number) => {
    const today = moment().add(backendDiff, "ms");

    let todayDayNo = today.day();

    if (today.hours() < MIDNIGHT_HOURS) {
        todayDayNo -= 1;
    }

    const dayOfWeek = convertMomentDayNoToSwe(todayDayNo);
    const todaysActiveDay = getShopsActiveDay(activeHours, dayOfWeek);

    if (!todaysActiveDay) return false;

    const { startingHour, stoppingHour } = todaysActiveDay;
    const [startHour, startMinute] = getHoursAndMinutes(startingHour);
    const [endHour, endMinute] = getHoursAndMinutes(stoppingHour);

    const startingHourDate = moment().set({
        day: todayDayNo,
        hour: startHour,
        minute: startMinute,
        second: 0
    });

    const isAfterMidnightHours = endHour < MIDNIGHT_HOURS;

    const stoppingHourDate = moment().set({
        day: isAfterMidnightHours ? todayDayNo + 1 : todayDayNo,
        hour: endHour,
        minute: endMinute,
        second: 0
    });

    return today.isBetween(startingHourDate, stoppingHourDate, undefined, "[)");
};

export const nearestFutureMinutes = (interval: number, someMoment: Moment) => {
    const roundedMinutes = Math.ceil(someMoment.minute() / interval) * interval;
    return someMoment.clone().minute(roundedMinutes).second(0);
};

export const toDayName = (date: string) => moment(date).format("dddd");

/**
 * Add extra midnight hours from the day before (e.g. Friday opens 09:00 - 03:00
 * then Satuday should have 00:00 -> 03:00 added
 *
 * @param {string} selectedDate given in YYYY-MM-DD
 * @param {array} activeHours - all shop active hours, used to fetch yesterday
 * @param {number} interval for each time stop
 * @param {any} now moment, what the current now is
 * @returns {array}
 */
export const addExtraMidnightHours = (
    selectedDate: string,
    activeHours: ActiveHour[],
    interval: number,
    now: MomentInput
): Moment[] => {
    const yesterday = moment(selectedDate + " 06:00").subtract(1, "days");
    const selectedDateWeekday = toDayName(yesterday.format("YYYY-MM-DD"));
    const yesterdayActiveHour = getShopsActiveDay(activeHours, selectedDateWeekday.toUpperCase());
    const [stoppingHour] = yesterdayActiveHour ? getHoursAndMinutes(yesterdayActiveHour.stoppingHour) : [null];
    let extraMidnightHours = [];

    // add extra hours from midnight
    if (stoppingHour && stoppingHour <= MIDNIGHT_HOURS) {
        const extraTimeStartMoment = moment.utc(selectedDate + " 00:00", "YYYY-MM-DD hh:mm");
        const extraTimeEndMoment = moment.utc(
            selectedDate + " " + yesterdayActiveHour!.stoppingHour,
            "YYYY-MM-DD hh:mm"
        );

        while (extraTimeStartMoment.isBefore(extraTimeEndMoment) && extraTimeStartMoment.isAfter(now)) {
            extraMidnightHours.push(extraTimeStartMoment.clone());
            extraTimeStartMoment.add(interval, "minutes");
        }
    }
    return extraMidnightHours;
};

/**
 * Get time intervals for given start and end time
 *
 * @param {string} start startTime given in HH:mm
 * @param {string} end endTime given in HH:mm
 * @param {string} selectedDate used to check if today, then crop start time to be after now, given in YYYY-MM-DD
 * @param {number} interval for each time stop
 * @param {any} now moment, what the current now is
 * @param {object} activeHours - all shop active hours, used to fetch yesterday
 * @returns {array}
 */
export const getTimeStops = (
    start: string,
    end: string,
    selectedDate: string,
    interval: number = 15,
    now: any = moment(),
    activeHours: ActiveHour[]
): Moment[] => {
    const momentStart = moment(selectedDate + " " + start, "YYYY-MM-DD HH:mm");
    const momentEnd = moment(selectedDate + " " + end, "YYYY-MM-DD HH:mm");

    const startHour = momentStart.hour();
    const endHour = momentEnd.hour();
    if (endHour < startHour) {
        momentEnd.add(1, "day");
    }

    const extraMidnightHours = addExtraMidnightHours(selectedDate, activeHours, interval, now);

    let startTime = momentStart;

    if (selectedDate === moment().format("YYYY-MM-DD")) {
        startTime = momentStart.isBefore(now) ? now : momentStart;
    }

    let timeStops = [];
    startTime = nearestFutureMinutes(interval, startTime);

    while (startTime.isBefore(momentEnd)) {
        timeStops.push(startTime.clone());
        startTime.add(interval, "minutes");
    }

    return [...extraMidnightHours, ...timeStops];
};

export const sortActiveEnglishHoursByDay = (activeHours: ActiveHour[]): ActiveHour[] => {
    const weekDays = moment.weekdays(true).map(value => value.toLowerCase());
    return activeHours.sort((a: ActiveHour, b: ActiveHour) => {
        return weekDays.indexOf(a.dayOfWeek.toLowerCase()) - weekDays.indexOf(b.dayOfWeek.toLowerCase());
    });
};

/**TEST SUITE */
/**
 *
 * @param activeHours acitvehours days, startingtime and endTime
 * @param date - moment date sent in if not will default to todays date
 * @returns activeHour - next time restuarant opens and closes
 */
export const getNextDayThatOpens = (activeHours: ActiveHour[], date?: moment.Moment): ActiveHour => {
    const sortedActiveHours = sortActiveEnglishHoursByDay(activeHours);
    const dayToCheck = date ? moment(date) : moment();
    const dayOfWeek = moment(dayToCheck).locale("en").format("dddd").toUpperCase();
    const foundDayOfTheWeekActiveHour = sortedActiveHours.find(day => day.dayOfWeek === dayOfWeek);

    let nextOpeningDay: ActiveHour = {} as ActiveHour;

    if (foundDayOfTheWeekActiveHour) {
        const startDay = moment(dayToCheck).startOf("day");

        const [hour, minute] = getHoursAndMinutes(foundDayOfTheWeekActiveHour.startingHour);
        const opening = moment(dayToCheck).set({ hour, minute });

        const isTimeBeforeOpening = moment(dayToCheck).isBetween(startDay, opening, undefined, "[)");

        if (isTimeBeforeOpening) {
            return foundDayOfTheWeekActiveHour;
        }

        const getsTodaysIndex = sortedActiveHours.findIndex(day => day.dayOfWeek === dayOfWeek);
        const lastAcitveHoursIndex = sortedActiveHours.length - 1;

        if (getsTodaysIndex === lastAcitveHoursIndex) {
            nextOpeningDay = sortedActiveHours[0];
        } else {
            nextOpeningDay = sortedActiveHours[getsTodaysIndex + 1];
        }
    }

    return nextOpeningDay;
};

const shopIsOpen = (activeHours: ActiveHour[], dayNo: number) => {
    const dayOfWeek = convertMomentDayNoToSwe(dayNo);

    const isOpen = activeHours.some(day => day.dayOfWeek === dayOfWeek);
    if (!isOpen) {
        const yesterdayDayNo = dayNo - 1 < 0 ? 6 : dayNo - 1;
        const yesterDay = convertMomentDayNoToSwe(yesterdayDayNo);
        const yesterDayHours = activeHours.find(day => day.dayOfWeek === yesterDay);
        if (!!yesterDayHours) {
            const [yesterDayEndHours] = getHoursAndMinutes(yesterDayHours.stoppingHour);
            return yesterDayEndHours < MIDNIGHT_HOURS;
        } else {
            return false;
        }
    } else {
        return true;
    }
};

const getDisabledText = (isShopClosed: boolean, isCateringUnavailable: boolean, userLanguage: ILanguages) => {
    let multiLangText: { en: string; sv: string } = { en: "", sv: "" };

    if (isShopClosed) {
        multiLangText = {
            en: "Closed",
            sv: "Stängd"
        };
    } else if (isCateringUnavailable) {
        multiLangText = {
            en: "Not available",
            sv: "Ej tillgängligt"
        };
    }

    return multiLangText[userLanguage];
};

export const enumerateDaysBetweenDate = (startDate: Moment, endDate: Moment) => {
    let dates = [];
    while (moment(startDate) <= moment(endDate)) {
        dates.push(startDate);
        startDate = moment(startDate).add(1, "days");
    }

    return dates;
};

export const getDateStops = (
    activeHours: ActiveHour[],
    startDay?: moment.Moment | "",
    userLanguage: ILanguages = "sv",
    showDisabledDates: boolean = true,
    amountOfDays = 7,
    excludeDays?: moment.Moment[]
) => {
    const today = (startDay || moment().startOf("day")).locale(userLanguage);
    const translated = multiLangDateStrings[userLanguage];

    const maxDate = today.clone().add(amountOfDays, "day");
    const days = [];

    while (today.isBefore(maxDate)) {
        const dayNo = today.day();

        const isShopClosed = !shopIsOpen(activeHours, dayNo);
        const isUnavailable = excludeDays ? excludeDays.some(excludedDay => excludedDay.isSame(today, "day")) : false;
        const shouldShowDisableText = isShopClosed || isUnavailable;

        const isToday = today.format("YYYY-MM-DD") === getToday(userLanguage).format("YYYY-MM-DD");
        const disableText = getDisabledText(isShopClosed, isUnavailable, userLanguage);

        const closedText = shouldShowDisableText ? ` - ${disableText}` : "";
        const dateText = `${isToday ? translated.today : capitalize(today.format("dddd DD/MM"))} ${closedText}`;

        if (!showDisabledDates && shouldShowDisableText) {
            today.add(1, "day");
        } else {
            days.push({
                dateText,
                date: today.format("YYYY-MM-DD"),
                disabled: shouldShowDisableText
            });
            today.add(1, "day");
        }
    }
    return days;
};

export const generateTimelineLabels = (desiredStartTime: string, interval: number, period: unitOfTime.Base) => {
    const periodsInADay = moment.duration(1, "day").as(period);

    const timeLabels = [];
    const startTimeMoment = moment(desiredStartTime, "HH:mm");
    for (let i = 0; i <= periodsInADay; i += interval) {
        startTimeMoment.add(i === 0 ? 0 : interval, period);
        timeLabels.push(startTimeMoment.format("HH:mm"));
    }

    return timeLabels;
};

export const convertDayOfWeekToLang = (userLanguage: ILanguages) => (dayOfWeek: string) => {
    const dayOfWeekCaps = dayOfWeek.toUpperCase();
    const daysOfWeek = multiLangDaysOfWeek[userLanguage];
    const dod = daysOfWeek.find(day => day.dayOfWeek === dayOfWeekCaps);
    return dod && dod.swe;
};

export const convertDayOfWeekToSwe = convertDayOfWeekToLang("sv");
export const convertDayOfWeekToEng = convertDayOfWeekToLang("en");

export const hasPickupTimeSessionExpired = (
    pickupObj: { time: string; date: string },
    backendDiff: number
): boolean => {
    if (!pickupObj || pickupObj.date === "" || pickupObj.time === "") return true;

    const { time, date } = pickupObj;

    const dateTimeNow = moment().add(backendDiff, "ms");

    const isTodayOrFuture = !moment(date).isBefore(dateTimeNow, "day");
    const isAsap = time === "asap";

    if (!isTodayOrFuture) return true;
    if (isAsap) return false;

    return dateTimeNow.diff(moment(`${date} ${time}`)) >= 0;
};

export const javaDateToDate = (javaDate: string): Date => moment.utc(javaDate).toDate();

export const formatDateToLocal = (javaDateTime: string, formatType: string = "YYYY-MM-DD HH:mm"): string => {
    const localTime = javaDateToDate(javaDateTime);
    return moment(localTime).format(formatType);
};

export const isPastDue = (pickupTime: string): boolean => {
    const now = moment().locale("sv");
    return now.isAfter(javaDateToDate(pickupTime));
};

export const diffInMinutes = (javaDadateTime: string) => {
    const diff = moment.duration(moment(javaDateToDate(javaDadateTime)).diff(moment().locale("sv")));
    return Math.ceil(diff.asMinutes());
};

export const getFormattedPickupTime = (pickupTime: string): string => {
    const now = moment().locale("sv");
    const tillPickup = now.to(javaDateToDate(pickupTime));
    return tillPickup.substr(tillPickup.indexOf(" ") + 1);
};

export const getActiveHourByWeekday = (
    activeHours: ActiveHour[] | undefined,
    dayOfWeek: string
): ActiveHour | undefined => {
    return activeHours?.find(activeHour => activeHour.dayOfWeek === dayOfWeek);
};

export const isTodayBeforeDate = (date: string): Boolean => {
    return moment().isBefore(date);
};

export const parseDateForJavaLocalDateTime = (date: moment.Moment): string => {
    return date.format("YYYY-MM-DDTHH:mm");
};

/**
 * @example parseJavascriptDateForJavaLocalDateTime(new Date()) => "YYYY-MM-DDTHH:mm"
 */
export const parseJavascriptDateForJavaLocalDateTime = (date: Date): string => {
    return date.toISOString().slice(0, 16);
};

export const getMinimumEndDateFromStartDate = (startDate?: string | moment.Moment): moment.Moment => {
    return startDate ? (moment(startDate).isBefore(moment()) ? moment() : moment(startDate)) : moment();
};

export const isDateOnOrBetweenDates = (
    dateCheck: moment.Moment | string,
    startDate: moment.Moment | string,
    endDate: moment.Moment | string
): boolean => {
    return moment(dateCheck).isBetween(moment(startDate), moment(endDate), undefined, "[]");
};

export const isLessThanFifteenMin = (
    date: moment.MomentInput,
    activeHour: ActiveHour | undefined,
    backendDiff: number
) => {
    let dateStr = date;
    const now = moment().add(backendDiff, "ms");
    const stoppingHour = activeHour?.stoppingHour;
    if (activeHour) {
        const { startingHour, stoppingHour } = activeHour;
        const [stopHour] = getHoursAndMinutes(stoppingHour);
        const [startHour] = getHoursAndMinutes(startingHour);

        if (stopHour < startHour) {
            const t = moment(date, "YYYY-MM-DD").startOf("day").add(1, "day");
            dateStr = t.format("YYYY-MM-DD");
        }
    }
    return moment(`${dateStr} ${stoppingHour}`).diff(now, "minutes") < 15;
};

/**
 * [FUNCTION] get full weekday name by language
 * @param {number} dayOfWeek iso day number
 * @param {boolean} shortDay true = "Monday" / false = "Mon"
 * @param {ILanguages} lang language to use
 * @returns Weekday in language
 * @example capitaliseDayOfWeekByLangLocale(1, false, "en") => "Monday" / capitaliseDayOfWeekByLangLocale(1, true, "en") => "Mon"
 */
export const capitaliseDayOfWeekByLangLocale = (
    dayOfWeek: number,
    shortDay: boolean,
    lang: ILanguages | undefined = "sv"
) => {
    const dayName = moment()
        .day(dayOfWeek)
        .locale(lang)
        .format(shortDay ? "ddd" : "dddd");
    const dayNameCapitalized = dayName.substring(0, 1).toUpperCase() + dayName.substring(1);
    return dayNameCapitalized;
};

//@ts-ignore
type MonthFormat = "numeric" | "short" | "2-digit" | "long" | "narrow" | undefined;

//@ts-ignore
export const getMonthNameFromUserLocaleAndDate = (
    userLocale: Intl.Locale,
    date: string | Date,
    monthFormat: MonthFormat = "long"
) => {
    const dateToCheck = new Date(date);
    //@ts-ignore
    const formattedDate = new Intl.DateTimeFormat(userLocale.language, { month: monthFormat });
    //@ts-ignore
    const monthName =
        formattedDate.formatToParts(dateToCheck).find((value: Intl.DateTimeFormatPart) => value.type === "month") ?? "";

    //@ts-ignore
    return !!monthName ? monthName.value : "";
};

//@ts-ignore
export const getSimpleDateFormat = (locale: Intl.Locale, date: Date) => {
    //@ts-ignore
    return new Intl.DateTimeFormat(locale.baseName, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
    }).format(date);
};

/**
 * [FUNCTION] - Send in Date and check if it is tomorrow
 * @param inputDate
 * @returns
 */
export const isTomorrow = (inputDate: Date) => {
    const now = new Date();

    // Get tomorrow's date by setting the current date one day ahead
    const tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);

    // Normalize input date (set hours, minutes, seconds, and milliseconds to zero)
    const input = new Date(inputDate.getFullYear(), inputDate.getMonth(), inputDate.getDate());

    // Compare the normalized dates
    return input.getTime() === tomorrow.getTime();
};
