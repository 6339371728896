import React from "react";
import { ThemeProvider as StyledThemeProvider, ThemeContext } from "styled-components";

import { theme } from "../../theme/theme";

export const ThemeProvider = ({ children }: any) => <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>;

export const useTheme = () => {
    const ctx = React.useContext(ThemeContext);
    ctx?.borderStyles;
    if (!ctx) {
        throw new Error("useTheme must be within ThemeProvider");
    }
    return ctx;
};
