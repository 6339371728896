import { useEffect } from "react";

import { useCallbackRef } from "./";

type Target = EventTarget | null | (() => EventTarget | null);
type Options = boolean | AddEventListenerOptions;

export const useEventListener = (
    target: Target,
    event: string,
    handler: ((event: Event) => void) | undefined,
    options?: Options
) => {
    const listener = useCallbackRef(handler);

    useEffect(() => {
        const node = typeof target === "function" ? target() : target ?? document;

        if (!handler || !node) return;

        node.addEventListener(event, listener, options);
        return () => {
            node.removeEventListener(event, listener, options);
        };
    }, [event, target, options, listener, handler]);

    return () => {
        const node = typeof target === "function" ? target() : target ?? document;
        node?.removeEventListener(event, listener, options);
    };
};
