import gql from "graphql-tag";
/* Graphql queries: */

export const GET_PRODUCT_CATEGORIES = gql`
    query getProductCategories($companyId: String!) {
        getProductCategories(companyId: $companyId) {
            id
            name
            description
            companyId
            sortOrder
        }
    }
`;

export const GET_PRODUCTS_BY_CATEGORY_ID = gql`
    query productsByCategoryId($categoryId: String!) {
        productsByCategoryId(categoryId: $categoryId) {
            id
            companyId
            name
            description
            defaultPrice
            priceType
            refProductCategoryId
            vatRate
            deleted
            imageUrl
            imageResizedUrls {
                small
                medium
                large
            }
            articleNumber
            allergens {
                name
                desc
            }
            modifications {
                sizes {
                    name
                    price
                    addonPrice
                }
                flavours {
                    name
                    price
                    addonPrice
                }
            }
        }
        bundleProductsByCategoryId(categoryId: $categoryId) {
            id
            companyId
            name
            defaultPrice
            priceType
            vatRate
            description
            refProductCategoryId
            imageUrl
            imageResizedUrls {
                small
                medium
                large
            }
            articleNumber
            allergens {
                name
                desc
            }
            bundleProductCategories {
                id
                name
                refProductIdList
                kdsUnitDisplayName
                limit
                color
            }
        }
    }
`;

export const GET_PRODUCTS_BY_COMPANY_ID = gql`
    query getProductsByCompanyId($companyId: String!) {
        getCompanyRefProducts(companyId: $companyId) {
            id
            companyId
            name
            description
            contents
            defaultPrice
            priceType
            refProductCategoryId
            vatRate
            deleted
            imageUrl
            imageResizedUrls {
                small
                medium
                large
            }
            articleNumber
            allergens {
                name
                desc
            }
            modifications {
                sizes {
                    name
                    price
                    addonPrice
                }
                flavours {
                    name
                    price
                    addonPrice
                }
            }
            productTags {
                alcoholTag
                volumeInCl
            }
        }
        getCompanyRefBundleProducts(companyId: $companyId) {
            id
            companyId
            name
            defaultPrice
            priceType
            vatRate
            description
            contents
            refProductCategoryId
            imageUrl
            imageResizedUrls {
                small
                medium
                large
            }
            articleNumber
            allergens {
                name
                desc
            }
            bundleProductCategories {
                id
                name
                refProductIdList
                kdsUnitDisplayName
                limit
                color
            }
            productTags {
                alcoholTag
                volumeInCl
            }
        }
    }
`;

export const GET_SHOP_MENUS = gql`
    query getShopMenus($shopId: String!) {
        getShopMenus(shopId: $shopId) {
            id
            name
            description
            companyId
            isExpressMenu
            expressStartMenu {
                header
                description
                expressStartMenuItemList {
                    refMenuItemId
                    expressStartMenuItemType
                    imageUrl
                }
            }
            activeHours {
                closed
                startingHour
                stoppingHour
                dayOfWeek
            }
            menuProductCategories {
                id
                name
                upsellCategory
                upsellCategoryAtPayment
                sortOrder
                color
                menuBundleProducts {
                    id
                    price
                    sortOrder
                    color
                    bundleCategoryColors {
                        bundleProductCategoryId
                        color
                    }
                    menuBundleModifications {
                        refProductId
                        modifications {
                            sizes {
                                name
                                price
                                addonPrice
                            }
                            flavours {
                                name
                                price
                                addonPrice
                            }
                        }
                    }
                    refBundleProduct {
                        id
                        name
                        description
                        defaultPrice
                        priceType
                        vatRate
                        companyId
                        refProductCategoryId
                        articleNumber
                        imageUrl
                        imageResizedUrls {
                            small
                            medium
                            large
                        }
                        bundleProductCategories {
                            id
                            limit
                            name
                            refProductIdList
                            kdsUnitDisplayName
                            color
                        }
                    }
                }
                menuProducts {
                    id
                    price
                    sortOrder
                    color
                    modifications {
                        sizes {
                            name
                            price
                            addonPrice
                        }
                        flavours {
                            name
                            price
                            addonPrice
                        }
                    }
                    refProduct {
                        id
                        name
                        refProductCategoryId
                        defaultPrice
                        priceType
                        description
                        vatRate
                        articleNumber
                        imageUrl
                        imageResizedUrls {
                            small
                            medium
                            large
                        }
                        allergens {
                            id
                            name
                            desc
                        }
                        modifications {
                            sizes {
                                name
                                price
                                addonPrice
                            }
                            flavours {
                                name
                                price
                                addonPrice
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const GET_MENUS = gql`
    query getMenus($companyId: String!) {
        getMenus(companyId: $companyId) {
            id
            name
            description
            companyId
            isExpressMenu
            expressStartMenu {
                header
                description
                expressStartMenuItemList {
                    refMenuItemId
                    expressStartMenuItemType
                    imageUrl
                }
            }
            activeHours {
                closed
                date
                dayOfWeek
                desc
                startingHour
                stoppingHour
            }
            menuProductCategories {
                id
                name
                upsellCategory
                upsellCategoryAtPayment
                sortOrder
                color
                menuBundleProducts {
                    id
                    price
                    sortOrder
                    color
                    bundleCategoryColors {
                        bundleProductCategoryId
                        color
                    }
                    menuBundleModifications {
                        refProductId
                        modifications {
                            sizes {
                                name
                                price
                                addonPrice
                            }
                            flavours {
                                name
                                price
                                addonPrice
                            }
                        }
                    }
                    refBundleProduct {
                        id
                        articleNumber
                        description
                        name
                        imageUrl
                        imageResizedUrls {
                            small
                            medium
                            large
                        }
                        companyId
                        defaultPrice
                        priceType
                        vatRate
                        refProductCategoryId
                        bundleProductCategories {
                            id
                            limit
                            name
                            refProductIdList
                            kdsUnitDisplayName
                            color
                        }
                    }
                }
                menuProducts {
                    id
                    price
                    sortOrder
                    color
                    modifications {
                        sizes {
                            name
                            price
                            addonPrice
                        }
                        flavours {
                            name
                            price
                            addonPrice
                        }
                    }
                    refProduct {
                        id
                        articleNumber
                        refProductCategoryId
                        name
                        imageUrl
                        imageResizedUrls {
                            small
                            medium
                            large
                        }
                        companyId
                        description
                        defaultPrice
                        priceType
                        vatRate
                        allergens {
                            name
                        }
                        modifications {
                            sizes {
                                name
                                price
                                addonPrice
                            }
                            flavours {
                                name
                                price
                                addonPrice
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const GET_COMPANIES = gql`
    query getCompanies {
        getCompanies {
            id
            name
            organisationNumber
            timezone
            email
            vatRates
            productCategoryIds
            updateChangeNumber
            imageUrl
            umbrellaCompany
            contactInformation {
                addressLine
                postCode
                city
                country
                phoneNumber
            }
            settings {
                posEnabled
                onlineEnabled
                stockTrackingEnabled
                companySubscriptionSettings {
                    friendlySubdomainName
                    title
                    description
                    vatRate
                    imageUrl
                }
            }
        }
    }
`;

export const GET_CART = gql`
    query GetCartProducts {
        shoppingCart @client {
            shops {
                id
                name
                desc
                companyId
                contactInformation {
                    addressLine
                    postCode
                    city
                    country
                    phoneNumber
                }
                imageUrl
                organisationNumber
                activeHours {
                    dayOfWeek
                    startingHour
                    stoppingHour
                }
                menuIds
                settings {
                    refProductCategoryIdsToExcludeInKDS
                    refBundleIdsToExcludeInKDS
                    showOnKDSWithinMinutesBeforePickup
                    allowedNumberOfOrdersInKDSBatch
                }
            }
            cartProducts {
                name
                refProductId
                refBundleProductId
                refProductCategoryId
                menuCategoryId
                unitPrice
                vatRate
                quantity
                weight
                priceType
                totalPrice
                totalNetPrice
                cartProductId
                shopId
                selectedBundleProductItems {
                    refProductId
                    kdsUnitDisplayName
                    bundleProductCategoryId
                    name
                    modifications {
                        sizes {
                            name
                            price
                            addonPrice
                        }
                        flavours {
                            name
                            price
                            addonPrice
                        }
                    }
                }
                bundleProductCategories {
                    id
                    name
                    refProductIdList
                    kdsUnitDisplayName
                    limit
                    color
                }
                modifications {
                    sizes {
                        name
                        price
                        addonPrice
                    }
                    flavours {
                        name
                        price
                        addonPrice
                    }
                }
            }
        }
    }
`;

export const VALIDATE_USER_EMAIL = gql`
    query validateUserEmail($email: String!) {
        validateUserEmail(email: $email) {
            email
        }
    }
`;

export const GET_QREPORT_TOKEN = gql`
    query qReportToken($companyId: String, $shopIds: [String]) {
        getQReportToken(companyId: $companyId, shopIds: $shopIds)
    }
`;

export const GET_CONTROL_UNIT_SERIAL = gql`
    query getControlUnitSerial($posId: String) {
        getControlUnitSerial(posId: $posId)
    }
`;
export const GET_COMPANY_AND_RESTAURANT_EMAILS = gql`
    query getCompanyAndRestaurantEmails {
        getCompanyAndRestaurantEmails
    }
`;
