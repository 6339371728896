import moment from "moment";

import { DEFAULT_CATERING_TIME_TO_CONFIRM, GLOBAL_TIP_PRODUCT_NAME, PaymentMethod } from "Constants";
import { EatingPreference } from "../providers/onlineProvider/OnlineProvider";
import { javaDateToDate } from "Utils";
import { Order, PaymentMethodAmount } from "Types";

const isCatering = (eatingPreference: EatingPreference | "") =>
    !!eatingPreference && eatingPreference === EatingPreference.CATERING;

const getCateringMinutesToConfirm = (cateringConfirmationDate: string, backendDiff: number) => {
    return moment(javaDateToDate(cateringConfirmationDate)).diff(moment().add(backendDiff, "ms"), "minutes");
};

const getTimeToConfirm = (restaurantTimeToConfirm?: number | null) => {
    return !!restaurantTimeToConfirm ? restaurantTimeToConfirm : DEFAULT_CATERING_TIME_TO_CONFIRM;
};

const hasPaymentMethod = (order: Order, paymentMethod: PaymentMethod): boolean => {
    return order.paymentMethodAmounts.some(
        (paymentMethodAmount: PaymentMethodAmount) => paymentMethodAmount.paymentMethod === paymentMethod
    );
};

const hasGiftCardPayment = (order: Order): boolean => {
    return order.paymentMethodAmounts.some(
        (paymentMethodAmount: PaymentMethodAmount) => paymentMethodAmount.paymentMethod === PaymentMethod.GIFT_CARD
    );
};

const hasTipProduct = (order: Order): boolean => {
    return !!order.orderProducts.find(orderProduct => orderProduct.name === GLOBAL_TIP_PRODUCT_NAME);
};

export {
    isCatering,
    getTimeToConfirm,
    getCateringMinutesToConfirm,
    hasPaymentMethod,
    hasGiftCardPayment,
    hasTipProduct
};
