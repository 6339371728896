import gql from "graphql-tag";

export const UPSERT_CUSTOMER_OPT_OUT_MAIL = gql`
    mutation upsertCustomerOptOut($confirmedEmail: String!, $id: String!, $addOptOut: Boolean) {
        upsertCustomerOptOut(confirmedEmail: $confirmedEmail, id: $id, addOptOut: $addOptOut)
    }
`;

export const ADD_OR_UPDATE_CUSTOMER_OPT_OUT_EMAIL = gql`
    mutation createOrSaveCustomerOptOut($customerOptOuts: CustomerOptOutEmailsInput!) {
        createOrSaveCustomerOptOut(customerOptOuts: $customerOptOuts)
    }
`;

export const UPSERT_CUSTOMER_OPT_OUT_MAIL_BY_SHOP_ID = gql`
    mutation upsertCustomerOptOutPerShop($customerOptOuts: CustomerOptOutDTOInput!) {
        upsertCustomerOptOutPerShop(customerOptOut: $customerOptOuts)
    }
`;