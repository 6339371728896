import { mergeWith } from "lodash";

import { CartProduct, SelectedBundleProductItem } from "Types";
import { getRefIdFromCartProduct, addBundleProductQuantity } from "Utils";

export type StockHashMap = {
    [refId: string]: number;
};

const getBundleItemRefQuantityMap = (bundleProductItems: SelectedBundleProductItem[], quantity: number) => {
    return bundleProductItems.reduce<StockHashMap>((acc, { refProductId }) => {
        if (!!refProductId) {
            if (!acc[refProductId]) {
                acc[refProductId] = quantity;
            } else {
                acc[refProductId] += quantity;
            }
        }
        return acc;
    }, {});
};

export const getRefIdAndQuantityHashMap = (cartProducts: CartProduct[]) => {
    return cartProducts.reduce<StockHashMap>((hashMap, cartProduct) => {
        const refId = getRefIdFromCartProduct(cartProduct);
        const {
            orderProduct: { quantity, selectedBundleProductItems }
        } = cartProduct;

        const hashValue = { [refId]: !!hashMap[refId] ? quantity + hashMap[refId] : quantity };
        hashMap = { ...hashMap, ...hashValue };

        if (selectedBundleProductItems) {
            // Have to multiply each bundleItem with bundleProduct's quantity
            const bundleItemRefQuantityHashMap: StockHashMap = getBundleItemRefQuantityMap(
                selectedBundleProductItems,
                quantity
            );

            // Combine the selectedBundle item hashMap with the existing hashMap
            Object.entries(bundleItemRefQuantityHashMap).forEach(entry => {
                const key = entry[0];
                const value = entry[1];
                if (!!hashMap[key]) {
                    hashMap[key] += value;
                } else {
                    hashMap[key] = value;
                }
            });
        }

        return hashMap;
    }, {});
};
