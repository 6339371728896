import { findLastIndex } from "lodash";

import { BundleProductCategory, Modifications, Addon, CartProduct, SelectedBundleProductItem } from "Types";
import {
    createTempBundleItemHolders,
    tempBundleItemHolder
} from "../../../admin/components/poses/shared/utils/cartController";

export enum BundleItemOperation {
    ADD = "ADD",
    REPLACE = "REPLACE",
    DECREMENT = "DECREMENT",
    REMOVE = "REMOVE",
    RESET_ALL_CATEGORIES = "RESET_ALL_CATEGORIES",
    RESET_CATEGORY = "RESET_CATEGORY",
    RESET_BUNDLE_ITEM = "RESET_BUNDLE_ITEM"
}

export enum AddonOperation {
    ADD = "ADD",
    INCREMENT = "INCREMENT",
    DECREMENT = "DECREMENT",
    RESET = "RESET"
}

const replaceLastBundleItem = (
    selectedBundleItems: SelectedBundleProductItem[],
    replacement: any,
    replaceIdx: number
) => {
    if (replaceIdx === -1) return;
    const copy = [...selectedBundleItems];
    copy[replaceIdx] = {
        ...copy[replaceIdx],
        ...replacement
    };
    return copy;
};

const getBundleItemIndex = (
    bundleItemOperation: BundleItemOperation,
    selectedBundleItems: SelectedBundleProductItem[],
    bundleProductCategoryId: string,
    itemName: string
) => {
    switch (bundleItemOperation) {
        case BundleItemOperation.REMOVE:
            return selectedBundleItems.findIndex(item => item.name === itemName);

        case BundleItemOperation.REPLACE:
            return selectedBundleItems.findIndex(
                bundleItem => bundleItem.bundleProductCategoryId === bundleProductCategoryId
            );

        case BundleItemOperation.ADD:
            return selectedBundleItems?.findIndex(
                item => item.bundleProductCategoryId === bundleProductCategoryId && !item.refProductId
            );

        case BundleItemOperation.DECREMENT:
            return findLastIndex(selectedBundleItems, item => item.name === itemName);

        default:
            return -1;
    }
};

export const upsertAddons = (addons: Addon[], addon: Addon, operation: AddonOperation) => {
    switch (operation) {
        case AddonOperation.ADD:
            return addons.concat({ ...addon, quantity: 1 });
        case AddonOperation.INCREMENT:
        case AddonOperation.DECREMENT: {
            const value = operation === AddonOperation.INCREMENT ? 1 : -1;
            return addons.map(a => {
                if (a.name === addon.name && a.groupId === addon.groupId) {
                    return { ...a, quantity: a.quantity + value };
                }
                return a;
            });
        }

        case AddonOperation.RESET:
            return addons.filter(a => {
                if (a.name === addon.name) {
                    return a.name === addon.name && a.groupId !== addon.groupId;
                }
                return a.name !== addon.name;
            });

        default:
            return addons;
    }
};

const filterOneAddon = (addons: Addon[], addon: Addon) => {
    const index = addons.findIndex(a => a.name === addon.name && a.groupId === addon.groupId);

    if (index !== -1) {
        return [...addons.slice(0, index), ...addons.slice(index + 1)];
    } else {
        return addons;
    }
};

const upserBundleItemAddons = (newAddon: Addon | null, currAddons: Addon[]) => {
    if (!!newAddon) {
        const addonAlreadyExists = currAddons.find(addon => addon.name === newAddon.name);
        return !!addonAlreadyExists ? filterOneAddon(currAddons, newAddon) : currAddons.concat(newAddon);
    }
    return [];
};

const onAddOrReplaceBundleItem = (
    selectedBundleProductItems: SelectedBundleProductItem[],
    replaceIdx: number,
    refProdToAdd: any,
    selectedModifications: Modifications | null,
    bundleProductCategory: BundleProductCategory,
    newAddon: Addon | null
) => {
    return selectedBundleProductItems.map((bundleItem, index) => {
        if (index === replaceIdx) {
            const currAddons = refProdToAdd.name === bundleItem.name ? [...bundleItem.addons] : [];

            return {
                name: refProdToAdd.name,
                refProductId: refProdToAdd.id || refProdToAdd.refProductId,
                refProductCategoryId: refProdToAdd.refProductCategoryId,
                kdsUnitDisplayName: bundleProductCategory.kdsUnitDisplayName || "",
                modifications: selectedModifications,
                bundleProductCategoryId: bundleProductCategory.id,
                modificationsToUse: refProdToAdd.modificationsToUse,
                addons: upserBundleItemAddons(newAddon, currAddons)
            };
        } else {
            return bundleItem;
        }
    });
};

export const upsertBundleItems = (
    refProdToAdd: any,
    bundleCategory: BundleProductCategory,
    selectedModifications: Modifications | null,
    addon: Addon | null,
    bundleItemOperation: BundleItemOperation,
    bundleCartProduct: CartProduct | null | undefined,
    overriddenIndex?: number
) => {
    const selectedBundleProductItems = bundleCartProduct?.orderProduct?.selectedBundleProductItems!;

    let replaceIdx: number;

    if (overriddenIndex !== undefined) {
        replaceIdx = overriddenIndex;
    } else {
        replaceIdx = getBundleItemIndex(
            bundleItemOperation,
            selectedBundleProductItems,
            bundleCategory.id,
            refProdToAdd.name
        );
    }

    const compareName = (bundleItem: SelectedBundleProductItem) =>
        bundleItem.name === refProdToAdd.name && bundleItem.bundleProductCategoryId === bundleCategory.id;
    const compareIds = (bundleItem: SelectedBundleProductItem) =>
        bundleItem.bundleProductCategoryId === bundleCategory.id;

    const mapBundleItems = (compareFunc: (bundleItem: SelectedBundleProductItem, refProdToAdd: any) => boolean) => {
        let idxCounter = 0;
        let prevCategoryId = "";
        return selectedBundleProductItems.map((bundleItem, idx) => {
            if (bundleItem.bundleProductCategoryId !== prevCategoryId) {
                prevCategoryId = bundleItem.bundleProductCategoryId;
                idxCounter = 0;
            }

            idxCounter++;
            if (compareFunc(bundleItem, refProdToAdd)) {
                return tempBundleItemHolder(bundleCategory, idxCounter - 1);
            } else {
                return bundleItem;
            }
        });
    };

    switch (bundleItemOperation) {
        case BundleItemOperation.ADD: {
            const amountOfBundleItems = selectedBundleProductItems.filter(
                item => item.refProductId && item.bundleProductCategoryId === bundleCategory.id
            ).length;
            const isLimitReached = bundleCategory.limit === amountOfBundleItems;

            if (!isLimitReached) {
                const updated = onAddOrReplaceBundleItem(
                    selectedBundleProductItems,
                    replaceIdx,
                    refProdToAdd,
                    selectedModifications,
                    bundleCategory,
                    addon
                );
                return updated;
            } else {
                return selectedBundleProductItems;
            }
        }
        case BundleItemOperation.REPLACE: {
            return onAddOrReplaceBundleItem(
                selectedBundleProductItems,
                replaceIdx,
                refProdToAdd,
                selectedModifications,
                bundleCategory,
                addon
            );
        }

        case BundleItemOperation.DECREMENT: {
            const decrementedItems = replaceLastBundleItem(
                selectedBundleProductItems,
                {
                    name: `${bundleCategory.name}: -`,
                    refProductId: null,
                    modifications: [],
                    addons: []
                },
                replaceIdx
            );

            return decrementedItems;
        }

        case BundleItemOperation.REMOVE: {
            return selectedBundleProductItems.map((item, index) => {
                if (index === replaceIdx) {
                    return {
                        ...item,
                        name: `${bundleCategory.name}: -`,
                        refProductId: null,
                        modifications: [],
                        addons: []
                    };
                } else {
                    return item;
                }
            });
        }

        case BundleItemOperation.RESET_ALL_CATEGORIES:
            return createTempBundleItemHolders(bundleCartProduct?.menuBundleProduct);

        case BundleItemOperation.RESET_BUNDLE_ITEM:
            return mapBundleItems(compareName);

        case BundleItemOperation.RESET_CATEGORY:
            return mapBundleItems(compareIds);

        default:
            return selectedBundleProductItems;
    }
};
