import React, { useState } from "react";
import * as Yup from "yup";
import { isEmpty } from "lodash";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import { heartBeatMessage } from "TempUtils";
import { IModalContext, useLanguage } from "Providers";
import { Shop, Terminal } from "Types";
import { formatShopUrlName } from "Utils";
import { Modal, ModalActions, ModalBody, ModalCloseBtn, ModalHeader } from "Molecules";
import { modalNames } from "Constants";
import {
    Box,
    Button,
    Flex,
    FormLabel,
    Indicator,
    Label,
    RHCheckBoxInput,
    RHFormInput,
    RHSelectInput,
    RHTextAreaInput
} from "Atoms";
import { NewFormDropZone } from "../../form-components/NewFormDropZone";
import { getPosInitialValues } from "../utils/posInitialValues";
import { MenusForPOS, POS } from "../utils/posTypes";

type Props = {
    shop: Shop;
    companyId: string;
    expressMenus: MenusForPOS[];
    terminals: Terminal[];
    updatePos: (expressPos: POS) => Promise<void>;
    expressToEdit?: POS;
};

export const ExpressForm: React.FC<IModalContext<Props>> = ({ modalContent, closeModal }) => {
    const [printerIsConnected, setPrinterIsConnected] = useState(false);
    const { translate } = useLanguage();

    const { shop, companyId, expressToEdit, expressMenus, terminals, updatePos } = modalContent;

    const checkPrinterIp = async (ip: string, deviceName: string) => {
        const heartBeatResult = await heartBeatMessage(ip, deviceName);
        setPrinterIsConnected(heartBeatResult);
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().min(2, translate("forCard")).required(translate("formErrorMissing")),
        connectedTerminalIds: Yup.string().when("onlineExpress", {
            is: false,
            then: Yup.string().required(translate("formErrorMustChooseOneAlt"))
        }),
        expressPOS: Yup.object().when("onlineExpress", {
            is: false,
            then: Yup.object().shape({
                startScreenHeader: Yup.string().required(translate("formErrorMissing")),
                startScreenBread: Yup.string().required(translate("formErrorMissing"))
            })
        })
    });

    const expressPosWithTerminal = {
        ...expressToEdit,
        shopId: shop.id,
        menuIds: expressToEdit?.menuIds.filter(menuId =>
            expressMenus.some(availableMenu => availableMenu.id === menuId)
        ),
        connectedTerminalIds: expressToEdit?.connectedTerminalIds?.length ? expressToEdit?.connectedTerminalIds[0] : "",
        ...(!!expressToEdit?.expressPOS && { expressPOS: { ...expressToEdit.expressPOS } })
    };

    const { initialValues } = getPosInitialValues(true, expressPosWithTerminal as unknown as POS);

    const { deviceName } = initialValues.receiptPrinter;

    const terminalOptions = terminals?.map(terminal => ({ label: terminal.name, value: terminal.id }));
    const expressMenuOptions =
        expressMenus?.map(menu => ({ label: menu.name, value: menu.id, isDisabled: menu.disabled })) ?? [];

    const modalHeader = !!initialValues?.name
        ? `${translate("modify")} ${initialValues.name}`
        : translate("addNewExpressKiosk");
    const formattedShopName = formatShopUrlName(shop?.name);
    const getExpressOnlineUrl = (formattedShopUrlName: string, publicId: string | undefined) => {
        return `${window.location.origin}/express/restaurant/${formattedShopUrlName}/${shop.publicId}/${publicId}/order`;
    };

    const {
        control,
        handleSubmit,
        setValue,
        getValues,
        formState: { isSubmitting, isValid, isDirty }
    } = useForm<POS>({
        defaultValues: initialValues,
        mode: "onBlur",
        resolver: yupResolver(validationSchema)
    });

    const allowTableService = useWatch({ control, name: "expressPOS.allowTableService" });
    const onlineExpress = useWatch({ control, name: "onlineExpress" });
    const usbLegacyPrinter = useWatch({ control, name: "receiptPrinter.usbLegacyPrinter" });
    const receiptIpAddress = useWatch({ control, name: "receiptPrinter.ip" });
    const startScreenBackgroundImgUrl = useWatch({ control, name: "expressPOS.startScreenBackgroundImgUrl" });
    const startScreenLogoImgUrl = useWatch({ control, name: "expressPOS.startScreenLogoImgUrl" });

    const { publicId } = getValues();

    const onSubmit = async (formValues: POS) => {
        const { onlineExpress, connectedTerminalIds } = formValues;
        if (!onlineExpress && !isEmpty(connectedTerminalIds)) {
            const adjFormValues = {
                ...formValues,
                connectedTerminalIds: [connectedTerminalIds]
            };
            await updatePos(adjFormValues as unknown as POS);
        } else {
            await updatePos(formValues);
        }
        closeModal(modalNames.EXPRESS_POS_ADMIN_MODAL);
    };

    return (
        <Modal open={true} onClose={() => closeModal(modalNames.EXPRESS_POS_ADMIN_MODAL)} isScrolling>
            <ModalCloseBtn onClick={() => closeModal(modalNames.EXPRESS_POS_ADMIN_MODAL)} />
            <ModalHeader>{modalHeader}</ModalHeader>
            <form onSubmit={handleSubmit(onSubmit)}>
                <ModalBody>
                    <RHFormInput
                        control={control}
                        name="name"
                        formLabel={translate("name")}
                        placeholder={translate("name")}
                        isFullWidth
                        isMandatory
                    />
                    <RHCheckBoxInput control={control} name="onlineExpress">
                        {translate("onlineExpress")}
                    </RHCheckBoxInput>
                    <Flex>
                        <RHCheckBoxInput
                            control={control}
                            name="expressPOS.allowTableService"
                            helperText={allowTableService ? translate("attentionTableServiceRequiresKeyboard") : ""}
                        >
                            {translate("allowTableService")}
                        </RHCheckBoxInput>
                    </Flex>
                    {onlineExpress && !!publicId && (
                        <Label
                            ml={2}
                            mb={4}
                            as="a"
                            cursor="pointer"
                            onClick={() =>
                                window.open(getExpressOnlineUrl(formattedShopName, publicId), "_blank", "noopener")
                            }
                        >
                            <Flex>
                                <Box as={FaGlobe} size="18px" top="2" mr={2} />

                                {getExpressOnlineUrl(formattedShopName, publicId)}
                            </Flex>
                        </Label>
                    )}
                    {!onlineExpress && (
                        <>
                            <RHFormInput
                                control={control}
                                name="description"
                                formLabel={translate("description")}
                                placeholder={translate("description")}
                                isFullWidth
                            />
                            <RHSelectInput
                                control={control}
                                name="menuIds"
                                noOptionsMessage={() => translate("noAlternative")}
                                formLabel={translate("connectedMenus")}
                                options={expressMenuOptions}
                                isMulti
                            />
                            <RHSelectInput
                                control={control}
                                formLabel={translate("cardTerminal")}
                                placeholder={translate("chooseCardTerminal")}
                                name="connectedTerminalIds"
                                options={terminalOptions}
                            />
                            <RHCheckBoxInput control={control} name="expressPOS.allowLimitedPOSFeatures">
                                {translate("allowReceiptsRefundsAndXReport")}
                            </RHCheckBoxInput>
                            <RHCheckBoxInput control={control} name="receiptPrinter.usbLegacyPrinter">
                                {translate("USBPrinter")}
                            </RHCheckBoxInput>
                            {!usbLegacyPrinter && (
                                <>
                                    <RHFormInput
                                        control={control}
                                        isMandatory
                                        formLabel={translate("receiptPrinterIpAddress")}
                                        name="receiptPrinter.ip"
                                        placeholder={translate("receiptPrinterIpAddress")}
                                        fullWidth
                                    />
                                    <Button
                                        pl={0}
                                        mb={4}
                                        type="button"
                                        onClick={() => checkPrinterIp(receiptIpAddress, deviceName)}
                                    >
                                        <Indicator
                                            status={printerIsConnected ? "positive" : "inactive"}
                                            mx={2}
                                            size="lg"
                                        />
                                        {printerIsConnected
                                            ? translate("receiptPrinterIsConnected")
                                            : translate("testConnection")}
                                    </Button>
                                </>
                            )}
                            <Flex mb={4} direction="column">
                                <FormLabel>{translate("startScreenBackgroundImage")}</FormLabel>
                                <NewFormDropZone
                                    name={"expressPOS.startScreenBackgroundImgUrl"}
                                    prevFile={startScreenBackgroundImgUrl}
                                    setFieldValue={(_: string, value: string) =>
                                        setValue("expressPOS.startScreenBackgroundImgUrl", value)
                                    }
                                    fileType="EXPRESS"
                                    companyId={companyId}
                                    dropZoneText={translate("clickOrDragImage")}
                                />
                            </Flex>
                            <RHCheckBoxInput control={control} name="expressPOS.startScreenBackgroundImgBlurred">
                                {translate("blurredBackgroundImage")}
                            </RHCheckBoxInput>
                            <RHTextAreaInput
                                control={control}
                                name="expressPOS.startScreenHeader"
                                formLabel={translate("startScreenHeader")}
                                isFullWidth
                            />
                            <RHTextAreaInput
                                control={control}
                                name="expressPOS.startScreenBread"
                                formLabel={translate("startScreenBodyText")}
                                isFullWidth
                            />
                            <Flex mb={4} direction="column">
                                <FormLabel>{translate("startScreenCompanyLogo")}</FormLabel>
                                <NewFormDropZone
                                    name="expressPOS.startScreenLogoImgUrl"
                                    prevFile={startScreenLogoImgUrl}
                                    setFieldValue={(_: string, value: string) =>
                                        setValue("expressPOS.startScreenLogoImgUrl", value)
                                    }
                                    fileType="EXPRESS"
                                    companyId={companyId}
                                    dropZoneText={translate("clickOrDragImage")}
                                />
                            </Flex>
                        </>
                    )}
                </ModalBody>
                <ModalActions>
                    <Button
                        type="submit"
                        isDisabled={isSubmitting || !(isValid && isDirty)}
                        themeColor="green"
                        width="100%"
                        isLoading={isSubmitting}
                    >
                        {translate("save")}
                    </Button>
                </ModalActions>
            </form>
        </Modal>
    );
};
