import { PaginationSortOptions, DIRECTION } from "Organisms";
import { ShopRating } from "../types";

/**
 * Sorting function for score on each shop
 * @param {ShopRating} shopRatingA 
 * @param {ShopRating} shopRatingB 
 * @returns  
 */
export const sortByOverallScore = (shopRatingA: ShopRating, shopRatingB: ShopRating) => {
    if (shopRatingA.shopKPIs?.overallRatings?.score && shopRatingB.shopKPIs?.overallRatings?.score) {
        const itemAScore = shopRatingA.shopKPIs?.overallRatings?.score;
        const itemBScore = shopRatingB.shopKPIs?.overallRatings?.score;
        return itemAScore > itemBScore ? -1 : itemBScore < itemAScore ? 1 : 0;
    } else if (shopRatingA.shopKPIs?.overallRatings?.score && !shopRatingB.shopKPIs?.overallRatings?.score) {
        return -1;
    } else if (!shopRatingA.shopKPIs?.overallRatings?.score && shopRatingB.shopKPIs?.overallRatings?.score) {
        return 1;
    }
    return 0;
};

/**
 * Sorting function for number of reviews in each shop
 * @param {ShopRating} shopRatingA 
 * @param {ShopRating} shopRatingB 
 * @returns  
 */
export const sortByNumberOfReviews = (shopRatingA: ShopRating, shopRatingB: ShopRating) => {
    if (shopRatingA.shopKPIs?.overallRatings?.noOfReviews && shopRatingB.shopKPIs?.overallRatings?.noOfReviews) {
        const itemAScore = shopRatingA.shopKPIs?.overallRatings?.noOfReviews;
        const itemBScore = shopRatingB.shopKPIs?.overallRatings?.noOfReviews;
        return itemAScore > itemBScore ? -1 : itemBScore < itemAScore ? 1 : 0;
    } else if (shopRatingA.shopKPIs?.overallRatings?.noOfReviews && !shopRatingB.shopKPIs?.overallRatings?.noOfReviews) {
        return -1;
    } else if (!shopRatingA.shopKPIs?.overallRatings?.noOfReviews && shopRatingB.shopKPIs?.overallRatings?.noOfReviews) {
        return 1;
    }
    return 0;
};

/**
 * Sorting function for number of reviews in each shop
 * @param {ShopRating} shopRatingA 
 * @param {ShopRating} shopRatingB 
 * @returns  
 */
export const sortByShopName = (shopRatingA: ShopRating, shopRatingB: ShopRating) => {
    return shopRatingA.name.localeCompare(shopRatingB.name, "sv", { sensitivity: "base" });
}

/**
 * Default sort settings for shop ratings view
 */
export const initialSortingOptions: PaginationSortOptions[] = [
    {
        text: "Betyg",
        value: "score",
        default: true,
        sortingDirection: DIRECTION.ASC,
        sortingOrder: 1,
        sortFunction: sortByOverallScore
    },
    {
        text: "Omdömen",
        value: "review",
        default: false,
        sortingDirection: DIRECTION.NONE,
        sortingOrder: 2,
        sortFunction: sortByNumberOfReviews
    },
    {
        text: "Restaurang",
        value: "shop",
        default: false,
        sortingDirection: DIRECTION.NONE,
        sortingOrder: 3,
        sortFunction: sortByShopName
    }
];