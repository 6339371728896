import React from "react";
import { createRoot } from "react-dom/client";

import * as Sentry from "@sentry/browser";
import ReactGA from "react-ga4";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ApolloProvider } from "react-apollo";
import { DndProvider } from "react-dnd";
import { ToastContainer } from "react-toastify";
import { HelmetProvider } from "react-helmet-async";

import {
    ModalProvider,
    ModalRenderer,
    QoplaProvider,
    LanguageProvider,
    DrawerProvider,
    DrawerRender,
    AuthUserProvider,
    ThemeProvider
} from "Providers";
import { envConstants, LOCALHOST, frontyEnv } from "Constants";
import { GlobalStyles } from "Atoms";
import { apolloClient } from "./graphql/clients/ApolloConfig";
import RoutingProvider from "./providers/routingProvider/RoutingProvider";
import { FlatProviders } from "Components";
import "whatwg-fetch";
import "semantic-ui-css/semantic.min.css";
import "./styles/styles.scss";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { sentryErrorHelper } from "Utils";

//TODO: 1. update to typescript file
//TODO: 2. update sentry to latest version

Sentry.init({
    ignoreErrors: [
        "ResizeObserver loop limit exceeded",
        "ResizeObserver loop completed with undelivered notifications.",
        "Expected to find a valid target",
        "null is not an object (evaluating 'g.readyState')",
        // This error occurs when a resource (javascript) fails to load, likely due to a network error.
        // More discussion about this https://github.com/getsentry/sentry-javascript/issues/6945
        "Unexpected token '<'",
        // This occurs when a user is using chrome built-in Google translation. We can't
        "String contained an illegal UTF-16 sequence.",
        // We got planty of these errors, but only for one customer https://github.com/qopla/fronty/issues/3453
        // Couldn't reproduce it, so we are ignoring it for now
        "Invalid or unexpected token"
    ],
    dsn: envConstants.BACKEND_URL !== LOCALHOST ? envConstants.SENTRY_DSN : null,
    release: envConstants.VERSION,
    environment: envConstants.BETA ? `${envConstants.PROFILE}-BETA` : envConstants.PROFILE,
    beforeSend: event => {
        return sentryErrorHelper(event);
    }
});

if (envConstants.PROFILE === frontyEnv.PROD) {
    ReactGA.initialize(envConstants.ANALYTICS_TRACKING_ID);
} else if (envConstants.PROFILE === frontyEnv.DEV) {
    ReactGA.initialize(envConstants.ANALYTICS_TRACKING_ID);
} else if (envConstants.PROFILE === frontyEnv.LOCAL) {
    // If you want to troubleshoot/see what events are firing, switch these two lines and open the dev console.
    // ReactGA.initialize(envConstants.ANALYTICS_TRACKING_ID, { debug: true });
    ReactGA.initialize(envConstants.ANALYTICS_TRACKING_ID);
}

const providers = [
    <HelmetProvider />,
    <DndProvider backend={HTML5Backend} />,
    <LanguageProvider />,
    <ThemeProvider />,
    <ApolloProvider client={apolloClient} />,
    <ModalProvider />,
    <DrawerProvider />,
    <AuthUserProvider />,
    <QoplaProvider />
];

const App = () => (
    <>
        <GlobalStyles />
        <FlatProviders providers={providers}>
            <RoutingProvider />
            <ModalRenderer />
            <DrawerRender />
            <ToastContainer />
        </FlatProviders>
    </>
);

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);
