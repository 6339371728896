import React, { createContext, useCallback, useContext, useRef } from "react";

import { BaseBoxProps, Box } from "Atoms";
import {
    ModalHeader,
    ModalCloseBtn,
    ModalActions,
    Modal,
    CloseButtonProps,
    ModalBody,
    ModalOverlay,
    ModalFocusScope
} from "Molecules";
import { Slider } from "../";
import { useTheme } from "Providers";

export type DrawerSizes = "full" | "xl" | "lg" | "md" | "sm" | "xs" | string;
type DrawerPositions = "right" | "left" | "bottom" | "top";

interface IDrawerContext {
    closeOnEscape?: boolean;
    size: DrawerSizes;
    isScrolling?: boolean;
    from: DrawerPositions;
    open: boolean;
    closeOnDimmerClick: boolean;
    onClose: () => void;
    onOverlayClick?: () => void;
}

const DrawerContext = createContext<IDrawerContext>({} as any);
const useDrawer = () => useContext(DrawerContext);

export type DrawerProps = {
    id?: string;
    size?: DrawerSizes;
    onClose: () => void;
    onCloseCallback?: () => void;
    onOverlayClick?: () => void;
    open?: boolean;
    from?: DrawerPositions;
    duration?: number;
    allowPinchZoom?: boolean;
    blockScrollOnMount?: boolean;
    isScrolling?: boolean;
    closeOnEscape?: boolean;
    closeOnDimmerClick?: boolean;
} & BaseBoxProps;

const drawerSizes = {
    xs: "3xs",
    sm: "2xs",
    md: "xs",
    lg: "lg",
    xl: "md",
    full: "100vw"
};

const Drawer: React.FC<DrawerProps> = ({
    id = "drawer",
    open = false,
    closeOnDimmerClick = true,
    closeOnEscape = false,
    color,
    onClose,
    onCloseCallback,
    from = "right",
    size = "md",
    duration,
    allowPinchZoom = false,
    blockScrollOnMount = true,
    onOverlayClick,
    ...props
}) => (
    <DrawerContext.Provider
        value={{
            size,
            from,
            open,
            closeOnDimmerClick,
            onClose,
            onOverlayClick,
            closeOnEscape
        }}
    >
        <Modal
            allowPinchZoom={allowPinchZoom}
            open={open}
            id={id}
            closeOnDimmerClick={closeOnDimmerClick}
            closeOnEscape={closeOnEscape}
            onClose={onClose}
            onCloseCallback={onCloseCallback}
            blockScrollOnMount={blockScrollOnMount}
            isDrawer
            {...props}
        >
            {props.children}
        </Modal>
    </DrawerContext.Provider>
);

const DrawerCloseButton: React.FC<CloseButtonProps> = ({ onClick, ...rest }) => (
    <ModalCloseBtn position="absolute" top="20px" right="20px" {...rest} />
);

const DrawerOverlay: React.FC<BaseBoxProps> = props => <ModalOverlay {...props} />;
const DrawerHeader: React.FC<BaseBoxProps> = props => <ModalHeader {...props} />;
const DrawerBody: React.FC<BaseBoxProps> = props => <ModalBody {...props} />;
const DrawerActions: React.FC<BaseBoxProps> = props => <ModalActions {...props} />;

const DrawerContent: React.FC<BaseBoxProps> = ({ children, ...props }) => {
    const { size, open, from, closeOnDimmerClick, closeOnEscape, onClose, onOverlayClick } = useDrawer();
    const { sizes } = useTheme();

    const mouseDownTarget = useRef<EventTarget | null>(null);

    const onMouseDown = useCallback((event: any) => {
        mouseDownTarget.current = event.target;
    }, []);

    const onKeyDown = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                event.stopPropagation();

                if (closeOnEscape) {
                    //@ts-ignore
                    onClose(event);
                }
            }
        },
        [closeOnEscape, onClose]
    );

    const containerProps = {
        onMouseDown,
        onClick: (event: any) => {
            event.stopPropagation();

            if (mouseDownTarget.current !== event.target) return;

            if (closeOnDimmerClick) {
                onClose && onClose();
            }

            onOverlayClick?.();
        },
        onKeyDown
    };

    const dialogProps = {
        onClick: (event: any) => {
            event.stopPropagation();
        },
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column"
    };

    const isLeftOrRight = from === "right" || from === "left";
    //@ts-ignore
    let _size = size in drawerSizes ? sizes[drawerSizes[size]] : size;
    _size = isLeftOrRight ? _size : "100%";

    return (
        <Box position="fixed" left="0" top="0" width="100%" height="100%" zIndex={1400} {...(containerProps as any)}>
            <ModalFocusScope>
                <Slider direction={from} in={open} maxWidth={_size} {...(dialogProps as any)} {...(props as any)}>
                    {children}
                </Slider>
            </ModalFocusScope>
        </Box>
    );
};

export { Drawer, DrawerBody, DrawerOverlay, DrawerActions, DrawerCloseButton, DrawerContent, DrawerHeader };
