import { useState, useEffect } from "react";
import { useQuery } from "react-apollo";

import { GET_PING_RESPONSE } from "GraphQLQueries";
import { SessionConstants } from "Constants";
import { useSessionStorage } from ".";

export type PingBackendState = {
    shopChangeNumber: string;
    companyChangeNumber: string;
    webFrontendVersion: string;
    buildNumber: number;
    controlUnitAlive: boolean;
    mothershipAlive: boolean;
};

const initialSessChangeNos = {
    shopChangeNumber: "",
    companyChangeNumber: "",
    webFrontendVersion: ""
};

type Args = {
    shopId: string | null;
    companyId: string | null;
    pollInterval?: number;
    webFrontendVersionCallback?: (frontendVersion: string) => void;
    shopUpdateCallback?: (shopChangeNumber: string) => void;
    companyUpdateCallBack?: (shopChangeNumber: string) => void;
};

export const usePingBackend = ({
    shopId,
    companyId,
    pollInterval = 60000,
    webFrontendVersionCallback,
    shopUpdateCallback,
    companyUpdateCallBack
}: Args) => {
    const [sessChangeNos, setSessChangeNos] = useSessionStorage(SessionConstants.CHANGE_NUMBERS, initialSessChangeNos);

    const [state, setState] = useState<PingBackendState>({
        shopChangeNumber: sessChangeNos?.shop || undefined,
        companyChangeNumber: sessChangeNos?.company || undefined,
        webFrontendVersion: sessChangeNos?.web || undefined,
        buildNumber: 0,
        controlUnitAlive: false,
        mothershipAlive: false
    });

    const { data } = useQuery(GET_PING_RESPONSE, {
        variables: {
            shopId,
            companyId
        },
        pollInterval,
        skip: !shopId
    });

    useEffect(() => {
        const hasWebFrontendVersionUpdated =
            state.webFrontendVersion !== undefined && state.webFrontendVersion !== sessChangeNos.webFrontendVersion;

        if (hasWebFrontendVersionUpdated) {
            setSessChangeNos(state);
            webFrontendVersionCallback && webFrontendVersionCallback(state.webFrontendVersion);
        }
    }, [state.webFrontendVersion]);

    useEffect(() => {
        const hasShopUpdated =
            state.shopChangeNumber !== undefined && state.shopChangeNumber !== sessChangeNos.shopChangeNumber;

        if (hasShopUpdated) {
            setSessChangeNos(state);
            shopUpdateCallback && shopUpdateCallback(state.shopChangeNumber);
        }
    }, [state.shopChangeNumber]);

    useEffect(() => {
        const hasCompanyUpdated =
            state.companyChangeNumber !== undefined && state.companyChangeNumber !== sessChangeNos.companyChangeNumber;

        if (hasCompanyUpdated) {
            setSessChangeNos(state);
            companyUpdateCallBack && companyUpdateCallBack(state.companyChangeNumber);
        }
    }, [state.companyChangeNumber]);

    useEffect(() => {
        if (!!data) {
            const {
                getPingResponse: {
                    shopUpdateChangeNumber,
                    companyUpdateChangeNumber,
                    webFrontendVersion,
                    buildNumber,
                    controlUnitAlive,
                    mothershipAlive
                }
            } = data;

            setState({
                shopChangeNumber: shopUpdateChangeNumber,
                companyChangeNumber: companyUpdateChangeNumber,
                webFrontendVersion,
                buildNumber,
                controlUnitAlive,
                mothershipAlive
            });
        }
    }, [data]);

    return state;
};
