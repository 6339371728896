import React from "react";
import { Link as RLink } from "react-router-dom";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, Drawer, DrawerOverlay } from "Organisms";
import { useAuthUser, useLanguage, IDrawerComponentContext, UserAccount, UserTypes } from "Providers";
import { Flex, Link, Box, Button, Image, Header, Stack, Collapsable } from "Atoms";
import { LanguagePicker } from "Components";
import { LOGIN_USER_ACCOUNT_MUTATION } from "GraphQLMutations";
import { useSwitch, useTypedMutation } from "Hooks";
import { GOOGLE_ANALYTICS_EVENT_ACTION, GOOGLE_ANALYTICS_EVENT_CATEGORY, Subscription } from "Types";
import { UserAccountFormValues, UserAccountLoginForm } from "../../../shared";
import { gaEvent } from "Utils";
import { GoogleAuthenticationButton } from "Molecules";
import { LinkType } from "Types";

interface ICustomerDrawer {
    onSuccessfulLoginCallback?: (userAccount: UserAccount) => void;
    onCloseDrawerCallback: () => void;
    subscription: Subscription;
}

type Props = IDrawerComponentContext<ICustomerDrawer>;

export const CompanySubscriptionAuthDrawer: React.FC<Props> = ({
    onCloseDrawer,
    props: { subscription, onCloseDrawerCallback, onSuccessfulLoginCallback }
}) => {
    const { translate } = useLanguage();
    const { setAuthUserAndToken } = useAuthUser();
    const navigate = useNavigate();

    const { open: isLoginFormOpen, onToggle: onToggleLoginFormOpen } = useSwitch();

    const [loginUserAccount] = useTypedMutation(LOGIN_USER_ACCOUNT_MUTATION);

    const handleLogin = async (values: UserAccountFormValues) => {
        gaEvent({
            category: GOOGLE_ANALYTICS_EVENT_CATEGORY.SUBSCRIPTION,
            action: GOOGLE_ANALYTICS_EVENT_ACTION.LOGIN,
            label: "Started"
        });
        const { data } = await loginUserAccount({
            variables: { input: { email: values.email, password: values.password } }
        });

        if (data && data.loginUserAccount) {
            const [res] = data.loginUserAccount;
            if (res.path) {
                return res;
            } else {
                setAuthUserAndToken(UserTypes.USER_ACCOUNT, res);
                gaEvent({
                    category: GOOGLE_ANALYTICS_EVENT_CATEGORY.USER_ACCOUNT,
                    action: GOOGLE_ANALYTICS_EVENT_ACTION.LOGIN,
                    label: "Success"
                });

                if (onSuccessfulLoginCallback) {
                    onSuccessfulLoginCallback(res);
                } else {
                    onCloseDrawer();
                }
            }
        } else {
            toast.error(translate("somethingWentWrong"));
        }
    };

    const onNavigateToSignup = () => {
        gaEvent({
            category: GOOGLE_ANALYTICS_EVENT_CATEGORY.SUBSCRIPTION,
            action: GOOGLE_ANALYTICS_EVENT_ACTION.REGISTER,
            label: "Started"
        });
        const callbackUrl = `${location.href}&chosenSubscriptionId=${subscription.id}&shouldLoginOnVerification=1`;
        const signupUrl = `/signup?callbackUrl=${encodeURI(callbackUrl)}`;
        navigate(signupUrl, {
            state: {
                subscriptionName: subscription.name,
                subscriptionImageUrl: subscription.imageUrl
            }
        });
    };

    return (
        <Drawer open onClose={onCloseDrawerCallback} isScrolling>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>
                    <LanguagePicker />
                </DrawerHeader>

                <DrawerBody py={4} maxHeight="none">
                    <Header as="h3" mb={3}>
                        {translate("subscribeTo")} {subscription.name}
                    </Header>
                    <Image src={subscription.imageUrl} w="100%" rounded="lg" h="200px" objectFit="cover" />
                    <Box>
                        <Stack stretch={6} mt={8}>
                            <Collapsable open={!isLoginFormOpen}>
                                <Box mb={6}>
                                    <Button onClick={onNavigateToSignup} size="lg" themeColor="green" fullWidth>
                                        {translate("signUpAndSubscribe")}
                                    </Button>
                                </Box>
                            </Collapsable>
                            <Button size="lg" onClick={onToggleLoginFormOpen} fullWidth>
                                {translate(isLoginFormOpen ? "close" : "loginAndSubscribe")}
                            </Button>
                        </Stack>
                        <Collapsable open={isLoginFormOpen}>
                            <Box mt={6}>
                                <UserAccountLoginForm handleLogin={handleLogin} />
                            </Box>
                        </Collapsable>
                        <GoogleAuthenticationButton
                            my={6}
                            onSuccess={userAccount =>
                                onSuccessfulLoginCallback && onSuccessfulLoginCallback(userAccount)
                            }
                        />
                        <Flex mt={2} h="auto" justify="flex-end">
                            <Link as={RLink as LinkType} color="blue.500" to="/forgot-password">
                                {translate("forgotPassword")}
                            </Link>
                        </Flex>
                    </Box>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};
