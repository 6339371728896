import React, { Children, cloneElement, isValidElement } from "react";

import { useTheme } from "Providers";
import { Flex, IInput, BaseBoxProps, Input, inputSizes } from "Atoms";
import { InputLeftElement, InputRightElement } from "Molecules";

interface IInputGroup {
    size?: IInput["size"];
    children: React.ReactNode;
}

export type InputGroupProps = IInputGroup & BaseBoxProps;

export const InputGroup: React.FC<InputGroupProps> = ({ children, size = "md", ...props }) => {
    const { sizes } = useTheme();
    // @ts-ignore
    const height = inputSizes[size] && inputSizes[size]["height"];

    let pl: any = null;
    let pr: any = null;

    return (
        <Flex h="auto" position="relative" {...props}>
            {Children.map(children, child => {
                if (!isValidElement(child)) return;

                if (child.type === InputLeftElement) {
                    pl = sizes[height];
                }
                if (child.type === InputRightElement) {
                    pr = sizes[height];
                }
                if (child.type === Input) {
                    return cloneElement(child, {
                        // @ts-ignore
                        size,
                        pl: child.props.pl || pl,
                        pr: child.props.pr || pr,
                        fullWidth: true
                    });
                }
                // @ts-ignore
                return cloneElement(child, { size });
            })}
        </Flex>
    );
};
