/**
 * @module textFormatHelpers
 *
 */

import { PaymentMethod } from "Constants";
import { getDecimalSeparator } from "../admin/components/analytics-reports/shared";

/**
 *  Create a fixed decimal percentage from two numbers
 * @param {number} part  - the small part to create the percentage from
 * @param {number} whole  - the total =  100%
 * @returns
 */
export const toPercentage = (part: number, whole: number) => `${getDecimalSeparator((part / whole) * 100)}%`;

export const removeLastChar = (str: string) => str.substring(0, str.length - 1);

/**
 * Removes everything after character.
 * @example removeAfterChar("martin#1234", "#") -> "martin"
 * @param str
 * @param char
 * @returns string
 */
export const removeAfterChar = (str: string, char: string) => str.split(char)[0];

export const replaceNewLineCharactersWithSpace = (str: string | null) => {
    if (!str) {
        return str;
    }

    /**
     * These regexes can be replaced by  inline `.replaceAll` functions if we upgrade our `lib` to `es2021`
     * Then we can just do
     *  cleanedString = cleanedString.replaceAll("\r\n", " ");
     */

    const carriageReturnRegex = new RegExp(/\r/g);
    const lineFeedRegex = new RegExp(/\n/g);
    const combinedReturnRegex = new RegExp(/\r\n/g);

    let cleanedString = str;
    cleanedString = cleanedString.replace(combinedReturnRegex, " ");
    cleanedString = cleanedString.replace(carriageReturnRegex, " ");
    cleanedString = cleanedString.replace(lineFeedRegex, " ");
    return cleanedString;
};

/**
 * Check if string contains any special characters
 * @example doesContainsSpecialCharacters("qop#la") -> true
 * @param str
 * @returns boolean
 */
export const doesContainsSpecialCharacters = (str: string) => {
    const noSpecialCharactersRegex = /^[ÅÄÖåäöA-Za-z0-9\s]+$/;
    return !noSpecialCharactersRegex.test(str);
};

export const swedishPaymentMethod = {
    CASH: { swe: "Kontant", engConstant: "CASH" },
    CARD: { swe: "Kortbetalning", engConstant: "CARD" },
    SWISH: { swe: "Swishbetalning", engConstant: "SWISH" },
    INVOICE: { swe: "Faktura", engConstant: "INVOICE" },
    GIFT_CARD: { swe: "Presentkort", engConstant: "GIFT_CARD" },
    MANUAL_CARD: { swe: "Fristående Kortbetalning", engConstant: "MANUAL_CARD" }
} as Record<PaymentMethod, { swe: string; engConstant: string }>;

export const cardText = {
    AMEX: "American Express"
};

/** [FUNCTION] - Capitalise each first letter of each word in a sentence
 * 
 */
export const capitaliseEachWord = (sentence: string) => {
    return sentence
        .split(" ")
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(" ");
};
