import { roundToTwo } from "NumberUtils";
import { getVatAmount } from "PriceUtils";
import {
    CartProduct,
    OpenProduct,
    MenuProduct,
    RefProduct,
    PriceType,
    OrderProduct,
    DeepPartial,
    OPEN_PRODUCT_TYPE
} from "Types";
import { createCartProductHashId } from "./hashValueHelper";

/*
 * Open Product Helper
 * 1. Send in open product object which inc. companyId, shopId, totalPrice, vatRate & menuId
 * 2. Create a partial cart product object and calculate totalNetPrice
 * 3. Create hash ids for the various objects
 * 4. Send back cart Product for order window
 */

/**
 *
 * @param {OpenProduct} openProduct
 * @returns {DeepPartial<CartProduct>} returns a partial cartProduct type
 */
export const createOpenCartProduct = (openProduct: OpenProduct, orderWindowId: string): DeepPartial<CartProduct> => {
    const menuProduct = createOpenMenuProduct(openProduct);
    const orderProduct = createOpenProductOrderProduct(openProduct, menuProduct.refProduct!);
    const cartProduct: Partial<CartProduct> = {
        orderProduct: orderProduct as OrderProduct,
        menuProduct: menuProduct as MenuProduct
    };
    const cartProductId = createCartProductHashId(cartProduct as CartProduct, orderWindowId);
    return {
        id: cartProductId,
        menuProduct: menuProduct,
        orderProduct: orderProduct
    };
};

/**
 * Function to create menu product and contains ref product creation
 * @param {OpenProduct} openProduct
 * @returns {DeepPartial<MenuProduct>} returns a partial type of menuProduct
 */
const createOpenMenuProduct = (openProduct: OpenProduct): DeepPartial<MenuProduct> => {
    return {
        id: openProduct.menuId,
        refProduct: createOpenRefProduct(openProduct)
    };
};

/**
 * Function to create ref Product object
 * @param {OpenProduct} openProduct
 * @returns {Partial<RefProduct>} returns partial refProduct type
 */
const createOpenRefProduct = (openProduct: OpenProduct): Partial<RefProduct> => {
    return {
        id: OPEN_PRODUCT_TYPE.OPEN_PRODUCT + "_" + openProduct.type,
        refProductCategoryId: OPEN_PRODUCT_TYPE.OPEN_PRODUCT_CATEGORY,
        name: "Öppen Produkt",
        companyId: openProduct.companyId,
        description: "Öppen produkt",
        defaultPrice: openProduct.totalPrice,
        priceType: PriceType.PRICE_PER_UNIT,
        vatRate: openProduct?.vatRate ?? 12
    };
};

/**
 * Function to create open order product with price and vat calculations
 * @param {OpenProduct} openProduct
 * @param {DeepPartial<RefProduct>} refProduct
 * @returns {Partial<OrderProduct>} returns partial order product object
 */
const createOpenProductOrderProduct = (
    openProduct: OpenProduct,
    refProduct: DeepPartial<RefProduct>
): Partial<OrderProduct> => {
    const vatAmount = getVatAmount(openProduct.vatRate, openProduct.totalPrice);
    const netAmount = roundToTwo(openProduct.totalPrice - vatAmount);
    return {
        name: refProduct.name,
        refProductId: refProduct.id,
        menuCategoryId: OPEN_PRODUCT_TYPE.OPEN_PRODUCT_MENU_CATEGORY,
        priceType: refProduct.priceType,
        refProductCategoryId: refProduct.refProductCategoryId,
        modifications: { flavours: openProduct.modification } as any,
        quantity: 1,
        shopId: openProduct.shopId,
        unitPrice: refProduct.defaultPrice,
        totalPrice: refProduct.defaultPrice,
        totalNetPrice: netAmount,
        vatRate: openProduct.vatRate,
        isOpenProduct: true,
        refBundleProductId: null,
        selectedBundleProductItems: null,
        weight: 0
    };
};
