import React, { useState } from "react";
import TruncateMarkup, { TruncateProps } from "react-truncate-markup";

import { BaseBoxProps, Box } from "Atoms";
import { Link } from "Atoms";
import { useLanguage } from "Providers";

type Props = {
    as?: React.ElementType;
    wrapperProps?: BaseBoxProps;
    showExpand?: boolean;
    children: React.ReactNode | string;
} & Omit<TruncateProps, "ellipsis">;

//TODO: change name to Truncate - when we remove the old one
export const NewTruncate: React.FC<Props> = ({ as, wrapperProps, children, showExpand = false, ...rest }) => {
    const { translate } = useLanguage();
    const [showMore, setShowMore] = useState(false);
    const isReactNode = React.isValidElement(children);

    const internalChild = isReactNode ? children : <span style={{ display: "flex" }}>{children}</span>;

    const ReadMore = () => {
        return (
            <Link ml={2} color="blue.500" as="span" onClick={() => setShowMore(!showMore)}>
                {translate("more")}
            </Link>
        );
    };
    const ShowLess = () => {
        return (
            <Link ml={2} color="blue.500" as="span" onClick={() => setShowMore(!showMore)}>
                {translate("less")}
            </Link>
        );
    };

    return (
        <Box as="span" {...wrapperProps}>
            {!showMore ? (
                <TruncateMarkup lines={1} ellipsis={showExpand ? <ReadMore /> : <span>...</span>} {...rest}>
                    {internalChild}
                </TruncateMarkup>
            ) : (
                <>
                    {internalChild}
                    <ShowLess />
                </>
            )}
        </Box>
    );
};
