import React, { useState } from "react";
import { Link } from "react-router-dom";
import { CookieSetOptions } from "universal-cookie";

import { Text, Button, Flex, Box } from "Atoms";
import { LanguagePicker, LanguageText } from "Components";
import { modals, useLanguage, useModal } from "Providers";
import { DrawerBody, DrawerContent, Drawer, DrawerOverlay } from "Organisms";
import { CookieCustomerConsent } from "Types";
import { useAnonymousUserId } from "OnlineHooks";

const cookiePolicyUrl = "https://app.termly.io/document/cookie-policy/0597b871-aceb-49c8-bc4d-d2488a0e84c7";

export const OnlineCookiesAcceptOverlay: React.FC<{
    setConsentCookie: (consent: CookieCustomerConsent, options?: CookieSetOptions | undefined) => void;
}> = ({ setConsentCookie }) => {
    const [isOpen, setIsOpen] = useState(true);

    const { translate } = useLanguage();
    const { openModal } = useModal();
    const { setAnonymousUserId } = useAnonymousUserId();

    const onSetCookieConsent = (consent?: CookieCustomerConsent) => {
        if (consent) {
            setConsentCookie(consent);
            setAnonymousUserId(consent.functional);
        } else {
            setConsentCookie({
                essential: true,
                functional: true,
                analytics: true,
                marketing: true
            });
            setAnonymousUserId(true);
        }

        setIsOpen(false);
    };

    return (
        <Drawer from="bottom" open={isOpen} onClose={() => {}}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerBody>
                    <Flex direction="column" align="center">
                        <Box width="100%" mb={4}>
                            <LanguagePicker size="sm" />
                        </Box>

                        <Flex direction={["column", "column", "row"]} align="center">
                            <Text fontSize="lg" m={0}>
                                <LanguageText tid="acceptCookies" as="span" color="black" />
                                <Link to="/privacy"> {translate("privacyPolicy")}</Link>
                                {` ${translate("or").toLowerCase()} `}
                                <Box as="a" href={cookiePolicyUrl}>
                                    Cookiepolicy
                                </Box>
                            </Text>
                            <Button
                                size="lg"
                                variant="outline"
                                minW="10rem"
                                ml={[0, 0, 8]}
                                mt={[4, 4, 0]}
                                width={["100%", "100%", "auto"]}
                                onClick={() =>
                                    openModal(modals.cookieManagerModal, { setConsentCookie: onSetCookieConsent })
                                }
                            >
                                {translate("manage")}
                            </Button>
                            <Button
                                size="lg"
                                themeColor="blue"
                                ml={[0, 0, 8]}
                                mt={[4, 4, 0]}
                                minW="10rem"
                                width={["100%", "100%", "auto"]}
                                onClick={() => onSetCookieConsent()}
                            >
                                {translate("acceptAll")}
                            </Button>
                        </Flex>
                    </Flex>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};
