import React from "react";
import moment from "moment";

import { Modal, ModalBody, ModalCloseBtn } from "Molecules";
import { Flex, Header, Box, Image } from "Atoms";
import { useLanguage } from "Providers";
import { convertDayOfWeekToSwe, getNextDayThatOpens } from "Utils";
import { OnlineOpeningHours } from "OnlineComponents";
import { ActiveHour } from "Types";
import { useQoplaStore } from "Stores";

type Props = {
    activeHours: ActiveHour[];
    closeModal: () => void;
};

export const OnlineOrderClosedModal: React.FC<Props> = ({ activeHours, closeModal }) => {
    const { translate } = useLanguage();

    const { selectedShop: shop } = useQoplaStore();

    const activeHour: ActiveHour = getNextDayThatOpens(activeHours);
    const isToday: boolean = moment().locale("en").format("dddd").toUpperCase() === activeHour.dayOfWeek;

    const nextDayOpen = activeHour.dayOfWeek ? convertDayOfWeekToSwe(activeHour.dayOfWeek) : null;
    const dayOpenText = isToday ? translate("today") : nextDayOpen;

    const displayOpening = dayOpenText ? (
        <Box>{`${translate("open")} - ${dayOpenText}: ${activeHour.startingHour} - ${activeHour.stoppingHour}`}</Box>
    ) : (
        <Box width={["100%", "50%"]}>
            <OnlineOpeningHours activeHours={activeHours} />
        </Box>
    );

    return (
        <Modal open={true} size="sm" maxW="50rem" rounded="md" isScrolling onClose={closeModal}>
            <ModalCloseBtn onClick={closeModal} zIndex={1500} />
            <ModalBody>
                <Flex justify="center" align="center" direction="column" textAlign="center">
                    <Header as="h1" size="xl" textAlign="center">
                        {shop?.name}
                    </Header>
                    <Image
                        fallbackSrc={shop?.imageUrl}
                        src={shop?.imageUrl}
                        display="flex"
                        width="96px"
                        rounded="md"
                        objectFit="contain"
                    />
                    <Header color="black" size="lg">
                        {translate("isClosedTitle")}
                    </Header>
                    {displayOpening}
                    <Header as="h1" size="md" color="newPrimary" fontFamily="qopla" textAlign="center">
                        Qopla
                    </Header>
                </Flex>
            </ModalBody>
        </Modal>
    );
};
