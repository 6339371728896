import { List, ListItem, Skeleton, Stack, Text } from "Atoms";
import { useLanguage } from "Providers";
import React, { useState } from "react";

interface IIdentifiable {
    id: string;
}

type Props = {
    items: Array<Partial<IIdentifiable>>;
    maxItemsToShow?: number;
    itemsToShowIncrement?: number;
    isLoading?: boolean;
    renderItem: (item: any) => JSX.Element;
};

const DEFAULT_MAX_ITEMS_TO_SHOW = 5;
const DEFAULT_ITEMS_TO_SHOW_INCREMENT = 10;

export const ExpandableList: React.FC<Props> = props => {
    const { translate } = useLanguage();
    const { items, renderItem, isLoading } = props;
    const maxItemsToShow = props.maxItemsToShow || DEFAULT_MAX_ITEMS_TO_SHOW;
    const itemsToShowIncrement = props.itemsToShowIncrement || DEFAULT_ITEMS_TO_SHOW_INCREMENT;

    const [state, setState] = useState({
        amountToShow: maxItemsToShow,
        hasReachedLimit: false
    });

    const onShowMore = () => {
        setState(currState => {
            const amountToShow = currState.amountToShow + itemsToShowIncrement;
            return {
                amountToShow,
                hasReachedLimit: amountToShow >= items.length
            };
        });
    };

    const onResetAmount = () => {
        setState({
            amountToShow: maxItemsToShow,
            hasReachedLimit: false
        });
    };

    const onClickShowMore = () => {
        if (state.hasReachedLimit) {
            onResetAmount();
        } else {
            onShowMore();
        }
    };

    let itemsToShow: typeof items = items;
    const canShowMore = items.length > maxItemsToShow;

    if (canShowMore) {
        itemsToShow = items.slice(0, state.amountToShow);
    }

    if (isLoading) {
        <Stack stretch={2}>
            <Skeleton h="20px" w="100%" />
            <Skeleton h="20px" w="100%" />
            <Skeleton h="20px" w="100%" />
            <Skeleton h="20px" w="100%" />
        </Stack>;
    }

    return (
        <List listStyle="none" stretch={2} ml={2}>
            {itemsToShow.map(item => (
                <ListItem key={item.id} display="flex" alignItems="center">
                    {renderItem(item)}
                </ListItem>
            ))}
            {canShowMore && (
                <Text as="span" fontSize="lg" color="blue.500" cursor="pointer" onClick={onClickShowMore}>
                    {translate(state.hasReachedLimit ? "showLess" : "showMore")}
                </Text>
            )}
        </List>
    );
};
