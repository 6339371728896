import { PaymentMethod } from "Constants";
import { PaymentTab, DTInfo } from "Types";

const defaultPaymentTab: PaymentTab = {
    id: "",
    displayHeaderText: "",
    displayText: "Väntar på kund",
    status: undefined,
    paymentMethod: undefined,
    driveThrough: false,
    cancelledByShop: undefined,
    terminalId: undefined,
    postponePayment: false,
    DTInfo: {
        orderNumber: undefined,
        orderId: undefined
    }
};
export const getDisplayText = (isDT: boolean, DTInfo: DTInfo, paymentMethod: string) => {
    let displayText = "";
    if (isDT && DTInfo) {
        displayText = `Order Nr: ${DTInfo.orderNumber}`;
    } else if (paymentMethod === PaymentMethod.INVOICE) {
        displayText = "Välj fakturakund";
    } else if (paymentMethod === PaymentMethod.POSTPONED) {
        displayText = "Order tillagd och parkerad";
    }
    return displayText || defaultPaymentTab.displayText;
};

export const addNewPaymentTab = (newProps: Object, activePaymentTabId: string): PaymentTab => {
    return {
        ...defaultPaymentTab,
        ...newProps,
        id: activePaymentTabId
    };
};

export const updatePaymentTab = (activePaymentTab: PaymentTab, newProps: Object): PaymentTab => {
    return {
        ...activePaymentTab,
        ...newProps,
        displayHeaderText: defaultPaymentTab.displayHeaderText,
        displayText:
            activePaymentTab.paymentMethod === "POSTPONED"
                ? "Order tillagd och parkerad"
                : defaultPaymentTab.displayText
    };
};

export const findActivePaymentTabsByStatuses = (statuses: string[], activePaymentTabs: Record<string, PaymentTab>) => {
    const paymentTabArray = Object.values(activePaymentTabs);

    return paymentTabArray.filter(paymentTab => statuses.includes(paymentTab.status as string));
};

export const findActivePaymentTabsByStatus = (status: string, activePaymentTabs: Record<string, PaymentTab>) => {
    return findActivePaymentTabsByStatuses([status], activePaymentTabs);
};
