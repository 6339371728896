import gql from "graphql-tag";

export const GET_PING_RESPONSE = gql`
    query getPingResponseQuery($shopId: String, $companyId: String) {
        getPingResponse(shopId: $shopId, companyId: $companyId) {
            buildNumber
            companyUpdateChangeNumber
            controlUnitAlive
            mothershipAlive
            globalQoplaMessage {
                id
                title
                text
                type
                level
            }
            shopUpdateChangeNumber
            webFrontendVersion
        }
    }
`;

export const GET_FRONTEND_VERSION = gql`
    query getFrontendVersion {
        getFrontendVersion
    }
`;
