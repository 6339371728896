import { useEffect } from "react";

import { CartProduct } from "Types";
import { useOrderWindowsStore } from "Stores";

export const useShouldClearOnlineCart = () => {
    const { CartManager, orderWindows, activeMenus } = useOrderWindowsStore();
    const { removeCartProducts } = CartManager;
    const [orderWindow] = orderWindows;

    useEffect(() => {
        // Sometimes we get an empty array of activeMenus when components are mounted
        // The cart is, however, persisted in local storage
        // Therefore, we skip so the cart is not cleared when the component is mounted
        // e.g. without this code, the cart is cleared when the Checkout button is clicked
        if (!!activeMenus.length) {
            // Collect all the product ids from the active menus
            // We must collect upsell products separately because they are not in `onlineProductCategories`
            const activeMenuProductIds = activeMenus
                .flatMap(menu => menu.onlineProductCategories)
                .flatMap(category => category.onlineProducts)
                .map(product => product.menuProduct?.id || product.menuBundleProduct?.id)
                .filter(Boolean);

            const activeUpsellProductIds = activeMenus
                .flatMap(menu => menu.onlineUpsellCategory?.onlineProducts)
                .map(product => product?.menuProduct?.id || product?.menuBundleProduct?.id)
                .filter(Boolean);

            const productsToRemove = orderWindow?.cartProducts.filter((cartProduct: CartProduct) => {
                const productId = cartProduct.menuProduct?.id || cartProduct.menuBundleProduct?.id;
                // Only remove products that are not in the active menus
                const isMenuProductInActiveMenus = activeMenuProductIds.includes(productId);
                const isUpsellProductInActiveMenus = activeUpsellProductIds.includes(productId);
                const shouldRemove = !isMenuProductInActiveMenus && !isUpsellProductInActiveMenus;
                return shouldRemove;
            });

            removeCartProducts(productsToRemove);
        }
    }, [activeMenus]);
};
