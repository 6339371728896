import { colors } from "../../../../theme/colors";

export const webColor = colors.yellow["500"];
export const posColor = colors.green["300"];
export const thirdPartyServiceColor = colors.pink["400"];
export const foodoraColor = colors.pink["500"];
export const ubereatsColor = "black";
export const woltColor = colors.blue["400"];
export const boltColor = "#2eb875";
export const cookColor = colors.red["300"];

export const prevWebColor = colors.indigo["400"];
export const prevPosColor = colors.blue["200"];
export const prevThirdPartyServiceColor = colors.purple["500"];

export const grayColor = colors.gray["400"];

export const highlightColumn = colors.green["500"];

export const colorsPaletteCategories = [
    colors.green["300"],
    colors.teal["300"],
    colors.indigo["300"],
    colors.yellow["300"],
    colors.orange["300"],
    colors.red["300"],
    colors.green["500"],
    colors.teal["500"],
    colors.indigo["500"],
    colors.yellow["500"],
    colors.orange["500"],
    colors.red["500"],
    colors.green["600"],
    colors.teal["600"],
    colors.indigo["600"],
    colors.yellow["600"],
    colors.orange["600"],
    colors.red["600"]
];

export const colorsPaletteCookTime = [
    colors.gray["200"],
    colors.green["500"],
    colors.green["400"],
    colors.green["300"],
    colors.green["200"],
    colors.yellow["300"],
    colors.yellow["400"],
    colors.orange["300"],
    colors.orange["400"],
    colors.red["300"]
];

export const getColor = (timeInMs: number): string => {
    if (timeInMs === 0) return colors.gray["200"];
    // > 1 hour
    else if (timeInMs > 3600000) return colors.red["600"];
    // from 0 to 2min
    else if (timeInMs > 0 && timeInMs <= 120000) return colors.green["500"];
    // from 2min to 4min
    else if (timeInMs > 120000 && timeInMs <= 240000) return colors.green["400"];
    // from 4min to 8min
    else if (timeInMs > 240000 && timeInMs <= 480000) return colors.green["300"];
    // from 8min to 10min
    else if (timeInMs > 480000 && timeInMs <= 600000) return colors.green["200"];
    // from 10min to 15min
    else if (timeInMs > 600000 && timeInMs <= 900000) return colors.yellow["300"];
    // from 15min to 20min
    else if (timeInMs > 900000 && timeInMs <= 1200000) return colors.yellow["400"];
    // from 20min to 25min
    else if (timeInMs > 1200000 && timeInMs <= 1500000) return colors.orange["400"];
    // from 25min to 30min
    else if (timeInMs > 1500000 && timeInMs <= 1800000) return colors.orange["400"];
    // from 30min to 60min
    else if (timeInMs > 1800000 && timeInMs <= 3600000) return colors.red["400"];
    return colors.gray["200"];
};
