import React from "react";
import { Icon } from "semantic-ui-react";
import { sortBy } from "lodash";

import { confirmNotification } from "TempUtils";
import { usePos, useLocalPosSettings } from "Providers";
import { OrderWindow } from "../components/OrderWindow";
import { PaymentHandler } from "../components/PaymentHandler";
import { getActivePaymentTabIcon, isCashInvoice } from "../utils";
import { Tab, TabPanel } from "Organisms";
import { CloseButton } from "Molecules";
import { Text } from "Atoms";
import { useOrderWindowsStore, usePosStore, useTableStore } from "Stores";
import { POS_MODE } from "Constants";

export const generateMenuPanes = (menus, MenuContent, activePane) => {
    const sortedMenus = sortBy(menus, "sortOrder");

    return sortedMenus.map(menu => {
        return {
            tab: (
                <Tab key={menu.id}>
                    <Text as="span">{menu.name}</Text>
                </Tab>
            ),
            tabPanel: (
                <TabPanel key={menu.id} h="100%">
                    <MenuContent menu={menu} activePane={activePane} />
                </TabPanel>
            )
        };
    });
};

const onRemoveOrderWindow = async (orderWindow, clearOrderWindow, abortParkedOrder) => {
    const { cartProducts, contactInformation, displayName } = orderWindow;
    const updatedDisplayName = displayName >= 1000 ? `Del ${displayName - 999}` : displayName;
    if (cartProducts.length > 0 || contactInformation) {
        const confirmTitle = contactInformation
            ? `Ta bort ${contactInformation.name}?`
            : `Ta bort ${updatedDisplayName}?`;
        const confirmDesc = contactInformation
            ? `${contactInformation.name} och dess produkter kommer att tas bort.`
            : `${updatedDisplayName} och dess produkter kommer att tas bort.`;

        const { value } = await confirmNotification(confirmTitle, confirmDesc);

        if (value) {
            if (orderWindow.postponeOrderId && orderWindow.contactInformation && !orderWindow.tableId) {
                // Parked orders
                abortParkedOrder({ variables: { orderId: orderWindow.postponeOrderId } });
            }
            clearOrderWindow(orderWindow?.id);
        }
    } else {
        clearOrderWindow(orderWindow?.id);
    }
};

export const generateOrderWindowsPanes = (orderWindows, posTerminals, activePane) => {
    const {
        paymentTabsUtils: { abortParkedOrder }
    } = usePos();
    const { activePaymentTabs } = usePosStore();

    const { getActiveOrderWindow, clearOrderWindow, split } = useOrderWindowsStore();
    const { isInTableSelectionMode } = useTableStore();

    const { disableOnLockingPos: isCustomerFacingEnabled } = useLocalPosSettings();

    const { fromOrderWindowId, to, mode: posMode } = split;
    const activeOrderWindow = getActiveOrderWindow();

    if (!activeOrderWindow?.id) {
        return [];
    }
    return orderWindows.map(orderWindow => {
        const { id, contactInformation, displayName } = orderWindow;

        const isFromSplitTab = to.includes(orderWindow.id);
        const isBaseSplitTab = fromOrderWindowId === id;

        const isSplitOrderWindow = isFromSplitTab || isBaseSplitTab;
        const isActiveOrderWindow = orderWindow.id === activeOrderWindow.id;

        const canRemove = !isSplitOrderWindow && isActiveOrderWindow && !isCustomerFacingEnabled;

        const { status, displayText } = activePaymentTabs[id] || {};
        const iconProps = getActivePaymentTabIcon(status, isCashInvoice(displayText));

        let updatedDisplayName = displayName;
        if (!!contactInformation) {
            updatedDisplayName = contactInformation.name;
        }

        const isDisabled = posMode === POS_MODE.SPLIT;

        return {
            tab: (
                <Tab key={id} h="auto" isDisabled={isDisabled}>
                    {iconProps && <Icon {...iconProps} />}
                    <Text as="span" mx={3}>
                        {updatedDisplayName}
                    </Text>
                    {canRemove && !isInTableSelectionMode && (
                        <CloseButton
                            as="div"
                            position="initial"
                            h="28px"
                            w="28px"
                            p={0}
                            onClick={e => {
                                e.stopPropagation();
                                onRemoveOrderWindow(orderWindow, clearOrderWindow, abortParkedOrder);
                            }}
                        />
                    )}
                </Tab>
            ),
            tabPanel: (
                <TabPanel key={id} h="100%">
                    <PaymentHandler orderWindowId={id} posTerminals={posTerminals}>
                        {props => <OrderWindow orderWindow={orderWindow} activePane={activePane} {...props} />}
                    </PaymentHandler>
                </TabPanel>
            )
        };
    });
};
