import Axios from "axios";
import { v4 as uuidv4 } from "uuid";

import { getActivePaymentTabTexts } from "../paymentHelpers";
import {
    CommonPOSPaymentParams,
    PaymentMethodAmount,
    PosOrderDTO,
    PosOrderDTOInput,
    SelectedPos,
    Shop,
    Terminal
} from "Types";
import { handleFailedOrder, handleCreatePosOrder, openPinByPass, makePinByPassPayment } from "./";
import { BaxiLocalModeResults, envConstants, paymentTabStatusTypes } from "Constants";
import { orderWindowsStore, posStore, qoplaStore } from "Stores";
import { addTipToCardOrder } from "../posHelpers";

type CardPaymentParams = { terminal: any; dtOrderId: string } & CommonPOSPaymentParams;

const cardRESTPayment = async (
    amount: number,
    terminal: Terminal,
    orderInput: PosOrderDTOInput,
    paymentFuncs: any,
    selectedPos: SelectedPos | null,
    activeOrderWindowId: string,
    updateActivePaymentTabStatus: Function,
    selectedShop: Shop | null,
    optionalReceipts: boolean,
    confirmOptionalReceipts: Function
) => {
    console.log("%c REST PAYMENT", "background: #222; color: #bada55");
    const orderIdentifier = uuidv4();
    const baxiPurchaseMessage = {
        transactionType: "purchase",
        amount: Math.round(amount * 100),
        orderId: orderIdentifier
    };

    // add to order history so we can use lastPurchase on these later
    paymentFuncs.addOngoingOrder({
        order: orderInput,
        orderWindowId: activeOrderWindowId,
        orderIdentifier: orderIdentifier,
        terminalId: terminal.terminalId
    });

    const { data } = await Axios.post(
        `${envConstants.NETS_CLOUD_REST_URL}/v1/terminal/${terminal.terminalId}/transaction`,
        baxiPurchaseMessage,
        {
            headers: {
                Authorization: `Bearer ${paymentFuncs.apiToken}`
            },
            // to prevent axios from throwing an error on non-2xx status codes
            validateStatus: () => true
        }
    );

    const netsResult = data?.result?.at(0)?.localModeEventArgs;

    // successful purchase
    if (netsResult?.result == BaxiLocalModeResults.FINANCIAL_TRANSACTION_OK) {
        const hasGivenTip = !!netsResult.TipAmount;
        let updatedOrderWithCardDetails = {
            ...orderInput,
            creditCardInfo: {
                cardNumber: netsResult.TruncatedPAN,
                cardIssuer: netsResult.CardIssuerName,
                transactionId: netsResult.TransactionId
            }
        } as PosOrderDTO;

        if (hasGivenTip) {
            // Append tip to paymentMethodAmounts
            updatedOrderWithCardDetails = addTipToCardOrder(updatedOrderWithCardDetails, netsResult.TipAmount);
        }

        const { getOrderWindow } = orderWindowsStore.getState();

        const { getActivePaymentTab } = posStore.getState();

        const activeOrderWindow = getOrderWindow(activeOrderWindowId);
        const activePaymentTab = getActivePaymentTab(activeOrderWindowId);

        // check which endpoint to call based on if this is a postponed order or not
        if ((activePaymentTab && activePaymentTab.driveThrough) || activeOrderWindow?.postponeOrderId) {
            const orderId =
                activePaymentTab && activePaymentTab.driveThrough
                    ? activePaymentTab?.DTInfo?.orderId
                    : activeOrderWindow?.postponeOrderId;
            paymentFuncs.payPostponedCloudPurchase(
                paymentFuncs.savePaidAttemptCloudPostponedOrder,
                updatedOrderWithCardDetails,
                selectedPos?.receiptPrinter,
                updateActivePaymentTabStatus,
                activeOrderWindowId,
                null,
                orderId,
                optionalReceipts,
                confirmOptionalReceipts,
                selectedShop
            );
        } else {
            paymentFuncs.cloudPurchase(
                paymentFuncs.createCloudPosOrder,
                updatedOrderWithCardDetails,
                selectedPos?.receiptPrinter,
                updateActivePaymentTabStatus,
                activeOrderWindowId,
                null, // not needed since this is used to clear terminal state in baxiprovider
                terminal.terminalId,
                optionalReceipts,
                confirmOptionalReceipts,
                selectedShop
            );
        }

        return;
    } else {
        // some kind of failure happened
        updateActivePaymentTabStatus(
            activeOrderWindowId,
            true,
            "Misslyckat",
            "", //data.failure?.error, //dont seem to need anything here since error is retrieved from websocket
            paymentTabStatusTypes.FAILED
        );
        return;
    }
};

export const makeCardPayment = async ({
    paymentMethodAmount,
    orderInput,
    updateActivePaymentTabStatus,
    terminal,
    activeOrderWindowId,
    paymentFuncs,
    dtOrderId,
    postponedInfo,
    optionalReceipts,
    confirmOptionalReceipts
}: CardPaymentParams) => {
    const { selectedShop } = qoplaStore.getState();
    const { queueHasStarted, updateQueuedOrdersList, selectedPos } = posStore.getState();

    try {
        let purchaseData = null;

        const isTotalZero = paymentMethodAmount.amount === 0;

        const posOrderInput = {
            ...orderInput,
            terminalId: terminal ? terminal.terminalId : null,
            paymentMethodAmounts: [paymentMethodAmount] as PaymentMethodAmount[],
            queueOrder: queueHasStarted
        };

        // Cloud purchase using websocket
        if (terminal && terminal.cloudEnabled && !isTotalZero && !terminal.cloudRESTEnabled) {
            paymentFuncs.makeCloudPurchase(
                terminal.terminalId,
                paymentMethodAmount.amount,
                posOrderInput,
                activeOrderWindowId
            );
            return;
        }

        // Cloud purchase using REST
        if (terminal && terminal.cloudEnabled && terminal.cloudRESTEnabled) {
            cardRESTPayment(
                paymentMethodAmount.amount,
                terminal,
                posOrderInput,
                paymentFuncs,
                selectedPos,
                activeOrderWindowId,
                updateActivePaymentTabStatus,
                selectedShop,
                optionalReceipts,
                confirmOptionalReceipts
            );
            return;
        }

        // Legacy baxi card payment
        // This is rarely used now and should be able to be removed
        // in near future
        const { data } = await handleCreatePosOrder(
            terminal,
            paymentMethodAmount,
            orderInput,
            paymentFuncs,
            postponedInfo
        );

        purchaseData =
            orderInput.driveThrough || orderInput.postponePayment
                ? data.payPostponedPaymentOrder
                : data.purchasePosOrder;

        if (purchaseData) {
            const { order, terminalResponse } = purchaseData;

            const displayTexts = getActivePaymentTabTexts(order, terminalResponse);
            if (terminalResponse && terminalResponse?.operationSuccess) {
                updateActivePaymentTabStatus(
                    activeOrderWindowId,
                    terminalResponse.operationSuccess,
                    displayTexts.headerText,
                    displayTexts.displayText,
                    paymentTabStatusTypes.APPROVED
                );
                return order;
            } else if (order.failedReason) {
                handleFailedOrder(activeOrderWindowId, order, updateActivePaymentTabStatus);
                return null;
            } else if (terminalResponse && terminalResponse.pinByPass) {
                const { value: allowPinByPass } = await openPinByPass();

                return makePinByPassPayment(
                    allowPinByPass || false, // it returns either true or undefined
                    orderInput,
                    paymentFuncs,
                    updateActivePaymentTabStatus,
                    terminal.terminalId,
                    paymentMethodAmount,
                    activeOrderWindowId,
                    dtOrderId
                );
            } else if (order.totalAmount == 0) {
                updateActivePaymentTabStatus(
                    activeOrderWindowId,
                    true,
                    displayTexts.headerText,
                    displayTexts.displayText,
                    paymentTabStatusTypes.APPROVED
                );
                if (queueHasStarted) {
                    updateQueuedOrdersList(order.id, order.orderNo, order.puckNo ?? "");
                }
                return order;
            } else {
                updateActivePaymentTabStatus(
                    activeOrderWindowId,
                    terminalResponse.operationSuccess,
                    displayTexts.headerText,
                    displayTexts.displayText,
                    terminalResponse.cancelledByShop ? paymentTabStatusTypes.CANCELLED : paymentTabStatusTypes.FAILED
                );
                return order;
            }
        }
    } catch (error) {
        console.log("ERROR IN MAKECARDPAYMENT", error);

        return null;
    }
};
