import React from "react";
import { List, Button } from "semantic-ui-react";

import { Modifications } from "./orderRenderUtils";
import { AddonsCartItem } from "../../poses/shared/components";
import { modals } from "Providers";

export const bundleItemPriceText = (price, quantity = 1, activeLanguage, translate) => {
    if (price) {
        return `${price > 0 ? "+ " : ""}${price * quantity}kr`;
    } else {
        return translate("included", activeLanguage);
    }
};

/**
 * @param  {Array} selectedBundleProductItems - All bundle items for given bundleProduct
 * @param  {Function} openModal - To open selected modal
 * @param  {Boolean} toggled - Decides if util buttons should display and if price should hide
 * @param  {Boolean} shouldRenderUtilButtons - Decides if util buttons should render
 * @param  {Object} orderProduct - The selected bundle product
 */

export const renderBundleProductItems = (
    selectedBundleProductItems,
    openModal,
    toggled,
    shouldRenderUtilButtons,
    orderProduct,
    menu,
    companyRefProds,
    handleSelectedBundleItem,
    outOfStockProductIds,
    hideStockProductIds,
    addons,
    activeLanguage,
    translate
) =>
    selectedBundleProductItems.map((bundleProductItem, i) => {
        const priceText = bundleItemPriceText(
            bundleProductItem.price,
            orderProduct.quantity,
            activeLanguage,
            translate
        );
        return (
            <List.Content key={bundleProductItem.refProductId + i}>
                <List.Description style={{ paddingLeft: "2.5rem" }}>
                    <div className="space-between" style={{ height: "38px" }}>
                        <h4 className="EC-bundle-item-text">{bundleProductItem.name}</h4>
                        {toggled && shouldRenderUtilButtons ? (
                            <Button
                                className="EC-edit-list-item-button"
                                id="bundle-list-item"
                                size="medium"
                                icon="pencil alternate"
                                color="red"
                                onClick={() =>
                                    openModal(modals.updateModal, {
                                        addons,
                                        menu,
                                        companyRefProds,
                                        handleSelectedBundleItem,
                                        orderProduct,
                                        bundleRefProduct: { ...bundleProductItem, index: i },
                                        outOfStockProductIds,
                                        hideStockProductIds
                                    })
                                }
                            />
                        ) : (
                            <h4 className="EC-bundle-item-text notranslate">{priceText}</h4>
                        )}
                    </div>
                    <Modifications modifications={bundleProductItem.modifications} />
                    {bundleProductItem.addons && <AddonsCartItem addons={bundleProductItem.addons} />}
                </List.Description>
            </List.Content>
        );
    });
