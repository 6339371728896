import { CartProduct, CartIdAndQuantity, DISCOUNT_TYPE, OrderWindow } from "Types";
import { isCartProductAPriceAdjust } from "../../../admin/components/poses/shared/utils/cartController";
import { isVariablePriceType } from "ProductUtils";
import {
    getCartProductOrgUnitPrice,
    updateCombinedDiscountsAndOtherDiscounts
} from "../../../admin/components/poses/shared/utils/cartController";
import { createCartProductHashId } from "./hashValueHelper";

enum HashValueId {
    DISCOUNT = "DISCOUNT",
    PRICEADJUST = "PRICEADJUST",
    NORMAL = "NORMAL",
    NONE = "NONE"
}

enum PriceAdjust {
    ADD = "ADD",
    REMOVE = "REMOVE",
    NONE = "NONE"
}

/**
 * Function to check if the discountedId is either normal hash id or discounted hash id
 * @param {CartIdAndQuantity[]} discountedIdsAndQuantity
 * @param {string} discountId
 * @param {string} normalHashId
 * @returns {[CartIdAndQuantity, HashValueId]}
 */
export const hashValueIdCheck = (
    discountedIdsAndQuantity: CartIdAndQuantity[],
    discountId: string,
    normalHashId: string
): [CartIdAndQuantity | null, HashValueId] => {
    const containsNormalHashId = discountedIdsAndQuantity.find(
        (value: CartIdAndQuantity) => value.cartId === normalHashId
    );
    const containsDiscountId = discountedIdsAndQuantity.find((value: CartIdAndQuantity) => value.cartId === discountId);
    if (containsNormalHashId) {
        return [containsNormalHashId, HashValueId.NORMAL];
    } else if (containsDiscountId) {
        return [containsDiscountId, HashValueId.DISCOUNT];
    }
    return [null, HashValueId.NONE];
};

/**
 *
 * @param {CartProduct} cart
 * @param {string} id
 * @returns {CartProduct}
 */
export const resetCartProduct = (cart: CartProduct, id: string) => {
    const updatedCartProduct: CartProduct = {
        ...cart,
        id: id
    };
    const { oldId, ...removeOldId } = updatedCartProduct;
    return removeOldId;
};

/**
 * Function also checks to see if the unit price has been increased
 * @param {CartProduct} cart
 * @returns {boolean}
 */
export const hasCartProductUnitPriceIncreased = (cart: CartProduct) => {
    const originalUnitPrice = getCartProductOrgUnitPrice(cart);
    const currentUnitPrice = cart.orderProduct.unitPrice;
    return currentUnitPrice > originalUnitPrice;
};

/**
 * Function - Checks to see if the cart product has a price adjust discount
 * @description if there is a price adjust discount or increase then the cart id hash value will be changed
 * to include the discount type and will also reset if the price adjust is removed. If neither of these
 * happen it will return none / nothing to do
 * @param {CartProduct} cart
 * @returns {[PriceAdjust, CartProduct | null]}
 */
export const onHandlePriceAdjustDiscountOrIncrease = (
    cart: CartProduct,
    orderWindowId: string
): [PriceAdjust, CartProduct | null] => {
    const hasPriceDecrease = isCartProductAPriceAdjust(cart);
    const hasPriceIncrease = hasCartProductUnitPriceIncreased(cart);

    const priceAdjustId = createCartProductHashId(cart, orderWindowId, {
        type: DISCOUNT_TYPE.PRICE_ADJUST,
        unitPrice: cart.orderProduct.unitPrice
    });

    const normalHashId = createCartProductHashId(cart, orderWindowId, null, false);

    if (hasPriceDecrease || hasPriceIncrease) {
        const priceDiscountCartProduct: CartProduct = { ...cart, id: priceAdjustId, oldId: normalHashId };
        return [PriceAdjust.ADD, priceDiscountCartProduct];
    } else if (!hasPriceDecrease && cart.updatedUnitPrice === false) {
        const { updatedUnitPrice, oldId, ...removeUpdateUnitFlag } = cart;
        return [PriceAdjust.REMOVE, resetCartProduct(removeUpdateUnitFlag, normalHashId)];
    }
    return [PriceAdjust.NONE, null];
};

/**
 * Function to split cart between the discount version of a product and a non discount version
 * @description handle quantity based discounts / normal discounts or price adjustments.
 * Will also reset cart Ids if discounts are removed
 * @param {OrderWindow} currentOrderWindow pass in current order window to check for split
 * @returns {OrderWindow} Pass back order window
 */
export const mergeOrSplitCartProductsByHashId = (currentOrderWindow: OrderWindow) => {
    /**  1. discountedIdsAndQuantity this is set within the discount modal - stores the quantity to be discounted
     *   2. discountedProductIds is also used to store discounts - this is saved when the order is completed
     */

    const isParkedOrder = currentOrderWindow.postponePayment;
    const isTableOrder = !!currentOrderWindow.tableId;
    const isTableAReopenedState = currentOrderWindow.tableId && currentOrderWindow.postponePayment;
    const { cartProducts, discountedIdsAndQuantity, discountedProductIds, id, previousCartProducts } =
        currentOrderWindow;
    const orderWindowId = id;

    let updatedDiscountedProductIds: string[] = discountedProductIds;
    let updatedDiscountedQuantities: CartIdAndQuantity[] = discountedIdsAndQuantity ?? [];
    let hasASplitCart = false;
    let hasAsMergedCart = false;

    let splitCartProducts = cartProducts.reduce((value: CartProduct[], cart: CartProduct) => {
        /** First check cart product is or is not a price adjustment
         *  Price Adjust = changing the unit price of one item or for multiple
         *  Is Price adjust - new hash id inc. Discount enum
         *  Is Not a Price adjust - return the hash id to the orginal value excludes discount enum
         */

        if (isVariablePriceType(cart.orderProduct.priceType)) {
            return [...value, cart];
        }

        const [priceAdjustType, cartProduct] = onHandlePriceAdjustDiscountOrIncrease(cart, orderWindowId);

        if ([PriceAdjust.ADD, PriceAdjust.REMOVE].includes(priceAdjustType) && cartProduct) {
            value = [...value, cartProduct];
        } else {
            /** Get hash value id with discount type */
            const discountHashId = createCartProductHashId(cart, orderWindowId, {
                type: DISCOUNT_TYPE.APPLIED_DISCOUNT
            });

            /** Get orginal hash id */
            const normalHashId = createCartProductHashId(cart, orderWindowId, null, false);

            /**
             * Check current cart id within the discounted id array
             * 1. NORMAL = original cart id
             * 2. DISCOUNT = cart product has a discount attached to it
             * 3. NONE = doesn't appear in the discounted id array
             * NOTE: that even though it doesn't appear in the discounted id array it can still be the discounted id
             * which means that the discount has been removed and the cart product id should be reset to the orginal id
             */
            const [cartIdAndQuantity, hashValueType] = hashValueIdCheck(
                discountedIdsAndQuantity ?? [],
                discountHashId,
                normalHashId
            );

            switch (hashValueType) {
                case HashValueId.NONE: {
                    /** if id isn't within the discountedIdsAndQuantity array on the orderwindow,
                     * but cart id is the discounted id. then it needs to be changed back to the original id
                     * the discount has been removed from the item
                     */

                    if (cart.id === discountHashId) {
                        const resetCart = resetCartProduct(cart, normalHashId);
                        value = [...value, resetCart];
                        hasAsMergedCart = true;
                    } else {
                        value = [...value, cart];
                    }
                    break;
                }
                case HashValueId.NORMAL: {
                    if (cart.id === normalHashId) {
                        /**
                         * if the discount type is 'QUANTITY_APPLIED_DISCOUNT' and the cart id has the original hash id
                         * this then will split cart product between discounted quantity and non discounted quantity
                         * else the discount is a full quantity discount
                         */
                        if (cartIdAndQuantity?.discountType === DISCOUNT_TYPE.QUANTITY_APPLIED_DISCOUNT) {
                            const [noDiscountedCartProduct, discountedCartProduct] = splitCartProduct(
                                cart,
                                cartIdAndQuantity.quantity,
                                discountHashId,
                                id
                            );
                            hasASplitCart = true;
                            value = [...value, discountedCartProduct, noDiscountedCartProduct];
                        } else {
                            value = [...value, { ...cart, id: discountHashId, oldId: normalHashId }];
                        }

                        /** Update product id arrays with correct hash value id */
                        const updatedCartIdQuantity: CartIdAndQuantity = {
                            cartId: discountHashId,
                            discountType: DISCOUNT_TYPE.APPLIED_DISCOUNT,
                            quantity: cartIdAndQuantity?.quantity!
                        };

                        updatedDiscountedProductIds = updatedDiscountedProductIds.map((cartProductId: string) =>
                            cartProductId === normalHashId ? discountHashId : cartProductId
                        );

                        updatedDiscountedQuantities = updatedDiscountedQuantities.map(
                            (orginalCart: CartIdAndQuantity) =>
                                orginalCart.cartId === normalHashId ? updatedCartIdQuantity : orginalCart
                        );
                    } else {
                        value = [...value, cart];
                    }
                    break;
                }
                case HashValueId.DISCOUNT: {
                    if (cart.id === discountHashId) {
                        /**
                         * the cart product could have already been discounted and this checks to see if discounted cart product
                         * has another quantity split
                         */
                        if (cartIdAndQuantity?.discountType === DISCOUNT_TYPE.QUANTITY_APPLIED_DISCOUNT) {
                            const [noDiscountedCartProduct, discountedCartProduct] = splitCartProduct(
                                cart,
                                cartIdAndQuantity.quantity,
                                discountHashId,
                                orderWindowId
                            );
                            hasASplitCart = true;
                            value = [...value, discountedCartProduct, noDiscountedCartProduct];
                        } else {
                            value = [...value, { ...cart, id: discountHashId, oldId: normalHashId }];
                        }
                    } else {
                        value = [...value, cart];
                    }
                    break;
                }
            }
        }
        return value;
    }, []);

    const cartProductsMerged = mergeCartProducts(splitCartProducts);
    const discountProductIds = [...new Set(updatedDiscountedProductIds)];
    const discountedPtoductsQuantities = mergeCartIdsAndQuantities(updatedDiscountedQuantities);
    let newPreviousCarts: CartProduct[] = [];

    const updatePreviousValues = () => {
        const updatedOrderWindow = {
            ...currentOrderWindow,
            cartProducts: cartProductsMerged,
            discountedProductIds: discountProductIds,
            discountedIdsAndQuantity: discountedPtoductsQuantities
        };
        return updateCombinedDiscountsAndOtherDiscounts(updatedOrderWindow);
    };

    if (isParkedOrder && !isTableOrder) {
        /** Will work with parked orders because the behaviour is to 'Utöka' first - discounts are not allowed until it has been updated */
        newPreviousCarts = updatePreviousValues();
    }

    if (isTableAReopenedState && (hasASplitCart || hasAsMergedCart)) {
        /** Table service - changed the behaviour to allow 'Utöka' on re-opened table
         * and to block discount splitting (becuase too much logic) (trust me tried it) */
        newPreviousCarts = updatePreviousValues();
    }

    return {
        ...currentOrderWindow,
        cartProducts: cartProductsMerged,
        ...(newPreviousCarts.length > 0 && { previousCartProducts: newPreviousCarts }),
        discountedProductIds: discountProductIds,
        discountedIdsAndQuantity: discountedPtoductsQuantities
    };
};

export const quantityDifference = (difference: Map<string, number>) => {
    return [...difference].reduce((total: number, value: [string, number]) => {
        return (total += value[1]);
    }, 0);
};

export const getQuantitiesWithCartId = (cartProducts: CartProduct[]) => {
    return cartProducts.reduce((cartProductIds: Map<string, number>, value: CartProduct) => {
        const { quantity } = value?.orderProduct;
        const usedId = value.oldId ? value.oldId : value.id;
        if (cartProductIds.has(usedId)) {
            const cartProduct = cartProductIds.get(usedId)!;
            cartProductIds.set(usedId, cartProduct + quantity);
        } else {
            cartProductIds.set(usedId, quantity);
        }
        return cartProductIds;
    }, new Map());
};

export const getDifferenceBetweenQuantities = (
    currentCartProducts: CartProduct[],
    previousCartProducts: CartProduct[]
) => {
    const current = getQuantitiesWithCartId(currentCartProducts);
    const previous = getQuantitiesWithCartId(previousCartProducts);
    return [...current].reduce((total: Map<string, number>, currentValue: [string, number]) => {
        if (previous.has(currentValue[0])) {
            const prevQuantity = previous.get(currentValue[0]);
            total.set(currentValue[0], currentValue[1] - prevQuantity!);
        } else {
            total.set(currentValue[0], currentValue[1]);
        }
        return total;
    }, new Map());
};

/**
 * Function to merge cart Products
 * @param {CartProduct[]} allCartProducts array of all cart Products
 * @returns {CartProduct[]} merged Cart Products
 */
export const mergeCartProducts = (allCartProducts: CartProduct[]) => {
    return [
        ...allCartProducts
            .reduce((cartProducts: Map<string, CartProduct>, value: CartProduct) => {
                if (cartProducts.has(value.id)) {
                    const cartProduct = cartProducts.get(value.id)!;
                    const { quantity, totalPrice, totalNetPrice } = value?.orderProduct;
                    const { orderProduct } = cartProduct;
                    const updatedCartProducts: CartProduct = {
                        ...cartProduct,
                        orderProduct: {
                            ...orderProduct,
                            totalNetPrice: orderProduct.totalNetPrice + totalNetPrice,
                            totalPrice: orderProduct.totalPrice + totalPrice,
                            quantity: orderProduct.quantity + quantity
                        }
                    };
                    cartProducts.set(value.id, updatedCartProducts);
                } else {
                    cartProducts.set(value.id, value);
                }
                return cartProducts;
            }, new Map())
            .values()
    ];
};

/**
 * Function to merge cartIds and discounted quantities
 * @param {CartIdAndQuantity[]} cartIdsAndQuantities array of ids and discounted quantities
 * @returns {CartIdAndQuantity[]} merged cartIds with sum of quantities
 */
const mergeCartIdsAndQuantities = (cartIdsAndQuantities: CartIdAndQuantity[]): CartIdAndQuantity[] => {
    return Object.values(
        cartIdsAndQuantities.reduce((cartIds: any, cart: CartIdAndQuantity) => {
            if (!cartIds[cart.cartId]) {
                cartIds[cart.cartId] = { cartId: cart.cartId, quantity: 0, discountType: cart.discountType };
            }
            cartIds[cart.cartId].quantity += cart.quantity;
            return cartIds;
        }, {})
    ) as CartIdAndQuantity[];
};

/**
 * Function to return 2 cart Products with spliting of quantity between discounted and non discounted
 * @param {CartProduct} cartProduct original Cart Product
 * @param {number} quantityToDiscount quantity selected for discount
 * @param {string} newId new cartId for discounted cart Product
 * @returns {CartProduct[]} two cart Products split between quantity in cart content
 */
const splitCartProduct = (
    cartProduct: CartProduct,
    quantityToDiscount: number,
    newId: string,
    orderWindowId: string
): CartProduct[] => {
    let noDiscountCartProduct = {} as CartProduct;
    let discountedCartProduct = {} as CartProduct;
    const alreadyDiscountedCartId = cartProduct.id === newId;

    if (alreadyDiscountedCartId) {
        const noDiscount: CartProduct = {
            ...cartProduct,
            id: createCartProductHashId(cartProduct, orderWindowId, null, false),
            orderProduct: {
                ...cartProduct.orderProduct,
                quantity: cartProduct.orderProduct.quantity - quantityToDiscount
            }
        };
        noDiscountCartProduct = noDiscount;

        discountedCartProduct = {
            ...cartProduct,
            id: newId,
            orderProduct: {
                ...cartProduct.orderProduct,
                quantity: quantityToDiscount
            }
        };
    } else {
        noDiscountCartProduct = {
            ...cartProduct,
            orderProduct: {
                ...cartProduct.orderProduct,
                quantity: cartProduct.orderProduct.quantity - quantityToDiscount
            }
        };

        discountedCartProduct = {
            ...cartProduct,
            id: newId,
            oldId: cartProduct.id,
            orderProduct: {
                ...cartProduct.orderProduct,
                quantity: quantityToDiscount
            }
        };
    }

    return [noDiscountCartProduct, discountedCartProduct];
};
