import React, { useState, useEffect } from "react";
import { IconType } from "@react-icons/all-files";
import { HiOutlineChevronUp } from "@react-icons/all-files/hi/HiOutlineChevronUp";
import { HiOutlineChevronDown } from "@react-icons/all-files/hi/HiOutlineChevronDown";
import { AiOutlineLine } from "@react-icons/all-files/ai/AiOutlineLine";

import { PaginationSortOptions, usePagination, DIRECTION, SortOptions } from "Organisms";
import { Button, ButtonProps, Flex, FlexProps } from "Atoms";
import { useLanguage } from "Providers";

type CustomSortProps = {
    sortOptions: PaginationSortOptions[];
    buttonProps?: ButtonProps;
};

type ButtonSortProps = {
    sortOption: PaginationSortOptions;
    resetSort: boolean;
    setCurrentSort: React.Dispatch<React.SetStateAction<string>>;
    buttonProps?: ButtonProps;
};

const chooseSortingDirection = (currentDirection: DIRECTION) => {
    if (currentDirection === DIRECTION.NONE) {
        return DIRECTION.ASC;
    }
    return currentDirection === DIRECTION.ASC ? DIRECTION.DESC : DIRECTION.ASC;
};

/**
 * WRAPPER - CUSTOM SORT WITH THIS
 * @param param0
 * @returns
 */
export const PaginationCustomSortWrapper: React.FC<FlexProps> = ({ ...props }) => (
    <Flex height="auto" p={2} mb={4} justifyContent={["center", "center", "center", "flex-start"]} {...props} />
);

/**
 * CUSTOM SORT & BUTTONBUTTON
 */
export const PaginationCustomSort: React.FC<CustomSortProps> = ({ sortOptions, buttonProps }) => {
    const [currentSort, setCurrentSort] = useState<string>("");

    return (
        <>
            {sortOptions.map((option: SortOptions) => {
                let resetSort = false;
                if (currentSort) {
                    resetSort = currentSort !== option.value;
                }
                return (
                    <ButtonSort
                        sortOption={option}
                        key={option.value}
                        resetSort={resetSort}
                        setCurrentSort={setCurrentSort}
                        buttonProps={buttonProps}
                    />
                );
            })}
        </>
    );
};

const ButtonSort: React.FC<ButtonSortProps> = ({ sortOption, resetSort, setCurrentSort, buttonProps }) => {
    const { setSortingBy } = usePagination();

    const { translate } = useLanguage();

    const [sortDirection, setSortDirection] = useState<DIRECTION>(sortOption.sortingDirection ?? DIRECTION.NONE);

    let icon: IconType = AiOutlineLine;

    if (sortDirection === DIRECTION.ASC) {
        icon = HiOutlineChevronUp;
    }

    if (sortDirection === DIRECTION.DESC) {
        icon = HiOutlineChevronDown;
    }

    const onHandleSort = () => {
        const sortingNext = chooseSortingDirection(sortDirection);
        setSortDirection(sortingNext);
        const newSortingOption = {
            ...sortOption,
            sortingDirection: sortingNext
        };
        setSortingBy(newSortingOption);
        setCurrentSort(sortOption.value);
    };

    useEffect(() => {
        if (resetSort) {
            setSortDirection(DIRECTION.NONE);
        }
    }, [resetSort]);

    const buttonText = !!sortOption.translationKey ? translate(sortOption.translationKey) : sortOption.text;

    return (
        <Button
            rightIcon={icon}
            themeColor="whiteAlpha"
            borderRadius="lg"
            color="gray.900"
            boxShadow="lg"
            borderColor="gray.900"
            borderStyle="solid"
            borderWidth="1px"
            height="1.8rem"
            ml={2}
            onClick={onHandleSort}
            {...buttonProps}
        >
            {buttonText}
        </Button>
    );
};
