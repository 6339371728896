import Axios from "axios";

import { BaxiLocalModeResults } from "Constants";
import { envConstants } from "Constants";
import { TranslateFunc } from "Providers";
import { errorNotification } from "TempUtils";
import { Order } from "Types";

type LogBaxiMessage = {
    terminalId: string;
    msg: string;
};

const baxiRefundCommand = (amount: number) => {
    return {
        transactionType: "returnOfGoods",
        // to remove further decimals, nets wants fractiozal amount and no decimals
        amount: Math.round(amount * 100)
    };
};

const baxiCancelCommand = {
    action: "cancelAction"
};

const baxiBasePath = `${envConstants.NETS_CLOUD_REST_URL}/v1/terminal/`;

const cancelOngoingAction = async (terminalId: string, apiToken: string) => {
    return await Axios.post(`${baxiBasePath}/${terminalId}/administration`, baxiCancelCommand, {
        headers: {
            Authorization: `Bearer ${apiToken}`
        },
        validateStatus: () => true
    });
};

const cardInfo = async (terminalId: string, apiToken: string) => {
    const { data } = await Axios.get(`${baxiBasePath}/${terminalId}/cardInfo`, {
        headers: {
            Authorization: `Bearer ${apiToken}`
        },
        validateStatus: () => true
    });

    return data;
};

const makeRESTRefund = async (terminalId: string, apiToken: string, amount: number, translate: TranslateFunc) => {
    const { data } = await Axios.post(`${baxiBasePath}/${terminalId}/transaction`, baxiRefundCommand(amount), {
        headers: {
            Authorization: `Bearer ${apiToken}`
        },
        validateStatus: () => true
    });

    if (data?.result?.at(0)?.localModeEventArgs?.result == BaxiLocalModeResults.FINANCIAL_TRANSACTION_OK) {
        return true;
    } else {
        if (data?.failure?.error) {
            errorNotification(translate("refundFailed"), data?.failure?.error);
        } else {
            errorNotification(translate("refundFailed"), translate("somethingWentWrong"));
        }

        return false;
    }
};

export const makeNetsSameCardRefund = async (
    terminalId: string,
    apiToken: string,
    originalRefundOrder: Order,
    amount: number,
    translate: TranslateFunc,
    logBaxiMessage: ({ variables }: { variables: LogBaxiMessage }) => void
) => {
    const cardInfoLookup = await cardInfo(terminalId, apiToken);

    const maskedPan = cardInfoLookup?.terminal?.readCardInfo.pan;

    if (maskedPan) {
        // check if masked pan === originalrefunder pan
        if (maskedPan === originalRefundOrder.creditCardInfo?.cardNumber) {
            // make refund
            const refundResult = await makeRESTRefund(terminalId, apiToken, amount, translate);
            if (refundResult) {
                return true;
            } else {
                await cancelOngoingAction(terminalId, apiToken);
                return false;
            }
        } else {
            logBaxiMessage({
                variables: {
                    terminalId,
                    msg: `Refund failed, card did not match original cardnumber: ${originalRefundOrder.creditCardInfo?.cardNumber}, card used now ${maskedPan}`
                }
            });
            // cancel ongoing action to free terminal
            await cancelOngoingAction(terminalId, apiToken);
            errorNotification(translate("refundFailed"), translate("refundCardDidNotMatch"));
            return false;
        }
    }
    errorNotification(translate("refundFailed"), translate("somethingWentWrong"));
    return false;
};
