import React, { forwardRef } from "react";

import { BaseBoxProps, Box } from "Atoms";
import { useTheme } from "Providers";

export type IndicatorStatus = "active" | "inactive" | "positive" | "intermediary";
export type IndicatorSize = "sm" | "md" | "lg";

interface IndicatorProperties {
    status: IndicatorStatus;
    size?: IndicatorSize;
}

export type IndicatorProps = IndicatorProperties & Omit<BaseBoxProps, "size">;

const sizeMapping = {
    sm: 2,
    md: 3,
    lg: 4
};

const statusColorMapping = {
    active: "blue.500",
    inactive: "red.500",
    positive: "green.500",
    intermediary: "orange.500"
};

export const Indicator: React.FC<IndicatorProps> = forwardRef(({ status, size = "md", ...rest }, ref) => {
    const theme = useTheme();

    const sizeIdentifier = sizeMapping[size];
    const themeSize = theme.sizes[sizeIdentifier];

    const statusColor = statusColorMapping[status];

    return <Box ref={ref} rounded="full" size={themeSize} bg={statusColor} {...rest} />;
});
