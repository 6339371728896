import {
    MenuProduct,
    MenuBundleProduct,
    RefBundleProduct,
    RefProduct,
    PriceType,
    Modification,
    CompanyLocale
} from "Types";
import { PRICE_TYPE_CATEGORIES } from "Constants";
import { isVariablePriceType, isBundleProduct } from "ProductUtils";
import { getMenuProductPrice, getMenuBundleProductPrice } from "./priceCalculationUtils";
import { roundTo } from "NumberUtils";

// Maybe these should go in constants?  Or Strings, or maybe have some nice prefix
// These will need to be translated at some point
// e.g. translate(priceTextKey, language);
const priceText = "Pris";
const bundleProductText = "Komboprodukt";
const hasModificationsText = "Har modifikationer";
// In addition, this will be based on a currency stored somewhere in the backend
const currencyText = "kr";

export const roundPriceForPriceType = (amount: number, priceType: PriceType): number => {
    const hasVariablePrice: boolean = isVariablePriceType(priceType);
    if (hasVariablePrice) {
        return roundTo(amount, 4);
    } else {
        return roundTo(amount, 2);
    }
};

export const getUnitStringForPriceType = (priceType: PriceType): string => {
    // There is an argument to be made here that we should return 'styck' and let the caller handle the results.
    const hasUnit: boolean = isVariablePriceType(priceType);
    if (!hasUnit) {
        return "";
    }

    for (const category of Object.values(PRICE_TYPE_CATEGORIES)) {
        for (const priceTypeOption of category.priceTypeOptions) {
            if (priceTypeOption.value === priceType) {
                // This uiText should probably be translated later when "kg" or "lb" is different between languages
                return priceTypeOption.uiText;
            }
        }
    }
    return "";
};

const getUnitStringPerPriceTypeUnit = (priceType: PriceType): string => {
    const hasUnit: boolean = isVariablePriceType(priceType);

    // For now this is only the default "Price per unit" "Styckpris"
    if (!hasUnit) {
        return "";
    }

    let uiText = getUnitStringForPriceType(priceType);
    if (uiText) {
        // The slash here might also be different depending on language/locale later
        return "/" + uiText;
    }

    console.error(`Unsupported priceType found: ${priceType} `);
    return "";
};

/***** PRICE RELATED ******/
export const getPriceString = (amount: number, priceType: PriceType): string => {
    const roundedAmount = roundPriceForPriceType(amount, priceType);
    const amountString: string = `${roundedAmount} ${currencyText}`;
    const unitString: string = getUnitStringPerPriceTypeUnit(priceType);

    return amountString + unitString;
};

export const getPriceStringForProduct = (product: RefProduct | RefBundleProduct) => {
    const price = product.defaultPrice;
    const priceType = product.priceType;
    return getPriceString(price, priceType);
};

export const getPriceStringForMenuProduct = (menuProduct: MenuProduct): string => {
    const menuPrice = getMenuProductPrice(menuProduct);
    const priceType = menuProduct.refProduct.priceType;
    return getPriceString(menuPrice, priceType);
};

export const getPriceStringForMenuBundleProduct = (menuBundleProduct: MenuBundleProduct): string => {
    const menuPrice = getMenuBundleProductPrice(menuBundleProduct);
    const priceType = menuBundleProduct.refBundleProduct.priceType;
    return getPriceString(menuPrice, priceType);
};

/***** PRODUCT SUBTITLES ******/
export const getProductSubtitleWithPrice = (product: RefProduct | RefBundleProduct) => {
    const isBundle: boolean = isBundleProduct(product);
    const bundleString = isBundle ? " | " + bundleProductText : "";
    return `${priceText} ${getPriceStringForProduct(product)} ${bundleString}`;
};

export const getProductSubtitleWithPriceAndMods = (
    product: RefProduct | RefBundleProduct,
    modifications: Modification[]
) => {
    const isBundle: boolean = isBundleProduct(product);
    const modificationsString = modifications ? " | " + hasModificationsText : "";
    const bundleString = isBundle ? " | " + bundleProductText : "";

    return `${priceText} ${getPriceStringForProduct(product)} ${modificationsString} ${bundleString}`;
};

/***** MENU PRODUCT SUBTITLES ******/
export const getMenuProductSubtitle = (menuProduct: MenuProduct) => {
    const modifications = menuProduct.modifications ? menuProduct.modifications : menuProduct.refProduct.modifications;
    const stringBuilderArray = [];
    stringBuilderArray.push(priceText);
    stringBuilderArray.push(getPriceStringForMenuProduct(menuProduct));
    if (modifications) {
        stringBuilderArray.push("|");
        stringBuilderArray.push(hasModificationsText);
    }
    return stringBuilderArray.join(" ");
};

export const getMenuBundleProductSubtitle = (menuBundleProduct: MenuBundleProduct) => {
    const stringBuilderArray = [];
    stringBuilderArray.push(priceText);
    stringBuilderArray.push(getPriceStringForMenuBundleProduct(menuBundleProduct));
    stringBuilderArray.push("|");
    stringBuilderArray.push(bundleProductText);
    return stringBuilderArray.join(" ");
};

export const formatModificationPrice = (addonPrice: number, price: number, isFromBundle: boolean) => {
    if (price === 0 && addonPrice === 0) {
        return null;
    } else if (price === 0) {
        return addonPrice && `${addonPrice >= 0 ? "+" : ""} ${addonPrice} kr`;
    } else {
        return isFromBundle ? `` : `${price} kr`;
    }
};

/**
 * @deprecated Start changing to using company locale and formatFinancialNumbers
 */
export const formatSwedishFinancialNumbers = (price: number) => {
    return new Intl.NumberFormat("sv-SE", { style: "currency", currency: "SEK" }).format(price).replace("−", "-");
};

/**
 * Function to format prices using intl.Locale or CompanyLocale
 * @param {number} price
 * @param {Intl.Locale | CompanyLocale} locale
 * @param {string} currency
 * @description IN TEST SUITE
 * @returns {string} formatted currency
 * @example formatFinancialNumbers(100, new Intl.Locale("fi-FI") || new CompanyLocale("fi-FI"), currencyCode?: "EUR") => 100 €
 */
//@ts-ignore
export const formatFinancialNumbers = (price: number, locale: Intl.Locale | CompanyLocale, currency?: string) => {
    //@ts-ignore
    if (locale instanceof CompanyLocale) {
        //@ts-ignore
        return new Intl.NumberFormat(locale.baseName, {
            style: "currency",
            currency: locale.currency
        })
            .format(price)
            .replace("−", "-");
    }

    if (currency) {
        //@ts-ignore
        return new Intl.NumberFormat(locale.baseName, {
            style: "currency",
            currency: currency
        })
            .format(price)
            .replace("−", "-");
    }

    throw new Error("Currency needed");
};
/**
 * Function to get only currency symbol depending on currency and locale
 * @param {Intl.Locale | CompanyLocale} locale
 * @param {string} currency
 * @description IN TEST SUITE
 * @returns {string} Symbol
 * @example getCurrencySymbol(new Intl.Locale("fi-FI") || new CompanyLocale("fi-FI"), currencyCode?: "EUR") => "€"
 */
//@ts-ignore
export const getCurrencySymbol = (locale: Intl.Locale | CompanyLocale, currency?: string) => {
    if (locale instanceof CompanyLocale) {
        //@ts-ignore
        const formatter = new Intl.NumberFormat(locale.baseName, {
            style: "currency",
            currency: locale.currency
        }).formatToParts();
        return formatter.find(value => value.type === "currency")?.value ?? "";
    }

    if (currency) {
        //@ts-ignore
        const formatter = new Intl.NumberFormat(locale.baseName, {
            style: "currency",
            currency: currency
        }).formatToParts();
        return formatter.find(value => value.type === "currency")?.value ?? "";
    }
    throw new Error("Currency needed");
};

/**
 * [Function] get full display name of currency according to locale
 * @param locale
 * @param currency
 * @returns
 * @example getCurrencyFullName(new Intl.Locale("sv-SE"), "SEK") => "svensk krona"
 */
//@ts-ignore
export const getCurrencyFullName = (locale: Intl.Locale, currency: string) => {
    //@ts-ignore
    let currencyName = new Intl.DisplayNames([locale.language], { type: "currency" });
    //@ts-ignore
    return currencyName.of(currency);
};
