import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

import { IRoute } from "./routes";
import { PageLayout, MustSelectPrompt } from "Molecules";
import { MANIFEST_URL } from "Constants";
import { FlatProviders } from "Components";
import {
    canAccessProtectedRoute,
    getRequirementForRoute,
    isAdminRoute,
    isExpressRoute,
    isAccountRoute,
    buildErrorMetadata
} from "./routingProviderUtils";
import { canTrackPath, trackPageView } from "Utils";
import { useAuthUser, useQopla } from "Providers";
import { envConstants, frontyEnv } from "Constants";
import { SelectedValues } from "Types";

import { useCookieConsent } from "Hooks";
import { usePosStore, useQoplaStore } from "Stores";
import GeneralRedirectErrorBoundary from "../../GeneralRedirectErrorBoundary";
import { useMismatch } from "Hooks";

type MetaProps = {
    title: string | undefined;
    manifestUrl: string | null;
};

const Meta = ({ title, manifestUrl }: MetaProps) => (
    <>
        {/** @ts-ignore */}
        <Helmet>
            {manifestUrl && <link rel="manifest" href={manifestUrl} />}
            {title && <title>{title}</title>}
        </Helmet>
    </>
);

export const CustomRoute: React.FC<{ route: IRoute }> = ({ route }) => {
    const { userAccount } = useAuthUser();
    const location = useLocation();
    const { allowSiteTracking } = useCookieConsent();
    const { authenticatedUser, logout } = useQopla();
    const { selectedCompany, selectedShop } = useQoplaStore();
    const { selectedPos, selectedTerminal } = usePosStore();

    useMismatch();

    const selectedValues = {
        authenticatedUser,
        selectedShop,
        selectedCompany,
        selectedPos,
        selectedTerminal,
        logout
    } as SelectedValues;

    let {
        component: Component,
        footer: Footer,
        nav: Nav,
        sideNavigation: SideNavigation,
        container: Container,
        unAuth: UnAuth
    } = route;

    const manifestToUse = () => {
        if (isAdminRoute(route)) return MANIFEST_URL.ADMIN;
        else if (isExpressRoute(route)) {
            // hack in order to be able to create dynamic manifests for express
            const manifest = {
                name: "Qopla Express",
                start_url: window.location.href,
                display: "fullscreen",
                icons: [
                    {
                        src: `${window.location.origin}/favicons/favicon.ico`,
                        sizes: "64x64 32x32 24x24 16x16",
                        type: "image/x-icon"
                    },
                    {
                        src: `${window.location.origin}/favicons/favicon-96x96.png`,
                        sizes: "96x96",
                        type: "image/png"
                    },
                    {
                        src: `${window.location.origin}/favicons/favicon-128x128.png`,
                        sizes: "128x128",
                        type: "image/png"
                    },
                    {
                        src: `${window.location.origin}/favicons/favicon-196x196.png`,
                        sizes: "196x196",
                        type: "image/png"
                    },
                    {
                        src: `${window.location.origin}/favicons/favicon-512x512.png`,
                        sizes: "512x512",
                        type: "image/png"
                    }
                ]
            };
            const manifestBlob = new Blob([JSON.stringify(manifest)], {
                type: "application/json"
            });
            const manifestURL = URL.createObjectURL(manifestBlob);
            const manifestLink = document.createElement("link");
            manifestLink.rel = "manifest";
            manifestLink.href = manifestURL;
            manifestLink.crossOrigin = "use-credentials";
            document.head.appendChild(manifestLink);

            // gotta return null here, otherwise the manifest will be added to the head twice
            return null;
        } else if (isAccountRoute(route)) return MANIFEST_URL.CUSTOMER;
        else return null;
    };

    const manifestUrl = manifestToUse();
    const errorMetaData = buildErrorMetadata(selectedValues);

    const _canAccessProtectedRoute = canAccessProtectedRoute(route, selectedValues.authenticatedUser, userAccount);
    const routeRequirement = getRequirementForRoute(route, selectedValues);

    if (!_canAccessProtectedRoute && UnAuth) {
        Component = <UnAuth />;
    } else if (!!routeRequirement) {
        Component = <MustSelectPrompt variant={routeRequirement as any} />;
    } else {
        const allProps = {
            hideNav: true,
            metadata: errorMetaData,
            sentryChild: route.sentryChild,
            selectedValues
        };

        Component = <Component {...allProps} />;
    }

    useEffect(() => {
        const isAllowedForEnvironment = [frontyEnv.PROD, frontyEnv.DEV, frontyEnv.DEMO].includes(
            envConstants.PROFILE ?? " "
        );

        const hasAllowedSiteTracking = location.pathname.includes("admin") || allowSiteTracking;

        if (isAllowedForEnvironment && hasAllowedSiteTracking && canTrackPath(location.pathname)) {
            trackPageView(location);
        }
    }, [location.pathname]);

    return (
        <GeneralRedirectErrorBoundary metadata={errorMetaData}>
            <Meta manifestUrl={manifestUrl} title={route.title} />

            <FlatProviders providers={route.providers || []}>
                <PageLayout
                    header={Nav && <Nav />}
                    sideNavigation={SideNavigation && <SideNavigation />}
                    main={Component}
                    container={Container ? Container : React.Fragment}
                    footer={Footer && <Footer />}
                />
            </FlatProviders>
        </GeneralRedirectErrorBoundary>
    );
};
