import gql from "graphql-tag";

import { KITCHEN_ORDERS_ATTRIBUTES, KITCHEN_ORDER_ATTRIBUTES } from "../fragments/kitchenFragments";

export const GET_KITCHEN_ORDERS_FOR_TIME_SLOT = gql`
    query getKitchenOrderForTimeSlot(
        $shopIds: [String]
        $day: String
        $startHour: Int
        $endHour: Int
        $startDate: Date
        $endDate: Date
    ) {
        getKitchenOrderForTimeSlot(
            shopIds: $shopIds
            day: $day
            startHour: $startHour
            endHour: $endHour
            startDate: $startDate
            endDate: $endDate
        ) {
            id
            shopId
            orderNo
            orderType
            eatingOption
            prepTime
            createdAt
            kitchenOrderProducts {
                ...kitchenOrderProductsResults
            }
        }
    }
    ${KITCHEN_ORDERS_ATTRIBUTES}
`;

export const POLL_WAITING_ORDERS = gql`
    query pollWaitingOrders($shopId: String!) {
        pollWaitingOrders(shopId: $shopId) {
            updatedAt
            id
            tableName
            discountName
            puckNo
            online
            onlineOrderStatus
            orderId
            orderNo
            orderType
            status
            pickupTime
            comment
            shopId
            takeAway
            homeDelivery
            eatingOption
            createdAt
            driveThrough
            postponePayment
            paymentStatus
            thirdPartyDeliveryType
            thirdPartyCode
            arriveAtCustomerTime
            courierPickupTime
            cateringConfirmationDate
            contactInformation {
                name
                lastName
                phoneNumber
                addressLine
                postCode
                doorCode
                city
                floorNumber
            }
            kitchenOrderProducts {
                name
                comment
                hash
                quantity
                price
                refProductId
                refBundleProductId
                refProductCategoryId
                menuCategoryId
                addons {
                    name
                    quantity
                    price
                }
                modifications {
                    sizes {
                        name
                        addonPrice
                    }
                    flavours {
                        name
                        addonPrice
                    }
                }
                selectedBundleProductItems {
                    refProductId
                    bundleProductCategoryId
                    name
                    hash
                    kdsUnitDisplayName
                    refProductCategoryId
                    addons {
                        name
                        quantity
                        price
                    }
                    modifications {
                        sizes {
                            name
                            addonPrice
                        }
                        flavours {
                            name
                            addonPrice
                        }
                    }
                }
            }
            webOrderType
        }
    }
`;

export const GET_UNFINISHED_PUBLIC_KITCHEN_ORDERS = gql`
    query getUnfinishedPublicKitchenOrders($shopId: String!) {
        getUnfinishedPublicKitchenOrders(shopId: $shopId) {
            id
            updatedAt
            online
            onlineOrderStatus
            driveThrough
            postponePayment
            orderNo
            status
            webOrderType
            orderType
            shopId
            takeAway
            homeDelivery
            eatingOption
            thirdPartyDeliveryType
            thirdPartyCode
            tableName
            kitchenOrderProducts {
                weight
            }
        }
    }
`;

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// IF YOU UPDATE THIS, UPDATE THE FOLLOWING AS WELL:
// 1. QUERY ABOVE POLL_WAITING_ORDERS
// 2. MUTATION IN ORDERMUTATION UPDATE_ONLINE_ORDER_STATUS
// 3. MUTATION IN MUTATION CHANGE_KITCHEN_STATUS_ORDER
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
export const GET_UNFINISHED_KITCHENORDERS = gql`
    query getUnfinishedKitchenOrders($shopId: String!) {
        getUnfinishedKitchenOrders(shopId: $shopId) {
            updatedAt
            id
            tableName
            tableId
            discountName
            puckNo
            online
            onlineOrderStatus
            orderId
            orderNo
            orderType
            status
            pickupTime
            comment
            shopId
            takeAway
            homeDelivery
            eatingOption
            createdAt
            driveThrough
            postponePayment
            paymentStatus
            thirdPartyDeliveryType
            thirdPartyCode
            arriveAtCustomerTime
            courierPickupTime
            cateringConfirmationDate
            contactInformation {
                name
                lastName
                phoneNumber
                addressLine
                postCode
                doorCode
                city
                floorNumber
            }
            kitchenOrderProducts {
                name
                comment
                hash
                quantity
                price
                refProductId
                refBundleProductId
                refProductCategoryId
                menuCategoryId
                weight
                addons {
                    name
                    quantity
                    price
                }
                modifications {
                    sizes {
                        name
                        addonPrice
                    }
                    flavours {
                        name
                        addonPrice
                    }
                }
                selectedBundleProductItems {
                    refProductId
                    bundleProductCategoryId
                    name
                    hash
                    kdsUnitDisplayName
                    refProductCategoryId
                    addons {
                        name
                        quantity
                        price
                    }
                    modifications {
                        sizes {
                            name
                            addonPrice
                        }
                        flavours {
                            name
                            addonPrice
                        }
                    }
                }
            }
            webOrderType
        }
    }
`;

export const GET_COOKING_BATCHES = gql`
    query getCookingBatches($shopId: String!) {
        getCookingBatches(shopId: $shopId) {
            id
            createdAt
            batchStatus
            batchNo
            kitchenBatchOrderList {
                kitchenOrderId
                orderStatus
            }
        }
    }
`;

export const GET_KITCHEN_BATCHES = gql`
    query getKitchenBatches($shopId: String!) {
        getKitchenBatches(shopId: $shopId) {
            id
            createdAt
            batchStatus
            batchNo
            kitchenBatchOrderList {
                kitchenOrderId
                orderStatus
            }
        }
    }
`;

export const GET_KITCHEN_ORDER_FOR_ORDER_HISTORY = gql`
    query getKitchenOrderForOrderHistory($orderId: String!) {
        getKitchenOrderForOrderHistory(orderId: $orderId) {
            ... on KitchenOrder {
                ...kitchenOrderResults
            }
        }
    }
    ${KITCHEN_ORDER_ATTRIBUTES}
`;
export const GET_KITCHEN_ORDERS_BY_ORDER_IDS = gql`
    query getKitchenOrdersByOrderIds($orderIds: [String]) {
        getKitchenOrdersByOrderIds(orderIds: $orderIds) {
            ... on KitchenOrder {
                ...kitchenOrderResults
            }
        }
    }
    ${KITCHEN_ORDER_ATTRIBUTES}
`;
