import {
    SelectedBundleProductItem,
    PosProviderRefProduct,
    Addon,
    CartProduct,
    Modifications,
    BundleProductCategory,
    PosProviderRefProductHashMap,
    ProductsStock,
    MenuBundleProduct
} from "Types";
import { getBundleItemQuantity, getMenuBundleModificationsToUse } from "Utils";
import { getBundleModAddonPriceText, getSingleModification } from "TempUtils";
import { AvailableBundleItem } from "../components";
import { ExtendedBundleProductCategory } from "OnlineTypes";

///////////////// BUNDLE ITEMS UTILS /////////////////

const transformRefProducts = (
    bundleCatsRefProductsList: string[],
    posCtxRefProducts: PosProviderRefProductHashMap
): PosProviderRefProduct[] => {
    return bundleCatsRefProductsList.reduce<PosProviderRefProduct[]>((acc, next) => {
        const refProduct = posCtxRefProducts[next];

        if (!!refProduct) {
            acc.push({
                ...refProduct,
                quantity: 0
            });
        }

        return acc;
    }, []);
};

const buildBundleProductCats = (
    bundleProductCategories: BundleProductCategory[],
    refProductsHashMap: PosProviderRefProductHashMap
): ExtendedBundleProductCategory[] => {
    let index = 0;
    return bundleProductCategories.map(cat => {
        const refProducts = transformRefProducts(cat.refProductIdList, refProductsHashMap);
        const refProductIdList = refProducts.map(refProduct => refProduct.id);

        return {
            ...cat,
            index: index++,
            refProducts,
            refProductIdList
        };
    });
};

const getActiveBundleItemsIndexes = (
    selectedBundleProductItems: SelectedBundleProductItem[],
    bundleProductCategory: ExtendedBundleProductCategory,
    addons: { [key: string]: Addon[] }
) => {
    return selectedBundleProductItems.reduce<number[]>((acc, bundleItem) => {
        const bundleItemAddons = bundleItem.refProductId ? addons[bundleItem.refProductId] : null;

        const index = bundleProductCategory.refProductIdList.findIndex(id => {
            if (id === bundleItem.refProductId && bundleItem.bundleProductCategoryId === bundleProductCategory.id) {
                if (bundleProductCategory.limit > 1 || !!bundleItemAddons) {
                    return true;
                }

                if (!!bundleItem.modificationsToUse) {
                    if (!!getSingleModification(bundleItem.modificationsToUse)) return false;
                    return true;
                }

                return false;
            }
        });

        if (index !== -1) {
            acc.push(index);
        }
        return acc;
    }, []);
};

const getSelectedBundleItemAmountPerCat = (
    selectedBundleProductItems: SelectedBundleProductItem[],
    bundleProductCategoryId: string
) => {
    return selectedBundleProductItems.reduce((acc, next) => {
        if (!!next.refProductId && next.bundleProductCategoryId === bundleProductCategoryId) {
            acc++;
        }
        return acc;
    }, 0);
};

const findAddedBundleItemInSelectedBundleProductItems = (
    bundleItemId: string,
    selectedBundleProductItems: SelectedBundleProductItem[]
) =>
    selectedBundleProductItems.find(selectedBundleProductItem => {
        const hasSelectedBundleItem = selectedBundleProductItem.refProductId === bundleItemId;

        if (selectedBundleProductItem.modificationsToUse) {
            const requiredModifications = getRequiredModifications(selectedBundleProductItem.modificationsToUse);
            const _isAllRequiredModificationsSelected = isAllRequiredModificationsSelected(
                requiredModifications,
                selectedBundleProductItem.modifications!
            );

            return _isAllRequiredModificationsSelected && hasSelectedBundleItem;
        } else {
            return hasSelectedBundleItem;
        }
    });

// const isBundleItemOutOfStock = (
//     remainingStockQuantity: number | undefined,
//     bundleItemQuantity: number,
//     bundleProductQuantity: number
// ) => {
//     return remainingStockQuantity === 0;

//     if (typeof remainingStockQuantity === "number") {
//         if (remainingStockQuantity === 0) {
//             return true;
//         } else {
//             const _bundleItemQuantity = bundleItemQuantity === 0 ? 1 : bundleItemQuantity; // Has to default to 1 since multiplying with 0 will be 0
//             return _bundleItemQuantity * bundleProductQuantity > remainingStockQuantity;
//         }
//     } else {
//         return false;
//     }
// };

const getBundleProductItems = (
    refProducts: PosProviderRefProduct[],
    selectedBundleProduct: CartProduct,
    bundleCatId: string,
    productsStock: ProductsStock
): AvailableBundleItem[] => {
    const {
        menuBundleProduct,
        orderProduct: { selectedBundleProductItems, quantity: bundleProductQuantity }
    } = selectedBundleProduct;

    return refProducts.map(refProduct => {
        const quantity = getBundleItemQuantity(refProduct.id, selectedBundleProductItems, bundleCatId);
        const modificationsToUse = getMenuBundleModificationsToUse(refProduct, menuBundleProduct as MenuBundleProduct);
        const priceText = modificationsToUse ? getBundleModAddonPriceText(modificationsToUse) : "";
        const remainingStockQuantity = productsStock.get(refProduct.id)?.remainingQuantity;
        const outOfStock = remainingStockQuantity === 0;
        //  isBundleItemOutOfStock(remainingStockQuantity, quantity, bundleProductQuantity);

        return {
            ...refProduct,
            quantity,
            modificationsToUse,
            priceText: priceText as string,
            outOfStock
        };
    });
};

const getPreselectedBundleProductItems = (
    selectedBundleProductItems: SelectedBundleProductItem[],
    singleProductCategories: ExtendedBundleProductCategory[]
) => {
    return selectedBundleProductItems?.reduce<any[]>((bundleItems, bundleProductItem: any) => {
        const foundPreselectedCategory = singleProductCategories.find(
            category => category.id === bundleProductItem.bundleProductCategoryId
        );
        if (!!foundPreselectedCategory) {
            const [refProduct] = foundPreselectedCategory.refProducts;

            const singleMods = refProduct.modifications ? getSingleModification(refProduct.modifications) : null;

            bundleItems.push({
                name: refProduct.name,
                refProductId: refProduct.id,
                refProductCategoryId: refProduct.refProductCategoryId,
                kdsUnitDisplayName: foundPreselectedCategory.kdsUnitDisplayName,
                modifications: singleMods ? singleMods : {},
                bundleProductCategoryId: foundPreselectedCategory.id,
                modificationsToUse: refProduct.modifications,
                addons: []
            });
        } else {
            bundleItems.push(bundleProductItem);
        }

        return bundleItems;
    }, []);
};

///////////////// MODIFIED ITEMS UTILS /////////////////

const getAddedBundleItem = (
    bundleItem: AvailableBundleItem,
    bundleItemIndex: number,
    selectedBundleProductItems: SelectedBundleProductItem[]
) => selectedBundleProductItems.find((t, index) => t.name === bundleItem.name && index === bundleItemIndex);

const getRequiredModifications = (modifications: any): string[] => {
    if (!modifications) return [];
    return Object.keys(modifications).reduce((acc, curr) => {
        if (modifications[curr].length > 0) {
            //@ts-ignore
            acc.push(curr);
        }
        return acc;
    }, []);
};

const isAllRequiredModificationsSelected = (requiredModifications: string[], modifications: Modifications) => {
    return requiredModifications.every(requiredModification =>
        Object.keys(modifications).includes(requiredModification)
    );
};

export {
    getActiveBundleItemsIndexes,
    getSelectedBundleItemAmountPerCat,
    findAddedBundleItemInSelectedBundleProductItems,
    getBundleProductItems,
    getRequiredModifications,
    isAllRequiredModificationsSelected,
    getAddedBundleItem,
    getSingleModification,
    buildBundleProductCats,
    getPreselectedBundleProductItems
};
