import { getModificationLengths, modificationsHasAddonPrice } from "TempUtils/TextFormat";
import { calculateBundleModPrice } from "TempUtils/PriceCalculations";

export const addModificationIfSingle = (menuProduct, addModification) => {
    const modifications = menuProduct.modifications ? menuProduct.modifications : menuProduct.refProduct.modifications;
    const { sizes, flavours } = getModificationLengths(modifications);
    if (sizes + flavours === 1) {
        if (sizes > 0) {
            addModification(null, modifications.sizes[0], menuProduct);
        } else if (flavours > 0) {
            addModification(modifications.flavours[0], null, menuProduct);
        }
        return true;
    }
    return false;
};

export const getSingleModification = modificationsToUse => {
    const { sizes, flavours } = getModificationLengths(modificationsToUse);
    if (sizes <= 1 && flavours <= 1) {
        let modifications = {};
        if (sizes > 0) modifications = { ...modifications, sizes: modificationsToUse.sizes[0] };
        if (flavours > 0) modifications = { ...modifications, flavours: modificationsToUse.flavours[0] };
        return modifications;
    }
    return null;
};

export const updateBundleItemModificationsIfSingle = async (
    selectedBundleProd,
    refProduct,
    funcUpdateBundleProductModification,
    overriddenModifications,
    productAddons,
    addModalFunc
) => {
    const modificationsToUse = overriddenModifications ? overriddenModifications : refProduct.modifications;
    const { sizes, flavours } = getModificationLengths(modificationsToUse);
    if (sizes + flavours === 1) {
        const modObjectToUse = {
            sizes: sizes ? modificationsToUse.sizes[0] : null,
            flavours: flavours ? modificationsToUse.flavours[0] : null
        };

        let product = refProduct;
        if (addModalFunc) {
            const selectedAddons = await addModalFunc(productAddons);
            product = { ...product, addons: selectedAddons ? selectedAddons : [] };
        }

        funcUpdateBundleProductModification({
            variables: {
                menuProduct: selectedBundleProd,
                newModifications: modObjectToUse,
                refProductToUpdate: {
                    ...product,
                    price: calculateBundleModPrice({
                        ...product,
                        modifications: modObjectToUse
                    })
                }
            }
        });
        return true;
    }
    return false;
};

export const singleModificationAddonPrice = modifications => {
    const { sizes } = getModificationLengths(modifications);
    if (modificationsHasAddonPrice(modifications)) {
        const addonPrice = sizes !== 0 ? modifications.sizes[0].addonPrice : modifications.flavours[0].addonPrice;
        return addonPrice !== 0 ? addonPrice : 0;
    }
    return 0;
};

export const getModifications = modifications => {
    return Object.keys(modifications).map(key => ({
        name: key,
        mods: modifications[key]
    }));
};

export const getRequiredModifications = modifications => {
    return Object.keys(modifications).reduce((acc, curr) => {
        acc[`${curr}IsRequired`] = modifications[curr].length > 0;
        return acc;
    }, {});
};
