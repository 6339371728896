import { AlcoholTag } from "Types";

export type DiscountType = { name: string; code: string; quantity: number; total: number; totalNet: number };

export type DiscountStatsType = {
    [id: string]: DiscountType;
};
export type OverviewResult = {
    aggregateReport: ReportStats;
    shopReports: ShopReports;
};
export type ShopReports = {
    [shopId: string]: ReportStats;
};

export type ProductCategorySaleType = {
    name: string;
    category: string;
    quantity: number;
    totalSum: number;
    totalNetSum: number;
    children?: BundleType[];
    weight?: number;
    avgWeight?: number;
};
export type BundleStats = {
    [key: string]: BundleType[];
};
export type BundleType = {
    contentId: string;
    quantity: number;
    category: string;
    totalSum: number;
    totalNetSum: number;
};

export enum ReportType {
    WEB = "Web",
    POS = "Pos",
    FOODORA = "Foodora",
    UBEREATS = "UberEats",
    WOLT = "Wolt",
    BOLT = "Bolt"
}

export enum DataType {
    Amount = "Amount",
    Quantity = "Quantity"
}

export type ComboDiscountType = {
    [key: string]: { totalSum: number; totalNetSum: number };
};

type CookTimeData = {
    [key: number]: number;
};
type kitchenData = {
    [key: number]: string[];
};

export type StatsPerTimePeriod = {
    quantity: number;
    total: number;
    totalNet: number;
};

export type SaleStatsPerTimePeriod = {
    [timestamp: string]: StatsPerTimePeriod;
};

export type ShopSettings = {
    ids: string[];
    activeHours: any[];
    selected: SelectedType[];
    availableThirdPartyServices: string[];
    onlineEatingOptions: string[];
    name?: string;
};

export type SelectedType = { label: string; value: string };
export type WeightStatType = {
    category: string;
    weight: number;
    quantity: number;
    totalSum: number;
    totalNetSum: number;
};
export type WeightStatsType = {
    [key: string]: WeightStatType;
};
type RegisterCountType = {
    [key: string]: {
        name: string;
        quantity: number;
        total: number;
        totalNet: number;
    };
};

type CategoryDataAmount = {
    [key: string]: { total: number; totalNet: number };
};

type CategoryDataQuantity = {
    [key: string]: { quantity: number; percent: number };
};

export enum EatingOptions {
    CATERING_DELIVERY = "CATERING_DELIVERY",
    CATERING_TAKE_AWAY = "CATERING_TAKE_AWAY",
    DT = "DT",
    EAT_HERE = "EAT_HERE",
    HOME_DELIVERY = "HOME_DELIVERY",
    TAKE_AWAY = "TAKE_AWAY",
    CATERING = "CATERING"
}

export enum REPORT_KEY {
    TOTAL_SALES_REPORT = "totalSalesReport",
    HOURLY_SALES_REPORT = "hourlySalesReport",
    DAILY_SALES_REPORT = "dailySalesReport",
    CATEGORY_SALES_REPORT = "categorySalesReport",
    COOK_TIME_HEAT_MAP = "cookTimeHeatMap",
    PRODUCT_CATEGORY_STATS = "productCategoryStatistics",
    WASTE_REPORT = "wasteReport",
    WEIGHT_REPORT = "weightReport",
    DISCOUNT_REPORT = "discountReport",
    ALCOHOL_REPORT = "alcoholReport"
}

export type EatingOptionType = { quantity: number; total: number; totalNet: number };

export type EatingOptionStatsType = { [eatingOption in EatingOptions]: EatingOptionType };

export type ReportFieldsType = {
    totalSum: number;
    totalNetSum: number;
    quantityOfOrders: number;
    quantityOfRefunds: number;
    refundsTotalSum: number;
    refundsTotalNetSum: number;
    quantityOfTips: number;
    tipsTotalSum: number;
    quantityOfRefundedTips: number;
    refundedTipsTotalSum: number;
    quantityOfCancelledOrders: number;
    cancelledTotalSum: number;
    cancelledTotalNetSum: number;
    quantityOfAbortedOrders: number;
    abortedTotalSum: number;
    abortedTotalNetSum: number;
    addonsProdStats: ProductCategorySaleType[];
    addonsTotalSum: number;
    addonsTotalNetSum: number;
    upsellProdStats: ProductCategorySaleType[];
    upsellTotalSum: number;
    upsellTotalNetSum: number;
    bundleStats: BundleStats[];
    prodStats: ProductCategorySaleType[];
    discountStats: DiscountStatsType;
    dailyAveragedCookStats: {
        [key: number]: {
            totalCookTime: CookTimeData;
            averageCookTime: CookTimeData;
            totalCookCount: CookTimeData;
            kitchenOrderIDs: kitchenData;
        };
    };
    saleStatsPerDay: SaleStatsPerTimePeriod;
    saleStatsPerHour: SaleStatsPerTimePeriod;
    averageSaleStatsPerHour: SaleStatsPerTimePeriod;
    averageSaleStatsPerDay: SaleStatsPerTimePeriod;
    registerCount: RegisterCountType;
    categoryAmountStats: CategoryDataAmount;
    categoryQuantityStats: CategoryDataQuantity;
    combinedDiscountStats: ComboDiscountType;
    eatingOptionStats: EatingOptionStatsType;
    weightStats: WeightStatsType;
};

export type ReportStats = {
    [reportName in ReportType]: ReportFieldsType;
};

export type IncludedDataSets = {
    [key: string]: boolean;
};

export type LegendType = {
    key: string;
    text: string;
    colors: string;
    showForSum?: boolean;
};

export type CategoryChartDataType = {
    id: string;
    label: string;
    value: number;
    Pos?: number;
    Web?: number;
};

export const CATEGORY_CHART = {
    PIE: "pie",
    BAR: "bar"
};

/**
 * Alcohol Report types
 */

export type AlcoholReportProps = {
    fetchTokenOrReset: () => Promise<string | undefined>;
    companyId: string;
    shopIds: string[];
};

export type AlcoholReport = {
    [key in AlcoholTag]: AlcoholStats;
};

export type AlcoholStats = {
    vatRate: number;
    totalNet: number;
    totalPrice: number;
    productStats: AlcoholProducts;
};

export type AlcoholProducts = {
    [key: string]: AlcoholProductStats;
};

export type AlcoholProductStats = {
    totalNet: number;
    totalPrice: number;
    quantity: number;
};

const defaultValues = { vatRate: 0, totalNet: 0, totalPrice: 0, productStats: {} };

export const defaultAlcoholReport: AlcoholReport = {
    [AlcoholTag.LIGHT_BEER]: defaultValues,
    [AlcoholTag.SPIRITS]: defaultValues,
    [AlcoholTag.STRONG_BEER]: defaultValues,
    [AlcoholTag.WINE]: defaultValues,
    [AlcoholTag.OTHER_ALCOHOLIC_DRINK]: defaultValues
};