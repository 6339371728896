import { Manager, Socket } from "socket.io-client";
import create, { SetState, GetState } from "zustand";
import { combine } from "zustand/middleware";
import Axios from "axios";
import { createTrackedSelector } from "react-tracked";

import {
    OrderWindow,
    OrderWindowCustomer,
    OrderWindowCustomerStatus,
    OrderWindowMeta,
    OrderWindowOrderType,
    OrderWindowStatus
} from "Types";
import { envConstants } from "Constants";
import { modals, transformNestedOrderProductModifications } from "Providers";
import { modalStore, orderWindowsStore } from "../";
import {
    OrderWindowSocketStoreActions,
    OrderWindowSocketStoreClientToServerEvents,
    OrderWindowSocketStoreServerToClientEvents,
    OrderWindowSocketStoreState
} from "./orderWindowSocketStoreTypes";
import { fetchActiveOrderWindowById, fetchOrderWindowById } from "../../api/qSocketServiceAPI";

const LOCAL_STORAGE_KEY = "orderWindowSocket";

/**
 * This needs to be cleared/reset  upon a few conditions
 *  - The table session is ended i.e. everyone has paid
 *  - The client connects to a different table than the one stored in storage
 *  - The table has a session, but the customer isn't in the list of customers on the order window
 */
const initialLocalStorageState = JSON.parse(window?.localStorage.getItem(LOCAL_STORAGE_KEY) || "{}");

const initialState: OrderWindowSocketStoreState = {
    orderStatus: OrderWindowStatus.IDLE,
    orderId: null,
    orderType: null,
    nickname: initialLocalStorageState?.nickname ?? null,
    orderWindowId: initialLocalStorageState?.orderWindowId ?? null,
    tableId: initialLocalStorageState?.tableId ?? null,
    hasSeenUpsell: initialLocalStorageState?.hasSeenUpsell ?? false,
    hasSeenGroupInvitation: false,
    hasLostWSConnection: false,
    isDeclined: false,
    orderWindowVersion: 0,

    hasGroupOrderStarted: false,
    groupOrderNicknames: [],
    activePayingCustomer: null,
    activePayingCustomerStatus: null,
    hasSeenFeedback: initialLocalStorageState?.hasSeenFeedback ?? false
};

const mutations = (
    setState: SetState<OrderWindowSocketStoreState>,
    getState: GetState<OrderWindowSocketStoreState>
) => {
    const manager = new Manager(`${envConstants.QSOCKET_SERVICE_URL}`, {
        reconnection: true,
        reconnectionDelay: 3000,
        reconnectionAttempts: Infinity,
        autoConnect: false
    });

    const socket = manager.socket("/orderWindow") as Socket<
        OrderWindowSocketStoreClientToServerEvents,
        OrderWindowSocketStoreServerToClientEvents
    >;

    socket.io.on("reconnect_attempt", attempt => {
        socket.emit("request-reconnect", {
            nickname: orderWindowSocketStore.getState().nickname,
            tableId: orderWindowSocketStore.getState().tableId,
            orderWindowId: orderWindowSocketStore.getState().getActiveOrderWindowId()
        });
    });
    socket.io.on("reconnect", attempt => {
        orderWindowSocketStore.setState({ hasLostWSConnection: false });
    });
    socket.io.on("reconnect_error", error => {
        console.error("Error when attempting reconnect for orderWindowSocket: ", error);
    });

    socket.io.on("error", error => {
        console.error("General error for orderWindowSocket: ", error);
    });

    socket
        .on("connect", () => {
            const tableId = orderWindowSocketStore.getState().tableId;
            const nickname = orderWindowSocketStore.getState().nickname;
            if (!!tableId && !!nickname) {
                orderWindowSocketStore.getState().registerToTable(tableId, nickname);
            }
        })
        .on("disconnect", socket => {
            orderWindowSocketStore.setState({ hasLostWSConnection: true });
        })
        .on("users-changed", data => {
            const [formattedOrderWindow] = transformNestedOrderProductModifications([data.updatedOrderWindow]);

            const localOrderWindowVersion = orderWindowSocketStore.getState().orderWindowVersion;

            if (data.updatedOrderWindowVersion > localOrderWindowVersion) {
                orderWindowsStore
                    .getState()
                    .setOrderWindowsAndActiveOrderWindowId([formattedOrderWindow], formattedOrderWindow.id);
            }

            setState({ orderWindowVersion: data.updatedOrderWindowVersion });

            if (data.event === "enter-request") {
                const canAcceptUser =
                    formattedOrderWindow.customers?.find(customer => customer.nickname === getState()?.nickname)
                        ?.status === OrderWindowCustomerStatus.CONNECTED;

                if (canAcceptUser) {
                    const isModalAlreadyOpen = modalStore.getState().isModalOpen(modals.groupOnlineOrderCustomersModal);
                    if (!isModalAlreadyOpen) {
                        modalStore.getState().openModal(modals.groupOnlineOrderCustomersModal, {
                            username: data.user,
                            clientId: data.clientId,
                            tableId: formattedOrderWindow.tableId as string
                        });
                    }
                }
            }
        })
        .on("updated-order-window", data => {
            const [formattedOrderWindow] = transformNestedOrderProductModifications([data.updatedOrderWindow]);

            const isGroupOrderActive = getState().hasGroupOrderStarted;
            if (!isGroupOrderActive) {
                orderWindowSocketStore.getState().setHasSeenGroupInvitation(false);
            }

            const localOrderWindowVersion = orderWindowSocketStore.getState().orderWindowVersion;

            if (data.updatedOrderWindowVersion > localOrderWindowVersion) {
                orderWindowsStore
                    .getState()
                    .setOrderWindowsAndActiveOrderWindowId([formattedOrderWindow], formattedOrderWindow.id);
            }

            setState({ orderWindowVersion: data.updatedOrderWindowVersion });
        })
        .on("updated-order-status", data => {
            setState({ orderStatus: data.orderStatus, orderId: data.orderId, orderType: data.orderType });
        })
        .on("retrieved-initial-order-window", data => {
            if (data.initialOrderWindow) {
                const [formattedOrderWindow] = transformNestedOrderProductModifications([data.initialOrderWindow]);
                orderWindowsStore
                    .getState()
                    .setOrderWindowsAndActiveOrderWindowId([formattedOrderWindow], formattedOrderWindow.id);
            }

            setState({
                orderWindowVersion: data.updatedOrderWindowVersion,
                groupOrderNicknames: data.groupOrderNicknames,
                hasGroupOrderStarted: data.groupOrderStarted,
                activePayingCustomer: data.activePayingCustomer,
                activePayingCustomerStatus: data.activePayingCustomerStatus
            });
        })
        /*
         * When user enters orderWindow, we need to set cookie which holds
         * orderWindowId and nickname
         */
        .on("entered-order-window", data => {
            window?.localStorage.setItem(
                LOCAL_STORAGE_KEY,
                JSON.stringify({
                    orderWindowId: data.orderWindowId,
                    tableId: data.tableId,
                    nickname: data.user,
                    hasSeenUpsell: false,
                    orderWindowVersion: data.updatedOrderWindowVersion
                })
            );
            setState({
                nickname: data.user,
                tableId: data.tableId,
                orderWindowId: data.orderWindowId,
                hasSeenUpsell: false,
                orderWindowVersion: data.updatedOrderWindowVersion
            });
        })
        .on("decline-enter-order-window", data => {
            setState({ isDeclined: data.isDeclined });
        })
        .on("group-order-changed", data => {
            const localOrderWindowVersion = orderWindowSocketStore.getState().orderWindowVersion;

            if (data.updatedOrderWindowVersion > localOrderWindowVersion) {
                const [formattedOrderWindow] = transformNestedOrderProductModifications([data.updatedOrderWindow]);

                orderWindowsStore
                    .getState()
                    .setOrderWindowsAndActiveOrderWindowId([formattedOrderWindow], formattedOrderWindow.id);
            }

            /**
             * When all customers leave's group order and there's no group order anymore.
             * We need to set 'hasSeenGroupInvitation' to false in order to show modal next time.
             */
            if (!data.groupOrderStarted) {
                orderWindowSocketStore.getState().setHasSeenGroupInvitation(false);
                orderWindowSocketStore.getState().setHasSeenFeedback(false);
                orderWindowSocketStore.getState().setHasSeenUpsell(false);
            }

            setState({
                groupOrderNicknames: data.groupOrderNicknames,
                hasGroupOrderStarted: data.groupOrderStarted,
                orderWindowVersion: data.updatedOrderWindowVersion
            });
        })
        .on("updated-customers-to-paid", data => {
            const activePayingCustomer: string = data.activePayingCustomer;
            const activePayingCustomerStatus: string = data.activePayingCustomerStatus;

            setState({ activePayingCustomer, activePayingCustomerStatus });
        });

    return {
        getActiveOrderWindowId() {
            return orderWindowsStore.getState().activeOrderWindowId;
        },
        onSetCustomerAsReadyToOrder(nickname: string) {
            orderWindowSocketStore.getState().onSetCustomerStatus(nickname, OrderWindowCustomerStatus.READY_TO_ORDER);
        },
        onSetCustomerAsConnected(nickname: string) {
            orderWindowSocketStore.getState().onSetCustomerStatus(nickname, OrderWindowCustomerStatus.CONNECTED);
        },
        onUpdateCartProductsToOrdered(cartProductIds: string[]) {
            socket.emit("update-cart-products-to-ordered", {
                orderWindowId: orderWindowSocketStore.getState().getActiveOrderWindowId(),
                cartProductIds
            });
        },
        onUpdateCustomersToPaid(customerNicknames: string[], orderId: string) {
            if (socket.connected) {
                socket.emit("update-customers-to-paid", {
                    orderWindowId: orderWindowSocketStore.getState().getActiveOrderWindowId(),
                    customerNicknames,
                    orderId
                });
            }
        },
        onUpdatePayingCustomers(customersToLock: string[], customersToUnlock: string[], customerWhoIsLocking: string) {
            socket.emit("update-paying-customers", {
                orderWindowId: orderWindowSocketStore.getState().getActiveOrderWindowId(),
                customerNicknamesToLock: customersToLock,
                customerNicknamesToUnlock: customersToUnlock,
                customerWhoIsLocking
            });
        },
        onStartGroupOrder(nickname: string) {
            const hasGroupOrderStarted = getState().hasGroupOrderStarted;
            const groupOrderNicknames = getState().groupOrderNicknames;
            const orderWindowId = orderWindowSocketStore.getState().getActiveOrderWindowId();

            const isAlreadyInGroupOrder = groupOrderNicknames.includes(nickname);

            if (hasGroupOrderStarted || isAlreadyInGroupOrder) {
                return;
            }

            socket.emit("start-group-order", {
                orderWindowId,
                nickname
            });
        },
        onLeaveGroupOrder(nickname: string) {
            const hasGroupOrderStarted = getState().hasGroupOrderStarted;
            const groupOrderNicknames = getState().groupOrderNicknames;
            const orderWindowId = orderWindowSocketStore.getState().getActiveOrderWindowId();

            const isNotInGroupOrder = !groupOrderNicknames.includes(nickname);

            if (!hasGroupOrderStarted || isNotInGroupOrder) {
                return;
            }

            socket.emit("leave-group-order", {
                orderWindowId,
                nickname
            });
        },
        onJoinGroupOrder(nickname: string) {
            const hasGroupOrderStarted = getState().hasGroupOrderStarted;
            const groupOrderNicknames = getState().groupOrderNicknames;
            const orderWindowId = orderWindowSocketStore.getState().getActiveOrderWindowId();

            const isAlreadyInGroupOrder = groupOrderNicknames.includes(nickname);

            if (!hasGroupOrderStarted || isAlreadyInGroupOrder) {
                return;
            }

            socket.emit("join-group-order", {
                orderWindowId,
                nickname
            });
        },
        onStopGroupOrder() {
            const hasGroupOrderStarted = getState().hasGroupOrderStarted;
            const orderWindowId = orderWindowSocketStore.getState().getActiveOrderWindowId();

            if (!hasGroupOrderStarted) {
                return;
            }

            socket.emit("stop-group-order", {
                orderWindowId
            });
        },
        onSetCustomerStatus(nickname: string[] | string, status: OrderWindowCustomerStatus) {
            const orderWindowId = orderWindowSocketStore.getState().getActiveOrderWindowId();

            socket.emit("update-customer-status", {
                orderWindowId,
                nicknames: Array.isArray(nickname) ? nickname : [nickname],
                status
            });
        },
        onOrderWindowUpdated(orderWindow: OrderWindow | undefined) {
            if (!orderWindow) {
                return;
            }

            /**
             * We want to ensure that the customer is connected in the order window.
             * We can assume that this is true if the orderWindow is successfully sent to the socket service
             */
            // const updatedCustomers = (orderWindow?.customers || []).map(customer => {
            //     if (
            //         customer.nickname == getState().nickname &&
            //         customer.status == OrderWindowCustomerStatus.DISCONNECTED
            //     ) {
            //         return { ...customer, status: OrderWindowCustomerStatus.CONNECTED };
            //     }
            //     return customer;
            // });

            // const updatedOrderWindow: OrderWindow = {
            //     ...orderWindow,
            //     customers: updatedCustomers as OrderWindowCustomer[]
            // };

            const isSocketConnected = orderWindow?.isFromSocket;
            if (isSocketConnected) {
                socket.emit("update-order-window", { updatedOrderWindow: orderWindow });
            }
        },
        async handleFetchOrderWindow(
            tableId: string,
            shouldSetOrderWindow: boolean = true,
            shouldFetchPaidOrderWindow: boolean = false
        ) {
            const res = await Axios.get<{ orderWindow: OrderWindow; orderWindowMeta: OrderWindowMeta }>(
                `${envConstants.QSOCKET_SERVICE_URL}/api/tableId/${tableId}/${shouldFetchPaidOrderWindow}`,
                {
                    headers: { "Cache-control": "no-cache" }
                }
            );

            const success = !!res.data?.orderWindow && !!res.data?.orderWindowMeta;

            if (success) {
                const orderWindow = res.data.orderWindow;
                const orderWindowMeta = res.data.orderWindowMeta;
                const [formattedOrderWindow] = transformNestedOrderProductModifications([orderWindow]);

                if (shouldSetOrderWindow) {
                    /**
                     * setOrderWindowsAndActiveOrderWindowId() worked unexpected here
                     * went with overriding the orderWindows and setting the activeOrderWindowId manually
                     * ensures that we will always set the correct orderWindow and orderWindowId
                     */
                    orderWindowsStore.getState().setOrderWindows([], [formattedOrderWindow]);
                    orderWindowsStore.getState().setActiveOrderWindowId(formattedOrderWindow.id);
                }

                setState({
                    orderWindowVersion: orderWindowMeta.version,
                    groupOrderNicknames: orderWindowMeta.groupOrderNicknames,
                    hasGroupOrderStarted: orderWindowMeta.groupOrderStarted
                });

                return orderWindow;
            }
        },
        async handleFetchActiveOrderWindowById(id: string, shouldSetOrderWindow: boolean = true) {
            const res = await fetchActiveOrderWindowById(id);

            const orderWindow = res?.data?.orderWindow;

            if (!!orderWindow) {
                const [formattedOrderWindow] = transformNestedOrderProductModifications([orderWindow]);

                if (shouldSetOrderWindow) {
                    /**
                     * setOrderWindowsAndActiveOrderWindowId() worked unexpected here
                     * went with overriding the orderWindows and setting the activeOrderWindowId manually
                     * ensures that we will always set the correct orderWindow and orderWindowId
                     */
                    orderWindowsStore.getState().setActiveOrderWindowId(formattedOrderWindow.id);
                    orderWindowsStore.getState().setOrderWindows([], [formattedOrderWindow]);
                }

                return formattedOrderWindow;
            }
        },
        async handleFetchOrderWindowById(id: string, shouldSetOrderWindow: boolean = true) {
            const res = await fetchOrderWindowById(id);

            const orderWindow = res?.data?.orderWindow;
            if (!!orderWindow) {
                const [formattedOrderWindow] = transformNestedOrderProductModifications([orderWindow]);

                if (shouldSetOrderWindow) {
                    /**
                     * setOrderWindowsAndActiveOrderWindowId() worked unexpected here
                     * went with overriding the orderWindows and setting the activeOrderWindowId manually
                     * ensures that we will always set the correct orderWindow and orderWindowId
                     */
                    orderWindowsStore.getState().setOrderWindows([], [formattedOrderWindow]);
                    orderWindowsStore.getState().setActiveOrderWindowId(formattedOrderWindow.id);
                }

                return formattedOrderWindow;
            }
        },
        registerToTable(tableId: string, nickname: string) {
            socket.emit("register-to-table", { tableId, nickname });
        },
        enterOrderWindow(tableId: string, nickname: string, clientId?: string) {
            socket.emit("enter-order-window", {
                tableId,
                nickname,
                clientId
            });
        },
        multipleEnterOrderWindow(tableId: string, customers: OrderWindowCustomer[]) {
            socket.emit("multiple-enter-order-window", {
                tableId,
                customers
            });
        },
        declineEnterOrderWindow(tableId: string, nickname: string, clientId: string) {
            socket.emit("decline-enter-order-window", {
                tableId,
                nickname,
                clientId
            });
        },
        leaveOrderWindow(tableId: string, nickname: string) {
            socket.emit("leave-order-window", {
                tableId,
                nickname
            });
        },
        handleUpdateOrderStatus(
            orderWindowId: string,
            orderStatus: OrderWindowStatus,
            orderType: OrderWindowOrderType | null,
            orderId?: string
        ) {
            socket.emit("update-order-status", {
                orderWindowId,
                orderStatus,
                orderType,
                orderId
            });
        },
        requestEnterOrderWindow(tableId: string, nickname: string) {
            socket.emit("request-enter-order-window", {
                tableId,
                nickname
            });
        },
        setHasSeenFeedback(hasSeenFeedback: boolean) {
            const orderWindowSocketStore = JSON.parse(window?.localStorage.getItem(LOCAL_STORAGE_KEY) ?? "");

            const updatedOrderWindowSocketStore = {
                ...orderWindowSocketStore,
                hasSeenFeedback
            };

            window?.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(updatedOrderWindowSocketStore));

            setState({
                hasSeenFeedback
            });
        },
        setHasSeenUpsell(hasSeenUpsell: boolean) {
            const { orderWindowId, tableId, nickname } = getState();

            window?.localStorage.setItem(
                LOCAL_STORAGE_KEY,
                JSON.stringify({
                    orderWindowId,
                    tableId,
                    nickname,
                    hasSeenUpsell
                })
            );
            setState({
                hasSeenUpsell
            });
        },
        setHasSeenGroupInvitation(hasSeenGroupInvitation: boolean) {
            const { orderWindowId, tableId, nickname, hasSeenUpsell } = getState();

            window?.localStorage.setItem(
                LOCAL_STORAGE_KEY,
                JSON.stringify({
                    orderWindowId,
                    tableId,
                    nickname,
                    hasSeenUpsell,
                    hasSeenGroupInvitation
                })
            );
            setState({
                hasSeenGroupInvitation
            });
        },
        removeGroupOrderLocalStorage() {
            // Remove local storage group order
            window?.localStorage.removeItem(LOCAL_STORAGE_KEY);
            setState({
                ...initialState,
                nickname: null,
                orderWindowId: null,
                tableId: null,
                hasSeenUpsell: false,
                hasSeenFeedback: false
            });
        },
        openSocketConnection() {
            socket.connect();
        }
    };
};

export const orderWindowSocketStore = create(
    combine<OrderWindowSocketStoreState, OrderWindowSocketStoreActions>(initialState, mutations)
);
export const useOrderWindowSocketStore = createTrackedSelector(orderWindowSocketStore);
