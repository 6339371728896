import React from "react";
import { Control, useFormState, useWatch } from "react-hook-form";

import { Center, Stack } from "Atoms";
import { OnlinePaymentMethod, TypeOfServiceDisruption } from "Types";
import {
    OnlineCheckoutCardPaymentButton,
    OnlineCheckoutSwishPaymentButton,
    OnlineCheckoutInvoicePaymentButton,
    OnlineCheckoutPaymentDisruption
} from "./components";
import {
    calculateOnlineCheckoutTotal,
    isOnlineInvoicePaymentEnabled,
    useServiceFeeQuery
} from "OnlineUtils";
import { useOnline } from "Providers";
import { useOrderWindowsStore, useQoplaStore } from "Stores";
import { shouldDisableSubmitButton } from "../../../../onlineCheckoutHelpers";

type OnlineCheckoutPaymentMethodsProps = {
    onClickPay: (paymentMethod: OnlinePaymentMethod) => void;
    control: Control<any>;
};

type IsLoading = (
    onlinePaymentMethod: OnlinePaymentMethod,
    currentPaymentMethod: OnlinePaymentMethod,
    isSubmitting: boolean
) => boolean;
const isLoading: IsLoading = (onlinePaymentMethod, currentPaymentMethod, isSubmitting) => {
    return onlinePaymentMethod === currentPaymentMethod && isSubmitting;
};

export const OnlineCheckoutPaymentMethods = ({ control, onClickPay }: OnlineCheckoutPaymentMethodsProps) => {
    const { getActiveOrderWindow } = useOrderWindowsStore();
    const {
        foodOptions,
        serviceDisruptions: { isCardServiceDisrupted, isSwishServiceDisrupted }
    } = useOnline();
    const { selectedShop } = useQoplaStore();

    const orderWindow = getActiveOrderWindow();
    // Get the form submission state and errors
    const { isSubmitting, errors } = useFormState({ control });

    // Get the current payment method
    const [rawTip, giftCard, paymentMethod] = useWatch({ control, name: ["tip", "giftCard", "paymentMethod"] });
    const isUsingGiftCards = !!giftCard;

    const { data: serviceFeeData } = useServiceFeeQuery(isUsingGiftCards);

    const serviceFee = serviceFeeData?.calculateServiceFee ?? null;
    const minAmountFreeDelivery = selectedShop?.settings.homeDeliverySettings.minAmountFreeDelivery ?? 0;
    const deliveryFee = foodOptions.deliveryInformation?.fee ?? 0;

    const onlineOrderTotal = calculateOnlineCheckoutTotal(
        orderWindow,
        rawTip,
        giftCard,
        serviceFee,
        minAmountFreeDelivery,
        deliveryFee
    );

    // Check if the shop accepts invoice payments
    const doesAcceptInvoicePayment = selectedShop?.settings?.cateringSettings?.acceptInvoicePayment ?? false;
    const shouldShowInvoicePaymentButton = isOnlineInvoicePaymentEnabled(foodOptions, doesAcceptInvoicePayment);

    // Later: Swish must only be allowed in Sweden

    // Set the loading state for each payment method button
    const isCardLoading = isLoading(OnlinePaymentMethod.CARD, paymentMethod, isSubmitting);
    const isSwishLoading = isLoading(OnlinePaymentMethod.SWISH, paymentMethod, isSubmitting);
    const isInvoiceLoading = isLoading(OnlinePaymentMethod.INVOICE, paymentMethod, isSubmitting);

    const hasErrors = shouldDisableSubmitButton(errors, paymentMethod);

    const isCardDisabled = isCardLoading;
    const isSwishDisabled = isSwishLoading;
    const isInvoiceDisabled = isInvoiceLoading;

    const isZeroAmount = onlineOrderTotal === 0;
    const hasProducts = !!orderWindow?.cartProducts.length;
    const showCardDisruption = !isZeroAmount && isCardServiceDisrupted && hasProducts;
    const showSwishDisruption = !isZeroAmount && isSwishServiceDisrupted && hasProducts;

    return (
        <Center h="auto">
            <Stack mb={4} w="full" h="auto">
                <OnlineCheckoutCardPaymentButton
                    onClickPay={onClickPay}
                    isLoading={isCardLoading}
                    isDisabled={isCardDisabled || showCardDisruption}
                />
                {showCardDisruption && (
                    <OnlineCheckoutPaymentDisruption disruptionType={TypeOfServiceDisruption.CARD} />
                )}
                <OnlineCheckoutSwishPaymentButton
                    onClickPay={onClickPay}
                    isLoading={isSwishLoading}
                    isDisabled={isSwishDisabled || showSwishDisruption}
                />
                {showSwishDisruption && (
                    <OnlineCheckoutPaymentDisruption disruptionType={TypeOfServiceDisruption.SWISH} />
                )}
                {shouldShowInvoicePaymentButton && (
                    <OnlineCheckoutInvoicePaymentButton
                        onClickPay={onClickPay}
                        isLoading={isInvoiceLoading}
                        isDisabled={isInvoiceDisabled}
                    />
                )}
            </Stack>
        </Center>
    );
};
