import React from "react";

import { BaseBoxProps, Button, ButtonProps, Input, InputProps } from "Atoms";
import { useLanguage } from "Providers";
import { InputGroup, InputRightElement } from "../";

type Props = {
    value: string;
    setValue: (value: string) => void;
    inputSize?: InputProps["size"];
    buttonSize?: ButtonProps["size"];
    inputRounded?: InputProps["rounded"];
} & Omit<BaseBoxProps, "size">;

export const ProductsSearchInput: React.FC<Props> = ({
    value,
    setValue,
    inputSize = "md",
    inputRounded = "md",
    buttonSize = "md",
    ...rest
}) => {
    const { translate } = useLanguage();

    const onClearValue = () => {
        setValue("");
    };

    return (
        <InputGroup size={inputSize} {...rest}>
            <Input
                value={value}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValue(event.target.value)}
                placeholder={translate("searchForProducts")}
                rounded={inputRounded}
            />
            <InputRightElement w="7rem">
                <Button onClick={onClearValue} size={buttonSize} isDisabled={!value}>
                    {translate("clear")}
                </Button>
            </InputRightElement>
        </InputGroup>
    );
};
