import React, { useState, useEffect } from "react";
import moment from "moment";

import { Header, Box, Flex, Button } from "Atoms";
import { GET_QOPLA_MESSAGES, GET_PUBLIC_SHOP_INFOS_BY_IDS } from "GraphQLQueries";
import { useMothershipQuery, useMothershipLazyQuery } from "Hooks";
import { MessagePreview } from "./";
import { useModal } from "Providers";
import { messageInitialState, ShopQoplaMessageType, MESSAGE_TYPE } from "../SuperAdmin";
import { modalNames } from "Constants";
import { useAccordionItemContext } from "Organisms";

type Props = {
    shopQoplaMessage: ShopQoplaMessageType | undefined;
    setGlobalQoplaMessage: (globalMessage: ShopQoplaMessageType) => void;
    setShopQoplaMessage: (message: ShopQoplaMessageType) => void;
};

export const MessageToRestaurants: React.FC<Props> = ({
    shopQoplaMessage,
    setGlobalQoplaMessage,
    setShopQoplaMessage
}) => {
    const { openModal } = useModal();
    const { isExpanded } = useAccordionItemContext();

    const [latestShopQoplaMessages, setLatestShopQoplaMessages] = useState([]);

    const { data: getQoplaMessagesData, loading: getQoplaMessagesLoading } = useMothershipQuery(GET_QOPLA_MESSAGES, {
        fetchPolicy: "network-only",
        skip: !isExpanded,
        variables: {
            limit: 1,
            type: "SHOP"
        }
    });

    const [getPublicShopInfo, { data: getPublicShop, loading: loadingPublicShop }] = useMothershipLazyQuery(
        GET_PUBLIC_SHOP_INFOS_BY_IDS,
        {
            fetchPolicy: "network-only"
        }
    );

    useEffect(() => {
        if (getQoplaMessagesLoading || !getQoplaMessagesData || !getQoplaMessagesData.getQoplaMessages) return;
        setLatestShopQoplaMessages(!!shopQoplaMessage ? [shopQoplaMessage] : getQoplaMessagesData.getQoplaMessages);
        //because of the limit 1 in GET_QOPLA_MESSAGES, we're going to get only one, latest message
        const latestMessage = getQoplaMessagesData.getQoplaMessages[0];
        getPublicShopInfo({
            variables: {
                shopIds: latestMessage.shopIds
            }
        });
    }, [getQoplaMessagesLoading, shopQoplaMessage]);

    const shopsList = getPublicShop?.getPublicShopInfosByIds || [];

    return (
        <>
            {latestShopQoplaMessages && latestShopQoplaMessages.length ? (
                <Header as="h3" size="md">
                    Senast skickat meddelande
                </Header>
            ) : null}
            {latestShopQoplaMessages.map((message: any, idx: number) => (
                <Flex flexDirection="column" key={idx}>
                    <Header as="h4" size="sm">
                        Skickades: {moment(message.createdAt).format("YYYY-MM-DD HH:mm")}
                    </Header>
                    <MessagePreview qoplaMessage={message} key={message.id} />
                    <Header as="h4" size="sm">
                        De restauranger som meddelandet skickats till
                    </Header>
                    <Flex flexWrap="wrap" marginBottom={2}>
                        {shopsList.map((shop: any) => (
                            <Box
                                key={shop.id}
                                flex="0 0 auto"
                                height="max-content"
                                margin={1}
                                paddingY={1}
                                paddingX={2}
                                backgroundColor="gray.200"
                                borderRadius="md"
                                boxShadow="sm"
                            >
                                {shop.name}
                            </Box>
                        ))}
                    </Flex>
                    <Header as="h4" size="sm" margin={0}>
                        Skickat som e-post: {message.email ? "Ja" : "Nej"}
                    </Header>
                    <Header as="h4" size="sm" margin={0}>
                        Visas på orderskärm: {message.orderDisplay ? "Ja" : "Nej"}
                    </Header>
                </Flex>
            ))}
            <Button
                themeColor="blue"
                style={{ marginTop: "1rem" }}
                onClick={() => {
                    openModal(modalNames.QOPLA_MESSAGE_MODAL, {
                        messageType: MESSAGE_TYPE.SHOP,
                        messageInitialState,
                        setGlobalQoplaMessage,
                        setShopQoplaMessage
                    });
                }}
            >
                Skriv nytt meddelande
            </Button>
        </>
    );
};
