import React from "react";
import { useRef } from "react";
import { Helmet } from "react-helmet-async";

import { useTypedMutation } from "Hooks";
import { BaseBoxProps, Box } from "Atoms";
import { useAuthUser, useLanguage, UserAccount, UserTypes } from "Providers";
import { GOOGLE_SIGN_IN } from "GraphQLMutations";
import { MutationGoogleSigninArgs } from "src/generated/graphql";
import { confirmOrCancelNotification } from "TempUtils";
import { colors } from "../../theme/colors";
import moment from "moment";
import { gaEvent } from "Utils";
import { GOOGLE_ANALYTICS_EVENT_ACTION, GOOGLE_ANALYTICS_EVENT_CATEGORY } from "Types";

type GoogleAuthenticationButtonProps = { onSuccess?: (userAccount: UserAccount) => void } & Partial<BaseBoxProps>;

/**
 * It is okay for this value to be public. It's not a secret.
 * It needs to match the clientId in the qUserService backend.
 * This value stays the same for every environment (local, dev, prod)
 *
 * Best I can tell, google only allows requests from whitelisted domains using this client-id
 * - qopla.com, qopla.se, dev.qopla.com
 * - localhost, localhost:3000
 */
const googleOAuthClientId = "283868014176-qethru2n1abrjg7vr27k1m6s09jfg1sl.apps.googleusercontent.com";

const GOOGLE_AUTH_CLIENT_LIB_URL = "https://accounts.google.com/gsi/client";

const GoogleAuthenticationButton: React.FC<GoogleAuthenticationButtonProps> = ({ onSuccess, ...rest }) => {
    const { translate, userLanguage } = useLanguage();
    const divRef = useRef<HTMLDivElement>(null);
    const { setAuthUserAndToken } = useAuthUser();

    // Not sure where in the qUserService to activate the generation of types for this query
    const [googleSignin] = useTypedMutation<any, MutationGoogleSigninArgs>(GOOGLE_SIGN_IN);

    const attemptAccountMerge = async (googleCredentials: string) => {
        // Attempt to merge accounts
        const { data } = await googleSignin({
            variables: { input: { googleCredentials, merge: true } }
        });
        if (data && data.googleSignin) {
            const [res] = data.googleSignin;
            if (res.path) {
                return res;
            } else {
                gaEvent({
                    category: GOOGLE_ANALYTICS_EVENT_CATEGORY.GOOGLE_USER_ACCOUNT,
                    action: GOOGLE_ANALYTICS_EVENT_ACTION.MERGE,
                    label: "Google account merge successful"
                });
                setAuthUserAndToken(UserTypes.USER_ACCOUNT, res);
                onSuccess && onSuccess(res);
            }
        }
    };

    const onGoogleAuthenticated = async (googleCredentials: string) => {
        const { data } = await googleSignin({
            variables: { input: { googleCredentials } }
        });

        /**
         * The error path == "merge' indicates that:
         *  1. The email address is already in use in the database
         *  2. The account with the matching email does not have a googleUserId yet
         **/
        const [res] = data?.googleSignin;

        const accountsNeedMerging = res?.__typename === "Error" && res?.path == "merge";
        if (accountsNeedMerging) {
            const answer = await confirmOrCancelNotification(
                translate("mergeGoogleAccountWithExisting"),
                "",
                `${translate("yes")} (${translate("recommended")})`,
                colors.green[500],
                translate("no"),
                "warning",
                "center",
                "true",
                "true",
                colors.red[500]
            );

            // Do not merge accounts
            if (!answer?.value) {
                return;
            }

            await attemptAccountMerge(googleCredentials);
        } else {
            if (data && data.googleSignin) {
                const [res] = data.googleSignin;
                if (res.path) {
                    return res;
                } else {
                    if (res?.createdAt) {
                        const secondsAgo = moment().diff(moment(res.createdAt), "seconds");
                        /**
                         * This is the callback for both login and registration
                         *
                         * There is no field in the UserAccount to show if it's a new account after this mutation.
                         *
                         * We'll check when the account was created.
                         * If it was recent, then we can assume, this was an account creation
                         */
                        if (secondsAgo < 30) {
                            gaEvent({
                                category: GOOGLE_ANALYTICS_EVENT_CATEGORY.GOOGLE_USER_ACCOUNT,
                                action: GOOGLE_ANALYTICS_EVENT_ACTION.REGISTER,
                                label: "Google account register confirmed"
                            });
                        } else {
                            gaEvent({
                                category: GOOGLE_ANALYTICS_EVENT_CATEGORY.GOOGLE_USER_ACCOUNT,
                                action: GOOGLE_ANALYTICS_EVENT_ACTION.LOGIN,
                                label: "Google account login successful"
                            });
                        }
                    }

                    setAuthUserAndToken(UserTypes.USER_ACCOUNT, res);
                    onSuccess && onSuccess(res);
                }
            }
        }
    };

    const onGoogleLoaded = () => {
        if (window?.google && divRef?.current) {
            try {
                window.google.accounts.id.initialize({
                    client_id: googleOAuthClientId,
                    callback: async res => {
                        !!res?.credential && onGoogleAuthenticated(res.credential);
                    }
                });

                const opts: GsiButtonConfiguration = {
                    type: "standard",
                    text: "continue_with",
                    // Depending on typescript version Intl.Locale is not recognized
                    //@ts-ignore
                    locale: new Intl.Locale(userLanguage).baseName
                };
                window.google.accounts.id.renderButton(divRef.current, opts);
            } catch (error) {
                console.log(error);
            }
        }
    };

    const handleScriptInject = (newState: any, addedTags: any) => {
        const newScriptTags = addedTags?.scriptTags;
        if (newScriptTags) {
            const foundScript = newScriptTags.find(({ src }: any) => src === GOOGLE_AUTH_CLIENT_LIB_URL);
            if (foundScript) {
                /**
                 * The tag had just been added to the Head
                 * We want to execute `onGoogleLoaded` when the script has finished loading
                 */
                foundScript.addEventListener("load", onGoogleLoaded);
            }
        } else {
            const existingScriptTags = newState?.scriptTags;
            const foundScript = existingScriptTags?.find(({ src }: any) => src === GOOGLE_AUTH_CLIENT_LIB_URL);
            if (foundScript) {
                /**
                 * The script tag already exists in the Head state and should already be loaded
                 * We can just call `onGoogleLoaded` now to show the button
                 */
                onGoogleLoaded();
            }
        }
    };

    return (
        <Box my={1} {...rest}>
            {/** @ts-ignore */}
            <Helmet
                script={[{ src: GOOGLE_AUTH_CLIENT_LIB_URL }]}
                onChangeClientState={(newState: any, addedTags: any) => {
                    handleScriptInject(newState, addedTags);
                }}
            />
            <Box my={2} ref={divRef} />
        </Box>
    );
};

export { GoogleAuthenticationButton };
