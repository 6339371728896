import React from "react";
import { AnimatePresence, HTMLMotionProps, MotionStyle, Variants as TVariants } from "framer-motion";

import { slideTransition, Variants, withDelay, WithTransitionConfig } from "Utils";
import { MotionDiv } from "Atoms";
import { TransitionEasings } from "../../utils/transitionHelpers";

export type SlideDirection = "top" | "left" | "bottom" | "right";

const defaultTransition = {
    exit: {
        duration: 0.15,
        ease: TransitionEasings.easeInOut
    },
    enter: {
        type: "spring",
        damping: 25,
        stiffness: 180
    }
};

export interface SlideOptions {
    direction?: SlideDirection;
}

const variants: Variants<SlideOptions> = {
    exit: ({ direction, transition, transitionEnd, delay }) => {
        const { exit: exitStyles } = slideTransition({ direction });
        return {
            ...exitStyles,
            transition: transition?.exit ?? withDelay.exit(defaultTransition.exit, delay),
            transitionEnd: transitionEnd?.exit
        };
    },
    enter: ({ direction, transitionEnd, transition, delay }) => {
        const { enter: enterStyles } = slideTransition({ direction });
        return {
            ...enterStyles,
            transition: transition?.enter ?? withDelay.enter(defaultTransition.enter, delay),
            transitionEnd: transitionEnd?.enter
        };
    }
};

export interface SlideProps extends WithTransitionConfig<HTMLMotionProps<"div">>, SlideOptions {}

export const Slider = React.forwardRef<HTMLDivElement, SlideProps>((props, ref) => {
    const {
        direction = "right",
        style,
        unmountOnExit,
        in: isOpen,
        className,
        transition,
        transitionEnd,
        delay,
        ...rest
    } = props;

    const transitionStyles = slideTransition({ direction });
    const computedStyle: MotionStyle = Object.assign({ position: "fixed" }, transitionStyles.position, style);

    const show = unmountOnExit ? isOpen && unmountOnExit : true;
    const animate = isOpen || unmountOnExit ? "enter" : "exit";

    const custom = { transitionEnd, transition, direction, delay };

    return (
        <AnimatePresence custom={custom}>
            {show && (
                <MotionDiv
                    {...rest}
                    ref={ref}
                    initial="exit"
                    animate={animate}
                    exit="exit"
                    custom={custom}
                    variants={variants as TVariants}
                    // @ts-ignore //TODO: fix this
                    style={computedStyle}
                />
            )}
        </AnimatePresence>
    );
});
