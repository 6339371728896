import _copy from "copy-to-clipboard";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { useLanguage } from "Providers";

export const useCopyToClipboard = () => {
    const { translateWithArgument } = useLanguage();
    const [hasCopied, setHasCopied] = useState(false);

    useEffect(() => {
        let id: NodeJS.Timeout;
        if (hasCopied) {
            id = setTimeout(() => {
                setHasCopied(false);
            }, 1500);
        }

        return () => clearTimeout(id);
    }, [hasCopied]);

    const copy = (value: string, showToast: boolean = true) => {
        _copy(value);
        if (showToast) {
            toast.success(`${translateWithArgument("copied", value)}`, {
                autoClose: 1500
            });
        }
        setHasCopied(true);
    };

    return { copy, hasCopied };
};
