import moment from "moment";

import { DIRECTION } from "Organisms";
import { PaginationSortOptions } from "Organisms";
import { CustomerFeedback } from "Types";

/**
 * Sorting function for rating on each feedback given
 * @param {CustomerFeedback} feedbackA
 * @param {CustomerFeedback} feedbackB
 */
export const sortByFeedbackRating = (feedbackA: CustomerFeedback, feedbackB: CustomerFeedback) => {
    return feedbackA.overallRating > feedbackB.overallRating
        ? -1
        : feedbackB.overallRating < feedbackA.overallRating
        ? 1
        : 0;
};

export const sortByDates = (feedbackA: CustomerFeedback, feedbackB: CustomerFeedback) => {
    const dateA = feedbackA.createdAt ? moment(feedbackA.createdAt) : null;
    const dateB = feedbackB.createdAt ? moment(feedbackB.createdAt) : null;
    if (dateA && dateB) {
        return dateA > dateB ? -1 : dateB < dateA ? 1 : 0;
    } else if (dateA && !dateB) {
        return -1;
    } else if (!dateA && dateB) {
        return 1;
    }
    return 0;
};

/**
 * Default settings for feeback sorting
 */
export const initialFeedbackSortingOptions: PaginationSortOptions[] = [
    {
        text: "Betyg",
        value: "score",
        default: true,
        sortingDirection: DIRECTION.ASC,
        sortingOrder: 1,
        sortFunction: sortByFeedbackRating
    },
    {
        text: "Datum",
        value: "date",
        default: false,
        sortingDirection: DIRECTION.NONE,
        sortingOrder: 2,
        sortFunction: sortByDates
    }
];
