import { useLanguage } from "Providers";
import { ReportStats, DataType, ReportType, IncludedDataSets } from "./reportTypes";
import { getCategoryStats, getUniqueNamesFrom } from "./utils";

type ResultType = {
    id: string;
    label: string;
    value: number;
    pos: number;
    web: number;
    foodora?: number;
    bolt?: number;
    wolt?: number;
    ubereats?: number;
};
interface CategoryTranslations {
    [key: string]: string;
}
export const calculateCategoryDataReport = (
    report: ReportStats,
    selectedDataType: DataType,
    isVATIncluded: boolean,
    includedDataSets: IncludedDataSets
) => {
    const { translate } = useLanguage();
    const getCategoryValue = (dataSource: any, category: string) => {
        let currentValue = 0;
        if (dataSource[category])
            currentValue =
                selectedDataType === DataType.Quantity
                    ? dataSource[category].quantity
                    : isVATIncluded
                    ? dataSource[category].total
                    : dataSource[category].totalNet;
        return currentValue;
    };

    const dataPos: any = getCategoryStats(selectedDataType, ReportType.POS, report);
    const dataWeb: any = getCategoryStats(selectedDataType, ReportType.WEB, report);
    const dataFoodora: any = getCategoryStats(selectedDataType, ReportType.FOODORA, report);
    const dataWolt: any = getCategoryStats(selectedDataType, ReportType.WOLT, report);
    const dataBolt: any = getCategoryStats(selectedDataType, ReportType.BOLT, report);
    const dataUberEats: any = getCategoryStats(selectedDataType, ReportType.UBEREATS, report);

    let totalAmount: number = 0;
    const combinedCategoriesArray = getUniqueNamesFrom(dataPos, dataWeb, dataFoodora, dataBolt, dataWolt, dataUberEats);

    let data = combinedCategoriesArray.map((category: string) => {
        let webValue = includedDataSets.web ? getCategoryValue(dataWeb, category) : 0;
        let posValue = includedDataSets.pos ? getCategoryValue(dataPos, category) : 0;
        const foodoraValue = includedDataSets.foodora ? getCategoryValue(dataFoodora, category) : 0;
        const woltValue = includedDataSets.wolt ? getCategoryValue(dataWolt, category) : 0;
        const boltValue = includedDataSets.bolt ? getCategoryValue(dataBolt, category) : 0;
        const uberEatsValue = includedDataSets.ubereats ? getCategoryValue(dataUberEats, category) : 0;

        const totalValue = posValue + webValue + foodoraValue + woltValue + boltValue + uberEatsValue;
        totalAmount += totalValue;

        const DELIVERY_FEE = "DELIVERY_FEE";
        const TIP = "TIP";
        const THIRD_PARTY_MENU_CAT_ID = "THIRD_PARTY_MENU_CAT_ID";
        const OPEN_PRODUCT = "OPEN_PRODUCT";

        const categoryTranslations: CategoryTranslations = {
            [THIRD_PARTY_MENU_CAT_ID]: translate("deliveryServices"),
            [DELIVERY_FEE]: translate("deliveryFee"),
            [TIP]: translate("tip"),
            [OPEN_PRODUCT]: translate("openProduct")
        };

        let categoryName = categoryTranslations[category] || category;

        let truncatedCategoryName = categoryName.length > 23 ? categoryName.slice(0, 23) + "..." : categoryName;

        let result: ResultType = {
            id: truncatedCategoryName,
            label: truncatedCategoryName,
            value: totalValue,
            pos: posValue,
            web: webValue
        };
        if (includedDataSets.foodora) result = { ...result, foodora: foodoraValue };
        if (includedDataSets.bolt) result = { ...result, bolt: boltValue };
        if (includedDataSets.wolt) result = { ...result, wolt: woltValue };
        if (includedDataSets.ubereats) result = { ...result, ubereats: uberEatsValue };

        return result;
    });

    return { data: data, total: totalAmount, combined: combinedCategoriesArray };
};
