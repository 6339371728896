import { IngredientLimiter } from "Types";

export type AddonsHashMapInput = {
    displayByName: boolean;
    id: string;
    name: string;
    limit: number;
    refProductIds: string[];
    sortOrder: number;
    ingredientLimiters: IngredientLimiter[];
};

type Ingredients = {
    limit: number;
    sortOrder: number;
    ingredient: {
        name: string;
        groupId: string;
        price: number;
    };
}[];

export type AddonsHashMapValue = {
    name: string;
    limit: number;
    ingredients: Ingredients;
    sortOrder: number;
    displayByName: boolean;
}[];

export type AddonsHashMap = {
    [key: string]: AddonsHashMapValue;
};

const getIngredients = ({ ingredientLimiters, id }: AddonsHashMapInput) => {
    return ingredientLimiters.reduce<Ingredients>((acc, next) => {
        const found = acc.find(({ ingredient }) => ingredient.name === next.ingredient.name);
        if (!found) {
            acc.push({ ...next, ingredient: { ...next.ingredient, groupId: id } });
        }
        return acc;
    }, []);
};

export const createAddonsHashMap = (addons: AddonsHashMapInput[]) => {
    return addons.reduce<AddonsHashMap>((acc, next) => {
        next.refProductIds.forEach(refProductId => {
            const nextIngredientGroup = {
                name: next.name,
                limit: next.limit,
                ingredients: getIngredients(next),
                sortOrder: next.sortOrder,
                displayByName: next.displayByName
            };

            acc[refProductId] = acc[refProductId]
                ? acc[refProductId].concat(nextIngredientGroup)
                : [nextIngredientGroup];
        });
        return acc;
    }, {});
};
