import React from "react";
import { Grid, Header } from "semantic-ui-react";
import { RefProductCard } from "./orderRenderUtils";
import "../expressCheckout.scss";
import { getBundleModAddonPriceText } from "TempUtils/getPriceText";

export const BundleProductCategories = ({
    selectedBundleProduct,
    selectedBundleItems,
    handleSelectedBundleItem,
    refProductHashMap,
    outOfStockProductIds,
    hideStockProductIds
}) => {
    const { bundleProductCategories } = selectedBundleProduct.refBundleProduct;

    const categoryToSelectFrom = bundleProductCategories.findIndex(
        cat => selectedBundleItems[cat.name] && cat.limit > selectedBundleItems[cat.name].choosenBundleItems.length
    );

    return [...bundleProductCategories]
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .map((bundleProdCat, idx) => {
        const selectedCatBundleItems = selectedBundleItems[bundleProdCat.name].choosenBundleItems;

        const catLimitReached = bundleProdCat.limit === selectedCatBundleItems.length;

        const bundleProductItems = bundleProdCat.refProductIdList
            .map(id => (id && refProductHashMap && refProductHashMap[id] ? refProductHashMap[id] : null))
            .filter(p => !!p);

        const handleSelectProduct = async prod => {
            const updatedProd = bundleProdCat.kdsUnitDisplayName
                ? { ...prod, kdsUnitDisplayName: bundleProdCat.kdsUnitDisplayName }
                : prod;
            await handleSelectedBundleItem(
                {
                    refProduct: updatedProd,
                    selectedBundleProd: selectedBundleProduct
                },
                bundleProdCat
            );
        };

        return (
            <Grid key={bundleProdCat.name} id={bundleProdCat.name} style={{ margin: "0" }}>
                <Grid.Row style={{ backgroundColor: "#F5F5F5", borderRadius: "5px" }}>
                    <Grid.Column>
                        <Header as="h2" style={{ marginBottom: "0", fontWeight: "500" }}>
                            {bundleProdCat.name} - Du har valt {selectedCatBundleItems.length} av {bundleProdCat.limit}
                        </Header>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16} style={{ paddingLeft: "0" }}>
                        <div className="four-items-grid" style={{ paddingLeft: "0" }}>
                            {bundleProductItems
                                .filter(prod => !(hideStockProductIds || []).includes(prod.id))
                                .map(prod => {
                                    let limitReachedAndGrayImage = false;
                                    if (catLimitReached) {
                                        limitReachedAndGrayImage = !!!selectedCatBundleItems.find(
                                            bundleItem => bundleItem.refProductId === prod.id
                                        );
                                    }
                                    const productAmount = selectedCatBundleItems.filter(
                                        bundleItem => bundleItem.refProductId === prod.id
                                    ).length;

                                    let priceText = null;
                                    if (priceText === null && prod.modifications) {
                                        priceText = getBundleModAddonPriceText(prod.modifications);
                                    }
                                    if (selectedBundleProduct.menuBundleModifications) {
                                        const menuMods = selectedBundleProduct.menuBundleModifications.find(
                                            mod => mod.refProductId === prod.id
                                        );
                                        if (menuMods) {
                                            priceText = getBundleModAddonPriceText(menuMods.modifications);
                                        }
                                    }

                                    const outOfStock = (outOfStockProductIds || []).includes(prod.id);

                                    const isPreselectedSingleItem =
                                        catLimitReached &&
                                        bundleProdCat.limit === 1 &&
                                        bundleProdCat.refProductIdList.length === 1;
                                    return (
                                        <RefProductCard
                                            key={prod.id}
                                            isCategoryToSelectFrom={idx === categoryToSelectFrom}
                                            product={{
                                                ...prod,
                                                outOfStock,
                                                limitReachedAndGrayImage
                                            }}
                                            productAmount={productAmount}
                                            priceText={priceText}
                                            isPreselectedSingleItem={isPreselectedSingleItem}
                                            handleSelectProduct={() => handleSelectProduct(prod)}
                                            className="menu-tabs-card"
                                            style={{
                                                backgroundColor: "transparent",
                                                color: "#000"
                                            }}
                                        />
                                    );
                                })}
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    });
};
