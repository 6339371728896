import Color from "color";

export const isDarkColor = (color: string) => Color(color).isDark();

export const string2Hex = (str: string) => {
    let hash = 0;
    if (str.length === 0) return hash;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
        hash = hash & hash;
    }
    let color = "#";
    for (let j = 0; j < 3; j++) {
        let value = (hash >> (j * 8)) & 255;
        color += ("00" + value.toString(16)).substr(-2);
    }
    return color;
};


export const convertHEXToRGBA = (hex: string, opacity?: number) => {
    hex = hex.replace("#", "");
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return `rgba(${r}, ${g}, ${b}, ${opacity == undefined ? 1 : opacity})`;
}