import { colors, typography, iconPaths } from ".";

const shadows = {
    sm: "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
    md: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    lg: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
    xl: "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
    "2xl": "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
    image: "-8px -4px 10px 0px rgba(0,0,0,0.40)",
    outline: "0 0 0 3px rgba(66, 153, 225, 0.6)",
    inner: "inset 0 2px 4px 0 rgba(0,0,0,0.06)",
    none: "none"
};

// First, create an alias for breakpoints
// aliases
//const breakpoints = { sm: "30em", md: "48em" };
const breakpoints = ["30em", "48em", "62em", "80em", "90em"];
//@ts-ignore
breakpoints.sm = breakpoints[0];
//@ts-ignore
breakpoints.md = breakpoints[1];
//@ts-ignore
breakpoints.lg = breakpoints[2];
//@ts-ignore
breakpoints.xl = breakpoints[3];
//@ts-ignore
breakpoints["2xl"] = breakpoints[4];

export const baseSizes = {
    "0": "0",
    "1": "0.25rem",
    "2": "0.5rem",
    "3": "0.75rem",
    "4": "1rem",
    "5": "1.25rem",
    "6": "1.5rem",
    "8": "2rem",
    "10": "2.5rem",
    "12": "3rem",
    "16": "4rem",
    "20": "5rem",
    "24": "6rem",
    "32": "8rem",
    "40": "10rem",
    "48": "12rem",
    "56": "14rem",
    "64": "16rem"
};

export const bigSizes = {
    full: "100%",
    "3xs": "20rem",
    "2xs": "30rem",
    xs: "40rem",
    sm: "46rem",
    md: "52rem",
    lg: "58rem",
    xl: "70rem",
    xxl: "80rem"
};

const zIndices = {
    hide: -1,
    auto: "auto",
    base: 0,
    docked: 10,
    dropdown: 1000,
    sticky: 1100,
    banner: 1200,
    overlay: 1300,
    modal: 1400,
    popover: 1500,
    skipLink: 1600,
    toast: 1700,
    tooltip: 1800
};

const container = {
    sm: "640px",
    md: "768px",
    lg: "1024px",
    xl: "1280px",
    "2xl": "1536px"
};

const sizes = {
    ...baseSizes,
    ...bigSizes,
    container
};

const borders = {
    none: 0,
    "1px": "1px",
    "2px": "2px",
    "4px": "4px"
};

const radii = {
    none: "0",
    sm: "0.125rem",
    md: "0.25rem",
    lg: "0.5rem",
    xl: "0.75rem",
    xxl: "1rem",
    full: "50%"
};

/*
 * THEME INFO
 * https://system-ui.com/theme/
 */

export const theme = {
    colors,
    borders,
    breakpoints,
    space: baseSizes,
    zIndices,
    sizes,
    shadows,
    radii,
    icons: iconPaths,
    ...typography
};
