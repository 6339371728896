import React, { useState, useEffect } from "react";
import { AiOutlineCloudUpload } from "@react-icons/all-files/ai/AiOutlineCloudUpload";
import { MdErrorOutline } from "@react-icons/all-files/md/MdErrorOutline";
import { MdBrokenImage } from "@react-icons/all-files/md/MdBrokenImage";

import { filetypeConstants } from "Constants";
import { UPLOAD_FILE } from "GraphQLMutations";
import { useMothershipMutation } from "Hooks";
import { BaseBoxProps, FormLabel, PseudoBox, Flex, Box, Text, Image, Spinner, Input } from "Atoms";
import { fileToBase64String } from "TempUtils";

type FileType = keyof typeof filetypeConstants;

export enum EventAllowed {
    ALL = "ALL",
    CLICK_SELECT = "CLICK_SELECT",
    SELECT = "SELECT"
}

export enum TextDisplayed {
    TOO_LARGE = "TOO_LARGE",
    WRONG_TYPE = "WRONG_TYPE",
    DEFAULT = "DEFAULT"
}

type DropZoneMessages = {
    [key in TextDisplayed]: string;
};

type ComponentProps = {
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    name: string;
    fileType: FileType;
    companyId: string;
    dropZoneText: string;
    prevFile?: string;
    acceptFiles?: string;
    allowedEvent?: EventAllowed;
};

type Props = ComponentProps & Omit<BaseBoxProps, "display" | "backgroundColor">;

const DropZoneDisplay: React.FC<{
    isUploading: boolean;
    imageUrl?: string;
    icon: any;
    iconColour: string;
    text: string;
    hasErrors: boolean;
}> = ({ isUploading, imageUrl, icon, iconColour, text, hasErrors }) => {
    const [brokenImageLink, setBrokenImageLink] = useState<boolean>(false);

    const onBrokenImage = () => {
        setBrokenImageLink(true);
    };

    useEffect(() => {
        if (imageUrl) {
            setBrokenImageLink(false);
        }
    }, [imageUrl]);

    if (isUploading) {
        return <Spinner color="#4299E1" />;
    }

    if (imageUrl && !brokenImageLink) {
        return <Image onError={onBrokenImage} src={imageUrl} alt="Image Preview" width="200px" />;
    }

    const hasBrokenImageAndError = brokenImageLink && hasErrors;

    if (hasBrokenImageAndError) {
        return (
            <>
                <Box as={icon} fontSize="1.5rem" color={iconColour} />
                <Text mt={2}>{text}</Text>
            </>
        );
    }

    return (
        <>
            <Box as={!brokenImageLink ? icon : MdBrokenImage} fontSize="1.5rem" color={iconColour} />
            <Text mt={2}>{text}</Text>
        </>
    );
};

export const NewFormDropZone: React.FC<Props> = ({
    setFieldValue,
    name,
    fileType,
    companyId,
    dropZoneText,
    prevFile,
    acceptFiles,
    allowedEvent = EventAllowed.ALL,
    ...rest
}) => {
    //TODO: TRANSLATION WILL DO ON TRANSLATION BRANCH - don't want duplicates
    const dropZoneMessages: DropZoneMessages = {
        TOO_LARGE: "Storleken på bilden får inte vara större än 5MB",
        WRONG_TYPE: "Filtypen är inte tillåten. Vänligen försök med en annan",
        DEFAULT: dropZoneText
    };

    const [fileTypesAllowed] = useState<string>(() => {
        if (acceptFiles) {
            return acceptFiles;
        }
        return "image/jpeg, image/png, image/gif, image/bmp";
    });
    const [hasErrors, setHasErrors] = useState<boolean>(false);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [backgroundColour, setBackgroundColour] = useState<string>("white");
    const [dropZoneMessage, setDropZoneMessage] = useState<TextDisplayed>(TextDisplayed.DEFAULT);

    const [uploadFile] = useMothershipMutation(UPLOAD_FILE);

    const isDragDisabled = [EventAllowed.CLICK_SELECT, EventAllowed.SELECT].includes(allowedEvent);
    const isDisabled = allowedEvent === EventAllowed.SELECT;
    const MAX_FILE_SIZE = 5000000;

    const standardBorder = "1px dashed #718096";
    const iconImage = !hasErrors ? AiOutlineCloudUpload : MdErrorOutline;
    const iconColour = !hasErrors ? "blue.500" : "gray.900";

    const onHandleUpload = async (file: any) => {
        setIsUpdating(true);
        const base64String = await fileToBase64String(file);
        const { data } = await uploadFile({
            variables: {
                base64Imgs: [
                    {
                        companyId,
                        imageFolder: filetypeConstants[fileType],
                        ...base64String
                    }
                ]
            }
        });
        if (data) {
            const { uploadFile: imageUrl } = data;
            setFieldValue(name, imageUrl[0]);
            setIsUpdating(false);
        }
    };

    const fileCanBeUploaded = (file: File) => {
        const acceptedFiles = fileTypesAllowed?.split(",").map(file => file.trim());
        if (!acceptedFiles?.includes(file.type)) {
            setHasErrors(true);
            setDropZoneMessage(TextDisplayed.WRONG_TYPE);
            return false;
        }
        if (file.size > MAX_FILE_SIZE) {
            setHasErrors(true);
            setDropZoneMessage(TextDisplayed.TOO_LARGE);
            return false;
        }
        return true;
    };

    const onCheckFiles = (files: any) => {
        const allFiles = [...files];
        if (allFiles.length > 1) {
            setHasErrors(true);
            return false;
        }
        if (allFiles.length === 1) {
            if (!fileCanBeUploaded(allFiles[0])) {
                return false;
            }
            onHandleUpload(allFiles[0]);
        }
        setDropZoneMessage(TextDisplayed.DEFAULT);
        setHasErrors(false);
    };

    return (
        <Flex
            mb={4}
            mt={3}
            p={4}
            width="100%"
            backgroundColor="white"
            borderColor="gray.600"
            borderRadius="lg"
            boxShadow="sm"
            {...rest}
        >
            <FormLabel
                style={{
                    cursor: isDisabled ? "not-allowed" : "pointer"
                }}
                textAlign="center"
                fontWeight="sm"
                borderRadius="lg"
                backgroundColor={backgroundColour}
                border={standardBorder}
                width="100%"
                p={4}
                onDragLeave={(ev: React.DragEvent<HTMLDivElement>) => {
                    if (isDragDisabled) {
                        setHasErrors(!isDragDisabled);
                    }
                    setBackgroundColour("white");
                    ev.preventDefault();
                }}
                onDragEnter={(ev: React.DragEvent<HTMLDivElement>) => {
                    if (isDragDisabled) {
                        setHasErrors(isDragDisabled);
                        return false;
                    }
                    ev.stopPropagation();
                    setBackgroundColour("gray.100");
                    ev.preventDefault();
                }}
                onDragOver={(ev: React.DragEvent<HTMLDivElement>) => {
                    if (isDragDisabled) {
                        setHasErrors(isDragDisabled);
                        return false;
                    }
                    ev.stopPropagation();
                    ev.preventDefault();
                }}
                onDrop={(ev: React.DragEvent<HTMLDivElement>) => {
                    if (isDragDisabled) {
                        setHasErrors(!isDragDisabled);
                        return false;
                    }
                    ev.preventDefault();
                    onCheckFiles(ev.dataTransfer.files);
                    setBackgroundColour("gray.100");
                }}
            >
                <Input
                    name={name}
                    onChange={(event: { currentTarget: { files: any } }) => {
                        const allFiles = [...event.currentTarget.files];
                        if (allFiles.length === 1) {
                            if (fileCanBeUploaded(event.currentTarget.files[0])) {
                                onHandleUpload(event.currentTarget.files[0]);
                            }
                        }
                    }}
                    type="file"
                    display="none"
                    padding="none"
                    accept={fileTypesAllowed}
                    isDisabled={isDisabled}
                />
                <PseudoBox
                    as="span"
                    _after={{
                        content: "{}",
                        display: "inline-block",
                        verticalAlign: "middle"
                    }}
                >
                    <Flex direction="column" justifyContent="center" alignItems="center">
                        <DropZoneDisplay
                            isUploading={isUpdating}
                            imageUrl={prevFile}
                            icon={iconImage}
                            iconColour={iconColour}
                            text={dropZoneMessages[dropZoneMessage]}
                            hasErrors={hasErrors}
                        />
                    </Flex>
                </PseudoBox>
            </FormLabel>
        </Flex>
    );
};
