import React from "react";

import { Text, BaseBoxProps } from "Atoms";
import { useLanguage } from "../../providers/languageProvider/LanguageProvider";

type Props = {
    tid: string;
} & BaseBoxProps;

export const LanguageText: React.FC<Props> = ({ tid, ...rest }) => {
    const { dictionary } = useLanguage();

    const text = dictionary[tid] || "";

    return <Text {...rest}>{text}</Text>;
};
