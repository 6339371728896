import React from "react";
import { useController } from "react-hook-form";
import { IoIosEyeOff } from "@react-icons/all-files/io/IoIosEyeOff";
import { IoIosEye } from "@react-icons/all-files/io/IoIosEye";

import { Input, InputProps } from "Atoms";
import { IconButton, InputGroup, InputRightElement } from "Molecules";
import { useSwitch } from "Hooks";
import { RHWrapper, RHCommonProps } from "./";

export type RHFormPasswordFormInputProps = {
    onlyShowLabelWithValue?: boolean;
} & RHCommonProps &
    InputProps;

export const RHFormPasswordFormInput: React.FC<RHFormPasswordFormInputProps> = ({
    name,
    control,
    placeholder,
    formLabel,
    helperText,
    isMandatory = false,
    isFullWidth = true,
    onlyShowLabelWithValue = false,
    isDisabled,
    wrapperProps,
    ...rest
}) => {
    const { open, onToggle } = useSwitch();

    const {
        field: { onChange, onBlur, value, ref },
        fieldState: { isTouched, error }
    } = useController({
        name,
        control
    });

    const isInvalid = !!(isTouched && error?.message);

    let shouldShowLabel = true;
    if (onlyShowLabelWithValue) {
        shouldShowLabel = !!value && !!formLabel;
    } else {
        shouldShowLabel = !!formLabel;
    }

    return (
        <RHWrapper
            name={name}
            control={control}
            formLabel={shouldShowLabel ? formLabel : undefined}
            helperText={helperText}
            wrapperProps={wrapperProps}
            isMandatory={isMandatory}
        >
            <InputGroup size="lg">
                <Input
                    type={open ? "text" : "password"}
                    size="lg"
                    rounded="lg"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value ?? ""}
                    name={name}
                    ref={ref}
                    isDisabled={isDisabled}
                    isInvalid={isInvalid}
                    placeholder={placeholder}
                    fullWidth={isFullWidth}
                    {...rest}
                    mb={0}
                    pr="4.5rem"
                />
                <InputRightElement width="4.5rem">
                    <IconButton
                        type="button"
                        size="sm"
                        rounded="lg"
                        fontSize="1.5rem"
                        onClick={onToggle}
                        icon={open ? IoIosEyeOff : IoIosEye}
                    />
                </InputRightElement>
            </InputGroup>
        </RHWrapper>
    );
};
