import React, { createContext, useContext } from "react";
import { IoMdArrowRoundForward } from "@react-icons/all-files/io/IoMdArrowRoundForward";
import { IoMdInformationCircleOutline } from "@react-icons/all-files/io/IoMdInformationCircleOutline";
import { IconType } from "@react-icons/all-files";

import { IconButton, NewIconButtonProps } from "Molecules";
import { Box, MotionDiv, BaseBoxProps } from "Atoms";

export const FlipCardFront: React.FC<BaseBoxProps> = ({ children, ...rest }) => {
    const { isFlipped } = useFlipCardContext();

    return (
        <>
            {!isFlipped && (
                <Box key={1} h="full" userSelect="none" {...rest}>
                    {children}
                </Box>
            )}
        </>
    );
};

export const FlipCardBack: React.FC<BaseBoxProps> = ({ children, ...rest }) => {
    const { isFlipped } = useFlipCardContext();

    return (
        <>
            {isFlipped && (
                <Box key={2} h="full" userSelect="none" transform="rotateY(180deg)" {...rest}>
                    {children}
                </Box>
            )}
        </>
    );
};

export const FlipCardContent: React.FC<BaseBoxProps> = ({ children, ...rest }) => <Box {...rest}>{children}</Box>;

export type FlipCardTriggerProps = {
    isFlippedIcon?: IconType;
    isNotFlippedIcon?: IconType;
} & NewIconButtonProps;

export const FlipCardTrigger: React.FC<FlipCardTriggerProps> = ({
    isFlippedIcon = IoMdArrowRoundForward,
    isNotFlippedIcon = IoMdInformationCircleOutline,
    ...rest
}) => {
    const { isFlipped, onFlip } = useFlipCardContext();

    return (
        <IconButton
            onClick={() => onFlip?.()}
            top="12px"
            left={isFlipped ? "12px" : undefined}
            right={!isFlipped ? "12px" : undefined}
            position="absolute"
            zIndex={1}
            w="fit-content"
            icon={isFlipped ? isFlippedIcon : isNotFlippedIcon}
            {...rest}
        />
    );
};

interface IFlipCardContext {
    isFlipped: boolean;
    onFlip?: () => void;
    shouldFlipOnCard?: boolean;
}

const FlipCardContext = createContext<IFlipCardContext>(null as any);
const useFlipCardContext = () => useContext(FlipCardContext);

const cardVariants = {
    selected: {
        rotateY: 180,
        opacity: [1, 0.1, 1],
        transition: { duration: 0.3, ease: "easeOut" }
    },
    notSelected: () => ({
        rotateY: 0,
        opacity: [1, 0.1, 1.01],
        transition: { duration: 0.3, ease: "easeOut" }
    })
};

export type FlipCardProps = {
    isFlipped: boolean;
    onFlip?: () => void;
    shouldFlipOnCard?: boolean;
} & BaseBoxProps;

export const FlipCard: React.FC<FlipCardProps> = ({
    isFlipped,
    onFlip,
    shouldFlipOnCard = false,
    children,
    ...rest
}) => {
    return (
        <FlipCardContext.Provider
            value={{
                isFlipped,
                onFlip,
                shouldFlipOnCard
            }}
        >
            <MotionDiv
                cursor="pointer"
                variants={cardVariants}
                initial={false}
                animate={isFlipped ? "selected" : "notSelected"}
                transition={{ opacity: 0 }}
                position="relative"
                onClick={() => {
                    shouldFlipOnCard && onFlip?.();
                }}
                {...rest}
            >
                {children}
            </MotionDiv>
        </FlipCardContext.Provider>
    );
};
