import { CartProduct, OrderProduct, PosOrderDTOInput, Table, TableMeta, OrderWindow, EatingOption } from "Types";
import {
    convertExtendedOrderProductsToOrderProducts,
    convertCartProductsToOrderProducts,
    changeAppliedDiscountIdsToOriginal
} from "Utils";
import { discountToDiscountMeta, setDiscountAndComboDiscountMeta } from "../paymentHelpers";

export const buildOrderInput = (
    isDriveThroughOrder: boolean,
    activeOrderWindow: OrderWindow,
    selectedShop: any,
    posId: string | undefined,
    dtExtendedOrderProducts: OrderProduct[] | null = null,
    postponePayment: any,
    puckNo: string | null,
    splitOrderNo: number | null,
    isInSplitMode: boolean,
    selectedTable: Table
): PosOrderDTOInput => {
    let orderWindow = activeOrderWindow;
    orderWindow = changeAppliedDiscountIdsToOriginal(orderWindow);
    const { cartProducts, takeAway } = orderWindow;
    const hasComboDiscounts = orderWindow.comboDiscounts?.length ?? false;
    let hasDiscountsInSplitMode = false;

    if (isInSplitMode) {
        hasDiscountsInSplitMode = cartProducts.some((cart: CartProduct) => {
            const { combinedDiscounts } = cart.orderProduct;
            return combinedDiscounts ? combinedDiscounts.length > 0 : false;
        });
    }

    let orderProducts = [];
    if (dtExtendedOrderProducts) {
        orderProducts = convertExtendedOrderProductsToOrderProducts(dtExtendedOrderProducts);
    } else {
        orderProducts = convertCartProductsToOrderProducts(cartProducts);
    }

    let discountMetaData = hasComboDiscounts
        ? setDiscountAndComboDiscountMeta(orderWindow)
        : discountToDiscountMeta(orderWindow);

    if (isInSplitMode && !hasDiscountsInSplitMode) {
        discountMetaData = null;
    }

    let tableMeta: TableMeta | null = null;
    if (selectedTable) {
        tableMeta = {
            id: selectedTable.id as string,
            shopId: selectedShop.id,
            name: selectedTable.name
        };
    }

    /** Check takeAway flag from active order window */
    const eatingOptionFromActiveOrderWindow = takeAway ? EatingOption.TAKE_AWAY : EatingOption.EAT_HERE;

    /** As the most common is not a drive through that check is done first
     * If it is a drive through order, then the eating option is always take away
     */
    const eatingOption = !isDriveThroughOrder ? eatingOptionFromActiveOrderWindow : EatingOption.TAKE_AWAY;

    return {
        postponePayment,
        driveThrough: isDriveThroughOrder,
        takeAway,
        orderProducts,
        shopId: selectedShop.id,
        posId,
        puckNo,
        discountMeta: discountMetaData,
        orderNo: splitOrderNo,
        splitPayment: isInSplitMode || false,
        tableMeta,
        eatingOption
    };
};
