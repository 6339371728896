import React from "react";
import { get } from "styled-system";

import { PseudoBox, PseudoBoxProps } from "../PseudoBox/PseudoBox";
import { useTheme } from "Providers";

type InputSizes = "sm" | "md" | "lg" | "xl";
type InputVariants = "default";

export interface IInput<T = HTMLInputElement> {
    isDisabled?: React.InputHTMLAttributes<T>["disabled"];
    isReadOnly?: React.InputHTMLAttributes<T>["readOnly"];
    isInvalid?: boolean;
    size?: InputSizes;
    variant?: InputVariants;
    as?: React.ElementType;
    fullWidth?: boolean;
    focusBorderColor?: string;
    errorBorderColor?: string;
}

type OmittedTypes = "size" | "width" | "disabled";

type InputHTMLAttributes = Omit<React.InputHTMLAttributes<HTMLInputElement>, OmittedTypes>;

export type InputProps<T = HTMLInputElement> = IInput<T> &
    InputHTMLAttributes &
    React.RefAttributes<T> &
    PseudoBoxProps;

const readOnly = {
    _readOnly: {
        bg: "transparent",
        boxShadow: "none !important",
        userSelect: "all"
    }
};

// todo: maybe add other styles?
const defaultProps = ({ theme, focusBorderColor, errorBorderColor }: any) => {
    const _focusBorderColor = get(theme.colors, focusBorderColor, focusBorderColor);
    const _errorBorderColor = get(theme.colors, errorBorderColor, errorBorderColor);

    return {
        ...readOnly,
        border: "1px",
        borderColor: "gray.200",
        borderStyle: "solid",
        background: "white",
        _hover: {
            borderColor: "gray.300"
        },
        _focus: {
            borderColor: _focusBorderColor,
            boxShadow: `0 0 0 1px ${_focusBorderColor}`,
            zIndex: 1
        },
        _disabled: {
            opacity: "0.4",
            cursor: "not-allowed"
        },
        _invalid: {
            borderColor: _errorBorderColor,
            boxShadow: `0 0 0 1px ${_errorBorderColor}`
        }
    };
};

const getVariantProps = (props: InputProps) => {
    switch (props.variant!) {
        case "default":
            return defaultProps(props);

        default:
            return {};
    }
};

const baseStyle = {
    display: "flex",
    alignItems: "center",
    transition: "all 0.25s",
    outline: "none",
    position: "relative",
    appearance: "none"
};

export const inputSizes = {
    xl: {
        fontSize: "3xl",
        px: 4,
        height: 16,
        lineHeight: "3.5rem",
        rounded: "lg"
    },
    lg: {
        fontSize: "lg",
        px: 4,
        height: 12,
        lineHeight: "3rem",
        rounded: "lg"
    },
    md: {
        fontSize: "md",
        px: 4,
        height: 10,
        lineHeight: "2.5rem",
        rounded: "md"
    },
    sm: {
        fontSize: "sm",
        px: 3,
        height: 8,
        lineHeight: "2rem",
        rounded: "sm"
    }
};

const getSizeProps = (size: InputSizes) => inputSizes[size];

type UseInputStyleProps = InputProps;

export const useInputStyle = (props: InputProps) => {
    const theme = useTheme();

    const _props = {
        ...props,
        theme
    };

    return {
        width: props.fullWidth ? "100%" : undefined,
        ...baseStyle,
        ...getSizeProps(_props.size!),
        ...getVariantProps(_props)
    };
};

export const Input: React.FC<InputProps> = React.forwardRef(
    (
        {
            as = "input",
            size = "md",
            variant = "default",
            focusBorderColor = "blue.500",
            errorBorderColor = "red.500",
            ...rest
        },
        ref
    ) => {
        const styles = useInputStyle({ ...rest, size, variant, focusBorderColor, errorBorderColor });

        return (
            <PseudoBox
                as={as}
                ref={ref}
                disabled={rest.isDisabled}
                aria-invalid={rest.isInvalid}
                readOnly={rest.isReadOnly}
                {...styles}
                {...rest}
            />
        );
    }
);
