import React, { useState } from "react";
import { Button, Checkbox, Grid, Icon, Modal } from "semantic-ui-react";
import { sortBy } from "lodash";

import { Addon, ProductIngredientGroups } from "Types";
import { Flex, Box, PseudoBox } from "Atoms";
import { SpringButton } from "TempUtils";
import { IModalContext, upsertAddons, AddonOperation, ThemeProvider } from "Providers";
import { isNegative } from "Utils";
import { getSelectedAddon, OnlineItemQuantityIndicator } from "OnlineComponents";
import { colors } from "../../../../theme/colors";

type Props = {
    addons: ProductIngredientGroups;
    preSelectedAddons: Addon[];
    outOfStockAddonNames: string[];
    onSubmit: (selectedAddons: any[]) => void;
    onDismiss: () => void;
    onClose?: () => void;
    posMode?: boolean;
};

export const ExpressAddonModal: React.FC<IModalContext<Props>> = ({
    modalContent: {
        addons: productAddonGroups,
        preSelectedAddons,
        outOfStockAddonNames,
        onSubmit,
        onDismiss,
        posMode,
        onClose
    }
}) => {
    const [selectedAddons, setSelectedAddons] = useState<Addon[]>(preSelectedAddons);
    const [countQuantity, setCountQuantity] = useState(0);

    if (!productAddonGroups) {
        return null;
    }

    const toggleAddon = (addon: Addon, operation: AddonOperation) => {
        setSelectedAddons(currAddons => upsertAddons(currAddons, addon, operation));
    };

    const handleCheckAddon = (
        isOutOfStock: boolean,
        selectedAddon: Addon | undefined,
        selectedAddonQuantity: number,
        ingredient: Addon,
        addonOperation: AddonOperation,
        isGroupLimit: boolean
    ) => {
        if (!isOutOfStock) {
            if (!!selectedAddon && isGroupLimit) {
                setCountQuantity((prevState: number) => prevState - selectedAddonQuantity);
                toggleAddon(ingredient, addonOperation);
            } else {
                if (isGroupLimit && !posMode) {
                    if (productAddonGroups[0].limit == 0 || countQuantity != productAddonGroups[0].limit) {
                        toggleAddon(ingredient, addonOperation);
                        setCountQuantity((prevState: number) => prevState + 1);
                    }
                } else {
                    toggleAddon(ingredient, addonOperation);
                }
            }
        }
    };

    const headerText = posMode ? "Extra tillval" : "Något extra till?";
    const clearButtonText = posMode ? "Töm" : "Nej tack";

    const sortedproductAddonGroups = sortBy(productAddonGroups, "sortOrder");

    return (
        <Modal open={true} basic size={"small"} id={"expressAddonModal"}>
            <Modal.Header>
                <Flex justify="space-between" width="100%">
                    <h1 style={{ margin: "auto" }}>{headerText}</h1>
                    {posMode && (
                        <SpringButton
                            style={{ backgroundColor: "transparent", border: "none" }}
                            customOnClick={() => {
                                if (onClose) onClose();
                            }}
                        >
                            <Icon
                                name="close"
                                size="large"
                                style={{ backgroundColor: "#da2828", color: "#FFF" }}
                                circular
                            />
                        </SpringButton>
                    )}
                </Flex>
            </Modal.Header>
            <Modal.Content scrolling>
                {sortedproductAddonGroups.map(productAddonGroup => {
                    const ingredientLimit = productAddonGroup.ingredients.filter(item => item.limit > 0);
                    const isGroupLimit = ingredientLimit.length == 0;
                    const addons = productAddonGroup.ingredients
                        .filter(addonGroup => !outOfStockAddonNames.includes(addonGroup.ingredient.name.toLowerCase()))
                        .concat(
                            productAddonGroup.ingredients.filter(addonGroup =>
                                outOfStockAddonNames.includes(addonGroup.ingredient.name.toLowerCase())
                            )
                        );
                    return (
                        <PseudoBox key={productAddonGroup.name + productAddonGroup.sortOrder} mb={"1em"}>
                            {addons.map((addonGroup, idx) => {
                                const ingredient = addonGroup.ingredient;
                                const mathSign = ingredient.price >= 0 ? "+" : "";
                                const checked =
                                    selectedAddons &&
                                    selectedAddons.some(
                                        selectedAddon =>
                                            selectedAddon.groupId === ingredient.groupId &&
                                            selectedAddon.name === ingredient.name
                                    );
                                const addonOperation = checked ? AddonOperation.RESET : AddonOperation.ADD;
                                const selectedAddon = getSelectedAddon(ingredient, selectedAddons);
                                const selectedAddonQuantity = selectedAddon ? selectedAddon.quantity : 0;
                                const isIncrementDisabled =
                                    (!posMode && selectedAddonQuantity === addonGroup.limit) ||
                                    isNegative(ingredient.price);
                                const isIncrementDisabledForGroup =
                                    isGroupLimit &&
                                    !posMode &&
                                    productAddonGroup.limit != 0 &&
                                    countQuantity == productAddonGroup.limit;

                                const isOutOfStock = outOfStockAddonNames.includes(ingredient.name.toLowerCase());

                                return (
                                    <>
                                        {/** @ts-ignore */}
                                        <Grid key={idx} columns={3}>
                                            <Grid.Row style={{ paddingTop: "0.2em", paddingBottom: "0.2em" }}>
                                                <Grid.Column width={7}>
                                                    <Box
                                                        onClick={() => {
                                                            handleCheckAddon(
                                                                isOutOfStock,
                                                                selectedAddon,
                                                                selectedAddonQuantity,
                                                                ingredient,
                                                                addonOperation,
                                                                isGroupLimit
                                                            );
                                                        }}
                                                    >
                                                        <Checkbox
                                                            className={"addon-checkbox"}
                                                            checked={!!selectedAddon}
                                                            disabled={isOutOfStock}
                                                        />
                                                        <span
                                                            style={{
                                                                paddingLeft: "1em",
                                                                fontSize: "1.8em",
                                                                textDecoration: isOutOfStock ? "line-through" : "none"
                                                            }}
                                                        >
                                                            {ingredient.name}
                                                        </span>
                                                        {isOutOfStock && (
                                                            <span
                                                                style={{
                                                                    color: colors.gray["400"],
                                                                    paddingLeft: "0.5em",
                                                                    fontSize: "1.8em"
                                                                }}
                                                            >
                                                                Slutsålt
                                                            </span>
                                                        )}
                                                    </Box>
                                                </Grid.Column>
                                                <Grid.Column width={3}>
                                                    {checked && (
                                                        <ThemeProvider>
                                                            <span style={{ fontSize: "1.8em" }}>
                                                                <OnlineItemQuantityIndicator
                                                                    hasOnlineCtx={false}
                                                                    quantity={selectedAddonQuantity}
                                                                    add={{
                                                                        function: () => {
                                                                            setSelectedAddons(currAddons =>
                                                                                upsertAddons(
                                                                                    currAddons,
                                                                                    ingredient,
                                                                                    AddonOperation.INCREMENT
                                                                                )
                                                                            );
                                                                            isGroupLimit &&
                                                                                setCountQuantity(
                                                                                    (prevState: number) => prevState + 1
                                                                                );
                                                                        },
                                                                        isDisabled:
                                                                            isIncrementDisabled ||
                                                                            isIncrementDisabledForGroup
                                                                    }}
                                                                    decrement={{
                                                                        function: () => {
                                                                            if (selectedAddonQuantity === 1) {
                                                                                setSelectedAddons(currAddons =>
                                                                                    upsertAddons(
                                                                                        currAddons,
                                                                                        ingredient,
                                                                                        AddonOperation.RESET
                                                                                    )
                                                                                );
                                                                            }
                                                                            isGroupLimit &&
                                                                                setCountQuantity(
                                                                                    (prevState: number) => prevState - 1
                                                                                );
                                                                            setSelectedAddons(currAddons =>
                                                                                upsertAddons(
                                                                                    currAddons,
                                                                                    ingredient,
                                                                                    AddonOperation.DECREMENT
                                                                                )
                                                                            );
                                                                        },
                                                                        isDisabled: !selectedAddonQuantity
                                                                    }}
                                                                />
                                                            </span>
                                                        </ThemeProvider>
                                                    )}
                                                </Grid.Column>
                                                <Grid.Column textAlign="right" width={6}>
                                                    <span style={{ fontSize: "1.8em" }} className="notranslate">
                                                        {mathSign} {ingredient.price} kr
                                                    </span>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </>
                                );
                            })}
                        </PseudoBox>
                    );
                })}
            </Modal.Content>
            <Modal.Actions>
                <Button
                    onClick={onDismiss}
                    content={clearButtonText}
                    size="huge"
                    style={{
                        color: "#fff",
                        backgroundColor: "#F19143"
                    }}
                    primary
                />
                <Button
                    style={{
                        color: "#fff",
                        backgroundColor: "#89cb9c"
                    }}
                    disabled={selectedAddons && selectedAddons.length === 0}
                    primary
                    size="huge"
                    onClick={() => onSubmit(selectedAddons)}
                    content={"Lägg till"}
                />
            </Modal.Actions>
        </Modal>
    );
};
