import React, { useEffect } from "react";
import { pickBy } from "lodash";

import { IModalContext, modals } from "Providers";
import { Modal, ModalHeader, ModalBody } from "Molecules";
import { PosModificationsModalList } from "./components";
import { Flex, Header } from "Atoms";
import { useModifications } from "Hooks";
import { PosModalCloseBtn } from "../../../pos/components/shared";

type Props = {
    productName: string;
    modalCallback: Function;
    modificationsToUse: any;
    isFromBundle: boolean;
};

export const PosModificationsModal: React.FC<IModalContext<Props>> = ({ modalContent, closeModal }) => {
    const { productName, modalCallback, modificationsToUse, isFromBundle } = modalContent;

    const {
        onSelectModification: handleSelectedModification,
        modificationsArray,
        selectedModifications,
        allAreSelected
    } = useModifications(modificationsToUse, {});

    const onCloseModal = () => closeModal(modals.posModificationsModal);

    useEffect(() => {
        if (allAreSelected) {
            const nonNullModifications = pickBy(
                selectedModifications,
                selectedModification => selectedModification !== null
            );
            modalCallback(nonNullModifications);
            onCloseModal();
        }
    }, [allAreSelected]);

    return (
        <Modal open onClose={onCloseModal} size="xl">
            <ModalHeader>
                <Flex align="center" justify="space-between">
                    <Header as="h1" size="xl" m="0">
                        Anpassa {productName}
                    </Header>
                    <PosModalCloseBtn closeModal={onCloseModal} />
                </Flex>
            </ModalHeader>
            <ModalBody>
                <PosModificationsModalList
                    isFromBundle={isFromBundle}
                    modifications={modificationsArray}
                    onSelectModification={handleSelectedModification}
                    selectedModifications={selectedModifications}
                />
            </ModalBody>
        </Modal>
    );
};
