export const typography = {
    fontSizes: {
        xs: "0.75rem",
        sm: "0.875rem",
        md: "1rem",
        lg: "1.125rem",
        xl: "1.25rem",
        "2xl": "1.5rem",
        "3xl": "1.875rem",
        "4xl": "2.25rem",
        "5xl": "3rem",
        "6xl": "4rem"
    },
    fontWeights: {
        thin: 100,
        light: 300,
        normal: 400,
        bold: 700,
        black: 900
    },
    lineHeights: {
        normal: "normal",
        none: "1",
        shorter: "1.25",
        short: "1.375",
        base: "1.5",
        tall: "1.625",
        taller: "2"
    },
    fonts: {
        body: "'Lato', sans-serif",
        heading: "'Lato', sans-serif",
        qopla: "qopla",
        giftcard: "giftcard"
    }
};
