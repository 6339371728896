import React from "react";
import { AiOutlineUser } from "@react-icons/all-files/ai/AiOutlineUser";
import { useMedia } from "react-media";
import { useLocation, useNavigate } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";

import { Flex, Header, Button, Avatar, Stack, Image, Box } from "Atoms";
import { LinkButton } from "Molecules";
import { LanguagePicker } from "Components";
import { useDrawer, useLanguage, useAuthUser, UserAccount } from "Providers";
import { isSubscriptionUrl, navigateToAccountRoute } from "Utils";
import { SantaHat } from "Assets";

type LoggedInContentProps = {
    isSubscriptionUrl: boolean;
    isOnAccountPage: boolean;
    userAccount: UserAccount;
};

const LoggedInContent: React.FC<LoggedInContentProps> = ({ isSubscriptionUrl, isOnAccountPage, userAccount }) => {
    const { logoutUserAccount } = useAuthUser();
    const { translate } = useLanguage();
    const navigate = useNavigate();
    const contactInformation = userAccount.contactInformation;
    const hasName = contactInformation.name || contactInformation.lastName;
    const userAccountImageUrl = userAccount.imageUrl;

    const name = hasName ? `${contactInformation.name} ${contactInformation.lastName}` : "";

    if (isOnAccountPage) {
        return (
            <Stack isInline stretch={4} alignItems="center">
                <LanguagePicker />
                <Dropdown
                    trigger={
                        <Button p="1" rounded="full" width={"3rem"} height={"3rem"}>
                            <Avatar name={name} src={userAccountImageUrl} />
                        </Button>
                    }
                    size="lg"
                    rounded="full"
                    icon={null}
                    pointing="right"
                >
                    <Dropdown.Menu>
                        <Dropdown.Header
                            content={
                                <Button
                                    fullWidth
                                    onClick={() => {
                                        logoutUserAccount();
                                        navigate("/login");
                                    }}
                                >
                                    {translate("logout")}
                                </Button>
                            }
                        />
                    </Dropdown.Menu>
                </Dropdown>
            </Stack>
        );
    }

    // Not on account page
    return (
        <Stack isInline stretch={4}>
            <LanguagePicker />
            <Button
                p="0"
                bg="transparent"
                rounded="full"
                onClick={() => {
                    navigateToAccountRoute(isSubscriptionUrl);
                }}
            >
                <Avatar name={name} />
            </Button>
        </Stack>
    );
};

export const CustomerNavbar = () => {
    const { userAccount } = useAuthUser();
    const { translate } = useLanguage();
    const isSmallScreen = useMedia({ query: "(max-width: 991px)" });

    const { onOpenDrawer } = useDrawer();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    
    // URL Tests
    const isOnLoginPage = pathname.includes("login");
    const isOnAccountPage = pathname.includes("account");
    const _isSubscriptionUrl = isSubscriptionUrl(location.hostname);

    const onOpenCustomerDrawer = () => {
        if (!isOnLoginPage) {
            onOpenDrawer("customerDrawer", {}, { navigate: navigate });
        }
    };
    const shouldPutUpSantaHat = new Date().getMonth() === 11;
    return (
        <Flex
            as="nav"
            align="center"
            justify="space-between"
            wrap="wrap"
            px={[4, 8]}
            py={4}
            color="black"
            h="auto"
            borderBottomWidth="1px"
            borderBottomStyle="solid"
            borderBottomColor="gray.200"
        >
            <Flex align="center" mr={5}>
                <Box position="relative">
                    {shouldPutUpSantaHat && (
                        <Image
                            maxW="24px"
                            left={{ base: "-12px", md: "-11px" }}
                            top={{ base: "-7px", md: "-6px" }}
                            position="absolute"
                            src={SantaHat}
                        />
                    )}
                    <Header m={0} as="h1" size="xl" color="newPrimary" fontFamily="qopla" textAlign="center">
                        Qopla
                    </Header>
                </Box>
            </Flex>
            <Flex h="auto">
                {userAccount ? (
                    // Logged in
                    <LoggedInContent
                        isSubscriptionUrl={_isSubscriptionUrl}
                        userAccount={userAccount}
                        isOnAccountPage={isOnAccountPage}
                    />
                ) : isSmallScreen ? (
                    // Logged out  & small
                    <Stack isInline stretch={4}>
                        <LanguagePicker />
                        <Button rightIcon={AiOutlineUser} onClick={onOpenCustomerDrawer}>
                            {translate("login")}
                        </Button>
                    </Stack>
                ) : (
                    // Logged out  & big
                    <Stack isInline stretch={4}>
                        <LanguagePicker />
                        <Button onClick={onOpenCustomerDrawer} variant="ghost">
                            {translate("login")}
                        </Button>
                        <LinkButton to="/signup" color="black" borderColor="black" variant="outline">
                            {translate("createAccount")}
                        </LinkButton>
                    </Stack>
                )}
            </Flex>
        </Flex>
    );
};
