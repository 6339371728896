import { createTrackedSelector } from "react-tracked";
import create from "zustand";
import { omit } from "lodash";

import {
    DTInfo,
    MenuProductCategory,
    PaymentMethod,
    PaymentTab,
    PaymentTabs,
    RefProduct,
    SelectedPos,
    SelectedTerminal,
    StringOrNull
} from "Types";
import { Terminal } from "Types";
import { addNewPaymentTab, createAddonsHashMap, getDisplayText, updatePaymentTab } from "Providers";
import { AddonsHashMap, AddonsHashMapInput } from "src/providers/pos/utils/createAddonsHashMap";
import { persistToStorage } from "Utils";
import { paymentTabsHeaderTexts, paymentTabStatusTypes, PersistConstants, SPLIT_TYPE, PosTypes, persistedDefaultValues as defaultValues } from "Constants";
import { qoplaStore, orderWindowsStore } from "Stores";
import { errorNotification, successNotification } from "TempUtils";
import { CREATE_BONG_FROM_ORDERS } from "GraphQLMutations";
import { newMothershipApolloClient } from "../graphql/clients";
import { wrapZustandStoreWithDevToolsIfNotProd } from "./storeUtils";

type QueuedOrderDetails = {
    orderIds: Set<string>;
    orderNo: string | null;
    puckNo: string | null;
};

const getPosType = (pos: SelectedPos | null): PosTypes | null => {
    if (!pos?.id) {
        return null;
    } else if (!!pos.expressPOS) {
        return PosTypes.EXPRESS;
    } else {
        return PosTypes.POS;
    }
};
export type PosStore = {
    posTerminals: Terminal[];
    setPosTerminals: (posTerminals: Terminal[]) => void;
    selectedTerminal: SelectedTerminal | null;
    setSelectedTerminal: (selectedTerminal: SelectedTerminal | null) => void;
    selectedPos: SelectedPos | null;
    setSelectedPos: (selectedPos: SelectedPos | null) => void;
    selectedPosType: PosTypes | null;
    setSelectedPosType: (selectedPosType: PosTypes | null) => void;
    activePaymentTabs: PaymentTabs;
    setActivePaymentTabs: (activePaymentTabs: PaymentTabs) => void;
    getActivePaymentTab: (paymentTabId: string) => PaymentTab;
    queueHasStarted: boolean;
    setQueueHasStarted: (queueHasStarted: boolean) => void;
    queuedOrders: QueuedOrderDetails;
    setQueuedOrders: (queuedOrders: QueuedOrderDetails) => void;
    handleQueueOrders: () => void;
    updateQueuedOrdersList: (orderId: StringOrNull, orderNo: StringOrNull, puckNo: StringOrNull) => void;
    addActivePaymentTab: (
        newActivePaymentTabId: string,
        paymentMethod: PaymentMethod,
        newStatus: string,
        isDriveThrough: boolean,
        DTInfo: DTInfo,
        terminalId: string,
        postponePayment: boolean
    ) => void;
    saveAndClearActivePaymentTab: (activePaymentTabIds: string) => void;
    handleSetActivePaymentTabs: (activePaymentTabs: PaymentTabs, updatedPaymentTab: PaymentTab) => void;
    updateActivePaymentTabStatus: (
        paymentTabId: string,
        operationSuccess: boolean,
        newDisplayHeaderText: string | null,
        newDisplayText: string | null,
        newStatus: string
    ) => void;

    refProducts: { [id: string]: RefProduct };
    setRefProducts: (refProducts: { [id: string]: RefProduct }) => void;
    outOfStockIds: Set<string>;
    setOutOfStockIds: (outOfStockIds: Set<string>) => void;
    activeCategory: MenuProductCategory | null;
    setActiveCategory: (activeCategory: MenuProductCategory, shouldClearSelectedBundleProduct?: boolean) => void;
    hideFromStockIds: Set<string>;
    setHideFromStockIds: (hideFromStockIds: Set<string>) => void;
    activeTerminalIds: string[];
    setActiveTerminalIds: (activeTerminalIds: string[]) => void;
    posZoomLevel: number;
    setPosZoomLevel: (posZoomLevel: number) => void;

    addons: AddonsHashMap;
    setAddons: (addons: AddonsHashMap) => void;
    handleSetAddons: (addons: AddonsHashMapInput[]) => void;
    selectedActiveMenuId: string | null;
    setSelectedActiveMenuId: (selectedActiveMenuId: string) => void;
    // posZoomLevelStored: number;
    // setPosZoomLevelStored: (posZoomLevelStored: number) => void;
    hasNoMenus: boolean;
    setHasNoMenus: (hasNoMenus: boolean) => void;
    shouldPollFromPos: boolean;
    setShouldPollFromPos: (shouldPollFromPos: boolean) => void;
};

export const posStore = create<PosStore>(
    wrapZustandStoreWithDevToolsIfNotProd((set, get) => ({
        posTerminals: [],
        setPosTerminals: (posTerminals: Terminal[]) =>
            set(() => ({
                posTerminals
            })),
        selectedTerminal: defaultValues.selectedTerminal as SelectedTerminal,
        setSelectedTerminal: (selectedTerminal: SelectedTerminal | null) =>
            set(() => {
                persistToStorage(PersistConstants.SELECTED_TERMINAL, selectedTerminal);
                return { selectedTerminal };
            }),
        selectedPos: defaultValues.selectedPos as SelectedPos,
        setSelectedPos: (selectedPos: SelectedPos | null) =>
            set(() => {
                persistToStorage(PersistConstants.SELECTED_POS, selectedPos);
                get().setSelectedPosType(getPosType(selectedPos));
                return { selectedPos };
            }),
        selectedPosType: PosTypes.POS,
        setSelectedPosType: (selectedPosType: PosTypes | null) =>
            set(() => {
                persistToStorage(PersistConstants.PAIRED_POS_TYPE, selectedPosType);
                return { selectedPosType };
            }),
        activePaymentTabs: {},
        setActivePaymentTabs: (activePaymentTabs: PaymentTabs) =>
            set(() => ({
                activePaymentTabs
            })),
        getActivePaymentTab: (paymentTabId: string) => get().activePaymentTabs[paymentTabId],
        queueHasStarted: false,
        setQueueHasStarted: (queueHasStarted: boolean) => set(() => ({ queueHasStarted })),
        queuedOrders: { orderIds: new Set(), orderNo: null, puckNo: "" } as QueuedOrderDetails,
        setQueuedOrders: (queuedOrders: QueuedOrderDetails) => set(() => ({ queuedOrders })),
        handleQueueOrders: async () => {
            const { setQueueHasStarted, queuedOrders, setQueuedOrders } = get();
            const { selectedShop } = qoplaStore.getState();
            setQueueHasStarted(!get().queueHasStarted);
            if (!!selectedShop?.id && queuedOrders.orderIds.size > 0) {
                const { data } = await newMothershipApolloClient.mutate({
                    mutation: CREATE_BONG_FROM_ORDERS,
                    variables: {
                        orderIds: [...queuedOrders.orderIds],
                        shopId: selectedShop?.id,
                        splitType: SPLIT_TYPE.DISHES,
                        puckNo: queuedOrders.puckNo
                    }
                });
                if (data && data.createBongFromOrders) {
                    successNotification(`Bong nr. ${queuedOrders.orderNo} skickad till köket`, 1400);
                    setQueuedOrders({ orderIds: new Set([]), orderNo: null, puckNo: null });
                } else {
                    errorNotification("Något gick snett", "Försök igen", 1400);
                }
            }
        },
        updateQueuedOrdersList: (orderId: StringOrNull, orderNo: StringOrNull, puckNo: StringOrNull) =>
            set(() => {
                const { queuedOrders, setQueuedOrders } = get();
                const updatedOrders = {
                    orderIds: new Set([...queuedOrders.orderIds, orderId]),
                    orderNo: queuedOrders.orderNo ? queuedOrders.orderNo : orderNo,
                    puckNo: puckNo ? puckNo : queuedOrders.puckNo
                } as QueuedOrderDetails;
                setQueuedOrders(updatedOrders);
            }),
        saveAndClearActivePaymentTab: (activePaymentTabId: string) => {
            const cloned = { ...get().activePaymentTabs };
            const filteredActivePaymentTabs = omit(cloned, activePaymentTabId);
            persistToStorage(PersistConstants.ACTIVE_PAYMENT_TABS, filteredActivePaymentTabs);
            get().setActivePaymentTabs(filteredActivePaymentTabs);
        },
        addActivePaymentTab: (
            newActivePaymentTabId: string,
            paymentMethod: PaymentMethod,
            newStatus: string,
            isDriveThrough: boolean,
            DTInfo: DTInfo,
            terminalId: string,
            postponePayment: boolean
        ) => {
            const activePaymentTabAlreadyExists = get().activePaymentTabs[newActivePaymentTabId];
            let newPaymentTab;

            const props = {
                status: newStatus,
                paymentMethod,
                terminalId,
                postponePayment
            };

            // check if paymentwindow already exists, used for DT_ONGOING
            if (activePaymentTabAlreadyExists) {
                newPaymentTab = updatePaymentTab(activePaymentTabAlreadyExists, props);
            } else {
                const newActivePaymentTab = {
                    ...addNewPaymentTab(props, newActivePaymentTabId),
                    displayText: getDisplayText(isDriveThrough, DTInfo, paymentMethod),
                    displayHeaderText: isDriveThrough && DTInfo ? "Order lagd" : "",
                    driveThrough: isDriveThrough,
                    DTInfo
                };
                newPaymentTab = newActivePaymentTab;
            }
            const updatedActivePaymentTabs = {
                ...get().activePaymentTabs,
                [newPaymentTab.id]: newPaymentTab
            } as PaymentTabs;
            get().setActivePaymentTabs(updatedActivePaymentTabs);
        },
        handleSetActivePaymentTabs: (activePaymentTabs: PaymentTabs, updatedPaymentTab: PaymentTab) => {
            if (paymentTabStatusTypes.DT_ONGOING === updatedPaymentTab.status) {
                persistToStorage(PersistConstants.ACTIVE_PAYMENT_TABS, {
                    ...activePaymentTabs,
                    [updatedPaymentTab.id]: updatedPaymentTab
                });
            }
            const updatedPaymentTabs = {
                ...get().activePaymentTabs,
                [updatedPaymentTab.id]: updatedPaymentTab
            };
            get().setActivePaymentTabs(updatedPaymentTabs);
        },
        updateActivePaymentTabStatus: (
            paymentTabId: string,
            operationSuccess: boolean,
            newDisplayHeaderText: string | null,
            newDisplayText: string | null,
            newStatus: string
        ) => {
            const activePaymentTab = get().activePaymentTabs[paymentTabId];

            // ATTENTION: Stop here to appreciate this beauty ;)
            // used to prevent canceltext when cancel is pressed after approved
            if (
                !operationSuccess &&
                newDisplayHeaderText === paymentTabsHeaderTexts.CANCELLED &&
                activePaymentTab &&
                activePaymentTab.status === paymentTabStatusTypes.APPROVED
            ) {
                return;
            }

            const updatedPaymentTab = {
                ...activePaymentTab,
                id: paymentTabId,
                displayHeaderText: newDisplayHeaderText,
                displayText: newDisplayText,
                status: newStatus
            } as PaymentTab;
            return get().handleSetActivePaymentTabs(get().activePaymentTabs, updatedPaymentTab);
        },
        refProducts: {},
        setRefProducts: (refProducts: { [id: string]: RefProduct }) => set(() => ({ refProducts })),
        outOfStockIds: new Set<string>(),
        setOutOfStockIds: (outOfStockIds: Set<string>) => set(() => ({ outOfStockIds: new Set(outOfStockIds) })),
        activeCategory: null,
        setActiveCategory: (activeCategory: MenuProductCategory, shouldClearSelectedBundleProduct: boolean = true) =>
            set(() => {
                // Nasty.
                shouldClearSelectedBundleProduct && orderWindowsStore.getState().setSelectedBundleCartProduct(null);
                return { activeCategory };
            }),
        hideFromStockIds: new Set<string>(),
        setHideFromStockIds: (hideFromStockIds: Set<string>) => set(() => ({ hideFromStockIds })),
        activeTerminalIds: [],
        setActiveTerminalIds: (activeTerminalIds: string[]) => set(() => ({ activeTerminalIds })),
        posZoomLevel: 0,
        setPosZoomLevel: (posZoomLevel: number) => set(() => ({ posZoomLevel })),
        addons: {},
        setAddons: (addons: AddonsHashMap) => set(() => ({ addons })),
        handleSetAddons: (addons: AddonsHashMapInput[]) => get().setAddons(createAddonsHashMap(addons)),
        selectedActiveMenuId: null,
        setSelectedActiveMenuId: (selectedActiveMenuId: string) => set(() => ({ selectedActiveMenuId })),

        // posZoomLevelStored: getZoomLevel(),
        // posZoomLevelStored: getLocalStorageItem(LOCAL_STORAGE_CONSTANTS.POS_ZOOM_LEVEL),
        // setPosZoomLevelStored: (posZoomLevelStored: number) => set(() => ({ posZoomLevelStored }))
        hasNoMenus: false,
        setHasNoMenus: (hasNoMenus: boolean) => set(() => ({ hasNoMenus })),
        /** Here will prevent polling on order windows for shops that only have one paired pos */
        shouldPollFromPos: false,
        setShouldPollFromPos: (hasMultiplePos: boolean) => set(() => ({ shouldPollFromPos: hasMultiplePos }))
    }))
);

export const usePosStore = createTrackedSelector(posStore);
