import React, { useContext, useState, useMemo, useEffect, memo, PropsWithChildren, ReactNode } from "react";
import { useMedia } from "react-media";
import { isEqual } from "lodash";
import { HiOutlineSearch } from "@react-icons/all-files/hi/HiOutlineSearch";
import { HiOutlineChevronLeft } from "@react-icons/all-files/hi/HiOutlineChevronLeft";
import { HiOutlineChevronRight } from "@react-icons/all-files/hi/HiOutlineChevronRight";
import { ImCross } from "@react-icons/all-files/im/ImCross";

import { BaseBoxProps, Box, Button, ButtonProps, Flex, NewGrid as Grid, Input, PseudoBox } from "Atoms";
import { IconButton, InputGroup, InputLeftElement, InputRightElement, Select, NewIconButtonProps } from "Molecules";

import { GLOBAL_BREAK_POINTS } from "Constants";
import { Languagekey } from "Providers";
import { PaginationFilterOption } from "Organisms";

interface IPagination {
    currentPage: number;
    itemsPerPage: number;
    maxPage: number;
    currentData: any[] | NullUndefined;
    allowSearch: boolean;
    allowSort: boolean;
    customSearch: boolean;
    sortOptions?: PaginationSortOptions[];
    sortingBy: PaginationSortOptions | NullUndefined;
    reIndex: number;
    searchQuery: string | null;
    customFilter: PaginationFilterOption<any> | NullUndefined;
    setCustomFilter: (filter: PaginationFilterOption<any>) => void;
    setSearchQuery: React.Dispatch<React.SetStateAction<string | null>>;
    setSortingBy: React.Dispatch<React.SetStateAction<PaginationSortOptions | null>>;
    setSearchProps: React.Dispatch<React.SetStateAction<string[] | NullUndefined>>;
    updateRowByProp: <T, Prop extends keyof T>(value: T, prop: Prop) => void;
    next: () => void;
    prev: () => void;
    goToPage: (page: number) => void;
}

export const PaginationContext = React.createContext<IPagination>(null as any);

type PaginationProps = {
    items: any[];
    numberPerPage: number;
    search?: string[] | null;
    sort?: SortOptions[] | NullUndefined;
    customSearch?: boolean;
    defaultSortDirection?: DIRECTION;
} & PropsWithChildren;

/**
 * Sort options default = DESC
 */
type SortOptions = {
    text: string;
    value: string;
    default: boolean;
    label?: string;
    translationKey?: Languagekey;
    sortFunction: (itemA: any, itemB: any) => number;
};

enum DIRECTION {
    ASC = "ASC",
    DESC = "DESC",
    NONE = "NONE"
}

type PaginationSortOptions = {
    sortingDirection?: DIRECTION;
    sortingOrder?: number;
} & SortOptions;

type NullUndefined = null | undefined;

/**
 * Pagination context provider
 * @param {Array} items sending in items any type
 * @param {number} numberPerPage number of items shown per page
 * @param {string[]} search string array for props on items to search
 * @param {SortOptions[]} sort options to sort by text & value
 * @returns provider values with children
 */
const Pagination: React.FC<PaginationProps> = ({
    items,
    numberPerPage,
    search,
    sort,
    customSearch,
    defaultSortDirection = DIRECTION.DESC,
    children
}) => {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [searchProps, setSearchProps] = useState<string[] | NullUndefined>(search);
    const [searchQuery, setSearchQuery] = useState<string | null>(null);
    const [sortOptions, setSortOptions] = useState<PaginationSortOptions[] | NullUndefined>([]);
    const [sortingBy, setSortingBy] = useState<PaginationSortOptions | null>(null);
    const [reIndex, setReIndex] = useState<number>(0);
    const [originalData, setOriginalData] = useState<any[]>(items);
    const [currentData, setCurrentData] = useState<any[] | NullUndefined>(originalData);
    const [itemsPerPage, setItemsPerPage] = useState<number>(numberPerPage);
    const [maxPage, setMaxPage] = useState<number>(0);
    const [customFilter, setCustomFilter] = useState<PaginationFilterOption<any> | NullUndefined>(null);

    const createSortingOptions = () => {
        if (sort) {
            const paginationSort = sort?.reduce((sortingOptions: PaginationSortOptions[], value: SortOptions) => {
                const createTwo: PaginationSortOptions[] = [
                    {
                        ...value,
                        sortingDirection: DIRECTION.ASC,
                        text: value.text + " Stigande",
                        default: value.default && defaultSortDirection === DIRECTION.ASC
                    },
                    {
                        ...value,
                        sortingDirection: DIRECTION.DESC,
                        text: value.text + " Fallande",
                        default: value.default && defaultSortDirection === DIRECTION.DESC
                    }
                ];
                return [...sortingOptions, ...createTwo];
            }, []);
            const defaultSort =
                paginationSort?.find((value: PaginationSortOptions) => value.default) ?? paginationSort[0];

            setSortingBy(defaultSort);
            setSortOptions(paginationSort);
        }
    };

    const sortingFunction = (data: any[]) => {
        if (sortingBy) {
            return data.sort((valueA: any, valueB: any) => {
                if (sortingBy.sortingDirection === DIRECTION.ASC) {
                    return sortingBy.sortFunction(valueA, valueB);
                } else {
                    return sortingBy.sortFunction(valueB, valueA);
                }
            });
        }
        return data;
    };

    const onSortingItems = () => {
        if (sortingBy) {
            const itemsSorted = sortingFunction(originalData);
            setOriginalData(itemsSorted);
            setReIndex(prev => prev + 1);
        }
    };

    const filterQuery = (reset: boolean, updated: boolean = false) => {
        if (reset) {
            const resetData = !updated ? originalData : items;
            setCurrentData(sortingFunction(resetData));
            setReIndex(prev => prev + 1);
            setCurrentPage(1);
        } else {
            let filteredData: any[];
            if (updated) {
                filteredData = items.filter((value: any) => {
                    return (
                        searchProps?.some((prop: string) => value[prop]?.toUpperCase()?.includes(searchQuery)) ?? false
                    );
                });
            } else {
                filteredData = originalData.filter((value: any) => {
                    return (
                        searchProps?.some((prop: string) => value[prop]?.toUpperCase()?.includes(searchQuery)) ?? false
                    );
                });
                setCurrentPage(1);
            }
            if (sortingBy) {
                filteredData = sortingFunction(filteredData);
            }
            setCurrentData(filteredData);
            setReIndex(prev => prev + 1);
        }
    };

    const filterDataByCustomFilter = (filter: PaginationFilterOption<any>) => {
        setCustomFilter(filter);
        if (filter?.isOrginal) {
            setCurrentData(sortingFunction(originalData));
            setReIndex(prev => prev + 1);
            setCurrentPage(1);
        } else {
            let filteredData = items?.filter(filter.filter);
            if (sortingBy) {
                filteredData = sortingFunction(filteredData);
            }
            setCurrentData(filteredData);
            setReIndex(prev => prev + 1);
            setCurrentPage(1);
        }
    };

    const updateData = <T, Prop extends keyof T>(dataSet: T[], value: T, prop: Prop) => {
        return dataSet?.map(curr => {
            if (curr[prop] === value[prop]) {
                return value;
            }
            return curr;
        });
    };

    const updateRowByProp = <T, Prop extends keyof T>(value: T, prop: Prop) => {
        const originalDataUpdate = updateData<T, Prop>(originalData, value, prop);
        setOriginalData(originalDataUpdate!);

        let filteredData: T[] = [];
        if (searchQuery) {
            filteredData = originalDataUpdate.filter((value: any) => {
                return (
                    searchProps?.some((prop: string) => value[prop]?.toUpperCase()?.startsWith(searchQuery)) ?? false
                );
            });
            if (sortingBy) {
                filteredData = sortingFunction(filteredData);
            }
        }
        setCurrentData(filteredData.length > 0 ? filteredData : originalDataUpdate);
        setReIndex(prev => prev + 1);
    };

    useEffect(() => {
        createSortingOptions();
    }, []);

    useEffect(() => {
        if (sortingBy) {
            onSortingItems();
            if (searchQuery) {
                filterQuery(!searchQuery);
            }
        }
    }, [sortingBy]);

    useEffect(() => {
        filterQuery(!searchQuery);
    }, [searchQuery]);

    useEffect(() => {
        if (currentData) {
            const calculateMaxPage = Math.ceil(currentData?.length / numberPerPage);
            setMaxPage(calculateMaxPage);
            if (numberPerPage !== itemsPerPage) {
                setItemsPerPage(numberPerPage);
                setReIndex(prev => prev + 1);
                setCurrentPage(1);
            }
        }
    }, [currentData, numberPerPage]);

    /** if anything it is added to list or deleted makes sure it re-indexes
     * if search is active it will appear on the page or be removed
     */
    useEffect(() => {
        if (items.length !== 0 && items.length !== originalData.length) {
            setOriginalData(!searchQuery ? sortingFunction(items) : items);
            filterQuery(!searchQuery, true);
        } else if (items.length !== 0 && items.length === originalData.length) {
            if (!isEqual(items, originalData)) {
                setOriginalData(!searchQuery ? sortingFunction(items) : items);
                filterQuery(!searchQuery, true);
            }
        }
    }, [items]);

    const next = () => {
        const newCurrentPage = Math.min(currentPage + 1, maxPage);
        setCurrentPage(newCurrentPage);
    };

    const prev = () => {
        const newCurrentPage = Math.max(currentPage - 1, 1);
        setCurrentPage(newCurrentPage);
    };

    const goToPage = (page: number) => {
        const pageNumber = Math.max(1, page);
        const newCurrentPage = Math.min(pageNumber, maxPage);
        setCurrentPage(newCurrentPage);
    };

    return (
        <PaginationContext.Provider
            value={{
                currentPage,
                maxPage,
                itemsPerPage,
                currentData,
                allowSearch: !!searchProps,
                allowSort: !!sort,
                sortOptions: sortOptions ? sortOptions : [],
                customSearch: customSearch ?? false,
                sortingBy,
                reIndex,
                searchQuery,
                customFilter,
                setCustomFilter: filterDataByCustomFilter,
                setSearchQuery,
                setSortingBy,
                setSearchProps,
                updateRowByProp,
                next,
                prev,
                goToPage
            }}
        >
            {children}
        </PaginationContext.Provider>
    );
};

export const PageConsumer = PaginationContext.Consumer;
export const usePagination = () => useContext(PaginationContext);

/**
 *  Pagination search box
 */
export const PaginationSearchBox: React.FC<{}> = () => {
    const { searchQuery, setSearchQuery, allowSort } = usePagination();
    const isSmallScreen = useMedia({ query: GLOBAL_BREAK_POINTS.SM });

    const resetSearchBox = () => {
        setSearchQuery(null);
    };

    return (
        <Flex
            justifyContent={allowSort && !isSmallScreen ? "flex-end" : "center"}
            alignItems="center"
            borderRadius="0.5rem"
            marginBottom="1rem"
            height="2.5rem"
        >
            <InputGroup boxShadow="lg" width={isSmallScreen ? "100%" : "max-content"}>
                <InputLeftElement>
                    <Box as={HiOutlineSearch} color="gray.900" m={2} />
                </InputLeftElement>
                <Input
                    placeholder="Sök..."
                    onChange={(e: React.FormEvent<HTMLInputElement>) =>
                        setSearchQuery(e.currentTarget.value.toUpperCase())
                    }
                    border="0"
                    value={searchQuery ?? ""}
                    _focus={{ border: "0", margin: "0" }}
                />
                <InputRightElement backgroundColor="gray.100" cursor="pointer" onClick={resetSearchBox}>
                    <Box as={ImCross} background="gray.200" fontSize="0.8rem" color="gray.700" />
                </InputRightElement>
            </InputGroup>
        </Flex>
    );
};

export const PaginationSort: React.FC<{}> = () => {
    const { sortOptions, setSortingBy } = usePagination();
    const isSmallScreen = useMedia({ query: GLOBAL_BREAK_POINTS.SM });

    let defaultValue = "";
    if (sortOptions && sortOptions.length) {
        defaultValue = sortOptions?.find((value: PaginationSortOptions) => value.default)?.text ?? sortOptions[0].text;
    }

    if (!sortOptions?.length) {
        return null;
    }

    return (
        <Flex justifyContent={!isSmallScreen ? "flex-start" : "center"} marginBottom="1rem" height="auto">
            <Select
                boxShadow="lg"
                borderRadius="lg"
                width={isSmallScreen ? "100%" : "max-content"}
                lineHeight="2rem"
                height="2.5rem"
                onChange={(e: React.FormEvent<any>) => {
                    const option =
                        sortOptions?.find((value: PaginationSortOptions) => value.text === e.currentTarget.value) ??
                        null;
                    setSortingBy(option);
                }}
                defaultValue={defaultValue}
            >
                {sortOptions?.map((value: PaginationSortOptions, index: number) => {
                    return (
                        <option key={index} value={value.text}>
                            {value.text}
                        </option>
                    );
                })}
            </Select>
        </Flex>
    );
};

type CurrentPageProps = {
    renderItem: (data: any, index: number) => JSX.Element;
    sortTitles?: string[];
    wrapperProps?: BaseBoxProps;
};

/**
 * Current page goes in the pagination main component as a child.
 * However you send in the render item as an element on how to render the data (mapped).
 * If you have allowed search the search box appears above the items.
 * BoxProps are for the Box around the items.
 * @param {Element} renderItem pass in element to be mapped to
 * @returns contents of the items to be displayed (dependant on choosen page)
 */
const CurrentPage: React.FC<CurrentPageProps & BaseBoxProps> = ({ renderItem, sortTitles, wrapperProps, ...rest }) => {
    const { currentData, reIndex, itemsPerPage, currentPage, allowSearch, customSearch, allowSort } = usePagination();
    const isSmallScreen = useMedia({ query: GLOBAL_BREAK_POINTS.SM });
    const hasSearchAndSort = allowSearch && allowSort;
    const displaySearch = !hasSearchAndSort && allowSearch && !customSearch;

    const getCurrentData = () => {
        const begin = (currentPage - 1) * itemsPerPage;
        const end = begin + itemsPerPage;
        return currentData?.slice(begin, end);
    };

    const onPageChange = useMemo(() => {
        return getCurrentData();
    }, [currentPage, reIndex]);

    if (!renderItem) {
        return null;
    }

    const valuesRendered = onPageChange?.map(renderItem) ?? [];

    return (
        <Flex direction="column" justifyContent="space-between" alignItems="center" {...wrapperProps}>
            {hasSearchAndSort && (
                <Grid
                    templateColumns={isSmallScreen ? "1fr" : "1fr 1fr"}
                    gap="0.5rem"
                    height="max-content"
                    style={{
                        height: isSmallScreen ? "5rem" : "2.5rem",
                        marginBottom: isSmallScreen ? "3rem" : "1rem",
                        padding: "0 1.3rem 0 1.3rem"
                    }}
                >
                    <PaginationSearchBox />
                    <PaginationSort />
                </Grid>
            )}
            {displaySearch && <PaginationSearchBox />}
            {!hasSearchAndSort && allowSort && <PaginationSort />}
            <PseudoBox {...rest}>{valuesRendered}</PseudoBox>
        </Flex>
    );
};

interface PageDataFunctionProps<T> {
    pageData: T[] | undefined;
    updateRowByProp: UpdateRowByProp;
}

type UpdateRowByProp = <T, Prop extends keyof T>(value: T, prop: Prop) => void;

/**
 * Current Page data bring back the current data of the page
 * {({pageData}) => { return ()}}
 * @param children
 * @returns child as a function
 */
const CurrentPageData: <T>(props: {
    children?: ReactNode | ((props: PageDataFunctionProps<T>) => React.ReactNode) | undefined;
}) => JSX.Element = ({ children }) => {
    const { currentData, reIndex, itemsPerPage, currentPage, updateRowByProp } = usePagination();

    const getCurrentData = () => {
        const begin = (currentPage - 1) * itemsPerPage;
        const end = begin + itemsPerPage;
        return currentData?.slice(begin, end);
    };

    const onPageChange = useMemo(() => {
        return getCurrentData();
    }, [currentPage, reIndex]);

    return typeof children === "function"
        ? (children as (props: PageDataFunctionProps<any>) => React.ReactNode)({
              pageData: onPageChange,
              updateRowByProp: updateRowByProp
          })
        : (children as any);
};

type PageButtonTheme = {
    [THEMEPLACES.WRAPPER]?: BaseBoxProps;
    [THEMEPLACES.BUTTONS]?: Omit<NewIconButtonProps, "icon">;
    [THEMEPLACES.PAGENUMBERS]?: ButtonProps;
    [THEMEPLACES.HOVERCOLOUR]?: string;
};

enum THEMEPLACES {
    WRAPPER = "WRAPPER",
    BUTTONS = "BUTTONS",
    PAGENUMBERS = "PAGENUMBERS",
    HOVERCOLOUR = "HOVERCOLOUR"
}

/**
 * Page buttons can show what you choose usefull for changing in mobile views
 * @param {number} showMaxPage number to show in the page component
 * @returns
 */
const PageButtons: React.FC<{ showMaxPage?: number; pageButtonTheme?: PageButtonTheme }> = ({
    showMaxPage = 10,
    pageButtonTheme
}) => {
    const { currentPage, maxPage, next, prev, goToPage } = usePagination();

    const pageContent = () => {
        let totalShowingPages = showMaxPage;
        if (showMaxPage > maxPage) {
            totalShowingPages = maxPage;
        }
        const start = currentPage - Math.floor(totalShowingPages / 2);
        const startMx = Math.max(start, 1);
        const startMin = Math.min(startMx, 1 + maxPage - totalShowingPages);

        return Array.from({ length: totalShowingPages }, (_, i) => startMin + i);
    };

    const pageNumbers = pageContent();
    const hoverColour = pageButtonTheme?.HOVERCOLOUR ?? "#EDF2F7";

    return (
        <Flex
            alignItems="center"
            justifyContent="center"
            background="white"
            marginLeft="auto"
            marginRight="auto"
            position="sticky"
            bottom="10px"
            width="max-content"
            zIndex={1100}
            marginTop="1.5rem"
            borderRadius="0.5rem"
            boxShadow="xl"
            height="auto"
            {...(pageButtonTheme?.WRAPPER && { ...pageButtonTheme.WRAPPER })}
        >
            <IconButton
                as={HiOutlineChevronLeft}
                _hover={{ background: hoverColour }}
                style={{ cursor: "pointer" }}
                background="none"
                border="none"
                color="newPrimary"
                onClick={prev}
                width="1.5rem"
                height="1.5rem"
                marginLeft="0.5rem"
                {...(pageButtonTheme?.BUTTONS && { ...pageButtonTheme.BUTTONS })}
            />

            {pageNumbers?.map((value: number) => {
                return (
                    <Button
                        key={value}
                        _hover={{ background: hoverColour }}
                        onClick={() => goToPage(value)}
                        background="none"
                        style={{ cursor: "pointer" }}
                        color={currentPage === value ? "blackAlpha" : "gray.400"}
                        fontSize="1rem"
                        {...(pageButtonTheme?.PAGENUMBERS && { ...pageButtonTheme.PAGENUMBERS })}
                    >
                        {value}
                    </Button>
                );
            })}

            <IconButton
                as={HiOutlineChevronRight}
                _hover={{ background: hoverColour }}
                style={{ cursor: "pointer" }}
                background="none"
                color="newPrimary"
                value="next"
                onClick={next}
                width="1.5rem"
                height="1.5rem"
                marginRight="0.5rem"
                {...(pageButtonTheme?.BUTTONS && { ...pageButtonTheme.BUTTONS })}
            />
        </Flex>
    );
};

export { Pagination, CurrentPage, PageButtons, CurrentPageData };
export type { SortOptions, PaginationSortOptions, PageButtonTheme };
export { DIRECTION };
