import { isDarkColor, string2Hex } from "Utils";
import { useTheme } from "Providers";
import { AvatarSizes } from ".";

export const avatarSizes = {
    "2xs": 4,
    xs: 6,
    sm: 8,
    md: 12,
    lg: 16,
    xl: 24,
    "2xl": 32,
    full: "full"
};

type AvatarStyle = {
    hasImage: boolean;
    size: AvatarSizes;
    name?: string;
};

export const useAvatarStyles = ({ hasImage, size, name }: AvatarStyle) => {
    const theme = useTheme();

    const bg = name ? string2Hex(name) : theme.colors.gray[400];
    const color = name ? (isDarkColor(bg) ? "#fff" : "gray.800") : "#fff";

    const baseProps = {
        display: "inline-flex",
        rounded: "full",
        alignItems: "center",
        flexShrink: "0",
        justifyContent: "center",
        position: "relative"
    };

    if (hasImage) {
        return {
            ...baseProps,
            width: `95%`,
            height: `95%`
        };
    }

    return {
        ...baseProps,
        size: avatarSizes[size],
        bg,
        color
    };
};
